import React from 'react';

export default function ToastErrorIcon() {
    return (
        <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="21.5" cy="21.5" r="21.5" fill="white" />
            <path d="M31.6765 29.0897L22.6334 13.4259C22.4806 13.1624 22.1996 13 21.8958 13C21.5909 13 21.3104 13.1624 21.1576 13.4259L12.1146 29.0897C11.9618 29.3532 11.9618 29.6779 12.1146 29.9414C12.2673 30.2049 12.5478 30.3673 12.8522 30.3673H30.9389C31.2426 30.3673 31.5243 30.2049 31.677 29.9414C31.8286 29.6779 31.8286 29.3532 31.6765 29.0897ZM14.3273 28.6638L21.8952 15.5552L29.4637 28.6638H14.3273ZM20.7596 18.4187H23.0309V25.1377H20.7596V18.4187ZM20.7596 26.2716H23.0309V27.9291H20.7596V26.2716Z" fill="#D66982" />
        </svg>
    )
}

