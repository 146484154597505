import { legacy_createStore as createStore, applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk';
import reducer from './reducers';
import { composeWithDevTools } from '@redux-devtools/extension';

//to remove debuger removed the composeWithDevTools functions
const configureStore = () => {
    let store;
    if (process.env.NODE_ENV === 'development') {
        store = createStore(reducer, {}, composeWithDevTools(applyMiddleware(thunk)));
    } else {
        store = createStore(reducer, {}, applyMiddleware(thunk));
    }
    return store;
};
export const store = configureStore();
export type DispatchType = typeof store.dispatch;
export interface AppSettingsState {
    data_loaded: boolean,
    valid_domains: Array<{ id: string, text: string }>,
    environment?: string,
    logo: {
        logo: string,
        logoAlt: string,
        light: string,
        dark:string,
        system_light: string,
        system_dark: string,
        system_logo_alt: string
    },
    email_header: {
        email_header_logo: string,
        email_header_logo_alt: string
    },
    sendrid_dynamic_templates: Array<{
        group_id: number,
        isSelected: boolean,
        name: string,
        template_id: string
    }>,
    federated_key_templates: Array<{
        group_id: number,
        isSelected: boolean,
        name: string,
        template_id: string
    }>,
    feature_config: Array<any>,
    landing_page_ui_config: Array<LandingPageTemplateItem>,
    chat_configuration: Array<string>,
    ppt_templates: Array<PptTemplateProps>,
    ppt_template_last_modified?: string,
    tab_main_heading?: string,
    search_ui: {
        showAdvance: boolean,
        advanceFields?: string[]
    },
    group_list: any,
    users_list: any,
    loadingSpinner: boolean,
    answer_variance_threshold: {
        min: number,
        max: number
    },
    workflow_types: string[],
    templates_list: Array<{
        name: string,
        is_default_template?: boolean,
        _id: string,
    }>,
    customer_notice: string
    show_customer_notice: boolean;
    customer_notice_dismissed: boolean;
    home_page_config: {
        home_page: string;
        is_quicklink_icon_visible: boolean;
    };
}

export type PptTemplateProps =
    {
        id?: string,
        active: boolean,
        name: string,
        type: string,
        visualType: string,
        layout?: string | null,
        groups?: boolean | null,
        file?: {
            url?: string,
            name?: string,
            source?: File,
            s3_path?: string,
        } | null
    }

export type SearchUIProps =
    {
        search_ui: {
            advanceFields?: string[],
        }
    }

export type UserListFromGroup = {
    email: string;
    name: string;
    user_id: string;
};
export type TemplateModuleOption = {
    isSelected: boolean,
    user: string,
}
export type TemplateModuleItem = {
    feature: string,
    options: Array<TemplateModuleOption>
    isEnabled?: boolean,
}
export interface LandingPageTemplateItem extends TemplateModuleItem {
    modules_availability: Array<TemplateModuleItem>
}

export type chatSuggestions = {
    id: string,
    text: string,
}