import fetchIntercept from 'fetch-intercept';

const fromIntercepter = true;

export const fetchInterceptor = (getServersStatus:any) => {

  fetchIntercept.register({
    request: function (url, config) {
      // Modify the url or config here
      // config.headers.name = 'example';
      return [url, config];
    },

    requestError: function (error) {
      // Called when an error occured during another 'request' interceptor call
      return Promise.reject(error);
    },

    response: function (response) {
      // Modify the reponse object
      if(response.status == 500) {
        getServersStatus(fromIntercepter)
      }
      return response;
    },

    responseError: function (error) {
      // Handle an fetch error
      return Promise.reject(error);
    },
  });
};
