export const reportTableColors = {
    dark:{
        ReportTableHeadingColor: '#fff',
        ReportHeaderBackground: '#323346',
        ReportInfoContainerOuterBackground: '#1b1c31',
        ReportEachContainerBackgroundColor: '#1B1C31',
        ReportTableRowColorFirst: '#818192',
        ReportTableRowColorSecond: '#5D5E6D',
        ReportTableRowTextColor: '#FFF',
        EditorHeading:`#fff`,
        BorderTopEditor:`#51526C`,
        EditorLabel:`#fff`,
        SearchReviewBg:`#323346`,
        SearchReviewBorder:`#454557`,
        EntitySearchBorder:`#454557`,
        EntityTypeBackground:`#323346`,
        EntityTextColor:`#fff`,
        ReviewHeading: `#fff`,
        ReviewInputText:`#fff`,
        EntityReviewBorderMain: `#454557`,
        EntityReviewTabBackground: `#1B1C31`,
        ActiveEntityReviewTabBackground: `#323346`,
        EntityBorderBottomTab:`#454557`,
        EntityTypeBackgroundEdit:  `#1B1C31`,
        ActionBarBorderColorEdit:`#454557`,
        FieldTypeBackgroundEdit:`#38394B`,
        FieldBorderColorEdit:`#454557`,
        SpinnerColorInTableSearch:"#fff",
        EditorSideBarBackground:`#212B36`,
        EditorSideBarDropDownBackground:`#000000`,
        EditorSideBarDropDownBG:"#444658",
        EditorSideBarDropDownBorder:`#3B3D5A`,
        EditorSideBarDropDownBorderColor:`#6c6c6c`,
        EditorSideBarDropDownTextColor:`#FFFFFF`,
    },
    light:{
        ReportTableHeadingColor: '#000',
        ReportHeaderBackground: '#E8E8E8',
        ReportInfoContainerOuterBackground: '#F5F3F3',
        ReportEachContainerBackgroundColor: '#F8F8F8',
        ReportTableRowColorFirst: '#EFF0F4',
        ReportTableRowColorSecond: '#FFFFFF',
        ReportTableRowTextColor: '#828282',
        EditorHeading:`#828282`,
        BorderTopEditor:`#E8E8E8`,
        EditorLabel:`#828282`,
        SearchReviewBg:`#E8E8E8`,
        SearchReviewBorder:`#DDDDDD`,
        EntitySearchBorder:`#DDDDDD`,
        EntityTypeBackground:`#F6F6F6`,
        EntityTextColor:`#828282`,
        ReviewHeading: `#828282`,
        ReviewInputText:`#828282`,
        EntityReviewBorderMain: `#DDDDDD`,
        EntityReviewTabBackground: `#DDDDDD`,
        ActiveEntityReviewTabBackground: `#DDDDDD`,
        EntityBorderBottomTab:`#DDDDDD`,
        EntityTypeBackgroundEdit:  `#FFFFFF`,
        ActionBarBorderColorEdit:`#DDDDDD`,
        FieldTypeBackgroundEdit:`#F6F6F6`,
        FieldBorderColorEdit:`#DDDDDD`,
        EditorSideBarBackground:`#fff`,
        EditorSideBarDropDownBackground:`#fff`,
        EditorSideBarDropDownBorder:`#dddddd`,
        EditorSideBarDropDownBorderColor:`#dddddd`,
        EditorSideBarDropDownTextColor:`#333333`,
    },
}