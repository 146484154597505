import { Colors } from "style/color";

export const auditLogsColors = {
    dark:{
        AuditBtnSpinner: '#828282',
        AuditMessage: Colors.WHITE,
        WebHookInputPlaceholderColor: Colors.WHITE,
        AuditlogsDropdowntext: "#dedede",
        AuditlogsDropdownBorder: "#4d4d4d",
        inputTextBackground: "#1b1c31",
    },
    light:{
        AuditBtnSpinner: Colors.WHITE,
        AuditMessage: Colors.BLACK,
        WebhookbgColor: Colors.WHITE,
        WebHookInputPlaceholderColor: Colors.BLACK,
        AuditlogsDropdowntext : "#333333",
        AuditlogsDropdownBorder:"#b4b4b4",
        inputTextBackground: Colors.WHITE,
    },
}