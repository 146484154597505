export const subscribedQuestionCalenderColors = {
    dark:{
        TimeLineCalendarBgColor:"#1B1C31",
        CalendarBgColor:"#323346",
        CalendarTextColor:"#fff",
        CalenderPointerArrowBorder: "#454557",
        CalenderPointerArrow:"#1b1c32 ",
        CalendarNotHighlightDatesBgColor:"#323346",
        CalendarNotHighlightDatesTextColor:"#fff",
        CalendarHighlightDatesBgColor:"#A4CD3C",
        CalendarBordarColor:"#454557",
        CalendarArrowButtonColor:"#828282",
        CalendarArrowHoverBGColor:"#454658",
        CalendarNoChangeDateBgColor:"#007BA2",
        SnapshotListBgColor:"#323346",
        SnapshotListBorderColor:"#454557",
        SnapshotHeaderTextColor:"#454557",
        ChangedSnapshotTextColor:"#A4CD3C",
        UnchangedSnapshotTextColor:"#51CDFF",
        ActiveDateSnapshotBorderColor:"#fff",
        CompareBgColor:"#1b1c31",
        CompareHeaderTextColor:"#fff",
        CompareChartBgColor:"#454557",
        CompareChartBordercolor:"#454557",
    },
    light:{
        TimeLineCalendarBgColor:"#fff",
        CalendarBgColor:"#fff",
        CalendarTextColor:"#828282",
        CalenderPointerArrow:"#FFF",
        CalenderPointerArrowBorder: "#E3E3E3",
        CalendarNotHighlightDatesBgColor:"#f1f3f4",
        CalendarNotHighlightDatesTextColor:"#828282",
        CalendarHighlightDatesBgColor:"#A4CD3C",
        CalendarBordarColor:"#E3E3E3",
        CalendarArrowButtonColor:"#828282",
        CalendarArrowHoverBGColor:"#dcebee",
        CalendarNoChangeDateBgColor:"#007BA2",
        SnapshotListBgColor:"#f6f6f6",
        SnapshotListBorderColor:"#E3E3E3",
        SnapshotHeaderTextColor:"#828282",
        ChangedSnapshotTextColor:"#A4CD3C",
        UnchangedSnapshotTextColor:"#51CDFF",
        ActiveDateSnapshotBorderColor:"#454557",
        CompareBgColor:"#fff",
        CompareHeaderTextColor:"#828282",
        CompareChartBgColor:"#fff",
        CompareChartBordercolor:"#E3E3E3",
    },
}