import { post, Response } from "makeRequest";


export type newCommentType = {
    record_unique_key:string;
    control_value: string;
    utterance_id: string;
    control_type: string;
    cellColumnId?:string;
}

export function addAnswerComment(data: newCommentType): Response<any> {
    return post("/add-row-feedback", data);
  }