import { get, post, Response } from "makeRequest";

import {
  Splat,
  SerializedSplat,
  SplatSortKey,
  UtteranceStatus,
} from "types/utterance";

type GetSplatsResponse = {
  splats: SplatsPayload
  done: boolean;
};

type SplatsPayload = {
  splats: Array<SerializedSplat>;
  csv: Array<any>
}

export function deserializeSplats(
  splats: Array<SerializedSplat>
): Array<Splat> {
  return splats.map((splat) => ({
    ...splat,
    created: new Date(splat.created),
  }));
}

type GetSplatsParams = {
  status?: UtteranceStatus;
  lastCreated?: number | null;
  sortKey: SplatSortKey;
  sortOrder: -1 | 1;
  page_no: number;
};

export function getSplats({
  sortKey,
  sortOrder,
  status,
  lastCreated,
  page_no
}: GetSplatsParams): Response<GetSplatsResponse> {
  const params = new URLSearchParams();

  params.append("sortKey", sortKey);
  params.append("sortOrder", String(sortOrder));

  if (typeof status === "number") {
    params.append("status", String(status));
  }

  if (lastCreated) {
    params.append("lastCreated", String(lastCreated));
  }

  return get(`/splat?${params.toString()}&page=${page_no}&limit=100`);

}
type commonResponse = {
    error?:string,
    message?:string,
};
export function reprocessSplats():Response<commonResponse> {
  return post(`/splat/reprocess`, {});
}

type ReprocessSingleSplatResponse = {
  prevStatus: UtteranceStatus;
  status: UtteranceStatus;
  error: string;
};

export function reprocessSingleSplat(
  splatId: string
): Response<ReprocessSingleSplatResponse> {
  return post(`/splat/reprocess/${splatId}`, {});
}

export function notifyHuma():Response<commonResponse> {
  return post("/splat/notify-huma", {});
}

export function notify():Response<commonResponse> {
  return post("/splat/notify", {});
}
