import { Colors } from "style/color"

export const answerPieChartColor = {
    dark:{
        AnswerPieChartBG: "#1B1C31",
        AnswerPieChartBGColumn: "#000000",
        AnswerPieTextColor: Colors.WHITE,
        ExportIconBG: "#1b1c31",
        AnalyzerDashboardBG: "#1b1c31",
        NewTableTextBackground: "#1b1c32",
        FeedBackBorder: "#454557",
        LinkTextColor: Colors.LINK_TEXT,
    },
    light:{
        AnswerPieChartBG: "#E1E1E1",
        AnswerPieChartBGColumn: Colors.WHITE,
        AnswerPieTextColor: Colors.DARK_CHARCOAL,
        ExportIconBG: "#c4c4c4",
        AnalyzerDashboardBG: Colors.WHITE,
        NewTableTextBackground: Colors.WHITE,
        ExpandBorderColor: "#454557",
        FeedBackBorder: "#cbcbcb",
        LinkTextColor: Colors.AZUL,
    },
}