
export const batchScreenColors = {
    dark:{
        updateBtnTextColor:'#ffffff',
        lockedDeleteIconBgColor:'#434343',
        lockedDeleteIconColor:'#ffffff',
        DropdownItemBgColor:'#4a4a4a',
        BatchActivityContextBorderColor:'#383838',
        BatchActivityContextLabelColor:'#ffffff',
        selectContainerBgColor:'#4a4a4a',
        selectContainerBorderColor:'#ffffff4a',
        EmailSuggestionsBgColor:'#4a4a4a',
        emailSuggestionsTextColor:'#c5bcbc',
        circleSpanBorderColor:'#828282',
        CancelBtnBorderColor:'#7272724D',
        SchedulerOptionsDropdownBgColor:'#454758',
        SchedulerOptionsDropdownBorderColor:'#6c6c6c',
    },
    light:{
        updateBtnTextColor:'#2C2C2C',
        lockedDeleteIconBgColor:'#c7c7c7bd',
        lockedDeleteIconColor:'#727272',
        DropdownItemBgColor:'#fff',
        BatchActivityContextBorderColor:'#D1D5DB',
        BatchActivityContextLabelColor:'#525264',
        selectContainerBgColor:'#fff',
        selectContainerBorderColor:'#D9D9D9',
        EmailSuggestionsBgColor:'#ffffff',
        emailSuggestionsTextColor:'#666',
        circleSpanBorderColor:'#e0e0e0',
        CancelBtnBorderColor:'#727272',
        SchedulerOptionsDropdownBgColor:'#ffffff',
        SchedulerOptionsDropdownBorderColor:'#D9D9D9',
    },
}
