import { get, post, Response } from "makeRequest";
import { SubscriptionData } from "types/subscription";
import { AnswerStatus } from "types/userHistory";
import { Visual } from "types/visual";

export type AnswerResponse = {
  utteranceId: string;
  utterance: string;
  subscribed: boolean;
  fetch_history?: Array<string>;
  time_stamp?: number;
  visual: Visual;
  status: AnswerStatus;
  event?: string[];
};

export type SubscriptionList = {
  body: Array<ArrayResponse>;
  status: number;
};
export type ArrayResponse = {
  question: string;
  utteranceId: string;
};

export type SubscribeQuestionResponse = {
  Id: string;
  utterance: string;
  thumbnail: string;
};

export type unsubscribeQuestionResponse = {
  body: {
    utterance: string;
  };
  status: number;
};

export type SubscriptionAlertType = {
  id?:string,
  subscription_id?:string,
  subscription_date: string,
  subscribed_user_timestamp: number,
  subscription_timestamp: number,
  schedule_type: string,
  file_type: Array<string>,
  utterance?: string,
  utterance_id: string,
  thumbnail?:string,
}

//subscribeQuestion
export function subscribeQuestion(
  utterance: string,
  visual_id: string | undefined
): Response<SubscribeQuestionResponse> {
  return post("/subscribe-utterance", { utterance, visual_id });
}

export function unSubscribeQuestion(
  Id: string,
  subscription_type?:string
): Response<unsubscribeQuestionResponse> {
  return post("/unsubscribe-utterance", { Id , subscription_type});
}

export function getSubscribedQuestions(): Response<SubscriptionData> {
  return get("/fetch-subscribed-questions");
}

export function getSubscribedQuestionvisual(): Response<any> {
  return get("/user-subscribed-questions");
}
export function fetchSubscribeQuestion(subscribed_id: string,type?:string): Response<any> {
  return get(`/subscribed-visual?subscribed_id=${subscribed_id}${(type)?`&type=${type}`:''}` );
}
export function fetchSubscribeQuestionMonthDates(
  question: string,
  month: string
): Response<any> {
  return post("/get-timestamp", {
    utterance: question,
    date: month,
  });
}

export function getSelectedQuestion(_id: string): Response<any> {
  return post("/fetch-previous-history", { id: _id });
}


export type SearchFilters = {
  searchValue:string,
  sortKey:string
}
export function getSearchResults({searchValue,sortKey}:SearchFilters):Response<SearchFilters>{
  return get(`/fetch-subscribed-questions?search_string=${searchValue}&order_key=${sortKey}`);
}

export function addSubscriptionAlert(payloadData: SubscriptionAlertType): Response<{subscription: SubscriptionAlertType, error: string,message:string,status:boolean}> {
  return post("/add-subscription-alert",payloadData);
}
export function updateSubscriptionAlert(payloadData: SubscriptionAlertType): Response<{subscription: SubscriptionAlertType, error: string,message:string,status:boolean}> {
  return post("/update-subscription-alert",payloadData);
}