export const newAnswerVisualColors = {
    dark:{
        AnswerContainerColor:"",
        AnswerTextColor:"#fff",
        AnswerBarVerticalColor: "#454658",
        AnswerLeftBackground:'#323346',
        BarChartColor:'#fff',
        SideSearchBarBackground:'#323346',
        ButtonsWrapperColor:"#2058E8",
        BarLeandsColor: "#fff",
        ActionBarFeedbBarChartBg:"#454658",
        SideBarBorder:"#d3d3d3",
        TableBackgroundColorAnswer: "#1d1d29",
        DataRangeTextColor: "#fff",
        QuickLinkTextLoader:"#fff",
        ArrowCrosssIcons:"#fff",
        DateTextColor:"#fff",
        CrosssIcons:"#fff",
        AdditionalFeedbackColor:"#fff",
        BarFeedbackColorContent:"#fff",
        SelectRadioHeading:"#fff",
        LightDarkModeLoader:"#323346",
        LightDarkModeCenterLoader:"#2d2e40",
        SearchSelectText:"#fff",
        CompareIconBgColor: "#fff",
        closeDebugChatListButtonColor:"#000000",
        featureTemplateTextColor: "#fff",
        ChatErrorBgColor: "#24292f",
        causeTextColor: "#fff",
        ChatErrorKeyColor:"fff",
        ChatErrorValueColor:"#AEAEB0",
        ChatErrorSpanColor:"#fff",
        FeedbackBgColor: "#1B1C31",
        FeedbackBorderColor: "#454557",
        AnalyzerParameterBgColor: "#2f3044",
        SelectedTabBgColor: "#3a3b58",
        DxTabColor: "#fff",
    },
    light:{
        AnswerContainerColor: "#ffffff",
        ButtonsWrapperColor:"#2058E8",
        ButtonsHeadingColor:"#ffffff",
        SpanColor:"#787878",
        ParahColor:"#646464",
        AnswerTextColor:"#323232",
        AnswerBarVerticalColor: '#fff',
        SepratorColor: "#C8C8C8",
        AnswerLeftBackground:'#fff',
        BarChartColor:'#323232',
        SideSearchBarBackground:'#fff',
        BarLeandsColor: "#323232",
        ActionBarFeedbBarChartBg:"#fff",
        SideBarBorder:"#C8C8C8",
        TableBackgroundColorAnswer: "#fff",
        DataRangeTextColor: "#323232",
        QuickLinkTextLoader:"#323232",
        ArrowCrosssIcons:"#323232",
        DateTextColor:"#787878",
        CrosssIcons:"#323232",
        AdditionalFeedbackColor:"#363537",
        BarFeedbackColorContent:"#363537",
        LightDarkModeLoader:"#f5f6f7",
        LightDarkModeCenterLoader:"#efefef",
        SearchSelectText:"#363537",
        TokenExpireTextColor:'#000',
        NewTokenTextColor: 'rgb(101, 109, 118)',
        searchOptionColor: "#f1fafe",
        searchAllResultColor:"1px solid #69bcd9",
        searchAllResultButtonColor:"1px solid #2373bb !important",
        SearchCrossIcon:"#363537",
        ChatMapVisualBgColor: "#fff",
        DashboardTableBgColor: "#e4e4e5",
        CompareIconBgColor: "#828282",
        closeDebugChatListButtonColor:"#000000",
        featureTemplateTextColor: "#525264",
        ChatErrorBgColor: "#e9c7c7",
        causeTextColor: "#000",
        ChatErrorKeyColor:"#000",
        ChatErrorValueColor:"#b70000",
        ChatErrorSpanColor:"#000",
        FeedbackBgColor: "#fff",
        FeedbackBorderColor: "#DCDCDC",
        AnalyzerParameterBgColor: "#fff",
        SelectedTabBgColor: "#e1e1e1",
        DxTabColor: "#000",
    },
}