
export type Alert = {
    type: "error" | "info" | "empty";
    message: string;
  };
  
interface ServiceWorkderState {
    serviceWorkerInitialized: boolean;
    serviceWorkerUpdated: boolean;
    serviceWorkerRegistration: ServiceWorkerRegistration | null;
}

export const initialState: ServiceWorkderState = {
    serviceWorkerInitialized: false,
    serviceWorkerUpdated: false,
    serviceWorkerRegistration: null,
}

const reducer = (state: ServiceWorkderState = initialState, action: any): ServiceWorkderState => {
    switch(action.type) {
        case 'SW_INIT':
            return {
                ...state,
                serviceWorkerInitialized: !state.serviceWorkerInitialized,
            };
        case 'SW_UPDATE':
            let update_available = false;
            if(action.payload.waiting) {
                update_available = true;
            }
            return {
                ...state,
                serviceWorkerUpdated: update_available,
                serviceWorkerRegistration: action.payload,
            };
        default:
        return state;
    }
}

export default reducer;