export const webHookEventsColors = {
    dark:{
        WebhookSerachbordercolor: '#7c7b7b',
        WebhookTextColor: '#fff',
        WebHookbordercolor: '#7c7b7b',
        webhookplaceholdercolor: '#fff',
        WebhookBgColor: '#1b1c32',
        WebhookBorderColor: '#7c7b7b',
        WebHookContentColor: '#fff',
        WebhookSerachbgcolor: '#323346',
        WebhookbgColor: '#1b1c32 ',
        formBgColor:'#323346',
        inputLabelColor:'#fff',
    },
    light:{
        WebhookTextColor: '#000',
        webhookplaceholdercolor: '#969696',
        WebHookContentColor: '#545B64',
        WebhookSerachbordercolor: '#b4b4b4',
        WebHookbordercolor:  '#2373bb',
        WebhookBgColor: '#f1fafe',
        WebhookBorderColor: '#69bcd9',
        WebhookSerachbgcolor: '#fff',
        WebhookbgColor:'#fff',
        formBgColor:'#fff',
        inputLabelColor:'#000',
    },
}