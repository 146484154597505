import { ActionType as TypeAction } from "../action-types";
import { Analyzer } from "types/analyzer";

export const initial_state: Analyzer = {
  workers: [],
  Queue_jobs: [],
  current_tab: "WORKERS",
  config: {
    'business_hrs': {
      'startTime': '',
      'endTime': '',
      'enableBusienssHrs': false,
      'adhocWorkerCount': 0,
      'serialWorkerCount': 0,
      'regularWorkerCount': 0,
      'largeWorkerCount': 0,
      'upWorkerCount': 0,
    },
    'workerPerLogin': {
      'usersPerWorker': 10,
      'usersActivityTime': 10,
      "enableWorkerPerLogin":true,
      'adhocWorkerCount': 0,
      'serialWorkerCount': 0,
      'regularWorkerCount': 0,
      'largeWorkerCount': 0,
      'upWorkerCount': 0,
    },
    'queueMaxWait': {
      'longestItemInQueueRegular': 10,
      'longestItemInQueueLarge': 10,
      'longestItemInQueueUp': 0,
      'longestItemInQueueAdhoc': 0,
      'longestItemInQueueSerial': 0,
      "enableQueueMaxWait":false,
      'adhocWorkerCount': 0,
      'serialWorkerCount': 0,
      'regularWorkerCount': 0,
      'largeWorkerCount': 0,
      'upWorkerCount': 0,
    },
    'queueDepth': {
      'workerPerQueueItemRegular': 10,
      'workerPerQueueItemLarge': 10,
      'workerPerQueueItemUp': 0,
      'workerPerQueueItemAdhoc': 0,
      'workerPerQueueItemSerial': 0,
      "enableQueueDepth":false,
      'adhocWorkerCount': 0,
      'serialWorkerCount':0,
      "regularWorkerCount": 0,
      'largeWorkerCount': 0,
      'upWorkerCount': 0,
    },
    "worker_config" : {
      "regular_worker" : {
          "memory" : 15360,
          "vcpu" : 8,
          "execution_timeout" : 120,
          "idle_timeout" : 0
      },
      "large_worker" : {
          "memory" : 64512,
          "vcpu" : 31,
          "execution_timeout" : 24,
          "idle_timeout" : 60
      },
      "adhoc_worker" : {
          "memory" : 74512,
          "vcpu" : 31,
          "execution_timeout" : 24,
          "idle_timeout" : 60
      },
      "up_worker" : {
          "memory" : 74512,
          "vcpu" : 31,
          "execution_timeout" : 24,
          "idle_timeout" : 60
      },
      "serial_worker" : {
          "memory" : 64512,
          "vcpu" : 35,
          "execution_timeout" : 24,
          "idle_timeout" : 60
      }
    },
    updated_by: "",
  },
  worker_queue_status: {
    "large": {
    "active_workers": 0,
    "queued_messages": 0,
  },
  "regular": {
    "active_workers": 0,
    "queued_messages": 0,
  },
  "adhoc": {
    "active_workers": 0,
    "queued_messages": 0,
  },
  "up": {
    "active_workers": 0,
    "queued_messages": 0,
  },
  "serial": {
    "active_workers": 0,
    "queued_messages": 0,
  }
},
  loading: false,
  isLoading: false,
  error : undefined,
  searchWorkersJobs: '',
  tableSearch: "",
  hasMore: true,
  refresh: false,
  searchDropdownOperator: "and",
  equalsSearchInput : [],
  analyzerEqualsSearchInput: [],
  searchContainsInput: [],
  analyzerSearchContainsInput: [],
  workerCheckbox: [],
  analyzerStatus:'',
  searchTerm: "",
  isSearchInProgress: false,
  headerSuggestions: [],
  rocketSearchOperator: 'and',
  analyzerContainsSearch:[],
  searchSelectedChips:[],
  searchJobsSelectedChips:[],
  terminating: false,
  total_records: 0,
  rebuildIndexLoader: false,
  isDragEnabled:false,
  workersContainsValue:[],
  workersSearchValue:[],
  isAnalyzerExportLoader: false,
  sortingOrder: {columnName: "", sortingOrder: ""},
  duration: "",
};

const reducer = (state: Analyzer = initial_state, action: any): Analyzer => {
  switch (action.type) {
    case TypeAction.UPDATE_ROCKET_ANALYZER_TABLE_SORTING:
      return {
        ...state,
        sortingOrder: {
            columnName: action.payload.columnName,
            sortingOrder: action.payload.sortingOrder
        }
      }
    case TypeAction.IS_DRAG_DROP_ENABLED:
      return { ...state, isDragEnabled: action.payload }
    case TypeAction.ANALYZER_REBUILD_INDEX_LOADER:
      return { ...state, rebuildIndexLoader: action.payload }
    case TypeAction.SHOW_TOTAL_RECORDS_OF_WORKER:
      return { ...state, total_records: action.payload }
    case TypeAction.UPDATE_TERMINATING_STATUS:
      return { ...state, terminating: action.payload }
    case TypeAction.SET_SEARCH_TERM_VALUE:
      return { ...state, searchTerm: action.payload };
    case TypeAction.ANALYZER_REFRESH_BUTTON:
      return {...state, refresh: action.payload};
    case TypeAction.UPDATE_SEARCH_TABLE_HAS_MORE:
      return {...state, tableSearch: action.payload };
    case TypeAction.FETCH_WORKERS_DETAILS:
      return { ...state , workers: action.payload }
    case TypeAction.UPDATE_WORKERS_HAS_MORE:
      return { ...state , hasMore: action.payload}
    case TypeAction.FETCH_JOBS_DETAILS:
      return { ...state, Queue_jobs: action.payload };
    case TypeAction.SET_LOADING_ELASTIC_SEARCH:
      return {...state, isLoading: action.payload};
    case TypeAction.FETCH_WORKER_BY_STATUS:
      return {...state, workers: action.payload};
    case TypeAction.FETCH_JOBS_BY_STATUS:
      return {...state, Queue_jobs: action.payload};
    case TypeAction.CURRENT_TAB:
      return { ...state, current_tab: action.payload };
    case TypeAction.WORKER_QUEUE_STATUS:
      return { ...state, worker_queue_status: action.payload };
    case TypeAction.FETCH_SQS_WORKER_CONFIG:
      return { ...state, config: action.payload };
    case TypeAction.SET_LOADING_WORKERS_JOBS:
      return { ...state, loading: action.payload };
    case TypeAction.SET_ERROR_WORKERS_JOBS:
      return { ...state, error: action.payload };
    case TypeAction.SEARCH_WORKERS_JOBS:
      return { ...state, searchWorkersJobs: action.payload };
    case TypeAction.SEARCH_DROPDOWN_OPERATOR:
      return { ...state, searchDropdownOperator: action.payload };
    case TypeAction.EQUALS_SEARCH_QUERY:
        return { ...state, equalsSearchInput: action.payload };
    case TypeAction.EQUALS_SEARCH_QUERY_FOR_ANALYZER:
        return { ...state, analyzerEqualsSearchInput: action.payload };
    case TypeAction.CONTAINS_SEARCH_QUERY:
      return { ...state, searchContainsInput: action.payload };
    case TypeAction.CONTAINS_SEARCH_QUERY_FOR_ANALYZER:
      return { ...state, analyzerSearchContainsInput: action.payload };
    case TypeAction.SET_WORKER_CHECKBOX:
      return { ...state , workerCheckbox: action.payload}
    case TypeAction.SET_WORKER_STATUS:
      return {...state, analyzerStatus: action.payload};
    case TypeAction.FETCH_WORKER_BY_ELASTIC_SEARCH:
      return {...state, workers: action.payload};
    case TypeAction.FETCH_JOBS_BY_ELASTIC_SEARCH:
      return {...state, Queue_jobs: action.payload};
    case TypeAction.SET_ROCKET_ANALYZER_SEARCH_IN_PROGRESS:
      return {...state, isSearchInProgress: action.payload};
    case TypeAction.SET_ROCKET_SEARCH_OPERATOR:
      return {...state, rocketSearchOperator: action.payload};
    case TypeAction.UPDATE_HEADER_SUGGESTIONS:
      return {...state, headerSuggestions: action.payload};
    case TypeAction.ANALYZER_CONTAINS_SEARCH:
      return {...state, analyzerContainsSearch: action.payload};
    case TypeAction.ANALYZER_SEARCH_SELECTED_CHIPS:
    return {...state, searchSelectedChips: action.payload};
    case TypeAction.ANALYZER_JOBS_SEARCH_SELECTED_CHIPS:
      return {...state, searchJobsSelectedChips: action.payload};
    case TypeAction.SET_WORKERS_SEARCH_VALUE:
      return { ...state, workersSearchValue: action.payload };
    case TypeAction.SET_WORKERS_CONTAIN_VALUE:
      return { ...state, workersContainsValue: action.payload };
    case TypeAction.UPDATE_ROCKET_ANALYZER_EXPORT_LOADER:
      return { ...state, isAnalyzerExportLoader: action.payload }
    case TypeAction.SHOW_SEARCH_DURATION:
      return { ...state, duration: action.payload }
    default:
      return state;
  }
};

export default reducer;
