import styled from "styled-components";

export const AppContainer = styled.div`
position: relative;
height: 100%;
.overlay{
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100vh;
  background: #c3c3c3;
  width: 100%;
  opacity: 0.3;
  z-index: 9999;
}
.closeSideBar {
  .sidebarContainer{
    margin-left: -250px;
    svg{
      cursor:pointer;
    }
  }
  .layoutContainer{
    margin-left: 0px;
    .openIcon{
      opacity: 1;
      display: flex;
      cursor: pointer;
    }
  }
  .chat_input_outer{
    margin-left: 0;
  }
}
.sidebarContainer{
    svg{
      cursor:pointer;
    }
  }
`;

export const PageWrapper = styled.div`
  display: block;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  background-color: ${({ theme }) => theme.topHeaderBackground};
  .pipeline_table_outer {
    margin-top: 50px;
    padding: 10px 60px;
    table{
      border: 1px solid #dee2e6;
      tbody{
        td{
          background-color: #f3f9ff;
          vertical-align:middle;
          .run_btn{
            cursor:pointer;
          }
          .run_btn:hover{
              svg{
                fill:#2058e8;
              }
              span{
                color:#2058e8;
              }
          }
        }
      }
    }
  }
`;

export const QAPageWrapper = styled.div<{ isOpen: boolean }>`
  display: flex;
  box-sizing: border-box;
  position: relative;
  height: 100%;
  display: block;
  background-color: ${({ theme }) => theme.WorkflowWrapperBackgroundColor};
  transition: width,left,right,.3s;
`;

export const RecentHistoryLoader = styled.div`
  display: grid;
  position: relative;
  flex-wrap: wrap;
  justify-items: center;
  width: inherit;
  padding: 20px 30px;
  width: 96%;
  margin: 0 auto;
  background: ${({ theme }) => theme.AllPageWrapperBackgroundColor};
`;

export const OuterContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    grid-auto-flow: column;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    .forAnswerPadding{
      padding:0px 20px;
      height:100%;
    }
`;

export const MainContainer = styled.main`
flex-grow: 1;
display: flex;
flex-direction: column;
overflow-y: auto;
grid-auto-flow: column;
margin: 0px;
overflow-x:hidden;
`;
export const LeftNavigationContainer = styled.main`
    display: flex;
    overflow-y: auto;
    grid-auto-flow: column;
    height:100%;
`;

export const OnTableSearchSpinner = styled.div`
border: 2px solid ${({ theme }) => theme.SpinnerColorInTableSearch};
border-bottom: 2px solid transparent;
border-radius: 50%;
width: 25px;
height: 25px;
-webkit-animation: spin 1s linear infinite; /* Safari */
animation: spin 1s linear infinite;
`;

export const TagBoxWrapper = styled.div`
.dx-list-item.dx-state-hover {
    background-color: rgba(0,0,0,.04);
    color: red;
}
`;

export const Wrapper = styled.div`
  height: --webkit-fill-available;
  flex-wrap: wrap;
  justify-items: center;
`;