import { ActionType } from '../action-types'
import { SplatState } from 'types/splat'


export const initialState: SplatState = {
    loading: false,
    done: false,
    isReprocessingAll: false,
    filter: "ALL",
    isReprocessing: [],
    data: [],
    csv: [],
    lastCreated: null,
    sort: {
      key: "REPORTED",
      order: -1,
    },
    refresh: true,
    hasMore: true,
    splatRecordLoading: false,
    searchTerm: '',
}

const reducer = (state: SplatState = initialState, action: any): SplatState => {
    switch(action.type) {
        case ActionType.FETCH_SPLATS_COMPLETED:
            return { ...state , data: action.payload, csv: action.csv, refresh: false }
        case ActionType.UPDATE_SPLAT_COMPLETED:
            return { ...state , data: [...state.data, ...action.payload],
                csv: [
                    [...state.csv[0], ...action.csv[0]],
                    [...state.csv[1], ...action.csv[1]],
                    [...state.csv[2], ...action.csv[2]],
                    [...state.csv[3], ...action.csv[3]],
                ], refresh: false }
        case ActionType.UPDATE_SPLAT_FILTER:
            return { ...state , filter: action.payload }
        case ActionType.SPLAT_REFRESH_START:
            return { ...state , refresh: true }
        case ActionType.SPLAT_REFRESH_COMPLETED:
            return { ...state , data: action.payload, refresh: false }
        case ActionType.SPLAT_REFRESH_FAILED:
            return { ...state , refresh: false }
        case ActionType.UPDATE_SPLATS_STATUS:
            return { ...state , data: state.data.filter((splat) => {
                if(action.payload.splatId == splat.id) {
                    splat.status = action.payload.status
                }
                return splat;
            } ) }
        case ActionType.UPDATE_SPLAT_HAS_MORE:
            return { ...state , hasMore: action.payload}
        case ActionType.SEARCH_SPLATS_TERM:
            return { ...state, searchTerm: action.payload };
        case ActionType.SET_LOADING_SPLAT:
            return { ...state, loading: action.payload}
        case ActionType.SPLAT_RECORD_LOADING:
            return { ...state, splatRecordLoading: action.payload}

        default:
            return state;
    }
}

export default reducer;