import { Dispatch } from "redux";
import { addConnectionRequest, getAllConnection,deleteConnectionRequest, addConnectionRequestUrl } from "services/appConnectionServices";
import { alert } from "./customRouter";

export const getAllConnections = (setConnections: (value: any) => void,setIsLoading: (value: boolean) => void) => {
    return async (dispatch: Dispatch) => {
    try {
        setIsLoading(true);
        const result = await getAllConnection();
        if (result.status === 200) {
            setConnections(result?.body?.connections)
            setIsLoading(false);
        } else {
            dispatch(alert("Error in fetching connection", "error"));
            console.error(
                "Error in fetching connections Api did not return 200 status"
            );
        }
        setIsLoading(false);
        return [];
    } catch (error) {
        console.error("Error in fetching connections");
        setIsLoading(false);
        return [];
    }
}
};

export const addNewConnection = (requestData: any,setConnections: (value: any) => void,setIsLoading: (value: boolean) => void) => {
    return async (dispatch: Dispatch<any>) => {
    try {
        setIsLoading(true);
        const response = await addConnectionRequest(requestData);
        if (response.status === 200) {
            dispatch(alert("Connection added successfully", {} ,"success"));
            dispatch(getAllConnections(setConnections,setIsLoading));
            return response.body;
        } else {
            dispatch(alert(response?.body?.error, "error"));
            console.error(
                "Error in fetching connections Api did not return 200 status"
            );
        }
        return null;
    } catch (error) {
        console.error("Error in fetching connections");
        return null;
    }
    }
};
    export const addNewOnedriveConnectionUrl = (requestData: any) => {
        return async (dispatch: Dispatch<any>) => {
            try {
                const response = await addConnectionRequestUrl(requestData);
                if (response.status === 200) {
                    // dispatch(alert("Connection added successfully", {} ,"success"));
                    // dispatch(getAllConnections(setConnections));
                    return response.body;
                } else {
                    dispatch(alert("Error in adding connection", "error"));
                    console.error(
                        "Error in fetching connections Api did not return 200 status"
                    );
                }
                return null;
            } catch (error) {
                console.error("Error in fetching connections");
                return null;
            }
        }
    };

export const deleteConnection = async(requestData: any) => {

    return async (dispatch: Dispatch) => {
    try {
        const response = await deleteConnectionRequest(requestData);
        if (response.status == 200) {
            dispatch(alert(response.body.message, {} ,"success"));
            return response.body.status;
        } else {
            dispatch(alert("Error in deleting connection", "error"));
            console.error(
                "Error in fetching connections Api did not return 200 status"
            );
        }
        return null;
    } catch (error) {
        console.error("Error in fetching connections");
        return null;
    }
    }
};