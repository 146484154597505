import { ActionType } from '../action-types'

export type Alert = {
    type: "error" | "info" | "empty" | "success" | "warning";
    message: string;
    option: any;
  };

interface CommonState {
    redux_alert: Alert,
    isMenuOpen: boolean ,
    hideLogo: boolean ,
    explanationShown: boolean,
    expandedAnswerComponentIndex: number;
    operationLayerCompareData:Array<any> | undefined;
    isSideBarExpand: boolean;
    portalType: string;

}

export const initialState: CommonState = {
    redux_alert: {
        type: "empty" ,
        message: "",
        option: {}
    },
    isMenuOpen: false ,
    hideLogo: false,
    explanationShown: false,
    expandedAnswerComponentIndex: -1,
    operationLayerCompareData: undefined,
    isSideBarExpand: true,
    portalType: "",
}

const reducer = (state: CommonState = initialState, action: any): CommonState => {
    switch(action.type) {
        case ActionType.REDUX_ALERT_CHANGE:
            const { type, message, option } = action.payload;
            return {
                ...state,
                redux_alert: {
                    type,
                    message,
                    option
                }
            };
        case ActionType.UPDATE_IS_MENU_OPEN:
            return { ...state , isMenuOpen: action.payload }
        case ActionType.UPDATE_HIDE_LOGO:
            return { ...state , hideLogo: action.payload }
        case ActionType.UPDATE_EXPLANATION_SHOWN:
            return { ...state , explanationShown: action.payload }
        case ActionType.SET_EXPANDED_ANSWER_COMPONENT_INDEX:
            return { ...state , expandedAnswerComponentIndex: action.payload }
        case ActionType.OPERATION_LAYER_COMPARE_DATA:
            return { ...state , operationLayerCompareData: action.payload }
        case ActionType.UPDATE_IS_EXPAND_SIDE_BAR:
            return { ...state , isSideBarExpand: action.payload }
        case ActionType.UPDATE_PORTAL_TYPE:
            return { ...state , portalType: action.payload }
        default:
            return state;
    }
}

export default reducer;