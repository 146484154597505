import {ReportConfigEditorState, version} from "types/reportConfig";
import {ActionType} from "../action-types";

export const initialState: ReportConfigEditorState = {
    versions:[],
    current_version :{
        id:"",
        config_nodes:[],
    },
    current_config_nodes: {
        id:"",
        node_intents:[]
    },
    currentTemplate: {
        id:"",
        name:"",
        version:"",
        template_name:"",
        templates:[],
    },
    currentFolder: {
        name: "",
        type: "",
        templates: [],
        files: [],
    },
    isFoldersLoading: false,
    inputData: {
        id:"",
        file_id:"",
        file_name: "",
        file_content: "",
        content_type:"",
        name:"",
    },
    isSaveProcessing: false,
    output: {
        presigned_url:"",
        data:"",
    },
    isOutputLoading: false,
    config_node_data: {},
    hasMore: true,
    spinnerLoader: false,
    isLoading: false,
    isCallingFunction: true,
    reportErrorMessage: {
        error_message: "",
        markdown_error: ""
    },
    json_diff_warning_message: "",
};

const reducer = (state: ReportConfigEditorState = initialState, action: any): ReportConfigEditorState => {
    switch (action.type) {
        case ActionType.SHOW_REPORT_ERROR_MESSAGE:
            return {...state, reportErrorMessage: action.payload }
        case ActionType.UPDATE_ACTION_VALUE:
            return {...state, isCallingFunction: action.payload};
        case ActionType.UPDATE_UP_CONFIG_PAGE_LOADER:
            return {...state, isLoading: action.payload};
        case ActionType.UPDATE_VIEW_MORE_SPINNER:
            return {...state, spinnerLoader: action.payload};
        case ActionType.UPDATE_VERSION_HAS_MORE:
            return {...state, hasMore: action.payload};
        case ActionType.SET_UP_CONFIG_NODES:
            return {...state, versions: state.versions.map((version:version) => {
                if(version.id === action.payload.id){
                    version.up_config = action.payload.up_config;
                }
                return version
            })};
        case ActionType.SET_REPORT_CONFIG_VERSIONS:
            return {...state, versions: action.payload};
        case ActionType.SET_CURRENT_REPORT_CONFIG_VERSION:
            return {...state, current_version: action.payload};
        case ActionType.SET_CURRENT_REPORT_CONFIG_NODES:
            return {...state, current_config_nodes: action.payload};
        case ActionType.SET_EDITOR_REPORT_TEMPLATE:
            return {...state, currentTemplate: action.payload};
        case ActionType.SET_CURRENT_EDITOR_FOLDER:
            return {...state, currentFolder: action.payload};
        case ActionType.UPDATE_FOLDERS_LOADING:
            return {...state, isFoldersLoading: action.payload};
        case ActionType.UPDATE_OUTPUT_LOADING:
            return {...state, isOutputLoading: action.payload};
        case ActionType.UPDATE_SAVE_PROCESSING:
            return {...state, isSaveProcessing: action.payload};
        case ActionType.SET_OUTPUT_DATA:
            return {...state, output: action.payload};
        case ActionType.SET_SELECTED_FILE_DATA:
            return {...state, inputData: action.payload};
        case ActionType.UPDATE_INPUT_FILE_DATA:
            return {
                ...state,
                inputData: {
                    ...state.inputData,
                    file_content: action.payload,
                    is_changed:true,
                },
            };
        case ActionType.SET_JSON_DIFF_WARNING_MESSAGE:
            return {...state, json_diff_warning_message: action.payload};
        default:
            return state;
    }
};

export default reducer;
