export const miscellaneousColors ={
    dark:{
        BackgroundDropdown : '#323346',
        DropdownColor: '#fff',
        SepratorColor: '#ffffff',
        NewTokenHeadingColor:'#ffffff',
        SelectMenuButtonColor:'#007ba2',
        DeleteMenuButtonColor:'rgb(220, 53, 69)',
        DeleteMenuButtonTextColor:'#fff',
        TokenExpireTextColor:'rgb(222, 222, 222)',
        NewTokenTextColor: '#fff',
        DeleteButtonColor:'#fff',
        TokenCodeColor:'#000',
        Heading3Color:'#fff',
        TokenCodeBgColor:'#dcdbdc',
        DeletePopupButtonColor:'rgb(220, 53, 69)',
        CancelPopupButtonColor:'#007ba2',
        searchOptionColor: "#323346",
        searchAllResultColor:"1px solid #7c7b7b",
        searchAllResultButtonColor:"1px solid #7c7b7b !important",
        SearchCrossIcon:"#e1e1e1",
        ChatMapVisualBgColor: "#1d1d29",
        DashboardTableBgColor: "#1b1c31",
        diffBtnBorderColor: "#dddddd30",
        diffBtnShadowColor: "#ddd",
        compareJsonBGColor: "#323346",
        HeaderLastChildColor: "#323346",

        // Header COLORS START HERE
        HeaderBackgroundColor: '#2F3044',
        HeaderSearchBarBorderColor: '#454557',
        HeaderSearchBarTextColor: '#FFFFFF',
        CPUIconBgColor: '#FFFFFF',
        VCPUTTextColor: '#FFFFFF',
        RightMenuIconColor: '#FFFFFF',
        HelpIconColor: '#1B1C31',
        AnalyzerHelpIconColor: '#323346',
        // HEADER COLROS ENDS HERE

        FavouriteIconColour: "white",

        // Unanswered Questions component starts here
        UnQuestionsBorderColor: '#454557',
        // Unanswered Questions component ends here

        AllPageWrapperBackgroundColor: '#212B36',
        AllWrapperBackgroundColor:'#212B36',
        WorkflowWrapperBackgroundColor: '#212B36',
        AnawerFullScreenBackgroundColor:'#212B36',
        TextColor:'#ffffff',
        SearchPrevNextBottonBackgroundColor:'#787878',
        SVGColor:'#24292F',
        choroplethBgColor: '#1b1c31',
        //Story Component COLORS START HERE
        StroyTooltipColor:'#000',
        // Story component COLORS END HERE


        //common button component
        ButtonComponentBackgroundColor:"#505050",

        
        //Spinner color
        SpinnerColor:'#fff',
        SpinnerColorForSubscribeButton: "#fff",

        // Admin setting
        AdminSettingInputFiledTextColor:'#c8c8c8',

        //feedback

        //Dark/Light Mode
        DarkLightSwitchBgColor: "#000000",

        //answer color
        ExpandAnswerTitleColor:'#ffffff',
        ExpandAnswerTextColor:'#C8C8C8',
        InputLaleTextColor:'#ffffff',
        // Question Icon Color Start here
        QuestionIconBgColor: '#53535F',
        // Question Icon Color ends here
        // DISCOVERY BOARD COLORS START HERE
        DiscoveryBoardTextColor: '#FFFFFF',
        // DISCOVERY BOARD COLORS END HERE

        // SETTING PAGE COLORS START HERE
        SelectFieldBorderColor: '#454562',
        SelectFieldBackgroundColor: '#1B1C31',
        SelectFieldColor: '#FFFFFF',
        // SETTING PAGE COLORS END HERE

        // FEEDBACK PAGE COLORS START HERE
        FeedbackSummaryTextColor: '#FFFFFF',
        // FEEDBACK PAGE COLORS END HERE

        // TOOLTIP COLORS START HERE
        ToolTipTextColor: '#1B1C31',
        // TOOLTIP COLORS END HERE

        // DASHBOARD TYPE COLORS START HERE
        DashbaordTypeBackgroundColor: '#1B1C31',
        // DASHBOARD TYPE COLORS END HERE

        // CHECKBOX COLORS START HERE
        CHECKED_BACKGROUND_COLOR: '#1B1C32',
        CHECKED_BORDER_COLOR: '#454557',
        // CHECKBOX COLORS END HERE


        // USER CONTEXT COLORS START HERE
        UserContextButtonBorderColor: '#F2F2F2',
        UserContextButtonColor: "#FFFFFF",
        // USER CONTEXT COLORS END HERE
        // HISTORYCONTENTLOADER COLOR START HERE
        HistoryContentLoaderSTOP1Color: '#323346',
        HistoryContentLoaderSTOP2Color: '#161627',
        HistoryContentLoaderSTOP3Color: '#323346',
        // HISTORYCONTENTLOADER COLOR END HERE

        // FRONT RELOAD COLOR START HERE
        FrontReloadBackgroundColor: '#1B1C32',
        // FRONT RELOAD COLOR END HERE

        body: '#FFF',
        text: '#363537',
        toggleBorder: '#FFF',
        background: '#363537',
        UnansweredQuestionsBorder: "#454557",
        UnansweredQuestionsBackgroundColor: "#1B1C31",
        UnansweredQuestionsBackgroundColorEven: "#1B1C31",
        BorderBottomColor:"#454557",
        MainBackgroundColorTable:"#323346",
        TableColorHeader:"#FFFFFF",
        TableBackgroundColor:"#323346",
        AnswerSearchQuestions:'#1B1C31',

        endMessageText:"#fff",
        AliasListBackground: "#323346",
        AliasListBackgroundBorder: "#454557",
        HeadingTextColor: "#fff",
        AliasArrowIcon:"#323346",
        AliasArrowcolor:"#fff",
        WorkflowHeading:"#fff",

        ErrorColor:"#fff",
        ErrorDetailBGColor:"rgba(255, 255, 255, 0.07)",
        ErrorDetailTextColor:"#FFF",
        ErrorDetailBorderColor:"#FF2D30",
        RmarkdownTextColor: "#000",
        borderColor:"transparent",
        actionBarBorderColor:"#C8C8C8",
        ShadowColor:"transparent",
        ChatInputBgColor:"#FFFFFF12",
        WebhookTextColor: '#fff',
         // calander 
        DatesOfCalanderOnHover: "#fff",
        AdminSettingBorder: "#454557",
        AdminSettingBackground: "#1b1c31",
        FeedBackBackground: "#1b1c31",
        CalendarTextColorFocus:"#FFF",
        LikeDislikeButtonArrow: "#454657",
        LikeDislikeButton: "#1b1c32",
        EmailTemplateTextColor: "#c8c8c8",
        ActionBarborderBottom: "#454557",

        // Unanswered Questions Table colors
        tableHeaderBgColor: "#1b1c31",
        tableDropDownBgColor: "#444444",

        //Logs table colors
        LogsTableBgColor: "#313245",

         //WORKFLOW COLORS STARTS HERE
        workflowTypeEmptyMessageColor:"#fff",

    },

    light: {
        // Header COLORS START HERE
        BackgroundDropdown : '#fff',
        DropdownColor: '#333',
        HeaderBackgroundColor: '#FFFFFF',

        HeaderSearchBarBorderColor: '#E1DFDF',
        HeaderSearchBarTextColor: '#000000',
        CPUIconBgColor: '#828282',
        VCPUTTextColor: '#828282',
        RightMenuIconColor: '#969696',
        HelpIconColor: '#C4C4C4',
        AnalyzerHelpIconColor: '#C4C4C4',
        WrapperColor:'#E1E1E1',
        // HEADER COLROS ENDS HERE

        // Unanswered Questions component starts here
        UnQuestionsBorderColor: '#DCDCDC',
        // Unanswered Questions component ends here
    
        AllPageWrapperBackgroundColor: '#e1e1e1',
        AllWrapperBackgroundColor:'#fff',
        WorkflowWrapperBackgroundColor: '#fff',
        AnawerFullScreenBackgroundColor:'#FFFFFF',
        TextColor:'#525264',
        SearchPrevNextBottonBackgroundColor:'#9898A2',
        SVGColor:'#851D72',
        choroplethBgColor:'#fff',

        //Story component colors start here
        StroyTooltipColor:'#F5F5FF',
        // Story component COLORS END HERE

        //Unanswered Questions component
        //common button component
        ButtonComponentBackgroundColor:"#8E8E98",

        //Spinner color
        SpinnerColor:'#525364',
        SpinnerColorForSubscribeButton: "#fff",
        SpinnerColorInTableSearch:"#323346",

        //Dark/Light Mode
        DarkLightSwitchBgColor: "#ffffff",
        //answer color
        // Admin setting
        AdminSettingInputFiledTextColor:'#000',
        ExpandAnswerTitleColor:'#55555E',
        ExpandAnswerTextColor:'#55555E',

         // DISCOVERY BOARD COLORS START HERE
        DiscoveryBoardTextColor: '#525264',
        // DISCOVERY BOARD COLORS END HERE

        // SETTING PAGE COLORS START HERE
        SelectFieldBorderColor: '#EDEAEA',
        SelectFieldBackgroundColor: '#F6F6F6',
        SelectFieldColor: '#828282',
        // SETTING PAGE COLORS END HERE


        // FEEDBACK PAGE COLORS START HERE
        FeedbackSummaryTextColor: '#828282',
        // FEEDBACK PAGE COLORS END HERE

        // TOOLTIP COLORS START HERE
        ToolTipTextColor: '#FFFFFF',
        // TOOLTIP COLORS END HERE

        // DASHBOARD TYPE COLORS START HERE
        DashbaordTypeBackgroundColor: '#E4E4E5',
        // DASHBOARD TYPE COLORS END HERE


        // CHECKBOX COLORS START HERE
        CHECKED_BACKGROUND_COLOR: '#FFF',
        CHECKED_BORDER_COLOR: '#EDEAEA',
        // CHECKBOX COLORS END HERE

        // USER CONTEXT COLORS START HERE
        UserContextButtonBorderColor: '#E0DEDE',
        UserContextButtonColor: "#525264",
        // USER CONTEXT COLORS END HERE

        // HISTORYCONTENTLOADER COLOR START HERE
        HistoryContentLoaderSTOP1Color: '#f5f6f7',
        HistoryContentLoaderSTOP2Color: '#EEE',
        HistoryContentLoaderSTOP3Color: '#f5f6f7',
        // HISTORYCONTENTLOADER COLOR END HERE

        
        // FRONT RELOAD COLOR START HERE
        FrontReloadBackgroundColor: '#FFFFFF',
        // FRONT RELOAD COLOR END HERE

        body: '#363537',
        text: '#000',
        toggleBorder: '#6B8096',
        background: '#999',
        UnansweredQuestionsBorder: "#E8E8E8",
        UnansweredQuestionsBackgroundColor: "#F9F9F9",
        UnansweredQuestionsBackgroundColorEven: "#FFFFFF",
        BorderBottomColor:"#EDEAEA",
        MainBackgroundColorTable:"#FFFFFF",
        TableColorHeader:"#828282",
        TableBackgroundColor:"#FFFFFF",
        AnswerSearchQuestions:'#F9F9F9',

        SearchBarTopBackground: 'rgb(255, 255, 255)',
        endMessageText:"#525264", 
        AliasListBackground: "#F6F6F6",
        AliasListBackgroundBorder: "#DDDDDD",
        HeadingTextColor: "#828282",
        AliasArrowIcon:"#E8E8E8",
        AliasArrowcolor:"#8F8F90",
        WorkflowHeading:"#414B54",

        workflowTypeEmptyMessageColor:"#323232",

        //webhook events 
        WebhookTextColor: '#333333',

        ErrorColor:"#323232",
        ErrorDetailBGColor:"#F2DEDE",
        ErrorDetailTextColor:"#A94442",
        ErrorDetailBorderColor:"#F00",
        RmarkdownTextColor: "#000",
        borderColor:"#C8C8C8",
        actionBarBorderColor:"#C8C8C8",
        ShadowColor:"#E1E1E1",

        // calander 
        DatesOfCalanderOnHover: "#454658",
        AdminSettingBorder: "#cbcbcb",
        AdminSettingBackground: "#fff",
        FeedBackBackground: "#fff",
        CalendarTextColorFocus:"#454658",
        LikeDislikeButtonArrow: "#ffffff",
        LikeDislikeButton: "#ffffff",
        EmailTemplateTextColor: "#000",
        ActionBarborderBottom: "#E9EBEE",
        InputLaleTextColor:"#525264",

        // Question Icon Color
        QuestionIconBgColor: '#828282',

        // Unanswered Questions Table colors
        tableHeaderBgColor: "#fff",
        tableDropDownBgColor: "#f6f6f6",

        // Logs table colors
        LogsTableBgColor: "#fff",
    }
}