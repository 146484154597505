import { Colors } from "style/color"

export const emailHeaderSectionColors = {
    dark:{
        EmailHeaderGraphic: "#454557",
        InputBorderAdminSetting: "#454562",
        UnansweredQuestionsTabBorderMain: "#454657",
        FeedBackSearchBackground: "#323346",
        FeedBackBackgroundInput: Colors.DARK_BLUE,
        FeedBackColorInput:  Colors.WHITE,
        SelectBackgroundColor: "#323346",
        SelectBorderColor: "#454557",
        TableTextColor:  Colors.WHITE,
        TableborderColor:"#7c7b7b",
        BackgroundColorSearch:"#323346",
        PlaceholderColorSearch:"#969696",
        SelectRadioText: Colors.WHITE,
        SearchTextColor: Colors.WHITE,
        TableHoverBgColor: "#404159",
        BorderColorLines:"#494949",
        SpinnerSearchCircle:"#007BA2",
        SearchDropBackground:"#454658",
        SearchDropBorder:"#333343",
        SearchTopBarBackground:"#323346",
        ClearFilterButtonBorder:"#c0c0c0",
        ClearFilterButtonText: Colors.WHITE,
    },
    light:{
        EmailHeaderGraphic: "#cbcbcb",
        InputBorderAdminSetting: "#cbcbcb",
        UnansweredQuestionsTabBorderMain: "#d8d8d8",
        FeedBackSearchBackground: "#cbcbcb",
        FeedBackBackgroundInput: "#ddddddd9",
        FeedBackColorInput: "#525364",
        SelectBackgroundColor:  Colors.WHITE,
        SelectBorderColor: "#E3E3E3",
        TableTextColor: Colors.DARK_CHARCOAL,
        TableborderColor:"#b4b4b4",
        BackgroundColorSearch: Colors.WHITE,
        PlaceholderColorSearch:"#969696",
        SelectRadioText:"#969696",
        SelectRadioHeading: Colors.BLACK,
        SelectCheckboxText:Colors.BLACK,
        SearchTextColor:Colors.SHARK_FIN,
        TableHoverBgColor: "#d9e0f0",
        BorderColorLines: "#B4B4B4",
        SpinnerSearchCircle:"#007BA2",
        SearchDropBackground:"#d9d9d9",
        SearchDropBorder: Colors.WHITE,
        SearchTopBarBackground: Colors.WHITE,
        ClearFilterButtonBorder:"#545b64",
        ClearFilterButtonText:"#545b64",
        FavouriteIconColour:'black',
    },
}