import { ActionType } from '../action-types'
import { QuestionNavigation } from 'types/questionNavigation'


export const initialState: QuestionNavigation = {
    open: true,
    tab: "recently-asked",
    suggestedQuestions: [],
    activeQuickLinkIndex: -1,
    activeQuickLinkQuestionIndex: -1,
    activeQuickLinksSuggestionIndex: -1,
    navActiveTab: "",
    isWorkflowAnswer: true,
}

const reducer = (state: QuestionNavigation = initialState, action: any): QuestionNavigation => {
    switch(action.type) {
        case ActionType.UPDATE_WORKFLOW_ANSWER_STATUS:
            return { ...state , isWorkflowAnswer: action.payload };
        case ActionType.NAVIGATION_ACTIVE_TAB:
            return { ...state , navActiveTab: action.payload };
        case ActionType.UPDATE_NAVIGATION_QUESTION_TAB:
            return { ...state , tab: action.payload };
        case ActionType.UPDATE_NAVIGATION_QUESTION_OPEN:
            return { ...state , open: action.payload };
        case ActionType.UPDATE_ACTIVE_QUICK_LINK_INDEX:
            return { ...state , activeQuickLinkIndex: action.payload };
        case ActionType.UPDATE_ACTIVE_QUICK_QUESTION_INDEX:
            return { ...state , activeQuickLinkQuestionIndex: action.payload };
        case ActionType.UPDATE_ACTIVE_QUICK_SUGGESTION_INDEX:
            return { ...state , activeQuickLinksSuggestionIndex: action.payload };
        default:
            return state;
    }
}

export default reducer;