import React from "react";

const HumaLogoIcon = ({
  width = "52",
  height = "52",
}: {
  width?: string;
  height?: string;
}) => {
  return (
    <div style={{width:width+'px',height:height+'px'}}>
    <svg viewBox="0 0 339 339" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 26.2657C0 11.7595 11.7595 0 26.2657 0H112.808V112.808H0V26.2657Z" fill="url(#paint0_linear_6677_322)"/>
      <mask id="mask0_6677_322"  maskUnits="userSpaceOnUse" x="0" y="0" width="113" height="113">
      <path d="M0 26.2657C0 11.7595 11.7595 0 26.2657 0H112.808V112.808H0V26.2657Z" fill="#D9D9D9"/>
      </mask>
      <g mask="url(#mask0_6677_322)">
      <circle cx="-136.885" cy="12.2908" r="232.855" fill="url(#paint1_linear_6677_322)"/>
      <circle cx="-136.885" cy="12.2908" r="232.855" fill="url(#paint2_linear_6677_322)"/>
      </g>
      <rect y="112.807" width="112.808" height="112.808" fill="url(#paint3_linear_6677_322)"/>
      <mask id="mask1_6677_322"  maskUnits="userSpaceOnUse" x="0" y="112" width="113" height="114">
      <rect y="112.807" width="112.808" height="112.808" fill="url(#paint4_linear_6677_322)"/>
      </mask>
      <g mask="url(#mask1_6677_322)">
      <circle cx="-136.885" cy="12.2888" r="232.855" fill="url(#paint5_linear_6677_322)"/>
      <circle cx="214.503" cy="252.554" r="179.145" fill="url(#paint6_linear_6677_322)"/>
      <circle cx="259.121" cy="292.793" r="190.426" fill="#12DEFF"/>
      <circle cx="292.626" cy="311.483" r="228.899" stroke="url(#paint7_linear_6677_322)" strokeWidth="1.17859"/>
      </g>
      <path d="M112.809 312.158C112.809 326.664 101.049 338.424 86.5429 338.424H0.000915527V225.616H112.809V312.158Z" fill="url(#paint8_linear_6677_322)"/>
      <mask id="mask2_6677_322"  maskUnits="userSpaceOnUse" x="0" y="225" width="113" height="114">
      <path d="M112.809 312.158C112.809 326.664 101.049 338.424 86.5429 338.424H0.000915527V225.616H112.809V312.158Z" fill="url(#paint9_linear_6677_322)"/>
      </mask>
      <g mask="url(#mask2_6677_322)">
      <circle cx="214.503" cy="252.554" r="179.145" fill="url(#paint10_linear_6677_322)"/>
      <circle cx="253.396" cy="301.886" r="188.406" fill="url(#paint11_linear_6677_322)"/>
      <circle cx="292.626" cy="311.483" r="228.899" stroke="url(#paint12_linear_6677_322)" strokeWidth="1.17859"/>
      </g>
      <rect x="112.807" y="112.807" width="112.808" height="112.808" fill="#06A7DF"/>
      <mask id="mask3_6677_322" maskUnits="userSpaceOnUse" x="112" y="112" width="114" height="114">
      <rect x="112.807" y="112.807" width="112.808" height="112.808" fill="#06A7DF"/>
      </mask>
      <g mask="url(#mask3_6677_322)">
      <circle cx="292.626" cy="311.483" r="228.309" fill="url(#paint13_linear_6677_322)"/>
      <circle cx="292.626" cy="311.483" r="228.899" stroke="url(#paint14_linear_6677_322)" strokeOpacity="0.6" strokeWidth="1.17859"/>
      <circle cx="-63.6451" cy="-67.3482" r="380.515" stroke="white" strokeOpacity="0.3" strokeWidth="1.34696"/>
      </g>
      <path d="M225.615 26.2657C225.615 11.7595 237.375 0 251.881 0H338.423V112.808H225.615V26.2657Z" fill="#3857D5"/>
      <mask id="mask4_6677_322" maskUnits="userSpaceOnUse" x="225" y="0" width="114" height="113">
      <path d="M225.615 26.2657C225.615 11.7595 237.375 0 251.881 0H338.423V112.808H225.615V26.2657Z" fill="#3857D5"/>
      </mask>
      <g mask="url(#mask4_6677_322)">
      <circle cx="-63.6431" cy="-67.3482" r="381.189" fill="url(#paint15_linear_6677_322)"/>
      <circle cx="-63.6431" cy="-67.3482" r="380.515" stroke="url(#paint16_linear_6677_322)" strokeOpacity="0.4" strokeWidth="1.34696"/>
      <circle cx="601.415" cy="271.411" r="341.79" fill="#3757D5"/>
      <circle cx="601.415" cy="271.411" r="341.117" stroke="url(#paint17_linear_6677_322)" strokeOpacity="0.6" strokeWidth="1.34696"/>
      </g>
      <rect x="225.615" y="112.807" width="112.808" height="112.808" fill="#983FB6"/>
      <mask id="mask5_6677_322"  maskUnits="userSpaceOnUse" x="225" y="112" width="114" height="114">
      <rect x="225.615" y="112.807" width="112.808" height="112.808" fill="#983FB6"/>
      </mask>
      <g mask="url(#mask5_6677_322)">
      <circle cx="-63.6431" cy="-67.3482" r="381.189" fill="url(#paint18_linear_6677_322)"/>
      <circle cx="-63.6431" cy="-67.3482" r="380.515" stroke="url(#paint19_linear_6677_322)" strokeOpacity="0.4" strokeWidth="1.34696"/>
      <circle cx="601.417" cy="271.411" r="341.79" fill="url(#paint20_linear_6677_322)"/>
      <circle cx="601.417" cy="271.411" r="341.117" stroke="url(#paint21_linear_6677_322)" strokeOpacity="0.3" strokeWidth="1.34696"/>
      </g>
      <path d="M225.615 225.615H338.423V312.157C338.423 326.663 326.663 338.423 312.157 338.423H225.615V225.615Z" fill="#F9039B"/>
      <mask id="mask6_6677_322"  maskUnits="userSpaceOnUse" x="225" y="225" width="114" height="114">
      <path d="M225.615 225.615H338.423V312.157C338.423 326.663 326.663 338.423 312.157 338.423H225.615V225.615Z" fill="#F9039B"/>
      </mask>
      <g mask="url(#mask6_6677_322)">
      <circle cx="601.415" cy="271.411" r="341.79" fill="url(#paint22_linear_6677_322)"/>
      <circle cx="601.415" cy="271.411" r="341.117" stroke="url(#paint23_linear_6677_322)" strokeOpacity="0.3" strokeWidth="1.34696"/>
      </g>
      <defs>
      <linearGradient id="paint0_linear_6677_322" x1="80.4807" y1="-11.4491" x2="125.267" y2="108.767" gradientUnits="userSpaceOnUse">
      <stop stopColor="#7170CE"/>
      <stop offset="1" stopColor="#6766C4"/>
      </linearGradient>
      <linearGradient id="paint1_linear_6677_322" x1="95.9702" y1="-10.4391" x2="-4.71481" y2="111.797" gradientUnits="userSpaceOnUse">
      <stop stopColor="#03033E"/>
      <stop offset="1" stopColor="#7A79CF"/>
      </linearGradient>
      <linearGradient id="paint2_linear_6677_322" x1="95.9702" y1="-10.4391" x2="-4.71481" y2="111.797" gradientUnits="userSpaceOnUse">
      <stop stopColor="#03033E"/>
      <stop offset="1" stopColor="#7A79CF"/>
      </linearGradient>
      <linearGradient id="paint3_linear_6677_322" x1="-5.72457" y1="118.868" x2="124.257" y2="222.247" gradientUnits="userSpaceOnUse">
      <stop stopColor="#00D5F9"/>
      <stop offset="1" stopColor="#16E8FF"/>
      </linearGradient>
      <linearGradient id="paint4_linear_6677_322" x1="47.1435" y1="155.236" x2="120.513" y2="226.478" gradientUnits="userSpaceOnUse">
      <stop stopColor="#00BDE0"/>
      <stop offset="1" stopColor="#16E8FF"/>
      </linearGradient>
      <linearGradient id="paint5_linear_6677_322" x1="-7.07198" y1="116.173" x2="76.1026" y2="132.673" gradientUnits="userSpaceOnUse">
      <stop stopColor="#00D4F8"/>
      <stop offset="1" stopColor="#3EE9FF"/>
      </linearGradient>
      <linearGradient id="paint6_linear_6677_322" x1="92.2664" y1="132.674" x2="64.9905" y2="380.178" gradientUnits="userSpaceOnUse">
      <stop stopColor="#09DDFC"/>
      <stop offset="1" stopColor="#0AF2FC"/>
      </linearGradient>
      <linearGradient id="paint7_linear_6677_322" x1="122.909" y1="102.705" x2="82.5003" y2="295.151" gradientUnits="userSpaceOnUse">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="white" stopOpacity="0"/>
      </linearGradient>
      <linearGradient id="paint8_linear_6677_322" x1="-6.73387" y1="225.616" x2="59.267" y2="345.832" gradientUnits="userSpaceOnUse">
      <stop stopColor="#ACCE6B"/>
      <stop offset="1" stopColor="#C0DA7E"/>
      </linearGradient>
      <linearGradient id="paint9_linear_6677_322" x1="-6.73387" y1="225.616" x2="59.267" y2="345.832" gradientUnits="userSpaceOnUse">
      <stop stopColor="#ACCE6B"/>
      <stop offset="1" stopColor="#C0DA7E"/>
      </linearGradient>
      <linearGradient id="paint10_linear_6677_322" x1="214.503" y1="73.4082" x2="64.9905" y2="380.178" gradientUnits="userSpaceOnUse">
      <stop stopColor="#9AC35B"/>
      <stop offset="1" stopColor="#9AC35B"/>
      </linearGradient>
      <linearGradient id="paint11_linear_6677_322" x1="253.396" y1="113.48" x2="253.396" y2="490.292" gradientUnits="userSpaceOnUse">
      <stop stopColor="#81A34E"/>
      <stop offset="1" stopColor="#7B9C49"/>
      </linearGradient>
      <linearGradient id="paint12_linear_6677_322" x1="122.909" y1="102.705" x2="82.5003" y2="295.151" gradientUnits="userSpaceOnUse">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="white" stopOpacity="0"/>
      </linearGradient>
      <linearGradient id="paint13_linear_6677_322" x1="230.666" y1="135.032" x2="130.991" y2="232.686" gradientUnits="userSpaceOnUse">
      <stop stopColor="#2AB2E4"/>
      <stop offset="1" stopColor="#0089D8"/>
      </linearGradient>
      <linearGradient id="paint14_linear_6677_322" x1="165.675" y1="155.236" x2="186.216" y2="106.072" gradientUnits="userSpaceOnUse">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="white" stopOpacity="0"/>
      </linearGradient>
      <linearGradient id="paint15_linear_6677_322" x1="306.097" y1="57.2454" x2="227.973" y2="-1.68404" gradientUnits="userSpaceOnUse">
      <stop stopColor="#6E85E1"/>
      <stop offset="1" stopColor="#4563DE"/>
      </linearGradient>
      <linearGradient id="paint16_linear_6677_322" x1="242.79" y1="111.46" x2="296.668" y2="-10.7759" gradientUnits="userSpaceOnUse">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="white" stopOpacity="0"/>
      </linearGradient>
      <linearGradient id="paint17_linear_6677_322" x1="369.402" y1="69.0312" x2="249.523" y2="171.4" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stopOpacity="0"/>
      <stop offset="1" stopColor="white"/>
      </linearGradient>
      <linearGradient id="paint18_linear_6677_322" x1="221.238" y1="151.869" x2="255.249" y2="149.849" gradientUnits="userSpaceOnUse">
      <stop stopColor="#A85EC2"/>
      <stop offset="1" stopColor="#B26FC9"/>
      </linearGradient>
      <linearGradient id="paint19_linear_6677_322" x1="242.79" y1="111.46" x2="296.668" y2="-10.7759" gradientUnits="userSpaceOnUse">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="white" stopOpacity="0"/>
      </linearGradient>
      <linearGradient id="paint20_linear_6677_322" x1="357.281" y1="96.9805" x2="259.627" y2="233.36" gradientUnits="userSpaceOnUse">
      <stop stopColor="#A449BE"/>
      <stop offset="0.64262" stopColor="#B051C7"/>
      <stop offset="1" stopColor="#8334A9"/>
      </linearGradient>
      <linearGradient id="paint21_linear_6677_322" x1="279.158" y1="231.34" x2="312.832" y2="95.6336" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stopOpacity="0"/>
      <stop offset="1" stopColor="white"/>
      </linearGradient>
      <linearGradient id="paint22_linear_6677_322" x1="350.545" y1="212.819" x2="300.707" y2="355.596" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FA029A"/>
      <stop offset="1" stopColor="#E60000"/>
      </linearGradient>
      <linearGradient id="paint23_linear_6677_322" x1="279.156" y1="231.34" x2="312.83" y2="95.6336" gradientUnits="userSpaceOnUse">
      <stop stopColor="white" stopOpacity="0"/>
      <stop offset="1" stopColor="white"/>
      </linearGradient>
      </defs>
      </svg>
    </div>
  );
};

export default HumaLogoIcon;
