import { ActionType } from '../action-types'
import { FeedbackState } from 'types/feedback'



export const initialState: FeedbackState = {
    loading: false,
    data: [] ,
    csv: [],
    lastCreated: null,
    sort: {
        key: "REPORTED",
        order: -1,
      },
    statusProcessing: false,
    hasMore: true,
}

const reducer = (state: FeedbackState = initialState, action: any): FeedbackState => {
    switch(action.type) {
        case ActionType.FETCH_FEEDBACK_COMPLETED:
            return { ...state , data: action.payload.data , lastCreated: action.payload.lastCreated, csv: action.payload.csv }
        case ActionType.UPDATE_FEEDBACK_COMPLETED:
            return {
                ...state, data: [...state.data, ...action.payload.data],
                lastCreated: action.payload.lastCreated,
                csv: [...state.csv, ...action.payload.csv]
            }
        case ActionType.UPDATE_FEEDBACK_HAS_MORE:
            return {...state, hasMore: action.payload}
        case ActionType.UPDATE_FEEDBACK_LOADING:
            return { ...state , loading: action.payload }
        case ActionType.UPDATE_FEEDBACK_STATUS:
            return { ...state , data: action.payload.feedbacks }
        case ActionType.UPDATE_STATUS_PROCESSING:
            return { ...state, statusProcessing: action.payload }
        default:
            return state;
    }
}

export default reducer;