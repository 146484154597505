import { ActionType } from "../action-types";
import {
  runWorkflows,
  fetchExistingWF,
  workflowCompleteAsync,
  getWorkFlowTypes,
} from "services/workflow";
import { Dispatch } from "redux";
import { alert } from "./customRouter";
import { RootState } from "state/reducers";
import { addHistoryEntry } from "./UserHistoryActions";
// import { ConsoleLogger } from "@aws-amplify/core";

export const getWorkFlowTypws = () => {
  return async (dispatch: Dispatch) => {
    dispatch(workFlowHeaderLoader(true));
    try {
      const response = await getWorkFlowTypes();
      if (response.status == 200) {
        dispatch(workFlowHeaderLoader(false));
        dispatch({
          type: ActionType.GET_WORKFLOW_TYPES,
          payload: response.body.data,
        });
      } else {
        dispatch(alert("Please try later! "));
      }
    } catch (err) {
      dispatch(alert("Please try later! "));
    }
  };
};

export const selectedWorkFlowIndex = (value: number) => {
  return {
    type: ActionType.SELECTED_WORKFLOW_INDEX,
    payload: value,
  };
};
export const selectedWorkFlowQuestions = (Questions: Array<any>) => {
  return {
    type: ActionType.SELECTED_WORKFLOW_QUESTIONS,
    payload: Questions,
  };
};

export const fetchExistingWorkFlow = () => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await fetchExistingWF();

      if (response.status == 200) {
        dispatch(workFlowQuestionPageLoader(false));
        if (response.body.data.length) {
          dispatch({
            type: ActionType.SAVE_EXISTING_WORKFLOWS,
            payload: response.body.data
        });
        }
      }
    } catch (err) {
      dispatch(alert("Please try later! "));
    }
  };
};

export const runWorkFlow = (workFlowName: string) => {
  return async (dispatch: Dispatch,getState: () => RootState) => {
    const current_workflows = getState().workflows.current_workflows
    try {
      const response = await runWorkflows(workFlowName);
      if (response.status == 200) {
        const data = {
          status: false,
          utterances: response.body,
          workflow: workFlowName,
        };

        dispatch({
          type: ActionType.SAVE_WORKFLOW_QUESTIONS,
          payload: data,
        });
        const addVisual = response.body[0];
        addVisual.visual = "None";
        dispatch(addHistoryEntry(addVisual));
        if(current_workflows.length){
          const findCurrentWorkFlowIndex = (data:any) => data.workflow == workFlowName;
          const getWFIndex = current_workflows.findIndex(findCurrentWorkFlowIndex);
        if(getWFIndex === -1) dispatch(selectedWorkFlowIndex(current_workflows.length))
       }
        dispatch(showWorkflowQuestions(true));
        dispatch(workFlowQuestionPageLoader(false));
      }else{
        dispatch(alert("Please try later! "));
        dispatch(showWorkflowQuestions(true));
        dispatch(workFlowQuestionPageLoader(false));
      }
    } catch (error) {
      console.log(error);
      dispatch(alert("Please try later! "));
    }
  };
};

export const formatWorkFlowData = (data: any, current_workflows: any) => {
  let current_index: number | undefined = undefined;
  
   [...current_workflows].map( (workFlows: any, index: number) => {
      const dataIndex = workFlows.utterances.find( (uttenence: any) => uttenence.utteranceId === data.utteranceId );
      if (dataIndex) {
        current_index = index;
      }
    }
  );


  if (current_index !== undefined) {
    const new_data = current_workflows[current_index].utterances.map(
      (utterance: any) => {
        if (utterance.utteranceId === data.utteranceId) {
          utterance.visual = data.visual;
          utterance.subscribed = data.subscribed;
          utterance.subscribed_id = data.subscribed_id;
          utterance.status = data.status;
          if (data.thumbnail) {
            utterance.thumbnail = data.thumbnail;
          } else {
            utterance.thumbnail = data.visual.thumbnail;
          }
        }
        return utterance;
      }
    );
    current_workflows[current_index].utterances = new_data;
    return current_workflows;
  }
};


export const updateStatus = (
  utteranceIds: string,
  current_workflows: any,
  index: number
) => {
  const new_data = current_workflows[index].utterances.map((utterance: any) => {
    if (utterance.utteranceId === utteranceIds) {
      utterance.status = "Processing";
      utterance.visual = "None";
    }
    return utterance;
  });
  current_workflows[index].utterances = new_data;
  return current_workflows;
};

export const checkForWorkFlowAsyncCompletions = () => {
  return async (dispatch: Dispatch<any>, getState: () => RootState) => {
    const current_workflows = getState().workflows.current_workflows;

    try {
      if (current_workflows.length === 0) return true;
      const workFlow = getState().workflows.selectedWFIndex;
      const workFlowName = current_workflows[workFlow].workflow;
      const utterances = current_workflows[workFlow].utterances;
      const utteranceIds = utterances
        .filter((e: any) => e.status === "Not started")
        .map((e: any) => e.utteranceId);

      if (utteranceIds.length === 0) return true;

      const ChangeUttteranceToProcess = updateStatus(
        utteranceIds[0],
        current_workflows,
        workFlow
      );
      dispatch({
        type: ActionType.UPDATE_UTTERANCE_DATE,
        payload: {
          data: ChangeUttteranceToProcess,
        },
      });

      const response = await workflowCompleteAsync(
        utteranceIds[0],
        workFlowName
      );
      if (response.status === 200) {
        const data = response.body;
        data[0].visual = "None";
        const new_data = formatWorkFlowData(
          data[0],
          current_workflows,
        );
        dispatch({
          type: ActionType.UPDATE_UTTERANCE_DATE,
          payload: {
            data: new_data,
          },
        });
        dispatch(addHistoryEntry(data[0]));
        return true;
      } else {
        dispatch(alert("Please try later! "));
      }
    } catch (error) {
      dispatch(alert("Please try later! "));
    }
  };
};

export const setToggle = (value: boolean) => {
  return {
    type: ActionType.SET_TOGGLE,
    payload: value,
  };
};

export const loadingSpinnerForSubscribed = (toggle: boolean) => {
  return {
    type: ActionType.SET_LOADING_SPINNER_FOR_SUBSCRIBED,
    payload: toggle,
  };
};

export const showWorkflowQuestions = (value: boolean) => {
  return {
    type: ActionType.SHOW_WORKFLOW_QUESTION,
    payload: value,
  };
};
export const workFlowHeaderLoader = (value: boolean) => {
  return {
    type: ActionType.WORKFLOW_HEADER_LOADING,
    payload: value,
  };
};
export const workFlowQuestionPageLoader = (value: boolean) => {
  return {
    type: ActionType.WORKFLOW_QUESTION_PAGE_LOADING,
    payload: value,
  };
};
export const showWorkflowHeader = (value: boolean) => {
  return {
    type: ActionType.SHOW_WORKFLOW_HEADER,
    payload: value,
  };
};
export const selectedWorkflowName = (wf_name:string) =>{
  return {
    type:ActionType.SELECTED_WOKRFLOW_NAME,
    payload:wf_name
  }
}
export const handleSelectedIndex = (indexValue:number) =>{
  return {
    type:ActionType.SELECTED_WOKRFLOW_INDEX,
    payload:indexValue
  }
}
