import { ActionType } from '../action-types';
import { Dispatch } from "redux";

export const updateQuestionNavigationTab = (tab: string) => {
    return (dispatch: Dispatch) => {
        dispatch({
        type: ActionType.UPDATE_NAVIGATION_QUESTION_TAB ,
        payload: tab
    })
  }
}
export const navigationActiveTab = (tabValue: string) => {
    return (dispatch: Dispatch) => {
        dispatch({
        type: ActionType.NAVIGATION_ACTIVE_TAB ,
        payload: tabValue
    })
  }
}

export const updateQuestionNavigationOpen = (open: boolean) => {
    return {
        type: ActionType.UPDATE_NAVIGATION_QUESTION_OPEN,
        payload: open
    }
}


export const updateActiveQuickLinkIndex = (index: number) => {
  return {
      type: ActionType.UPDATE_ACTIVE_QUICK_LINK_INDEX,
      payload: index
  }
}


export const setAnswerState = (value:boolean) => {
    return {
        type: ActionType.UPDATE_WORKFLOW_ANSWER_STATUS,
        payload: value
    }
}

export const updateActiveQuickLinkQuestionIndex = (value: number) => {
    return {
        type: ActionType.UPDATE_ACTIVE_QUICK_QUESTION_INDEX,
        payload: value
    }
}

export const updateActiveQuickLinksSuggestionIndex = (value: number) => {
    return {
        type: ActionType.UPDATE_ACTIVE_QUICK_SUGGESTION_INDEX,
        payload: value
    }
}