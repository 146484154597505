export const subscriptionColors = {
    dark:{
        PreviousButtonBGColor:"#454658",
        PreviousButtonTextColor:"#FFFFFF",
        SubscribedQuestionListtButtonBGColor:"#323346",
        SubscribedQuestionListButtonTextColor:"#FFFFFF",
        SubscriptionBarButtonColor:"#323346",
        SubscriptionBar_ButtonColor:"#fff",
        MenuBarTableEvenRowColor:"#454557",
        MwnuBarTableOddRowColor:"#5D5E6D",
        SubscribeButtonBGColor:"#494A59",
        SubscribedButtonBGColor:"#07516F",
        SubscribeButtonTextColor:"#51CDFF",
        SubscribeButtonBorderColor:"#51CDFF",
        SubsciptionNoRecordFound:"#fff",
        SubsciptionBorder: "#505050",
},
    light:{
        PreviousButtonBGColor:"#f6f6f6",
        PreviousButtonTextColor:"#828282",
        SubscribedQuestionListtButtonBGColor:"#d2cece",
        SubscribedQuestionListButtonTextColor:"#848484",
        SubscriptionBarButtonColor: '#fff',
        SubscriptionBar_ButtonColor: 'black',
        MenuBarTableEvenRowColor:"#f3f3f3",
        MwnuBarTableOddRowColor:"#c4c4c4a3",
        SubscribeButtonBGColor:"#FFFFFF",
        SubscribedButtonBGColor:"#E8F0FE",
        SubscribeButtonTextColor:"#2376E5",
        SubscribeButtonBorderColor:"#fff",
        SubsciptionBorder: "#B4B4B4",
        SubsciptionNoRecordFound:"#000",
    },
}
