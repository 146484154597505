import { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import { useTypedSelector } from "./use-typed-selector";
import { CUSTOMER_ID  } from "config";

export const useLoadSentry = () => {
  const { email } = useTypedSelector(({ auth }) => auth.user);
  const environment = useTypedSelector(
    ({ siteSettings }) => (siteSettings as any).environment
  );
  const [isEnv, setIsEnv] = useState(true);
  const url = window.location.href;
  const { hostname,searchParams } = new URL(url);
  const debugParam = searchParams.get('debug');
  const isDebug = debugParam !== null ;
  const Sentry_Dsn = process.env.REACT_APP_DSN!;
  const tunnelUrl = process.env.REACT_APP_API_ROOT!;
  function initializeSentry(environment: string, Sentry_Dsn: string) {
    if (["dev", "stage"].includes(environment)) {
      Sentry.init({
        dsn: Sentry_Dsn,
        debug: isDebug?true:false,
        attachStacktrace: true,
        enableTracing: environment == "stage" ? false : true,
        tunnel: `${tunnelUrl}/sentry/tunnel`,
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.browserProfilingIntegration(),
          Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
          })
        ],
        // Performance Monitoring
        tracesSampleRate: 0.1,
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          /^https:\/\/dev\..+\.huma\.ai\/api/,
          /^https:\/\/stage\..+\.huma\.ai\/api/,
        ],
        // Session Replay
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        environment: CUSTOMER_ID + "-" + environment,
        profilesSampleRate: 1.0,
      });
    } else if (environment === "prod" && CUSTOMER_ID === "009") {
      Sentry.init({
        dsn: Sentry_Dsn,
        debug: isDebug?true:false,
        attachStacktrace: true,
        enableTracing: false,
        tunnel: `${tunnelUrl}/sentry/tunnel`,
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.browserProfilingIntegration(),
          Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
          }),

        ],
        // Performance Monitoring
        tracesSampleRate: 0.1,
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          /^https:\/\/dev\..+\.huma\.ai\/api/,
          /^https:\/\/stage\..+\.huma\.ai\/api/,
        ],
        // Session Replay
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        environment: CUSTOMER_ID + "-" + environment,
        profilesSampleRate: 1.0,
      });
    } else if (environment === "prod") {
      Sentry.init({
        dsn: Sentry_Dsn,
        debug: isDebug?true:false,
        attachStacktrace: true,
        enableTracing: false,
        tunnel: `${tunnelUrl}/sentry/tunnel`,
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration({
            maskAllText: CUSTOMER_ID === "009" ? false : true,
            blockAllMedia: false,
          }),
        ],
        // Performance Monitoring
        tracesSampleRate: 0.1,
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          /^https:\/\/prod\..+\.huma\.ai\/api/,
          /^https:\/\/\..+\.huma\.ai\/api/,
        ],
        // Session Replay
        replaysSessionSampleRate: 0.0,
        replaysOnErrorSampleRate: 1.0,
        environment: CUSTOMER_ID + "-" + environment,
      });
    }
  }
  useEffect(() => {
    if (environment && isEnv) {
      if (hostname !== "localhost") {
        initializeSentry(environment, Sentry_Dsn);
        setIsEnv(false);
      }
    }
  }, [environment]);
  Sentry.setContext("Issue Metadata", {
    email: email,
    environment: environment,
    url: url,
  });
  Sentry.setUser({
    email: email,
  });
};
