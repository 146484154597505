import { get, Response, post } from "makeRequest";
import { UserHistoryEntry } from "types/userHistory";

type GetUserHistoryResponse = {
  history: Array<UserHistoryEntry>;
  date:Array<string>
};

export function getUserHistory(page_no: number , limit: number, historySearchString:string , historyOrderKey:string ): Response<GetUserHistoryResponse> {
  return get(`/history-pagination-v2?page=${page_no}&limit=${limit}&search_string=${historySearchString}&order_key=${historyOrderKey}`);
}

export function getUserHistoryAnswer(data: any):Response<any> {
  return post("/history-visual" , data);
}

export function deleteUserHistory(data:any): Response<any> {
  return post('/delete-history', data);
}
export function fetchHistoryRecords():Response<any>{
  return get("/history-pagination-v3")
}

export function fetchPreviousRecords(date:any): Response<any> {
  return get(`/history-pagination-v3?utteranceDate=${date}`);
}

export function getHistoryItem(id:string){
  return get(`/get-history-item?utterance_id=${id}`);
}