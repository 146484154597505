import produce from 'immer'
import { ActionType } from '../action-types';
import { Action } from '../actions';
import { AppSettingsState } from 'state';
import { defaultAdvanceFields } from "components/Config/SearchAlias/schema/main";
import {PptTemplateProps} from "../store"

export const initialState: AppSettingsState = {
    data_loaded: false,
    valid_domains: [{ id: 'huma.ai', text: 'huma.ai' }],
    environment: '',
    logo: {
        logo: "https://huma-customer-frontend-assets.s3.amazonaws.com/009/logo/logo.svg",
        logoAlt: "Customer-logo",
        light: "https://huma-server.dev.009.huma.ai/get-image/66fcf675e9658bcc584f7abe",
        dark: "https://huma-server.dev.009.huma.ai/get-image/66fba9e70c7ac62f103696d4",
        system_light: "https://huma-server.dev.009.huma.ai/get-image/66fcf675e9658bcc584f7abe",
        system_dark: "https://huma-server.dev.009.huma.ai/get-image/66fba9e70c7ac62f103696d4",
        system_logo_alt: "Huma-logo"
    } ,
    email_header: {
        email_header_logo: "",
        email_header_logo_alt: ""
    },
    chat_configuration: [],
    feature_config: [
        {isSelected: true, user: 'Huma-user'},
        {isSelected: false, user: 'Regular-user'}
    ],
    landing_page_ui_config:[],
    sendrid_dynamic_templates: [] ,
    federated_key_templates: [],
    ppt_templates: [],
    tab_main_heading:'',
    search_ui: {
        showAdvance: false,
        advanceFields: defaultAdvanceFields
    },
    group_list: [],
    users_list: [],
    loadingSpinner: false,
    answer_variance_threshold: {
        min: 0,
        max: 0
    },
    workflow_types: [],
    templates_list: [],
    customer_notice: "",
    show_customer_notice: false,
    customer_notice_dismissed: true,
    home_page_config: {
        home_page: "chat",
        is_quicklink_icon_visible: false,
    }
};

const reducer = produce((state: AppSettingsState = initialState , action: Action ): AppSettingsState | void  => {

    switch (action.type) {
        // users and groups
         case ActionType.UPDATE_GROUP_LIST:
            return { ...state, group_list: action.payload }
         case ActionType.UPDATE_USERS_LIST:
            return { ...state, users_list: action.payload }
        case ActionType.UPDATE_SPINNER_LOADING_GROUPS:
            return { ...state, loadingSpinner: action.payload }
        case ActionType.ADD_NEW_GROUP:
            return {
                ...state,
                group_list: [...state.group_list, action.payload]
            }
        case ActionType.DELETE_GROUP:
            return {
                ...state,
                group_list: [...state.group_list.filter((group:string)=>group!=action.payload)]
            }
        //TESTED
        case ActionType.FETCH_APP_SETTINGS_COMPLETED:
            return {...state, ...action.payload} ;


        //TESTED
        case ActionType.INSERT_VALID_DOMAIN:
            const valid_domain = {
                id: action.payload.id,
                text: action.payload.id
            }
            state.valid_domains.push(valid_domain);
            return;
        //TESTED
        case ActionType.DELETE_VALID_DOMAIN:
            state.valid_domains = state.valid_domains.filter((tag: any, index: number) => index !== action.payload)
            return;
        //TESTED
        case ActionType.FETCH_ESSENTIALS_SETTINGS_COMPLETED:
            return {
                ...state,
                data_loaded: true,
                valid_domains: action.payload?.valid_domains,
                environment: action.payload?.environment,
                chat_configuration: action.payload?.chat_configuration,
                feature_config: action.payload?.feature_config || state.feature_config,
                landing_page_ui_config: action.payload?.landing_page_ui_config || state.landing_page_ui_config,
                logo: {
                    logo: action.payload?.logo?.logo ,
                    logoAlt: action.payload?.logo?.logoAlt,
                    light: action.payload?.logo?.light,
                    dark: action.payload?.logo?.dark,
                    system_light: action.payload?.logo?.system_light,
                    system_dark: action.payload?.logo?.system_dark,
                    system_logo_alt: action.payload?.logo?.system_logo_alt
                },
                search_ui: action.payload?.search_ui || state.search_ui,
                customer_notice: action.payload?.customer_notice || state.customer_notice,
                home_page_config: action.payload?.home_page_config || state.home_page_config,
            }
        //TESTED
        case ActionType.UPDATE_LOGO_COMPLETED:
            state.logo.logo = action.payload.logo
            return;
        case ActionType.UPDATE_LIGHT_LOGO_COMPLETED:
            state.logo.light = action.payload.light
            return;
        case ActionType.UPDATE_DARK_LOGO_COMPLETED:
            state.logo.dark = action.payload.dark
            return;
        //TESTED
        case ActionType.UPDATE_LOGOALT_COMPLETED:
            state.logo.logoAlt = action.payload.logoAlt
            return;
        case ActionType.UPDATE_SYSTEM_LOGO_ALT_COMPLETED:
            state.logo.system_logo_alt = action.payload.system_logo_alt
            return;
        case ActionType.UPDATE_SYSTEM_LIGHT_COMPLETED:
            state.logo.system_light = action.payload.system_light
            return;
        case ActionType.UPDATE_SYSTEM_DARK_COMPLETED:
            state.logo.system_dark = action.payload.system_dark
            return;
        //TESTED
        case ActionType.UPDATE_EMAIL_HEADER_LOGO_COMPLETED:
            state.email_header.email_header_logo = action.payload.email_header_logo
            return;
        //TESTED
        case ActionType.UPDATE_EMAIL_HEADER_LOGO_ALT_COMPLETED:
            state.email_header.email_header_logo_alt = action.payload.email_header_logo_alt
            return;

        case ActionType.ADD_PPT_TEMPLATE:
            return {
                ...state,
                ppt_templates: [
                    action.payload,
                    ...(state.ppt_templates||[])
                ]
            }
        case ActionType.DELETE_PPT_TEMPLATE:
            const idx = action.payload
            const new_ppt_templates = state.ppt_templates.filter((item, index) => index !== +idx)
            return {
                ...state,
                ppt_templates:new_ppt_templates
            }

        
        case ActionType.UPDATE_PPT_TEMPLATE:
            const newPpts =  state.ppt_templates.map( ppt => {
                return ppt.id == action.payload.id ?
                 action.payload : ppt
            })
            return {
                ...state,
                ppt_templates:newPpts
            }
    
        case ActionType.UPDATE_PPT_TEMPLATE_SUCCESS:
            return {
                ...state
            }

        case ActionType.UPDATE_PPT_TEMPLATE_FAILED:
            return {
                ...state
            }
        case ActionType.UPLOAD_PPT_TEMPLATE_SUCCESS:

            const newPPTs = state.ppt_templates.map((item:PptTemplateProps) => {
                if (action.payload.id === item.id) {
                    return {
                        ...item,
                        file: action.payload.file
                    }
                }
                return item
            })

            return {
                ...state,
                ppt_templates: newPPTs
            }
            return state
        case ActionType.UPLOAD_PPT_TEMPLATE_FAILED:
            return state;
        case ActionType.SET_SHOW_ADVANCE:
            return {...state, search_ui: {
                ...state.search_ui,
                showAdvance: action.payload
            }}
        case ActionType.SET_ADVANCE_FIELDS_SELECTIONS:
            return {
                ...state,
                search_ui: {
                    ...state.search_ui,
                    advanceFields: action.payload
                }
            }
        case ActionType.ADD_NEW_QUESTION_CONTEXT:
            return {
                ...state,
                chat_configuration: [...state.chat_configuration, action.payload]
            }

        case ActionType.REMOVE_QUESTION_CONTEXT:
            return {
                ...state,
                chat_configuration: state.chat_configuration.filter((tag: any, index: number) => index !== action.payload)
                }
        case ActionType.UPDATE_ANSWER_VARIANCE_THRESHOLD:
            return {
                ...state,
                answer_variance_threshold: {
                    ...state.answer_variance_threshold,
                    ...action.payload
                }}
        case ActionType.UPDATE_TEMPLATE_LIST:
            return{
                ...state,  templates_list: action.payload
            }
        case ActionType.UPDATE_CUSTOMER_NOTICE:
            return {
                ...state,
                customer_notice: action.payload
            }
        case ActionType.UPDATE_SHOW_CUSTOMER_NOTICE:
            return {
                ...state,
                show_customer_notice: action.payload
            }
        case ActionType.UPDATE_CUSTOMER_NOTICE_DISMISSED:
            return {
                ...state,
                customer_notice_dismissed: action.payload
            }
        default:
            return state;
    }
});

export default reducer;
