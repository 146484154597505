// This must be the first line in src/index.tsx
import "react-app-polyfill/stable";
import React from "react";
import {createRoot} from "react-dom/client";
import { Provider as HistoryProvider } from "great-history";
import { Provider as StoreProvider } from 'react-redux';
import { store as reduxStore } from './state';
import App from "App";
import "react-quill/dist/quill.snow.css";
import setupApp from "setupApp";

// setting up the app service worker and amplify hub listener and amplify configuration
setupApp();

// rendering the app
const container = document.getElementById("root");
if(container){
  const root = createRoot(container);
  root.render(
    <StoreProvider store={reduxStore}>
      <HistoryProvider>
        <App />
      </HistoryProvider>
    </StoreProvider>
  );
}