import { ActionType } from '../action-types'
import { tokenState } from 'types/token'


export const initialState : tokenState = {
    note: '',
    expirationTime: null,
    tokens:[],
    current_token: null,
    isToShowList: false,
    isShowTokenLoader:false,
    isTokenNameExists:false,
    tokenErrorMessage:"",
}

const reducer = (state: tokenState = initialState, action: any): tokenState => {
  switch (action.type) {
    case ActionType.SET_TOKEN_NOTE_VALUE:
      return { ...state, note: action.payload };
    case ActionType.SET_EXPIRATION_TIME:
      return { ...state, expirationTime: action.payload };
    case ActionType.GET_TOKENS:
      return { ...state, tokens: action.payload };
    case ActionType.IS_SHOW_TOKEN_LOADER:
      return { ...state, isShowTokenLoader: action.payload };
    case ActionType.IS_TOKEN_NAME_EXISTS:
      return { ...state, isTokenNameExists: action.payload };
    case ActionType.SET_TOKEN_ERROR_MESSAGE:
      return { ...state, tokenErrorMessage: action.payload };
    case ActionType.DELETE_TOKEN:
      const token_id = action.payload;
      const updatedItems = state.tokens.filter((item):any => item.token_id !== token_id);
      return {
        ...state,
        tokens: updatedItems,
      };
    case ActionType.ADD_TOKEN:
      return {...state, current_token: action.payload}
    case ActionType.UPDATE_PAGES_FOR_TOKEN:
      return { ...state, isToShowList: action.payload }
    default:
      return state;
  }
};
export default reducer;