import { ActionType } from '../action-types'
import { NotificationState } from 'types/notification';

  
export const initialState: NotificationState = {
    notifications: [],
    notificationHeaderState: "ALL",
    isNotificationLoaded:false,
    showNotificationAnswer: false,
    isNotificationsClear:false,
}

const reducer = (state: NotificationState = initialState, action: any): NotificationState => {
    switch(action.type) {
        case ActionType.SHOW_NOTIFICATION_ANSWER:
            return { ...state, showNotificationAnswer: action.payload };
        case ActionType.FETCH_NOTIFICATIONS_COMPLETED:
            return { ...state, notifications: action.payload, isNotificationsClear: false , isNotificationLoaded:false };
            case ActionType.FETCH_NOTIFICATION_LOADING_STATE:
                return { ...state, isNotificationLoaded: action.payload };
            case ActionType.CLEAR_NOTIFICATION_IS_LOADING:
                return { ...state,isNotificationsClear:action.payload };
        case ActionType.UPDATE_NOTIFICATION_HEADER_STATE:
            return { ...state , notificationHeaderState: action.payload };
        case ActionType.DELETE_NOTIFICATION_COMPLETED:
            state.notifications.filter((notification) => action.payload !== notification.id )
            return {...state , notifications: state.notifications.filter((notification) => action.payload !== notification.id )}
        case ActionType.MARK_NOTIFICATION_READ:
            return { ...state , notifications: state.notifications.map((notification) => {
                if(notification.id === action.payload) {
                    notification.read = true;
                } 
                return notification;
            }) }
        default:
            return state;
    }
}

export default reducer;