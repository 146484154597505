import { Dispatch } from "redux";
import { accessAllParameters, createParameters, deleteParameter, updateParameter } from "services/analyzerParameters";
import { ActionType } from "state/action-types";
import { alert } from "./customRouter";
interface AnalyzerParams {
  key: string;
  value: string;
}
type updateParams = {
  id: string;
  data: AnalyzerParams;
};
type createParameter = {
  parameter: AnalyzerParams;
};
export const updateAnalyzerParameters = (value: updateParams) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(parameterLoader(true));
      const response = await updateParameter(value);
      if(response.status === 200) {
        dispatch(updateAnalyzerParams(value));
        dispatch(parameterLoader(false));
      }else if(response.status === 409 || response.status === 500 || response.status === 403){
        dispatch(parameterLoader(false));
        dispatch(alert(`Error: ${response.body.error}`));
      }
      dispatch(parameterLoader(false));
    } catch(error) {
      dispatch(parameterLoader(false));
      console.log(error);
    }
  };
};

export const createAnalyzerParameters = (value: createParameter) => {
  return async (dispatch: Dispatch<any>) => {
    try {
     dispatch(parameterLoader(true));
      const response = await createParameters(value);
      if(response.status == 200) {
        dispatch({
          type: ActionType.CREATE_NEW_PARAMETERS,
          payload: response.body
        })
        dispatch(parameterLoader(false));
        dispatch(alert("Parameter Added Successfully!!!", {position: "bottom-left"}, "success"))
      }
      else if(response.status === 409 || response.status === 500 || response.status === 403){
        dispatch(parameterLoader(false));
        dispatch(alert(`Error: ${response.body.error}`));
      }
      dispatch(parameterLoader(false));
    } catch(error) {
      dispatch(parameterLoader(false));
      console.log(error);
    }
  };
};



export const fetchAnalyzerParameters = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(parameterPageLoader(true))
      const response = await accessAllParameters();
      if (response.status === 200) {
        dispatch(parameterPageLoader(false))
        dispatch({
          type: ActionType.GET_ANALYZER_PARAMETERS,
          payload: response.body.parameters,
        });
      }
      else if (response.status === 500 || response.status === 403){
        dispatch(parameterPageLoader(false));
        dispatch(alert(`Error: ${(response.body)?.error}`));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const parameterLoader = (value:boolean) => {
  return{
    type: ActionType.IS_PARAMETER_LOADER,
    payload: value
  }
}


export const parameterPageLoader = (value:boolean) => {
  return{
    type: ActionType.IS_PARAMETER_PAGE_LOADER,
    payload: value
  }
}

export const deleteAnalyzerParameters = (deleteId:string) => {
  return async (dispatch: Dispatch) => {
    try {
      const res = await deleteParameter(deleteId);
      if (res.status === 200) {
        dispatch({
          type: ActionType.DELETE_ANALYZER_PARAMETERS,
          payload: deleteId,
        });
        dispatch(alert(res.body.message, {position: "bottom-left"}, "success"))
      }else{
        dispatch(alert((res?.body)?.error));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const updateAnalyzerParams = (values:updateParams) => {
  return {
    type: ActionType.UPDATE_ANALYZER_PARAMETERS,
    payload:values ,
  };
};

