import { Colors } from "style/color";

export const compareVariationColors = {
    dark:{
        WrapperBgColor:'rgb(50, 51, 70)',
        spanColor: Colors.WHITE,
        hrBorderColor: Colors.WHITE,
        QuickLinksCardBorderColor:'#009788',
        AddNewQuestionHeadingcolor:Colors.WHITE,
        DifferenceBtnBgcolor: Colors.WHITE,
        jsonWrapperBgColor:'#323346',
        jsonWrappertextColor: Colors.WHITE,
        EditHeaderInputBorderColor: Colors.WHITE,
        HelpIconColor: Colors.WHITE,
        UserContextEveryOneIconColor:  Colors.WHITE,
        readoutBgColor: "#323346",
        readOutContent:  Colors.WHITE,
        readOutTableContent:  Colors.WHITE,
        readOutTableBorder: Colors.TEXT_GRAY,
        readoutTableListBorder:"#78787869",
        readoutTableTdColor: Colors.WHITE,
        PopUpTextColor: Colors.WHITE,
        warningMessageColor: Colors.WHITE,
    },
    light:{
        WrapperBgColor: Colors.WHITE,
        spanColor: Colors.DARK_CHARCOAL,
        hrBorderColor:'#0000001a',
        QuickLinksCardBorderColor: Colors.TRANSPARENT,
        AddNewQuestionHeadingcolor:'#525264',
        DifferenceBtnBgcolor:'rgba(50,57,88,0.1)',
        jsonWrappertextColor:'#212529',
        EditHeaderInputBorderColor:'rgba(0, 0, 0, 0.13)',
        HelpIconColor:Colors.BLACK,
        UserContextEveryOneIconColor: "#969696",
        diffBtnBorderColor: "#E3E3E3",
        diffBtnShadowColor:  Colors.WHITE,
        compareJsonBGColor:  Colors.WHITE,
        readoutBgColor:  Colors.WHITE,
        readOutContent: Colors.BLACK,
        HeaderLastChildColor: "#ebebeb",
        PopUpTextColor: Colors.BLACK,
        readoutTableTdColor:'#787878',
        readOutTableContent: Colors.DARK_CHARCOAL,
        readOutTableBorder: Colors.TEXT_GRAY,
        readoutTableListBorder:"#78787869",
        warningMessageColor: Colors.DARK_CHARCOAL,
    },
}