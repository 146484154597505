export enum ActionType {
     INSERT_VALID_DOMAIN = 'insert_valid_domain',
     DELETE_VALID_DOMAIN = 'delete_valid_domain',
     FETCH_VALID_DOMAINS = 'fetch_valid_domains',

     FETCH_APP_SETTINGS_COMPLETED = 'fetch_app_settings_completed',
     FETCH_ESSENTIALS_SETTINGS_COMPLETED = 'fetch_essentials_settings_completed',
     FETCH_SYSTEM_STATUS_COMPLETED = 'fetch_system_status_completed',
     START_SERVERS = 'start_servers',

     PASSWORD_RESET_COMPLETED = 'password_reset_completed',
     UPDATE_DARK_LOGO_COMPLETED="update_light_logo_completed",
     UPDATE_LIGHT_LOGO_COMPLETED="update_dark_logo_completed",
     UPDATE_LOGO_COMPLETED = 'update_logo_completed',
     UPDATE_LOGOALT_COMPLETED = 'update_logoalt_completed',
     UPDATE_EMAIL_HEADER_LOGO_COMPLETED = 'update_email_header_logo_completed',
     UPDATE_EMAIL_HEADER_LOGO_ALT_COMPLETED = 'update_email_header_logo_alt_completed',
     UPDATE_SYSTEM_LIGHT_COMPLETED = 'update_system_light_completed',
     UPDATE_SYSTEM_DARK_COMPLETED = 'update_system_dark_completed',
     UPDATE_SYSTEM_LOGO_ALT_COMPLETED = 'update_system_logo_alt_completed',

     SIGN_IN_USER_SUCCESS = 'auth__sign_in_user_success',
     SIGN_OUT_USER_SUCCESS = 'auth__sign_out_user_success',

     REGISTER_USER_SUCCESS = 'auth__register_user_success',

     AUTH_USER = 'auth_user',

     SIGN_IN_EMAIL_CHANGED = 'auth__sign_in_email_changed',
     SIGN_IN_PASSWORD_CHANGED = 'auth__sign_in_password_changed',

     FORGOT_EMAIL_CHANGED = 'auth__forgot_email_changed',

     UPDATE_THEME = 'update_theme',
     UPDATE_SHOW_CUSTOMER_NOTICE = 'update_show_customer_notice',
     UPDATE_CUSTOMER_NOTICE_DISMISSED = 'update_customer_notice_dismissed',

     // LOADING EVENTS
     SIGN_IN_LOADING_CHANGE = 'sign_in_form_loading_updated',
     REGISTER_LOADING_CHANGE = 'register_form_loading_updated',
     CONFIRM_ACCOUNT_FORM_LOADING_CHANGE = 'confirm_account_form_loading',
     STATE_PASSWORD_RESET_LOADING = 'start_password_reset_loading',
     CHANGE_PASSWORD_LOADING = 'change_password_loading',
     EXPORT_ACTION_LOADING = 'export_action_loading',
     ADD_NEW_STORY_LOADING = 'add_new_story_loading',




     REGISTER_EMAIL_CHANGED = 'auth__register_email_changed',
     REGISTER_PASSWORD_CHANGED = 'auth__register_password_changed',
     RESET_PASSWORD_CODE_CHANGE = 'reset_password_code_change',
     RESET_PASSWORD_DATA_CHANGE = 'reset_password_data_change',

     ACCOUNT_TO_CONFIRM = 'account_to_Confirm_updated',
     REDIRECT = 'redirect',
     VERIFY_CODE_CHANGED = 'auth__verify_code_change',
     RESET_REDIRECT = 'reset_redirect',

     APP_LOADED = 'app_loaded',

     UPDATE_PASSWORD_RESET_STATE = 'update_password_reset_state',

     // COMMON
     REDUX_ALERT_CHANGE = 'redux_alert_change',
     UPDATE_IS_MENU_OPEN = 'update_is_menu_open',
     UPDATE_HIDE_LOGO = 'update_hide_logo',
     UPDATE_EXPLANATION_SHOWN = 'update_explanation_shown',
     REDUX_STATE_RESET = "redux_state_reset",

     UPDATE_LOCAL_USER_SETTING = 'update_local_user_setting',
     SET_EXPANDED_ANSWER_COMPONENT_INDEX = 'set_expanded_answer_component_index',
     UPDATE_PORTAL_TYPE = 'update_portal_type',

     // NOTIFICATION
     FETCH_NOTIFICATIONS_COMPLETED = 'fetch_notifications_completed',
     FETCH_NOTIFICATION_LOADING_STATE="fetch_notification_loading-state",
     UPDATE_NOTIFICATION_HEADER_STATE = 'update_notification_header_state',
     DELETE_NOTIFICATION_COMPLETED = 'delete_notification_completed',
     MARK_NOTIFICATION_READ = 'mark_notification_read',
     CLEAR_NOTIFICATION_IS_LOADING = "clear_notification_is_loading",
     // REGISTER_PASSWORD_CONFIRM_CHANGED	= 'auth__register_password_confirm_changed',
     SHOW_NOTIFICATION_ANSWER = 'show_notification_answer',


     // FEEDBACK
     FETCH_FEEDBACK_COMPLETED = 'fetch_feedback_completed',
     UPDATE_FEEDBACK_COMPLETED = 'update_feedback_completed',
     UPDATE_FEEDBACK_HAS_MORE = 'updade_feedback_completed',
     UPDATE_FEEDBACK_LOADING = 'update_feedback_loading',
     UPDATE_FEEDBACK_STATUS = 'update_feedback_status',
     UPDATE_STATUS_PROCESSING = 'update_status_processing',

     // Splat
     FETCH_SPLATS_COMPLETED = 'fetch_splats_completed',
     UPDATE_SPLAT_COMPLETED = 'update_splat_completed',
     UPDATE_SPLAT_FILTER = 'update_splat_filter',
     UPDATE_SPLATS_STATUS = 'update_splats_status',
     SPLAT_REFRESH_START = 'splat_refresh_start',
     SPLAT_REFRESH_COMPLETED = 'splat_refresh_completed',
     SPLAT_REFRESH_FAILED = 'splat_refresh_failed',
     SEARCH_SPLATS_TERM = 'search_splats',

     // QuestionBar
     BLUE_QUESTION_INPUT = 'blur_question_input',
     FOCUS_QUESTION_INPUT = 'focus_question_input',
     RESET_QUESTION_INPUT = 'reset_question_input',
     HYDRATE_QUESTION_INPUT = 'hydrate_Question_input',
     SET_QUESTION_INPUT_PLACEHOLDER = 'set_question_input_placeholder',
     STREAM_RECOGNIZING_FRAGMENT = 'stream_recognizing_fragment',
     STREAM_SPEECH_INPUT = 'stream_speech_input',
     STREAM_SPEECH_INPUT_FROM_COMMAND_MODE = 'stream_speech_input_from_command_mode',
     STREAM_QUESTION_INPUT = 'stream_question_input',
     LOAD_SUGGESTIONS = 'load_suggestions',
     CLEAR_SUGGESTIONS = 'clear_suggestions',
     UPDATE_SUGGESTION_INDEX = 'update_suggestion_index',
     ADD_SEARCH_SUGGESTION = 'add_search_suggestion',

     // QuestionNavigation
     UPDATE_NAVIGATION_QUESTION_TAB = 'update_navigation_question_tab',
     UPDATE_NAVIGATION_QUESTION_OPEN = 'update_navigation_question_open',
     UPDATE_ACTIVE_QUICK_LINK_INDEX = 'update_active_quick_link_index',
     UPDATE_ACTIVE_QUICK_QUESTION_INDEX = 'update_active_quick_question_index',
     UPDATE_ACTIVE_QUICK_SUGGESTION_INDEX = 'update_active_quick_suggestion_index',
     NAVIGATION_ACTIVE_TAB = 'navigation_active_tab',
     SHOW_QUICK_LINK_CONTEXT = 'show_quick_link_context',
     HIDE_QUICK_LINK_CONTEXT = 'hide_quick_link_context',
     UPDATE_WORKFLOW_ANSWER_STATUS = 'update_workflow_answer_status',

     // QuickLinks
     UPDATE_QUICK_LINKS_CATEGORIES = 'update_quick_links_categories',
     UPDATE_USER_CONTEXT = "update_user_context",
     UPDATE_CURRENT_USER_CONTEXT = "update_current_user_context",
     UPDATE_QUICK_LINK_LOADING = 'update_quick_link_loading',
     QUICK_LINKS_LOADER_ON_PAGE_LOAD = 'update_links_loader_on_page_load',
     UPDATE_USER_CONTEXT_LOADING = 'update_user_context_loading',
     SET_COMPARE_ANSWER_VIEW = "set_compare_answer_view",
     STORE_COMPARE_ANSWER_DATA = "store_compare_answer_data",
     SHOW_JOB_LOGS_COMPUTED_BY_USER = "show_job_logs_computed_by_user",
     SHOW_JOB_LOGS_COMPUTED_BY_CLI = "show_job_logs_computed_by_cli",
     UPDATE_TO_SHOW_JOBS_LOGS = "update_to_show_jobs_logs",
     UPDATE_QUICK_LINK_FOCUS_LOADING = "update_quick_link_focus_loading",
     UPDATE_QUICK_LINK_SOURCE_LOADING = "update_quick_link_source_loading",
     // UserHistory
     FETCH_USER_HISTORY_COMPLETED = 'fetch_user_history_completed',
     UPDATE_FETCH_STATE = 'update_fetch_state',
     UPDATE_HISTORY_INDEX = 'update_history_index',
     ADD_HISTORY_ENTRY = 'add_history_entry',
     UPDATE_HISTORY = 'update_history',
     UPDATE_HISTORY_FROM_CHAT = 'update_history_from_chat',
     UPDATE_USER_HISTORY_ENTRY = 'update_user_history_entry',
     UPDATE_CHART_OPTION_USER_HISTORY = 'update_chart_option_user_history',
     UPDATE_USER_HISTORY_VISUAL_BY_UTTERANCE_ID = 'update_user_history_visual_by_utterance_id',
     UPDATE_USER_HISTORY_VISUAL_BY_QUESTION = 'update_user_history_visual_by_question',
     UPDATE_LOADING_SINGLE_HISTORY = 'update_loading_single_history',
     FETCH_USER_HISTORY_DATE = "fetch_user_history_date",
     UPDATE_HISTORY_DATE = 'update_hostory_date',
     UPDATE_USER_HISTORY_JOB_ID = 'update_user_history_job_id',
     ADD_PRIMARY_FIELDS_DATA = 'add_primary_fields_data',
     IS_ERROR_WHILE_SEARCH = 'is_error_while_search',


     // PdfViewer
     UPDATE_PDF_ALLHIGHLIGHTS = 'update_pdf_allhighlights',
     UPDATE_HIGHLIGHT_TEXT = 'update_highlight_text',
     UPDATE_PDF_URL = 'update_pdf_url',
     UPDATE_PDF_VIEWER = 'update_pdf_viewer_status',
     UPDATE_PDF_HIGHLIGHT_PAGE_NUMBER = 'update_pdf_highlight_page_number',
     UPDATE_ALL_LINKS_PARTS = 'update_all_links_parts',

     //PPT
     ADD_PPT_TEMPLATE = "add_ppt_template",
     UPDATE_PPT_TEMPLATE = "update_ppt_template",
     DELETE_PPT_TEMPLATE = "delete_ppt_template",
     UPLOAD_PPT_TEMPLATE = 'upload_ppt_template',
     UPLOAD_PPT_TEMPLATE_SUCCESS = 'upload_ppl_template_success',
     UPLOAD_PPT_TEMPLATE_FAILED = 'upload_ppl_template_failed',
     UPDATE_PPT_TEMPLATE_SUCCESS = 'update_ppl_template_success',
     UPDATE_PPT_TEMPLATE_FAILED = 'update_ppl_template_failed',

     //Search UI
     UPDATE_SEARCH_UI_SUCCESS = 'update_search_ui_success',
     UPDATE_SEARCH_UI_FAILED = 'update_search_ui_failed',
     SET_SHOW_ADVANCE = 'set_show_advance',
     SET_ADVANCE_FIELDS_SELECTIONS = 'set_advance_fields_selections',

     // Stories
     FETCH_STORIES_COMPLETED = 'fetch_stories_completed',
     UPDATE_STORIES_SPINNER = 'update_stories_spinner',


     // Stories > Present Mode
     EXIT_PRESENT_MODE = 'exit_present_mode',
     GO_TO_NEXT_SLIDE = 'go_to_next_slide',
     GO_TO_PREVIOUS_SLIDE = 'go_to_previous_slide',
     UPDATE_STORIES_PRESENT_MODE = 'update_stories_present_mode',

     // STORY
     UPDATE_STORY_EXPORT_TYPE = 'update_story_export_type',
     CLEAR_STORY_ACTIVE_BAR_ACTIVEACTION = 'clear_story_active_bar_activeAction',
     UPDATE_STORY_ACTIVE_ACTION = 'update_story_active_action',

     //   Slide
     STORIES_SPINNER_ON_CROSS = 'stories_spinner_on_cross',
     UPDATE_STORIES_HANDLE_CLICK = 'update_handle_click',
     UPDATE_SLIDE_JUST_ADDED = 'update_slide_just_added',
     CLEAR_SLIDE_JUST_ADDED = 'clear_slide_just_added',
     SET_SLIDE_CONTENT_WITH_STORY_INDEX_SLIDE_INDEX = 'set_slide_content_with_story_index_slide_index',
     ADD_SLIDE_INDEX = 'add_slide_to_index',
     ADD_SLIDE = 'add_slide',
     DELETE_SLIDE = 'delete_slide',
     ADD_STORY_TO_INDEX = 'add_story_to_index',
     ADD_STORY = 'add_story',
     REMOVE_STORY = 'remove_story',
     CLOSE_STORY_OPTIONS_BY_INDEX = 'close_story_options_by_index',
     CLOSE_ALL_STORY_OPTIONS = 'cloase_all_story_options',
     SET_ACTIVE_SLIDE = 'set_active_slide',
     TOGGLE_IS_STORY_OPTIONS_OPEN_ACTION = 'toggle_is_story_options_open_action',
     UPDATE_SLIDE_PREVIEW = 'update_slide_preview',
     UPDATE_IS_STORY_OPEN = 'update_is_story_open',
     TOGGLE_IS_STORY_OPEN = 'toggle_is_story_open',
     UPDATE_STORY_TITLE = 'update_story_title',
     ADD_NEW_STORY_SLIDER_LOADER = "add_new_story_slider_loader",
     UPDATE_SLIDE_LOADER = "update_slide_loader",

     // ANSWER
     SEARCH_ERROR = 'search_error',
     HYDRATE_VISUAL_ANSWER_COPY = 'hydrate_visual_answer_copy',
     UPDATE_SEARCH_TABLE_HAS_MORE = 'update_search_table_has_more',
     HYDRATE_ANSWER_COPY = 'hydrate_answer_copy',
     UPDATE_NEWTABLE_VALUE = 'update_newtable_value',
     SEARCH_VALUE_CLEARED = 'search_value_cleared',
     FETCH_NEWTABLE_COMPLETED = 'fetch_newtable_completed',
     UPDATE_NEWTABLE_HAS_MORE = 'update_newtable_has_more',
     UPDATE_ANSWER_COMPLETED = 'update_answer_completed',
     FETCH_ANSWER_COMPLETED = 'fetch_answer_completed',
     HYDRATE_ANSWER = 'hydrate_answer',
     UPDATE_ANSWER_STATE = 'update_answer_state',
     UPDATE_CURRENT_VISUAL = 'update_current_visual',
     CLEAR_ANSWER = 'clear_answer',
     PRE_ASK_FAILED = 'pre_ask_failed',
     UPDATE_LAYOUT = 'update_layout',
     SET_WAVE_LOADER = 'set_wave_loader',
     UPDATE_PIE_CHART_RANGE = 'update_pie_chart_range',
     UPDATE_BAR_LAYOUT = 'update_bar_layout',
     UPDATE_FONT_SIZE = 'update_font_size',
     UPDATE_GROUP = 'update_group',
     LOW_HIGH_SWITCH = 'low_high_switch',
     SELECTED_PREVIOUS_QUESTION_INDEX = "selected_previous_question_index",
     UPDATE_RANGE_FOR_BAR_VISUAL = 'update_range_for_bar_visual',
     UPDATE_TEXT_FONT_SIZE = 'update_text_font_size',
     UPDATE_PADDING_FOR_BAR_CHART = 'update_padding_for_bar_chart',
     UPDATE_PADDING_FOR_BAR_CHART_VERTICAL = 'update_padding_for_bar_chart_vertical',
     DEMO_ANSWER_DATA = 'demo_answer_data',
     UPDATE_SEARCH_TERM = 'update_search_term',
     UPDATE_FEEDBACK_CHART_OPTIONS = 'update_feedback_chart_options',
     UPDATE_BARCHART_REVERSE = 'update_barchart_reverse',
     UPDATE_INNER_RADIUS = 'update_inner_radius',
     UPDATE_SEARCHABLES = 'update_searchables',
     UPDATE_SEARCHED_AND_SELECTED = 'update_searched_and_selected',
     UPDATE_LABEL_SKIP_SIZE = 'update_label_skip_size',
     UPDATE_CIRCLE_PACKING_LEAVES_ONLY = 'update_circle_packing_leaves_only',
     UPDATE_CIRCLE_PACKING_PADDING = 'update_circle_packing_padding',
     UPDATE_TABLE_FILTER_INDEX = 'update_table_filter_index',
     UPDATE_CHART_OPTIONS = 'update_chart_options',
     ANALYZER_REBUILD_INDEX_LOADER = 'analyzer_rebuild_index_loader',
     UPDATE_SHOW_LEGEND = 'update_show_legend',
     UPDATE_SEARCHED_AND_SELECTED_LINE_CHART = 'update_searched_and_selected_line_chart',
     UPDATE_CURRENT_VISUAL_JOB_ID_AND_COMPUTE_ROUTE = 'update_current_visual_job_id_and_compute_route',
     UPDATE_ANSWER_COMPONENTS = 'update_answer_components',
     TOGGLE_ANSWER_SIDEBAR = 'toggle_answer_sidebar',
     UPDATE_EXPORT_IN_PROGRESS = 'update_export_in_progress',
     ALIAS_TABLE_SEARCH_TERM='alias_table_search_term',
     CHECK_AND_UPDATE_ELASTIC_INDEXED = 'check_and_update_elastic_indexed',
     UPDATE_TABLE_SEARCH_INDEXED = 'update_table_search_indexed',
     SET_ANSWER_SUBSCRIPTION = 'set_answer_subscription',
     UPDATE_TITLE_CASE = "update_title_case",
     UPDATE_TITLE_IN_USER_HISTORY = "update_title_in_user_history",
     UPDATE_UUID_CASE = "update_uuid_case",
     UPDATE_QUESTION_TITLE_FOR_EMPTY_SCREEN = "update_question_title_for_empty_screen",
     UPDATE_TABLE_CHIPS = "update_table_chips",
     UPDATE_ASKED_QUESTION_UID="update_asked_question_uid",

     // ACTIONBAR
     UPDATE_EXPORT_TYPE = 'update_export_type',
     UPDATE_ACTION_FEEDBACK_ERROR = 'update_action_feedback_error',
     UPDATE_ACTIVE_ACTION = 'update_active_action',
     UPDATE_FEEDBACK_TEXT = 'update_feedback_text',
     CLEAR_CURRENT_ACTION = 'clear_current_action',
     UPDATE_FEEDBACK_JUST_SUBMITTED = 'update_feedback_just_submitted',
     UPDATE_SEARCHABLES_LINE_CHART = 'update_searchables_line_chart',
     SET_AND_UPDATE_REPORT_MARKDOWN_EXPORT = "set_and_update_report_markdown_export",
     GET_AND_UPDATE_EXPORT_ID = "get_and_update_export_id",
     ADD_NEW_ITEM_TO_EXPORT = "add_new_item_to_export",
     EMPTY_STATE_AFTER_DOWNLOAD_REPORT = "empty_state_after_download_report",

     // Analyzer Dashboard

     FETCH_WORKERS_DETAILS = 'fetch_workers_details',
     FETCH_WORKER_BY_ELASTIC_SEARCH = 'fetch_workers_by_elastic_search',
     SET_ROCKET_SEARCH_OPERATOR='set_rocket_search_operator',
     UPDATE_WORKERS_HAS_MORE = 'update_workers_hasmore',
     FETCH_JOBS_DETAILS = 'fetch_jobs_details',
     ANALYZER_REFRESH_BUTTON = 'analyzer_refresh_button',
     UPDATE_COLUMNS_FILTER_CLICKED = 'update_columns_filter_clicked',
     SPLAT_COLUMNS_FILTER_CLICKED = 'splat_columns_filter_clicked',
     WORKER_UPDATE_COLUMNS_FILTER_CLICKED = 'worker_update_columns_filter_clicked',
     QUEUEJOBS_UPDATE_COLUMNS_FILTER_CLICKED = 'queuejobs_update_columns_filter_clicked',
     UPDATE_PRE_GLOBAL_FILTERED_ROWS = 'update_pre_Global_Filtered_Rows',
     UPDATE_ROWS = 'update_rows',
     UPDATE_TOTAL_COLUMNS_WIDTH = 'update_total_columns_width',
     UPDATE_DATA_TRANSFORMING = 'update_data_transforming',
     UPDATE_AUTO_SAVE = 'update_auto_save',
     HIDE_ACTION_BAR = 'hide_action_bar',
     CURRENT_TAB = 'current_tab',
     ANALYZER_REFRESHING = 'analyzer_refreshing',
     WORKER_QUEUE_STATUS = "worker_queue_status",
     FETCH_WORKER_BY_STATUS = "fetch_worker_by_status",
     FETCH_JOBS_BY_STATUS = "fetch_jobs_by_status",
     FETCH_SQS_WORKER_CONFIG = "fetch_sqs_worker_config",
     UPDATE_SQS_WORKER_CONFIG = "update_sqs_worker_config",
     SET_ERROR_WORKERS_JOBS = "set_error_workers_jobs",
     SEARCH_WORKERS_JOBS = "search_workers_jobs",
     DISABLED_EXPORT_BUTTON = 'disabled_export_button',
     SPINNER_FOR_DOWNLOAD_PDF = 'spinner_for_download_pdf',
     CHECKING_PDF_STATUS = 'checking_pdf_status',
     SHOW_DOWNLOAD_PDF_BUTTON = 'show_download_pdf_button',
     SET_PDF_STATUS_VALUE = 'set_pdf_status_value',
     SEARCH_DROPDOWN_OPERATOR = 'search_dropdown_operator',
     EQUALS_SEARCH_QUERY = 'equals_search_query',
     EQUALS_SEARCH_QUERY_FOR_ANALYZER = 'equals_search_query_for_analyzer',
     CONTAINS_SEARCH_QUERY = 'contains_search_query',
     CONTAINS_SEARCH_QUERY_FOR_ANALYZER = 'contains_search_query_for_analyzer',
     ANALYZER_SEARCH_SELECTED_CHIPS = 'analyzer_search_selected_chips',
     ANALYZER_CONTAINS_SEARCH='analyzer_contains_search',
     IS_TRIM_DESCRIPTION = 'is_trim_description',
     IS_ADJUST_WIDTH = 'is_adjust_width',
     IS_TABLE_SEARCH = 'is_table_search',
     SET_SEARCH_TERM_VALUE = 'set_search_term_value',
     FETCH_JOBS_BY_ELASTIC_SEARCH='fetch_jobs_by_elstic_search',
     SET_ROCKET_ANALYZER_SEARCH_IN_PROGRESS = "set_rocket_ananlyzer_search_in_progress",
     UPDATE_TERMINATING_STATUS = "update_terminating_status",
     SET_PDF_WAVE_LOADER='set_pdf_wave_loader',
     UPDATE_HEADER_SUGGESTIONS = 'update_header_suggestions',
     SET_WORKERS_SEARCH_VALUE='set_workers_search_value',
     SET_WORKERS_CONTAIN_VALUE='set_workers_contain_value',
     ANALYZER_JOBS_SEARCH_SELECTED_CHIPS='set_jobs_search_selected_selected_chips',
     UPDATE_ROCKET_ANALYZER_EXPORT_LOADER = "update_rocket_analyzer_export_loader",
     UPDATE_ROCKET_ANALYZER_TABLE_SORTING = "update_rocket_analyzer_table_sorting",
     SHOW_SEARCH_DURATION = "show_search_duration",

     // UPDATE_SELECTED_SEARCH_DATA = 'update_selected_search_data',
     SET_LOADING_ELASTIC_SEARCH = 'set_loading_elastic_search',
     OPERATION_LAYER_COMPARE_DATA = 'operation_layer_compare_data',
     SET_LOADING_WORKERS_JOBS = 'set_loading_workers_jobs',
     UPDATE_SPLAT_HAS_MORE = 'update_splat_has_more',
     UPDATE_ANSWER_HAS_MORE = 'update_answer_has_more',
     UPDATE_SUBSCRIPTON_STATUS = 'update_subscription_status',
     UPDATE_USER_HISTORY_SUBSCRIPTION = "update_user_history_subscripton",
     SET_LOADING_SPLAT = 'SET_LOADING_SPLAT',
     SPLAT_RECORD_LOADING = 'splat_record_loading',
     NEWTABLE_RECORD_LOADING = 'newtable_record_loading',
     UPDATE_SUBSCRIPTION_HISTORY_PDF_STATUS_BY_UTTERANCE_ID = 'update_subscription_history_pdf_status_by_utterance_id',
     UPDATE_IS_EXPAND_SIDE_BAR = 'update_is_expand_side_bar',

     // SUBSCRIPTON ANSWER 
     UPDATE_SUBSRIPTION_HISTORY = "update_subscripton_history",
     SUBSCRIPTION_HISTORY = "subscription_history",
     SET_LOADING_SUBSCRIPTION_LIST = "set_loading_subscription_list",
     REMOVE_SUBSCRIPTION = "remove_subscription",
     UPDATE_CURRENT_ANSWER_STATE = "update_current_answer_state",
     UPDATE_PREVIOUS_ANSWER_STATE = "update_previous_answer_state",
     SET_SUBSCRIBE_BUTTON_LOADING = "set_subscribe_button_loading",
     SET_SUBSCRIBE_QUESTION_CHARTS = "set_subscribe_question_charts",
     SET_ISSUBSCRIBE = "set_isSubscribe",
     ADD_UTTERENCE_ID = "add_utterence_id",
     UNSUBSCRIBE_QUESTION_INDEX = 'unsubscribe_question_index',
     SET_DASHBOARD_QUESTION_CHART_LOADING = 'set_dashboard_question_chart_loading',
     SET_SELECTED_MONTH_CHANGED_QUESTION_DATES = 'set_selected_month_changed_question_dates',
     SET_CALENDAR_LOADING = "set_calender_loading",
     SHOW_CALENDAR = "show_calendar",
     CURRENT_SELECTED_MONTH = "current_selected_month",
     SELECTED_DATE_QUESTION_DATA = "selected_date_question_data",
     CHART_LOADING = "chart_loading",
     CURRENT_QUESTIION_TIMESTAMP = "current_question_timestamp",
     UPDATE_SUBSCRIPTION_LIST = "update_subscription_list",
     USER_SUBSCIPTION_LIST_INDEX = "user_subscription_list_index",
     DISPLAY_SUBSCRIBED_QUESTIONS = 'display_subscribed_questions',
     UPDATE_SUBSCRIBE_QUESTION_DATE = 'update_subscribe_question_dates',
     UPDATE_SUBSCRIPTION_DATA = 'update_subscription_data',
     UPDATE_SEARCH_VALUE = 'update_search_value',
     UPDATE_SORT_VALUE = 'update_sort_value',
     UPDATE_CHART_OPTION_SUBSCRIPTION_HISTORY = 'UPDATE_CHART_OPTION_SUBSCRIPTION_HISTORY',
     UPDATE_USER_SUBSCRIPTION_VISUAL_BY_UTTERANCE_ID = 'UPDATE_USER_SUBSCRIPTION_VISUAL_BY_UTTERANCE_ID',
     ERROR_SUBSCRIPTION_SEARCH = 'error_subscription_search',

     // COMPARE QUESTIONS 
     COMPARE_QUESTIONS = 'compare_questions',
     LEFT_QUESTION_VISUAL = 'left_question_visual',
     RIGHT_QUESTION_VISUAL = "right_question_visual",
     LEFT_QUESTION_LOADING = 'left_question_loading',
     RIGHT_QUESTION_LOADING = "right_question_loading",
     COMPARE_QUESTION_DATE = "compare_question_date",
     COMPARE_QUESTION_SNAPSHOTS = "compare_question_snapshots",
     NEW_TABLE_SEARCH_TERM = "new_table_search_term",
     SET_WORKER_STATUS = 'set_worker_status',

     //Edit answer and alias form
     EDIT_FORM_OPEN_OR_CLOSE = "edit_form_open_or_close",
     CHANGE_CLOSE_BTN_STATE = "change_close_button_state",
     GET_LISTING_RESPONSE = "get_listing_response",
     IS_LOADING_ALIAS = 'is_loading_alias',
     SEARCHED_TOTAL_RECORDS = 'searched_total_records',
     IS_SEARCH_PROGRESS='is_search_progress',
     SUGGESTION_LISTING='suggestion_listing',
     SUGGESTION_SEARCH_LIST='suggestion_search_list',
     ALIAS_SEARCH_TERM = 'alias_search_term',
     ALIAS_SEARCH_OPERATOR='alias_search_operator',
     ALIAS_CONTAINS_SEARCH_INPUT='alias_contains_search_input',
     ALIAS_EQUALS_SEARCH_INPUT='alias_equals_search_input',
     SEARCH_ALIAS_HEADER_VALUE='search_alias_header_value',
     ALIAS_LOADER_TABLE='alias_loader_table',

     // INSERT_ASLIAS_FORM_DATA = "insert_alias_form_data",
     EDIT_SEARCH_ALIAS_BUTTON_STATE = 'edit_search_alias_button_state',
     ALIAS_SPINNER_ON_DELETE = 'alias_spinner_on_delete',
     ALIAS_SPINNER_ON_UPDATE = 'alias_spinner_on_update',
     Alias_SET_ID = 'alias_set_id',
     ALIAS_UPDATED_BY_QUESTION = 'alias_updated_by_question',
     ALIAS_SET_NEW_SEARCH_TERM = 'alias_set_new_search_term',

     GET_ALIAS_SCHEMA = 'get_alias_schema',
     UPDATE_ALIAS_SCHEMA = 'update_alias_schema',
     UPDATED_ALIAS = 'updated_alias',
     UPDATE_ALIAS_ERROR = 'update_alias_error',
     ADD_NEW_ALIAS_SUCCESS = 'add_new_alias_success',
     ADD_NEW_ALIAS_SUCCESS_RE_RUN = 'add_new_alias_success_re_run',
     UPDATE_ALIAS_SUCCESS_RE_RUN = 'update_alias_success_re_run',
     UPDATE_ALIAS_SUCCESS = 'update_alias_success',
     UPDATED_ALIAS_LIST = 'updated_alias_list',
     DELETE_ALIAS_IN_PROGRESS = 'delete_alias_in_progress',
     DELETE_ALIAS_SUCCESS = 'delete_alias_success',
     DELETE_ALIAS_FAILED = 'delete_alias_failed',
     SHOW_LOADER_ON_SUBMIT_ALIAS = 'show_loader_on_submit_alias',
     ADD_NEW_ALIAS_DATA = 'add_new_alias_data',
     DELETE_SELECTED_ALIAS_FROM_LIST = 'delete_selected_alias_from_list',
     SET_AND_UPDATE_ALIAS_ROW_ID = 'set_and_update_alias_row_id',
     IS_ALIAS_FORM_LOADER='is_alias_form_loader',

     // CREATE_ALIAS_ERROR_MESSGAE = 'create_alias_error_message', // CREATE_ALIAS_ERROR_MESSGAE = 'create_alias_error_message',

     // Workflow indexes
     SET_TOGGLE = 'set_toggle',
     GET_WORKFLOW_TYPES = 'get_workflow_types',
     SAVE_WORKFLOW_QUESTIONS = 'save_workflow_questions',
     SAVE_EXISTING_WORKFLOWS = "save_existing_workflows",
     SHOW_WORKFLOW_ANSWER = 'show_workflow_answer',
     UPDATE_UTTERANCE_DATE = "update_utterance_data",
     SHOW_WORKFLOW_QUESTION = "show_workFlow_question",
     WORKFLOW_HEADER_LOADING = "workflow_Header_laoding",
     WORKFLOW_QUESTION_PAGE_LOADING = "workflow_question_page_loading",
     SELECTED_WORKFLOW_INDEX = "selected_workFlow_index",
     SELECTED_WORKFLOW_QUESTIONS = "selected_workFlow_questions",
     SHOW_WORKFLOW_HEADER = "show_workflow_header",
     SELECTED_WOKRFLOW_NAME = "selected_workflow_name",
     SELECTED_WOKRFLOW_INDEX = 'selected_workflow_index',
     UPDATE_WORKFLOW_HISTORY_PDF_STATUS_BY_UTTERANCE_ID= 'update_workflow_history_pdf_status_by_utterance_id',
     UPDATE_USER_WORKFLOW_VISUAL_BY_UTTERANCE_ID = 'update_user_workflow_visual_by_utterance_id',

     // TOP NAV BAR 
     CHANGE_TOP_NAV_TYPE = "change_top_nav_type",
     SHOW_ANSWER_ON_SUBSCRIBED_ANSWER = 'show_answer_on_subscribed_answer',
     // FILTER_ACCORDING_TO_DATE = "filter_according_to_date",

     //Recently asked question user history
     RECENT_HISTORY_RECORDS = 'recent_hostory_records',
     QUESTION_CONTEXT_TOP_NAVBAR = 'question_context_top_navbar',
     FILTER_ACCORDING_TO_DATE = "filter_according_to_date",
     OPEN_SEARCH_SUGGESTION = "open_search_suggestion",
     SPINNER_ON_DELETE = 'spinner_on_delete',
     SPINNER_ON_VIEW_MORE = 'spinner_on_view_more',
     RECENTLY_ASKED_QS_RESPONSE = 'recently_asked_qs_response',
     IS_RECENT_HISTORY_QUESTION_FOUND = 'is_recent_history_question_found',
     SEARCH_RESULTS_DATE = 'search_results_date',
     SEARCH_RESULTS_HISTORY = 'search_results_history',
     LOADER_ON_SEARCH_RESULTS = 'loader_on_search_results',
     SEARCHED_WORD = 'searched_word',
     SEARCHED_ORDER_KEY = 'searched_order_key',
     VIEW_MORE_UPDATE_PAGE = 'view_more_update_page',
     SET_DELETE_SPINNER_VALUE = 'set_delete_spinner_value',
     UPDATE_HAS_MORE='update_has_more',
     // Subscribed page
     SET_LOADING_SPINNER_FOR_SUBSCRIBED = 'set_loading_spinner_for_subscribed',
     UPDATE_CONTEXT_ANIMATION = 'update_context_animation',
     SET_QUESTION_INDEX = 'set_question_index',
     UPDATE_VISUAL_TYPE = 'update_visual_type',
     SET_KEY_PPTX_DOWNLOAD = 'set_key_pptx_download',
     SET_PDF_DOWNLOAD_KEY = 'set_pdf_download_key',
     UPDATE_PPTX_STATUS = 'update_pptx_status',
     UPDATE_REPORT_SUBSCRIPTION_ALERT_LOADER = "update_report_subscription_alert_loader",
     SHOW_REPORT_SUBSCRIBED_DATE = "show_report_subscribed_date",
     TOGGLE_SUBSCRIBED_REPORT_ICON = "toggle_subscribed_report_icon",
     UPDATE_SUBSCRIBED_REPORT_VIEWER_LOADER = "update_subscribed_report_viewer_loader",
     UPDATE_PRE_SIGNED_URL_FOR_REPORT = "update_pre_signed_url_for_report",
     UPDATE_SUBSCRIPTION_ALERT_RESPONSE = "update_subscription_alert_response",
     TOGGLE_SUBSCRIPTION_UPDATE_BUTTON = "toggle_subscription_update_button",

     //chat
     SET_CHAT_INPUT_VALUE = 'set_chat_input_value',
     UPDATE_HISTORY_ITEM = 'update_history_item',
     DELETE_CHAT_MESSAGES = 'delete_chat_messages',
     SCROLL_MESSAGES_LIST_TO_BOTTOM = 'scroll_messages_list_to_bottom',
     TOGGLE_ANSWER_MODAL = 'toggle_answer_modal',
     UPDATE_USER_CHAT_HISTORY_VISUAL_BY_QUESTION = 'updater_user_chat_history_visual_by_question',
     UPDATE_CHAT_CHART_OPTION_USER_HISTORY = 'update_chat_chart_option_user_history',
     UPDATE_CHAT_HISTORY_VISUAL = 'update_chat_history_visual',
     UPDATE_CHAT_HISTORY_PDF_STATUS_BY_UTTERANCE_ID='update_chat_history_pdf_status_by_utterance_id',
     FOCUS_CHAT_INPUT = 'focus_chat_input',
     ADD_AGENT = "add_agent",
     SELECT_FOCUS = "select_focus",
     SELECT_SOURCE = "select_source",
     SET_CHAT_CONFIG = "set_chat_config",
     SET_SHOW_QUERY_LIST_COLLAPSE = "set_show_query_list_collapse",
     SET_SELECTED_COMMAND = "set_selected_command",
     SET_FOCUS = "set_focus",
     SET_VALUE = "set_value",
     SET_CHAT_INPUT_QUERY_COMMANDS = "set_chat_input_query_commands",
     SET_CHAT_INPUT_SOURCES = "set_chat_input_sources",
     SET_ACTIVE_AGENT = "SET_ACTIVE_AGENT",
     SET_SUBSCRIPTION = "set_subscription",
     SET_HAS_NEXT_PAGE = "set_has_next_page",
     SET_PAGE_NUMBER = "set_page_number",
     REFETCH_CHAT_CONFIG="refetch_chat_config",
     SET_CITATION_HREF='set_citation_href',
     SET_SOURCE_RECORDS='set_source_records',
     SET_IS_SOURCE_RECORDS_LOADING='set_is_source_records_loading',
     SET_SOURCE_RECORDS_POSITION='set_source_records_position',
     SET_CURRENT_CITATION_HREF='set_current_citation_href',

     //Toggle dropdown
     TOGGLE_DROPDOWN = "toggle_dropdown",
     TOGGLE_VISIBLE = "toggle_visible",
     SHOW_ABBREVIATED_TEXT = "show_abbreviated_text",
     IS_ADD_COLUMN = "is_add_column",
     SELECTED_TABLE_COLUMN = "selected_table_column",
     SET_VISIBLE_COLUMNS = "set_visible_columns",
     TABLE_SEARCH_SUGGESTION = "table_search_suggestion",
     SEARCH_TABLE_HEADER_VALUE = "search_table_header",
     ADD_FEEDBACK_COMMENT = "add_feedback_comment",
     IS_SUGGESTION_RESPONSE = 'is_suggestion_response',
     TABLE_SLIDER_SET_RANGE = 'table_slider_set_range',
     UPDATE_READMORE_LESS_TOGGLE_VISIBLE = 'update_readmore_less_toggle_visible',


     // Favourite Page
     UPDATE_FAVOURITE_STAR_ITEMS = 'update_favourite_star_items',
     LOADING_SPINNER_FOR_FAVOURITE = 'loading_spinner_for_favourite',
     UPDATE_FAVOURITE_LIST = 'update_favourite_list',
     UPDATE_VISUAL = 'update_visual',
     UPDATE_HISTORY_VISUAL = 'update_history_visual',
     UPDATE_SPINNER_FOR_UNFAVOURITE = 'update_spinner_for_unfavourite',
     UPDATE_FAVOURITE_RESPONSE = 'update_favourite_response',
     UPDATE_USER_HISTORY_PDF_STATUS_BY_UTTERANCE_ID = 'update_user_history_pdf_status_by_utterance_id',
     UPDATE_FAVORITE_HISTORY_PDF_STATUS_BY_UTTERANCE_ID = 'update_favorite_history_pdf_status_by_utterance_id',
     UPDATE_SELECTED_FAVOURITE_INDEX = 'update_selected_favourite_index',
     UPDATE_CHART_OPTION_FAVORITE_HISTORY = "UPDATE_CHART_OPTION_FAVORITE_HISTORY",
     UPDATE_USER_FAVOURITE_VISUAL_BY_UTTERANCE_ID="UPDATE_USER_FAVOURITE_VISUAL_BY_UTTERANCE_ID",

     // Users and Groups
     UPDATE_GROUP_LIST = 'update_group_list',
     UPDATE_USERS_LIST = 'update_users_list',
     UPDATE_SPINNER_LOADING_GROUPS = 'update_spinner_loading_groups',
     ADD_NEW_GROUP = 'add_new_group',
     DELETE_GROUP = 'delete_group',
     GET_LIST_OF_USERS_FROM_GROUP = 'get_list_of_users_from_group',
     REMOVE_USER_FROM_GROUPS = 'remove_user_from_groups',
     GET_UPDATED_CONTEXT_LABEL = 'get_updated_context_label',
     IS_EDIT_USER_CONTEXT_TABS='is_edit_user_context_tabs',
     IS_EDIT_QUICKLINK_QUESTION_STATUS='is_edit_quicklink_question_status',
     SHOW_LOADING_SPINNER_BEFORE_GETTING_GORUP_LIST = 'show_loading_spinner_before_getting_group_list',
     UPDATE_GROUP_LIST_USER_LOADER_ACTION = 'update_group_list_user_loader_action',
     UPDATE_ACTION_TO_CALL_API_ONCE = 'update_action_to_call_api_once',
     FETCH_UPDATED_EVENTS_SEARCH_USER_ID = 'fetch_updated_events_search_user_id',
     UPDATE_SELECTED_USER_EMAIL_ACTION = 'update_selected_user_email_action',

     // new answer visual
     UPDATE_POP_UP_VISIBLE = 'update_pop-up-visible',
     // chat_ configuration
     ADD_NEW_QUESTION_CONTEXT = 'add_new_question_context',
     REMOVE_QUESTION_CONTEXT = 'remove_question_context',
     UPDATE_ANSWER_VARIANCE_THRESHOLD = 'update_answer_variance_threshold',

     //token
     ADD_TOKEN = 'add_token',
     SET_TOKEN_NOTE_VALUE = 'set_token_note_value',
     SET_EXPIRATION_TIME = 'set_expiration_time',
     GET_TOKENS = 'get_tokens',
     DELETE_TOKEN = 'delete_token',
     UPDATE_PAGES_FOR_TOKEN = 'update_pages_for_token',
     IS_SHOW_TOKEN_LOADER = 'is_show_token_loader',
     IS_TOKEN_NAME_EXISTS = 'is_token_name_exists',
     SET_TOKEN_ERROR_MESSAGE = 'set_token_error_message',

     // Table
     SET_SELECTED_OPTIONS = 'set_selected_options',
     UPDATE_HAS_NEXT_PAGE = 'update_has_next_page',
     UPDATE_FEEDBACK_COLUMN = 'update_feedback_column',
     HYDRATE_COPY_QUESTION_INPUT = 'hydrate_copy_question_input',
     UPDATE_INPUT_SEARCH_VALUE_TERM = 'update_input_search_value_term',
     HAS_CHAT_MESSAGES_NEXT_PAGE = 'has_chat_messages_next_page',
     ADD_SEARCH_INDEXES = 'add_search_indexes',

     //access methods
     GET_ANALYZER_PARAMETERS = 'get_analyzer_parameters',
     DELETE_ANALYZER_PARAMETERS = 'delete_analyzer_parameters',
     UPDATE_ANALYZER_PARAMETERS = 'update_analyzer_parameters',
     CREATE_NEW_PARAMETERS = 'create_new_parameters',
     SET_WORKER_CHECKBOX = 'set_worker_checkbox',
     IS_PARAMETER_LOADER='is_parameter_loader',
     IS_PARAMETER_PAGE_LOADER='is_parameter_page_loader',

     //Developer Seeting
     CURRENT_DEVELOPER_TAB = "CURRENT_DEVELOPER_TAB",
     GET_WEBHOOK_RECORD_IS_SUCCESS = "GET_WEBHOOK_RECORD_IS_SUCCESS",
     UPDATE_WEBHOOK_RECORD_SUCCESS = "UPDATE_WEBHOOK_RECORD_SUCCESS",
     CREATE_WEBHOOK_RECORD_SUCCESS = "CREATE_WEBHOOK_RECORD_SUCCESS",
     DELETE_WEBHOOK_RECORD_SUCCESS = "DELETE_WEBHOOK_RECORD_SUCCESS",
     GET_SINGLE_WEBHOOK_RECORD_IS_SUCCESS = "GET_SINGLE_WEBHOOK_RECORD_IS_SUCCESS",
     GET_WEBHOOK_EVENTS_IS_PENDING ="get_webhook_events_is_pending",
     GET_WEBHOOK_EVENTS= "get_webhook_events_is_success",
     GET_WEBHOOK_EVENTS_IS_FAILURE= "get_webhook_events_is_failure",
     UPDATE_WEBHOOK_RECORDS_DATA = 'update_webhook_records_data',
     UPDATE_EVENTS_REBUILD_INDEX_LOADER = 'update_events_rebuild_index_loader',
     UPDATE_EVENTS_SEARCH_DROPDOWN_TOGGLE = 'update_events_search_dropdown_toggle',
     UPDATE_EVENTS_SEARCH_SUB_DROPDOWN_TOGGLE = 'update_events_search_sub_dropdown_toggle',
     UPDATE_EVENTS_SEARCH_SUGGESTION_DROPDOWN = 'update_events_search_suggestion_dropdown',
     UPDATE_EVENTS_SEARCH_HEADER = 'update_events_search_header',
     UPDATE_EVENTS_SEARCH_SELECTED_OPTIONS = "update_events_search_selected_options",
     GET_EVENTS_TOTAL_RECORDS = "get_events_total_records",
     UPDATE_WEBHOOK_EVENTS_SEARCH_TERM = "update_webhook_events_search_term",
     UPDATE_EVENTS_OPERATOR_SELECTION = "update_events_operator_selection",
     IS_WEBHOOK_LOADER = "IS_WEBHOOK_LOADER",

     //Developer Webhook elastic search types
     GET_WEBHOOK_EVENT_SEARCH_DATA = "get_webhook_event_search_data",
     GET_WEBHOOK_EVENT_SEARCH_DATA_IS_PENDING = "get_webhook_event_search_data_is_pending",
     GET_WEBHOOK_EVENT_SEARCH_DATA_IS_FAILURE = "get_webhook_event_search_data_is_failure",

     GET_WEBHOOK_EVENT_SEGGESTION_IS_PENDING="get_webhook_event_suggestion_is_pending",
     GET_WEBHOOK_EVENT_SEGGESTION="get_webhook_event_suggestion",
     GET_WEBHOOK_EVENT_SEGGESTION_IS_FAILURE = "get_webhook_event_suggestion_is_failure",

     FILTER_SEARCH_DATA="flter_search_data",
     GET_FILTERED_DATA_ISSUCCESS="get_filtered_data_issuccess",
     GET_FILTERED_DATA_ISPENDING="get_filtered_data_ispenidng",
     GET_FILTERED_DATA_ISFAILURE="get_filtered_data_isfailure",

     CLEAR_FILTER_SEARCH_DATA="clear_filter_search_data",


     //Audit Logs
     ADD_AUDIT_LOGS_TABLE='add_audit_logs_table',
     ADD_AUDIT_LOGS_FILTERS ='add_audit_logs_filters',
     IS_TABLE_LOADER = 'is_table_loader',
     IS_BUTTON_LOADER='is_button_loader',
     IS_MESSAGE='is_message',
     REMOVE_FILTER_DATA = "REMOVE_FILTER_DATA",
     CUSTOMIZE_FILTER_SEARCH_DATA = "customize_filter_search_data",

     //Quick links edit mode 
     IS_TOGGLE_EDIT_MODE='is_toggle_edit_mode',
     IS_OPEN_ADD_NEW_CONTEXT_BOX='is_open_add_new_context_box',
     IS_OPEN_EDIT_CONTEXT_LABEL='is_open_edit_context_label',
     UPDATE_EDIT_MODE_USER_CONTEXT = 'update_edit_mode_user_context',
     USER_CONTEXT_TABS = 'user_context_tabs',
     REMOVE_SELECTED_CONTEXT_TAB= 'remove_selected_context_tab',
     IS_EDIT_DASHBOARD_CONTEXT_LOADER='is_edit_dashboard_context_loader',
     QUICK_LINKS_NEW_CATEGORY_ID='quick_links_category_id',
     IS_QUICK_LINKS_ADD_QUESTION='is_quick_links_add_question',
     QUICK_LINKS_DASHBOARD = 'quick_links_dashbaord',
     DELETE_SELECTED_CATEGORY_CARD = 'delete_selected_category_card',
     SELECTED_TAB_ID= 'selected_tab_id',
     MODIFIED_QUICK_LINKS_WITH_INDEX_CHANGED = 'modified_quick_links_with_index_changed',
     SET_EDIT_CATEGORY_CARD_IS_OPEN = "set_edit_category_card_is_open",
     INITIAL_USER_CONTEXT_LOADER = "initial_user_context_loader",
     UPDATE_LEFT_ACTIVE_QUICK_QUESTION_INDEX = "update_left_active_quick_question_index",
     UPDATE_LEFT_ACTIVE_QUICK_LINK_INDEX = "update_left_active_quick_link_index",
     UPDATE_LEFT_ACTIVE_QUICK_SUGGESTION_INDEX = "update_left_active_quick_suggestion_index",
     IS_EDIT_MODE_BUTTON_LOADER = 'is_edit_mode_loader',
     IS_ADD_QUESTION_BUTTON_LOADER='is_add_question_button_loader',
     IS_REMOVE_QUESTION_LOADER='is_remove_question_loader',
     REMOVE_AND_UPDATE_QUICK_LINK_DASHBOARD = 'remove_and_update_quick_link_dashboard',
     IS_SET_BUTTON_LOADER = 'is_set_button_loader',
     UPDATE_QUICK_LINK_QS_STATUS='update_quick_links_qs_status',
     UPDATE_QUICK_LINK_GROUP_QS_STATUS='update_quick_link_group_qs_status',
     IS_EDIT_QUESTION_STATUS='is_edit_question_status',
     IS_QUICK_LINKS_LOADED = 'is_quick_links_loaded',
     HIDE_AND_SHOW_TEXTAREA = 'hide_and_show_textarea',
     UPDATE_QUICK_LINK_LOADER = 'update_quick_link_loader',
     UPDATE_SINGLE_ASKED_QUESTION_INDEX = 'update_single_asked_question_index',
     UPDATE_GROUP_ASKED_QUESTION_INDEX = 'update_group_asked_question_index',
     UPDATE_GROUP_QUICK_LINK_LOADER = 'update_group_quick_link_loader',
     SHOW_TOTAL_RECORDS_OF_WORKER = 'show_total_records_of_worker',
     IS_DRAG_DROP_ENABLED='is_drag_drop_enabled',
     UPDATE_SELECTED_GROUP_NAME = 'update_selected_group_name',
     UPDATE_QUESTION_WITH_CATEGORY_ID_QUESTION_ID_GROUP_NAME = "update_question_with_category_id_question_Id_group_name",
     UPDATE_QUESTION_WITH_CATEGORY_ID_QUESTION_ID = "update_question_with_category_id_question_id",
     UPDATE_SUGGESTIONS_WITH_CATEGORY_ID = "update_suggestions_with_category_id",

     //Quick links test
     SET_RUN_TEST_QUICK_LINKS_DATA = "set_run_test_quick_links_data",
     SET_RUN_TEST_QUICK_LINKS_HAS_MORE = "set_run_test_quick_links_has_more",
     SET_RUN_TEST_CONFIG_NAME = "set_run_test_config_name",
     IS_RUN_TEST_CONTEXT_LOADER = "is_run_test_context_loader",
     UPDATE_RUN_TEST_QUICK_LINKS = "update_run_test_quick_links",
     RUN_TEST_SELECTED_TAB_ID = "run_test_selected_tab_id",
     GET_USER_CONTEXT_TABS = "get_user_context_tabs",
     GET_UPDATED_RUN_TEST_CONTEXT_LABEL = "get_updated_run_test_context_label",
     SET_QUICK_LINK_TEST_PAYLOADS_DATA = "set_quick_link_test_payloads_data",
     TOGGLE_SELECT_ALL_QUESTIONS = "toggle_select_all_questions",
     SAVE_TEST_PAYLOAD = "save_test_payload",
     IS_RUN_TEST_SAVE_LOADER = "is_run_test_save_loader",
     UPDATE_RUN_TEST_PAYLOAD = "update_run_test_payload",
     UPDATE_RUN_TEST_RUN_DETAILS = "update_run_test_run_details",
     UPDATE_RUN_TEST_RUN_DETAILS_HAS_MORE = "update_run_test_run_details_has_more",
     UPDATE_QUICK_LINK_COMPARISON_RESULTS = "update_quick_link_comparison_results",
     UPDATE_RUN_TEST_HAS_MORE_MESSAGES = "update_run_test_has_more_messages",
     SET_QUICK_LINK_TEST_PAYLOADS_HAS_MORE = "set_quick_link_test_payloads_has_more",
     SET_RUN_TEST_LATEST_MESSAGES = "set_run_test_latest_messages",
     SET_RUN_TEST_REFERENCE_MESSAGES = "set_run_test_reference_messages",
     SET_BATCH_CURRENT_CHAT_THREAD = "set_batch_current_chat_thread",
     UPDATE_REFERENCE_CHAT_THREAD_DETAILS = "update_reference_chat_thread_details",
     UPDATE_CURRENT_APPROVAL_STATUS = "update_current_approval_status",
     UPDATE_CURRENT_LOCK_STATUS = "update_current_lock_status",
     SET_IS_SIDEBAR_OPEN = "set_is_sidebar_open",
     UPDATE_LOCK_AND_APPROVAL_HISTORY_LOGS = "update_lock_and_approval_history_logs",
     UPDATE_REFERENCE_BUTTON_DETAILS = "update_reference_button_details",
     SET_COMMON_LOADER_FOR_RUN_TEST_TABLE = "set_common_loader_for_run_test_table",
     SAVE_ISSUE_INFO_ACTION = "save_issue_info_action",
     RESET_RUN_TEST_STATE = "reset_run_test_state",
     UPDATE_ACTION_NAME_OF_LOCK_AND_APPROVAL = "update_action_name_of_lock_and_approval",
     UPDATE_BATCH_UTTERANCE_ACTIVITY = "update_batch_utterance_activity",
     UPDATE_BATCH_ACTIVITY_DURATION = "update_batch_activity_duration",
     UPDATE_BATCH_ACTIVITY_UNSELECTED_ITEMS = "update_batch_activity_unselected_items",
     UPDATE_BATCH_UTTERANCE_ACTIVITY_HAS_MORE = "update_batch_utterance_activity_has_more",
     GET_USERS_EMAIL_LIST_FOR_SCHEDULER = "get_users_email_list_for_scheduler",
     SAVE_SCHEDULER_PAYLOAD = "save_scheduler_payload",
     UPDATE_USE_CACHE = "update_use_cache",

     //Report config
     SET_REPORT_CONFIG_VERSIONS = 'set_report_config_versions',
     SET_CURRENT_REPORT_CONFIG_VERSION = "set_current_report_config_version",
     SET_CURRENT_REPORT_CONFIG_NODES = 'set_current_report_config_nodes',
     SET_REPORT_CONFIG_NODE_INTENTS = 'set_report_config_node_intents',
     SET_EDITOR_REPORT_TEMPLATE = 'set_editor_report_template',
     SET_CURRENT_EDITOR_FOLDER = 'set_current_editor_folder',
     UPDATE_FOLDERS_LOADING = 'update_folders_loading',
     SET_SELECTED_FILE_DATA = "set_selected_file_data",
     UPDATE_INPUT_FILE_DATA = "update_input_file_data",
     UPDATE_OUTPUT_LOADING = 'update_output_loading',
     UPDATE_SAVE_PROCESSING = 'update_save_processing',
     SET_OUTPUT_DATA = "set_output_data",
     UPDATE_EDITOR_SIDEBAR_LOADING="update_editor_sidebar_loading",
     SET_UP_CONFIG_NODES = "set_up_config_nodes",
     UPDATE_VERSION_HAS_MORE = "update_version_has_more",
     UPDATE_VIEW_MORE_SPINNER = "update_view_more_spinner",
     UPDATE_UP_CONFIG_PAGE_LOADER = "update_up_config_page_loader",
     UPDATE_ACTION_VALUE = "update_action_value",
     SHOW_REPORT_ERROR_MESSAGE = "show_report_error_message",
     SET_JSON_DIFF_WARNING_MESSAGE = "set_json_diff_warning_message",

     //chat v2
     UPDATE_CHATS_THREADS="update_chats_threads",
     UPDATE_CHAT_DEBUG_MODE="update_chat_debug_mode",
     UPDATE_QUESTION_HISTORY_VISIBILITY="update_question_history_visibility",
     UPDATE_SEARCH_CHAT_QUERY = 'update_search_chat_query',
     UPDATE_HAS_MORE_CHAT_THREADS='update_has_more_chat_threads',
     SET_CURRENT_CHAT_THREAD='set_current_chat_thread',
     SET_CHAT_THREAD_MESSAGES='set_chat_thread_messages',
     UPDATE_HAS_MORE_MESSAGES='update_has_more_messages',

     // Message actions
     TOGGLE_MESSAGE_LOGS_VISIBILITY='toggle_message_logs_visibility',
     //workflow
     UPDATE_TEMPLATE_LIST = 'update_template_list',
     
     //customer notice
     UPDATE_CUSTOMER_NOTICE = 'update_customer_notice',

     // Chat Configuration
     UPDATE_IS_CREATED_OPEN = "update_is_created_open",
     UPDATE_AGENTS_LIST = "update_agents_list",
     UPDATE_FOCUS_LIST = "update_focus_list",
     UPDATE_SOURCES_ALIAS_LIST = "update_source_alias_list",
     UPDATE_SOURCES_LIST = "update_sources_list",
     UPDATE_IS_LOADING = "update_is_loading",
     FETCH_FORM_SCHEMA_FOR_CHAT_CONFIG = "fetch_form_schema_for_chat_config",
     UPDATE_IS_ATTACH_FOCUS = "update_is_attach_focus",
     FETCH_ATTACH_NODE_SCHEMA = "fetch_attach_node_schema",
     UPDATE_NODES_DATA_FOR_ATTACH_FOCUS = "update_nodes_data_for_attach_focus",
     ADD_NEW_ROW_DATA_IN_FOCUS_LIST = "add_new_row_data_in_focus_list",
     CREATE_NEW_AGENT_LIST_IN_FORM_FIELD = "create_new_agent_list_in_form_field",
     UPDATE_FOCUS_INTENTS_LIST = "update_focus_intents_list",
     FETCH_EDIT_AGENT_SCHEMA = "fetch_edit_agent_schema",
     OPEN_ATTACH_MENU = "open_attach_menu",
     UPDATE_TREE = "update_tree",
     UPDATE_IS_EDIT_FORM_OPEN = "update_is_edit_form_open",
     SET_PAGE_NUMBER_ROOT_NODE = "set_page_number_root_node",
     SET_HAS_NEXT_PAGE_ROOT_NODE = "set_has_next_page_root_node",
     UPDATE_GRID="update_grid",
     UPDATE_IS_CONFIG_CHANGE="update_is_config_change",
     CHANGE_AFS_TAB="change_afs_tab",
     UPDATE_SETTINGS_AGENTS_LIST="update_settings_agents_list",
     REFETCH_CHAT_CONFIG_LOADER="refetch_chat_config_loader",
     UPDATE_RESET_STATE_FOR_LOADING="update_reset_state_for_loading"
}
