import styled from "styled-components";

export const FeedbackButton = styled.button`
    background-color: ${({ theme }) => theme.mode === "light" ? '#ffffff' : '#1b1c31'};
    color: ${({ theme }) => theme.mode === 'light' ? '#2158e8' : 'white'};
    position: fixed;
    bottom: 2px;
    right: 15px;
    border: none;
    border-bottom: 1px solid;
    border-top: 1px solid;
    border-left: 1px solid;
    border-right: 1px solid;
    border-radius: 10px;
    padding: 3px 6px 3px 14px;
    border-color: ${({ theme }) => theme.mode === 'light' ? '#2158e8' : 'rgb(255 255 255 / 25%)'};
    ${({ theme }) => theme.mode === 'light' ? '' : 'box-shadow: rgb(0 0 0 / 13%) 0px 0px 13px 0px;'};
    z-index: 9999;
    font-size: 14px;
    font-weight: 600;
    svg {
        padding: 0 6px;
        font-size: 14px;
        transform: translateY(0.5px);
    }
`;

export const PopupOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.PopupOverlayColor};
  z-index: 1000;
`;

export const PopupContent = styled.div`
  border-radius: 1px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1001;
`;
