import { get, Response } from "makeRequest";

export function getStarHistoryItems(utterance_id: string, is_star:boolean): Response<any> {
    if(is_star === true){
      return get(`/star-history-item?utterance_id=${utterance_id}&is_star=${is_star}`); 
    }else{
      return get(`/star-history-item?utterance_id=${utterance_id}`);
    }
  }
  
  export function getFavouriteItems():Response<any> {
    return get(`/get-favourite-items`);
  }