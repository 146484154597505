import { Dispatch  } from "redux";
import { RootState } from "state";
import { FeedbackKind } from "types/feedback";
import * as answerServices from "../../services/answer"
import {openCalendar} from "../action-creators/SubscriptionHistoryAction";
import { createFeedback } from "services/feedback";
import { ActionType as TypeAction } from 'state/action-types'
import { TableVisual } from "types/visual";
import { alert } from './customRouter';
import { ExportType } from "types/actionBar";
import { submitHtmlToAdobe, getPdfStatus,getPpt } from "../../services/answer";
import { asyncToDownloadPdf, downloadReportExportOptions } from "makeRequest";
import { updateExportInProgress } from "./AnswerActions";
import { fetchRMarkdownExportStatus, fetchRMarkdownPdfExportRequest } from "services/actionBarServices";
import { ExportStatusTypes } from "types/answer";

export type ActionType =
  | "EXPORT"
  | "POSITIVE_FEEDBACK"
  | "NEGATIVE_FEEDBACK"
  | "FAVORITE"
  | "PRESENT";


  export const updateActionBarOptionExplanded = (explanded: boolean) => {
    return {
      type: 'action_bar_options_expanded',
      payload: explanded
    };
  }

export const getS3Object = (path:string, question: string, extension?: string) => {
    return async (dispatch: Dispatch) => {
        const response =  await answerServices.getS3Object(path,question,extension);
        dispatch(updateExportInProgress(false));
        dispatch(setpptKey(""));
        dispatch({
            type: TypeAction.CHECKING_PDF_STATUS,
            payload: '',
        });
        return response;
  }
}


export const exportPpt = () => {
    return async (dispatch: Dispatch, getState: ()=> RootState) => {

        const state: RootState = getState();
        const exportInProgress = state.answer.exportInProgress;

        const utteranceIds = {
            "utterance_id" : state.answer.utteranceId
        }
        const visual = (state.answer.visual as any);
        const visualType = visual?.type;

            if (visualType !== "none") {
                if (exportInProgress) {
                    const {body, status} = await answerServices.downloadPpt(utteranceIds);

                    if (status == 200) {
                        if (body.status === "SUCCEEDED") {
                            dispatch(setpptKey(body.ppt_s3_file));
                            dispatch(setWaveLoader(false));
                            dispatch(disabledButtonAction(false));
                            return true;
                        } else if (body.status === "FAILED") {
                            dispatch(setpptKey(""));
                            dispatch(setWaveLoader(false))
                            dispatch(updateExportInProgress(false));
                            return true;
                        } else if(body?.error) {
                            dispatch(setpptKey(""));
                            dispatch(setWaveLoader(false))
                            dispatch(updateExportInProgress(false));
                        } else {
                            dispatch(setWaveLoader(true))
                            dispatch(setpptKey(""));
                            return false;
                        }
                    }
                }
                else{
                    return true;
                }
            }
        return true;
  }
}

export const setpptKey = (key: string) => {
    return {
        type: TypeAction.SET_KEY_PPTX_DOWNLOAD,
        payload: key
    }

}
  export const getCSVForIndex = () => {
      return async (dispatch: Dispatch, getState: ()=> RootState) => {
            const state: RootState = getState();
            const indexes = (state.answer.visual as TableVisual).chart_options.filteredIndex;
            const response =  await answerServices.getCSVForIndex(state.answer.utteranceId , indexes!);
            return response;     
  }
}

export const getExcelForIndex = () => {
    return async (dispatch: Dispatch, getState: ()=> RootState) => {
        const state: RootState = getState();
        const tableSearchTerm = state.answer.tableSearchTerm;
        const oldTableSearchTerm = (state.answer.visual as TableVisual).chart_options.searchedTerm;
        let indexes = null;
        if ((tableSearchTerm && tableSearchTerm != '') || (oldTableSearchTerm && oldTableSearchTerm != '')) {
            indexes = (state.answer.visual as TableVisual).chart_options.filteredIndex
        }
        const response = await answerServices.getExcelForIndex(state.answer.utteranceId, indexes!);
        return response;

    }
}
export const selectExportType = (exportType: ExportType) => {
    return {
        type: TypeAction.UPDATE_EXPORT_TYPE,
        payload: {
            type: exportType,
          }
    }
}

export const saveActionBarAction = () => {
    
    return async (dispatch: Dispatch, getState: ()=> RootState) => {
        const state: RootState = getState();
        const tab = state.questionNavigation.tab;
        const {visual_id, utteranceId} =  (state.answer.visual as any);
        // any is because it can be any chart check if Visual can be used?
        const chart_options = (state.answer.visual as any).chart_options; 
        const pathname = state.questionBar.current_type;
        dispatch( updateAutoSave(true) );

        try {
          if (visual_id && chart_options) {
            const response = await answerServices.updateChartOptions(
              visual_id,
              chart_options
            );

            if (response.status == 200) {
              if (tab !== "chat") {
                if(pathname=="favorite"){
                    const current_user_history_index = state.FavouriteBar.currentFavouriteIndex;
                    dispatch({
                      type: TypeAction.UPDATE_CHART_OPTION_FAVORITE_HISTORY,
                      payload: {
                        index: current_user_history_index,
                        chart_options: chart_options,
                      },
                    });
                } else if(pathname=="subscription"){
                    const current_user_subscription_index = state.subscriptionHistory.setIndex;
                    dispatch({
                        type: TypeAction.UPDATE_CHART_OPTION_SUBSCRIPTION_HISTORY,
                        payload: {
                          index: current_user_subscription_index,
                          chart_options: chart_options,
                        },
                      });
                }else{
                    const current_user_history_index = state.userHistory.index;
                    dispatch({
                    type: TypeAction.UPDATE_CHART_OPTION_USER_HISTORY,
                    payload: {
                        index: current_user_history_index,
                        chart_options: chart_options,
                    },
                    });
                }
              } else {
                dispatch({
                  type: TypeAction.UPDATE_CHAT_CHART_OPTION_USER_HISTORY,
                  payload: {
                    utteranceId,
                    chart_options,
                  },
                });
              }
            }
          }
        } catch (error) {
            console.log(error, "error here")
        }
       dispatch( updateAutoSave(false) );
    }
}


export const updateDataTransforming = (value: boolean) => {
    return {
        type: TypeAction.UPDATE_DATA_TRANSFORMING,
        payload: value
    }
}

export const updateLabelSkipSize = (value: number) => {
    return {
        type: TypeAction.UPDATE_LABEL_SKIP_SIZE,
        payload: value
    }
}


export const updateSearchTeam = (value: string) => {
    return {
        type: TypeAction.UPDATE_SEARCH_TERM,
        payload: value
    }
}


export const updatetotalColumnsWidth = (value:number) => {
    return{
        type:TypeAction.UPDATE_TOTAL_COLUMNS_WIDTH,
        payload: value
    }
}


export const streamFeedbackErrorAction = (error: string) => {
    return {
        type: TypeAction.UPDATE_ACTION_FEEDBACK_ERROR,
        payload: error
    }
}


export const streamFeedbackText = (text: string) => {
    return {
        type: TypeAction.UPDATE_FEEDBACK_TEXT ,
        payload: text
    }
}


export const clearCurrentAction = () => {
    return {
        type: TypeAction.CLEAR_CURRENT_ACTION ,
    }
}


export const setJustSubmittedFeedback = (kind: FeedbackKind | null) => {
    return {
        type: TypeAction.UPDATE_FEEDBACK_JUST_SUBMITTED ,
        payload: kind
    }
    
}


export const updateCirclePackingPadding = (value: number) => {
    return {
        type: TypeAction.UPDATE_CIRCLE_PACKING_PADDING ,
        payload: value
    }
}
export const updateCirclePackingLeavesOnlyToggle = (value: boolean) => {
    return {
        type: TypeAction.UPDATE_CIRCLE_PACKING_LEAVES_ONLY ,
        payload: value
    }
}


export const hideActionBar = (value: boolean) => {
    return (dispatch: Dispatch) => {
        dispatch({
            type: TypeAction.HIDE_ACTION_BAR ,
            payload: value
        });
    }
}

export const updateAutoSave = (value: boolean) => {
    return {
        type: TypeAction.UPDATE_AUTO_SAVE,
        payload: value
    }
}


 export const setActiveAction = (action: ActionType | null) => {
    return {
            type: TypeAction.UPDATE_ACTIVE_ACTION ,
            payload: action
       }
 }
 export const setColumnsFilterClicked = (action:boolean) => {
    return {
        type: TypeAction.UPDATE_COLUMNS_FILTER_CLICKED,
        payload: action
    }
}

export const setWorkerColumnsFilterClicked = (action:boolean) => {
    return {
        type: TypeAction.WORKER_UPDATE_COLUMNS_FILTER_CLICKED,
        payload: action
    }
}

export const setQueueJobsColumnsFilterClicked = (action:boolean) => {
    return {
        type: TypeAction.QUEUEJOBS_UPDATE_COLUMNS_FILTER_CLICKED,
        payload: action
    }
}

  export const selectActionBarAction = (action: ActionType | null) => {
    return async (dispatch: Dispatch , getState: ()=> RootState) => {
        const state: RootState = getState();
        const activeAction = state.actionBar.activeAction;
            dispatch(openCalendar(undefined));
        if (activeAction === action) {
            dispatch(setActiveAction(null));
        } else {
            dispatch(setActiveAction(action));
        }
    }
  }

export const submitFeedbackAction = () => {
    return async (dispatch: Dispatch<any> , getState: ()=> RootState) => {
        const state: RootState = getState();
        const utteranceId = state.answer.utteranceId
        const utterance = state.answer.question
        const text = state.actionBar.feedback.text
        const activeAction = state.actionBar.activeAction
        const kind = activeAction === "POSITIVE_FEEDBACK" ? FeedbackKind.POSITIVE : FeedbackKind.NEGATIVE;
        try {
            const response = await createFeedback(utteranceId, utterance, kind, text);
            if (response.status === 200) {
                
                dispatch( clearCurrentAction() )
                // dispatch( setJustSubmittedFeedback(kind) );

                //update kind here
                dispatch({
                    type: TypeAction.UPDATE_FEEDBACK_CHART_OPTIONS,
                    payload: kind
                });



                dispatch(saveActionBarAction());
                
              } else {
                  dispatch( streamFeedbackErrorAction("Please try again later") );
                // streamFeedbackError("Please try again later");
              }
        } catch (error) {
            console.log(error , "error");
            dispatch( streamFeedbackErrorAction("Please try again later") )
        }
    }
}

export const updateSearchedAndSelected = (values: Array<{title: string, value: string}>) => {
    return {
        type: TypeAction.UPDATE_SEARCHED_AND_SELECTED,
        payload: values
    }
}

export const updateSearchables = (values: Array<{title: string, value: string}>) => {
    return {
        type: TypeAction.UPDATE_SEARCHABLES,
        payload: values
    }
}

export const updateReverse = (value: boolean) => {
    return {
        type: TypeAction.UPDATE_BARCHART_REVERSE ,
        payload: value
    }
}


export const updateSearchablesLineChart = (values: Array<{title: string, value: string}>) => {
    return {
        type: TypeAction.UPDATE_SEARCHABLES_LINE_CHART,
        payload: values
    }
}

export const updateSearchedAndSelectedLineChart = (values: Array<{title: string, value: string}>) => {
    return {
        type: TypeAction.UPDATE_SEARCHED_AND_SELECTED_LINE_CHART,
        payload: values
    }
}

export const setUnansweredColumnsFilterClicked= (value: boolean) => {
    return {
        type: TypeAction.SPLAT_COLUMNS_FILTER_CLICKED,
        payload: value
    }
}

export const updatePdfStatusInChat = (pdf_status:string, utterance_id: string) => {
    return {
        type: TypeAction.UPDATE_CHAT_HISTORY_PDF_STATUS_BY_UTTERANCE_ID,
        payload: {pdf_status, utterance_id}
    }
}

// export const updatePdfStatusInWorkflow = (pdf_status:string, utterance_id: string) => {
//     return {
//         type: TypeAction.UPDATE_WORKFLOW_HISTORY_PDF_STATUS_BY_UTTERANCE_ID,
//         payload: {pdf_status, utterance_id}
//     }
// }
type reqData = {
    data: Uint8Array,
    utterance_id: string,
}

export const convertHtmlToPdf = (reqData: reqData) => {

    return async (dispatch: Dispatch, getState: ()=> RootState) => {
        const state: RootState = getState();
        const currentTab = state.questionNavigation.tab;
        const { data, utterance_id } = reqData;
        try {
            const response = await submitHtmlToAdobe(data, utterance_id);
            const { pdf_status } = response.body;
            if(response.status == 200) {
                dispatch({
                    type: TypeAction.CHECKING_PDF_STATUS,
                    payload: pdf_status,
                });
                if(currentTab == 'chat') {
                    dispatch(updatePdfStatusInChat(pdf_status,utterance_id));
                } else {
                    dispatch(updatePdfStatusInHistory(pdf_status,utterance_id));
                }
                dispatch(isTrimText(true));
                dispatch(adjustWidth(false));
            }else if (response.status == 500){
                dispatch(setPdfWaveLoaderAction(false))
                dispatch(disabledButtonAction(false));
                dispatch(alert("Failed to export PDF file."));
            }
        } catch (error) {
            dispatch(setPdfWaveLoaderAction(false))
            dispatch(disabledButtonAction(false));
            dispatch(alert("Failed to export PDF file."));
        }
    }
}


export const setPdfWaveLoaderAction = (value:boolean)=>{
    return {
        type: TypeAction.SET_PDF_WAVE_LOADER,
        payload: value
    }
}

export const adjustWidth = (value:boolean) => {
    return {
      type: TypeAction.IS_ADJUST_WIDTH,
      payload: value
    }
  
  }

  
export const isTrimText = (value:boolean) => {
    return {
        type: TypeAction.IS_TRIM_DESCRIPTION,
        payload:value
    }
}

const PDF_STATUS = ['', 'done', 'SUCCEEDED', 'failed','FAILED', undefined]

export const checkPdfStatusAction = () => {
    return async (dispatch: Dispatch, getState: ()=> RootState) => {
        const state: RootState = getState();
        try {
            const { utteranceId, pdf_status } = state.answer;
                if (PDF_STATUS.includes(pdf_status)) return;
                const response = await getPdfStatus(utteranceId);
                if (response.status == 200) {
                    const { pdf_status } = response.body;
                    if(pdf_status == "in progress"){
                        dispatch(disabledButtonAction(true));
                        dispatch(setPdfWaveLoaderAction(true));
                    }
                    dispatch({
                        type: TypeAction.CHECKING_PDF_STATUS,
                        payload: pdf_status,
                    });
                    dispatch(updatePdfStatusInHistory(pdf_status,utteranceId));
                    if(pdf_status == "done") {
                        dispatch(setPdfWaveLoaderAction(false))
                        dispatch(disabledButtonAction(false));
                        dispatch(handleStatusAction(true));
                    }
                    if(pdf_status == "failed"){
                        dispatch(setPdfWaveLoaderAction(false))
                        dispatch(alert("Failed to export Pdf"));
                    }
                }else if(response.status === 500){
                    dispatch(disabledButtonAction(false));
                    dispatch(handleStatusAction(false));
                    return;
                }
                dispatch(disabledButtonAction(false));
                dispatch(handleStatusAction(false));
                return;
        } catch (error) {
            dispatch(disabledButtonAction(false));
            // dispatch(alert("Error! has been occurred to convert pdf"));
        }
    }
}

export const updatePdfStatusInHistory = (pdf_status:string, id: string) => {
    return {
        type: TypeAction.UPDATE_USER_HISTORY_PDF_STATUS_BY_UTTERANCE_ID,
        payload: {pdf_status, id}
    }
}

type requestData = {
    utterance_id: string,
    utterance: string,
}

export const downloadPdfAction = (requestData: requestData) => {
    return async (dispatch: Dispatch) => {
        try {
            const {utterance_id, utterance} = requestData;
            await asyncToDownloadPdf(utterance_id, utterance);
            dispatch(handleSpinnerToDownloadPdf(false));
            // update checkPdfStatus in answer, user history, and chat
            dispatch({
                type: TypeAction.CHECKING_PDF_STATUS,
                payload: '',
            });
            dispatch(updatePdfStatusInHistory('', utterance_id))

        }catch(error){
            dispatch(handleSpinnerToDownloadPdf(false));
            dispatch(alert("failed to download pdf"));
        }
    }
}

export const disabledButtonAction = (value:boolean) => {
    return {
        type: TypeAction.DISABLED_EXPORT_BUTTON,
        payload: value
    }
}
export const handleStatusAction = (value:boolean) => {
    return {
        type: TypeAction.SET_PDF_STATUS_VALUE,
        payload: value
    }
}
export const handleSpinnerToDownloadPdf = (value:boolean) => {
    return {
        type: TypeAction.SPINNER_FOR_DOWNLOAD_PDF,
        payload: value
    }
}


export const generatePptExport = (utterance_id?:string, visual_type?:any, visual_layout?:any ) => {
    return async (dispatch: Dispatch, getState: ()=> RootState) => {
        const state: any = getState();
        const range = state.answer.visual?.chart_options?.range;
        const {tableSearchTerm,tableSliderRange} = state?.answer;
        const {equalsSearchInput,searchContainsInput,searchDropdownOperator } = state.analyzer;
        const selectedDropdown = state.answer?.visual?.chart_options?.searchedAndSelected;
        const isNormalTable = state.answer?.visual?.isNormalTableData;
        const indexes = state.answer?.searchIndex;
        const getIndex = selectedDropdown?.map((value: any) => value.index);

        const visual_payload: any = {
            utterance_id: utterance_id,
            visual_type: [{ visual_type: visual_type }]
          };

          if (visual_layout && visual_type === 'bar_chart') {
            visual_payload.visual_type[0].visual_layout = visual_layout;
          }
          if(visual_type !== 'table'){
            if(range.length){
                visual_payload.search_filters = [{
                    "range":[`${range[0]}:${range[1]}`],
                    "is_range_filter": true,
                    "utterance_id": utterance_id,
                    ...(selectedDropdown.length ? {
                        "is_elastic_search_filter": true,
                        "filtered_indexes": getIndex
                    }: {})
                }]
              }
          }else{
            if(!isNormalTable){
                if(tableSliderRange){
                visual_payload.search_filters = [
                    {
                        ...(tableSearchTerm ? {
                            "is_elastic_search_filter": true,
                            "searchQuery": tableSearchTerm,
                            "is_range_filter": true,
                            "range": tableSliderRange,
                            "utterance_id": utterance_id
                        } : (equalsSearchInput.length || searchContainsInput.length) && !tableSearchTerm ? {
                            "is_elastic_search_filter": true,
                            "is_search_header": true,
                            ...(equalsSearchInput.length ? {"equals": equalsSearchInput} : {}),
                            ...(searchContainsInput.length ? {"contains": searchContainsInput} : {}),
                            "l_operator": searchDropdownOperator,
                            "is_range_filter": true,
                            "range": tableSliderRange,
                            "utterance_id": utterance_id
                        } : {
                            "is_range_filter": true,
                            "range": tableSliderRange,
                            "utterance_id": utterance_id
                        })
                    }
            ]
              }
            }else if(isNormalTable){
                if(indexes.length){
                    visual_payload.search_filters = [
                        {
                            "filtered_indexes": indexes,
                            "utterance_id": utterance_id
                        }
                    ]
                }
            }
          }

        const response:any = await getPpt(visual_payload);
        if(response?.status == 200){
            dispatch(updateExportInProgress(true));
        }
    }

}

export const setWaveLoader = (value:boolean) =>{
    return{
        type: TypeAction.SET_WAVE_LOADER,
        payload: value
    }
}

export const generateTablePdf = (id:string) => {
    return async (dispatch: Dispatch, getState: ()=>RootState) => {
        const state: any = getState();
        try{
            const visual_payload:any={};
            const {equalsSearchInput, searchContainsInput, searchDropdownOperator} = state.analyzer;
            const searchQueryTerm = state.answer?.tableSearchTerm;
            const range = state.answer?.tableSliderRange;
            const visual_type = state.answer?.visual.type;
            const utterance_id = state.answer?.utteranceId;
            const isNormalTable = state.answer?.visual?.isNormalTableData;
            const indexes = state.answer?.searchIndex;

            if (visual_type === "table" && !isNormalTable) {
                if (range.length) {
                    visual_payload.search_filters = [
                        {
                            ...(searchQueryTerm
                                ? {
                                      is_elastic_search_filter: true,
                                      searchQuery: searchQueryTerm,
                                      is_range_filter: true,
                                      range: [`${range[0]}:${range[1]}`],
                                      utterance_id: utterance_id,
                                  }
                                : (equalsSearchInput.length || searchContainsInput.length) && !searchQueryTerm
                                ? {
                                      is_elastic_search_filter: true,
                                      is_search_header: true,
                                      ...(equalsSearchInput.length ? {equals: equalsSearchInput} : {}),
                                      ...(searchContainsInput.length ? {contains: searchContainsInput} : {}),
                                      l_operator: searchDropdownOperator,
                                      is_range_filter: true,
                                      range: [`${range[0]}:${range[1]}`],
                                      utterance_id: utterance_id,
                                  }
                                : {
                                      is_range_filter: true,
                                      range: [`${range[0]}:${range[1]}`],
                                      utterance_id: utterance_id,
                                  }),
                        },
                    ];
                }
            } else if (visual_type === "table" && isNormalTable &&  indexes.length) {
                visual_payload.search_filters = [
                    {
                        filtered_indexes: indexes,
                        utterance_id: utterance_id,
                    },
                ];
            }

            const response = await submitHtmlToAdobe(visual_payload ? visual_payload : "",id, visual_type);
            if(response.status == 200){
                dispatch({
                    type: TypeAction.CHECKING_PDF_STATUS,
                    payload: response.body.pdf_status,
                });
                dispatch(updatePdfStatusInHistory(response.body.pdf_status,utterance_id));

            }

        }catch(error){
            console.log(error)
        }

    }
}

export const selectExportTypeOfRMarkdownAction = (exportType: string) => {
    return {
        type: TypeAction.SET_AND_UPDATE_REPORT_MARKDOWN_EXPORT,
        payload: exportType
    }
}

export const exportRmarkdownAction = (exportType: string, u_id: string) => {
    return async (dispatch: Dispatch) => {
        try{
            const requestPayload = {file_type: exportType, utterance_id: u_id}
            const payload = {
                type: exportType,
                export_id: "",
                utterance_id: u_id,
                status: "",
            }
            dispatch({
                type: TypeAction.ADD_NEW_ITEM_TO_EXPORT,
                payload: payload
            })
            const response = await fetchRMarkdownPdfExportRequest(requestPayload);
            if(response.status === 200){
                dispatch({
                    type: TypeAction.GET_AND_UPDATE_EXPORT_ID,
                    payload: response.body
                })
            }else {
                dispatch({
                    type: TypeAction.GET_AND_UPDATE_EXPORT_ID,
                    payload: {
                                type: exportType,
                                export_id: "",
                                utterance_id: u_id,
                                status: "FAILED",
                            }
                        })
                dispatch(alert(response.body?.error));
            }
        }catch(error){
            dispatch({
                type: TypeAction.GET_AND_UPDATE_EXPORT_ID,
                payload: {
                            type: exportType,
                            export_id: "",
                            utterance_id: u_id,
                            status: "FAILED",
                        }
                })
            console.log("Error: ", error);
        }
    }
}

const reportStatus = ['IN_PROGRESS', 'SUBMITTED']
export const checkRmarkdownExportStatusAction = () => {
    return async (dispatch: Dispatch, getState: ()=>RootState) => {
        const exportType = getState().actionBar.rmarkdownExportType;
        try{
            const report_id = getState().answer.reportExportStatus;
            const exportIds:any = [];
            report_id.map((item: ExportStatusTypes) => {
                if(reportStatus.includes(item.status)) {
                    exportIds.push(item.export_id);
                }
            });
            if (exportIds.length === 0) return;
            const response = await fetchRMarkdownExportStatus(exportIds);

            switch (response.status) {
                case 200 :
                    const pdf_status_payloads = response.body;
                    if(pdf_status_payloads&&pdf_status_payloads?.length > 0){
                        pdf_status_payloads.map((ele)=> {
                            dispatch({
                                type: TypeAction.GET_AND_UPDATE_EXPORT_ID,
                                payload: ele
                            })
                        })
                    }
                    break;
                case 400 :case 500: case 401: case 409 :case 404 :case 403:
                    dispatch(alert(response.body?.error));
                        dispatch({
                            type: TypeAction.GET_AND_UPDATE_EXPORT_ID,
                            payload: {
                                type: exportType,
                                export_id: "",
                                utterance_id: "",
                                status: "FAILED",
                            }
                        })
                break;
                default:
                    dispatch(alert("Unknown error occurred", { position: "bottom-left" }, "error"));
                    break;
            }
                
        }catch(error){
            dispatch({
                type: TypeAction.GET_AND_UPDATE_EXPORT_ID,
                payload: {
                    type: exportType,
                    export_id: "",
                    utterance_id: "",
                    status: "FAILED",
                }
            })
            console.log("Error: ",error)
        }
    }
}

export const downloadReportMarkdownAction = (question: string) => {
    return async (dispatch: Dispatch, getState: ()=>RootState) => {
        try{
            const state: RootState = getState();
            const report_status = state.answer.reportExportStatus;
            const exportType = state.actionBar.rmarkdownExportType;
            const filteredReportStatus = report_status.filter((item: ExportStatusTypes) => item.type === exportType);
            const s3_Path = filteredReportStatus[0].s3_path;
            if(s3_Path){
            await downloadReportExportOptions(s3_Path, question, exportType);
                dispatch({
                    type: TypeAction.EMPTY_STATE_AFTER_DOWNLOAD_REPORT,
                    payload: exportType
                })
            }
        } catch(error){
            dispatch(alert("failed to download Report Markdown"));
        }
    }
}