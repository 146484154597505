export const scrollBarCustomColor = {
    dark:{
        //ScrollBar Custom
        ScrollBarThumb: "#454657",
        ScrollBarThumbHover: "#333",
        ScrollBarDropDown: "#d8d8d8",
    },
    light:{
         //ScrollBar Custom
        ScrollBarThumb: "#b5b5b5",
        ScrollBarThumbHover: "#8E8E98",
        ScrollBarDropDown: "#d8d8d8",
    }
}