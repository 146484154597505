import React from "react";
import { useTypedSelector } from "hooks/use-typed-selector";
import ServiceWorkerAlert from "./ServiceWorkerAlert";

export default function ServiceWorker() {
  const {
    serviceWorkerInitialized: isServiceWorkerInitialized,
    serviceWorkerUpdated: isServiceWorkerUpdated,
    serviceWorkerRegistration,
  } = useTypedSelector(({ serviceWorker }) => serviceWorker);
  const updateServiceWorker = () => {
    if (serviceWorkerRegistration) {
      const registrationWaiting = serviceWorkerRegistration.waiting;

      if (registrationWaiting) {
        registrationWaiting.postMessage({ type: "SKIP_WAITING" });
        registrationWaiting.addEventListener("statechange", (e: any) => {
          if (e.target.state === "activated") {
            window.location.reload();
          }
        });
      }
    }
  };
  return (
    <>
      {isServiceWorkerInitialized && (
        <ServiceWorkerAlert
          text="Service Worker is initialized for the first time"
          type={"SW_INIT"}
        />
      )}

      {isServiceWorkerUpdated && (
        <ServiceWorkerAlert
          text="There is a new version available."
          buttonText="Update"
          type={"SW_UPDATE"}
          onClick={updateServiceWorker}
        />
      )}
    </>
  );
}
