import { ActionType } from '../action-types'
import { AnswerState, ExportStatusTypes } from 'types/answer';
import { BarChartVisual , CirclepackingVisual, PieChartVisual, TableVisual, TreemapVisual , LineChartVisual, ReportVisual } from "types/visual";
import { SubscriptionAlertType } from 'services/subscribeQuestion';

export const initialState: AnswerState = {
    answer_type: '',
    state: "View",
    selectedPreviousQuestion:"current",
    time_stamp:1661153419,
    prevAskSucceeded: true,
    question: "",
    job_id: null,
    subscribed:false,
    subscribedQuestionUtterenceId:undefined,
    is_starred:false,
    fetch_history:[],
    isHistory:false,
    compute_route: '',
    reportAnswerArray: [1,2],
    refresh: true,
    hasMore: true,
    answer_copy:'',
    tableSearchTerm:'',
    data: [],
    rows: [],
    error: "",
    newTableRecordLoading:false,
    ...{
      utteranceId: "",
      visual: {
        type: "none",
        is_elastic_indexed: false,
        export_types: [],
        searchResults : [],
        report_subscription: {
          file_type: [],
          schedule_type: "daily",
          subscribed_user_timestamp: "",
          subscription_date: "",
          utterance_id: ""
        }
      },
    },
    isAlias : false,
    isClose: true,
    allAlias: [],
    reRunQuestion: false,
    isButtonClick: false,
    formResponse: [],
    createAliasErrorResponse: '',
    isError: true,
    isSpinnerDelete: true,
    isSpinnerUpdate: false,
    isDeletingAlias: false,
    aliasSchema: [],
    disabledButton: false,
    downloadSpinner: false,
    statusValue: false,
    dropdownState: false,
    isAllColumnVisible : false,
    tableSuggestion:[],
    feedbackComment:[],
    tableHeader : '',
    isSuggestionResponse: false,
    tableSliderRange: [],
    selectedColumn: "",
    selectedFields: [],
    isAddColumn: false,
    popUpVisible: false,
    barLayoutOrder:'descending',
    showAnswerSideBar: false,
    readMoreLessToggle: true,
    isTrimDescription: true,
    pieChartRange : [0,10],
    selectedOptions: [],
    pdf_status: '',
    exportInProgress: false,
    is_search_spinner:false,
    inputSearchValue: "",
    isSearchBoxCleared : false,
    searchIndex:[],
    showAbbreviatedText: false,
    isLoadingAlias: false,
    isSubmittingLoader: false,
    selectedRowsId: [],
    isAliasFormLoader:false,
    utteranceCompare: '',
    alias_total_records: 0,
    searchLoader: false,
    suggestionListHeader:[],
    searchSuggestions:[],
    aliasSearchValue:'',
    aliasOperator:'and',
    aliasEqualsInput:[],
    aliasContainsInput:[],
    aliasHeader:'',
    aliasLoaderTable:false,
    tableSearchIndexStatus: "",
    subscription: {
      subscribeUtteranceState: null,
    },
    updateText: "",
    uuid: null,
    inputQuestion: "",
    rmarkdownExportID: "",
    reportExportStatus: [],
    reportAlertLoaderText: "",
    showDate: "",
    selectIconType: [],
    reportViewerLoader: false,
    updateAskedQuestionUid:"",
  };

const reducer = (state: AnswerState = initialState, action: any): AnswerState => {
    switch (action.type) {
      case ActionType.UPDATE_SUBSCRIPTION_ALERT_RESPONSE:
        const { id,schedule_type, file_type,subscription_date, subscribed_user_timestamp, utterance_id: UtteranceId }: SubscriptionAlertType = action.payload;
        const updatedReportSubscription = {
          file_type: file_type,
          schedule_type: schedule_type,
          subscription_date: subscription_date,
          utterance_id: UtteranceId,
          subscribed_user_timestamp: subscribed_user_timestamp,
          id: id || "",
      };

      return {
          ...state,
          visual: {
              ...state.visual,
              report_subscription: updatedReportSubscription
          }
      };

      case ActionType.UPDATE_SUBSCRIBED_REPORT_VIEWER_LOADER:
        return { ...state, reportViewerLoader: action.payload }
      case ActionType.TOGGLE_SUBSCRIBED_REPORT_ICON:
        const toggledIconName = action.payload;
        const isIconSelected = state.selectIconType.includes(toggledIconName);

        let updatedSelectIcon;
        if (isIconSelected) {
          // If the icon is already selected, remove it
          updatedSelectIcon = state.selectIconType.filter(icon => icon !== toggledIconName);
        } else {
          // If the icon is not selected, add it
          updatedSelectIcon = [...state.selectIconType, toggledIconName];
        }
        return { ...state, selectIconType: updatedSelectIcon };

      case ActionType.SHOW_REPORT_SUBSCRIBED_DATE:
        return { ...state, showDate: action.payload }
      case ActionType.UPDATE_REPORT_SUBSCRIPTION_ALERT_LOADER:
        return { ...state, reportAlertLoaderText: action.payload }
      case ActionType.GET_AND_UPDATE_EXPORT_ID:
          const { export_id, status, s3_path, type, utterance_id: u_id }: ExportStatusTypes = action.payload;
          const updatedIndex = state.reportExportStatus.findIndex(exportStatus => exportStatus.type === type);
          return {
              ...state,
              reportExportStatus: state.reportExportStatus.map((item, index) => {
                  if (index === updatedIndex) {
                      return {
                          ...item,
                          status,
                          s3_path,
                          type,
                          utterance_id: u_id,
                          export_id
                      };
                  }
                  return item;
              }),
          };

      case ActionType.ADD_NEW_ITEM_TO_EXPORT:
        const newItem = action.payload;
        const filteredStatus = state.reportExportStatus.filter(item => item.type !== newItem.type);
        return { ...state, reportExportStatus: [...filteredStatus, newItem] };

      case ActionType.EMPTY_STATE_AFTER_DOWNLOAD_REPORT:
        const exportType = action.payload;
        const update_reportExportStatus = state.reportExportStatus.filter(report => report.type !== exportType);
        return {...state, reportExportStatus: update_reportExportStatus}

      case ActionType.UPDATE_QUESTION_TITLE_FOR_EMPTY_SCREEN:
        return { ...state, inputQuestion: action.payload }
      case ActionType.UPDATE_TITLE_CASE:
        return { ...state, updateText: action.payload }
      case ActionType.UPDATE_UUID_CASE:
        return { ...state, uuid: action.payload }
      case ActionType.ALIAS_LOADER_TABLE:
        return{...state, aliasLoaderTable:action.payload }
      case ActionType.SEARCH_ALIAS_HEADER_VALUE:
        return{...state, aliasHeader:action.payload }
      case ActionType.ALIAS_CONTAINS_SEARCH_INPUT:
        return{...state, aliasContainsInput:action.payload }
      case ActionType.ALIAS_EQUALS_SEARCH_INPUT:
        return{...state, aliasEqualsInput:action.payload }

      case ActionType.ALIAS_SEARCH_OPERATOR:
        return{...state, aliasOperator:action.payload }
      case ActionType.ALIAS_SEARCH_TERM:
        return { ...state, aliasSearchValue: action.payload }
      case ActionType.SUGGESTION_SEARCH_LIST:
        return { ...state, searchSuggestions: action.payload }
       case ActionType.SUGGESTION_LISTING:
        return { ...state, suggestionListHeader: action.payload }
      case ActionType.SEARCHED_TOTAL_RECORDS:
        return { ...state, alias_total_records: action.payload }
        case ActionType.IS_SEARCH_PROGRESS:
        return { ...state, searchLoader: action.payload }
      case ActionType.IS_ALIAS_FORM_LOADER:
        return { ...state, isAliasFormLoader: action.payload }
      case ActionType.SET_AND_UPDATE_ALIAS_ROW_ID:
        return { ...state, selectedRowsId: action.payload }
      case ActionType.IS_LOADING_ALIAS:
        return { ...state, isLoadingAlias: action.payload }
      case ActionType.SHOW_LOADER_ON_SUBMIT_ALIAS:
        return { ...state, isSubmittingLoader: action.payload }
      case ActionType.ADD_SEARCH_INDEXES:
        return {...state, searchIndex: action.payload}
      case ActionType.IS_TABLE_SEARCH:
                return{...state, is_search_spinner: action.payload}

      case ActionType.IS_TRIM_DESCRIPTION:
        return {...state, isTrimDescription: action.payload};

      case ActionType.UPDATE_PIE_CHART_RANGE:
        return {...state, pieChartRange: action.payload}


      case ActionType.SHOW_ABBREVIATED_TEXT:
        return {...state, showAbbreviatedText: action.payload}

     case ActionType.UPDATE_BAR_LAYOUT:
      return {
        ...state,
        visual: {
          ...(state.visual as BarChartVisual),
          chart_options: {
            ...(state.visual as BarChartVisual).chart_options,
            order: action.payload,
          },
        },
      };

        case ActionType.UPDATE_FONT_SIZE:
         return {
          ...state,
          visual: {
            ...(state.visual as BarChartVisual),
            chart_options: {
              ...(state.visual as BarChartVisual).chart_options,
              fontSize: action.payload,
            },
          },
        };

      case ActionType.SELECTED_TABLE_COLUMN:
        if( state.selectedFields.length == 0) {

        }
        return {...state, selectedColumn: action.payload,
          selectedFields: state.visual.data?.headers || []}
      
      case ActionType.SET_VISIBLE_COLUMNS:
        return {
          ...state,
          selectedFields: action.payload.map((k:string)=>k.toLowerCase()) || []
        }

      case ActionType.UPDATE_POP_UP_VISIBLE:
        return {...state, popUpVisible: action.payload};

      case ActionType.UPDATE_VISUAL:
        return {...state, is_starred : action.payload};

      case ActionType.IS_SUGGESTION_RESPONSE:
        return { ...state, isSuggestionResponse: action.payload };

      case ActionType.TABLE_SLIDER_SET_RANGE:
        return { ...state, tableSliderRange: action.payload };

      case ActionType.TABLE_SEARCH_SUGGESTION:
        return { ...state, tableSuggestion: action.payload };

      case ActionType.SEARCH_TABLE_HEADER_VALUE:
        return { ...state, tableHeader: action.payload };

      case ActionType.TOGGLE_DROPDOWN:
        return { ...state, dropdownState: action.payload };

      case ActionType.DISABLED_EXPORT_BUTTON:
        return { ...state, disabledButton: action.payload };
      
      case ActionType.SPINNER_FOR_DOWNLOAD_PDF:
        return { ...state, downloadSpinner: action.payload };

      case ActionType.CHECKING_PDF_STATUS:
        return { ...state, pdf_status: action.payload };

      case ActionType.SET_PDF_STATUS_VALUE:
        return { ...state, statusValue: action.payload };

      case ActionType.ALIAS_SPINNER_ON_UPDATE:
        return { ...state, isSpinnerUpdate: action.payload };

      case ActionType.ALIAS_SPINNER_ON_DELETE:
        return { ...state, isSpinnerDelete: action.payload };

      case ActionType.DELETE_ALIAS_IN_PROGRESS:
        return { ...state, isDeletingAlias: true };

      case ActionType.DELETE_ALIAS_SUCCESS:
        return { ...state, isDeletingAlias: false, error: null };

      case ActionType.DELETE_ALIAS_FAILED:
        return { ...state, isDeletingAlias: false, error: action.payload };

      case ActionType.UPDATE_ALIAS_SCHEMA:
        return {...state, aliasSchema: action.payload}
      case ActionType.UPDATED_ALIAS:
        return {...state, allAlias: [
          ...state.allAlias.filter(item=>item._id!==action.pay_id),
          action.payload
        ]}
      case ActionType.UPDATED_ALIAS_LIST:
        return {
          ...state,
          allAlias: state.allAlias.map((item) => {
            return item._id === state.selectedRowsId[0] ? { ...item, ...action.payload } : item;
          })
        }

      case ActionType.ADD_NEW_ALIAS_DATA:
        return { ...state, allAlias: [action.payload, ...state.allAlias]}

      case ActionType.DELETE_SELECTED_ALIAS_FROM_LIST:
        return {
          ...state,
          allAlias: state.allAlias.filter((data) => !action.payload.includes(data._id))
        };

      case ActionType.UPDATE_ALIAS_SUCCESS:
      return {...state, newAliasName: action.payload.rule_name, isAlias: false}

      case ActionType.ADD_NEW_ALIAS_SUCCESS:
        return {...state, updateAliasError: '', newAliasName: action.payload.rule_name, isAlias: false}

      case ActionType.ADD_NEW_ALIAS_SUCCESS_RE_RUN:
        return {...state, updateAliasError: '', newAliasName: action.payload.rule_name,reRunQuestion: true }

      case ActionType.UPDATE_ALIAS_SUCCESS_RE_RUN:
        return {...state,reRunQuestion: true }

      case ActionType.UPDATE_ALIAS_ERROR:
        return {...state, updateAliasError: action.payload}
      case ActionType.ALIAS_UPDATED_BY_QUESTION:
        return {...state, reRunQuestion: true}

      case ActionType.UPDATE_NEWTABLE_VALUE:
        return {
            ...state,
            visual:
            {
                ...state.visual as TableVisual,
                data: action.payload
        }}
      case ActionType.NEWTABLE_RECORD_LOADING:
          return { ...state, newTableRecordLoading: action.payload}
      case ActionType.FETCH_NEWTABLE_COMPLETED:
          return { ...state , data: action.payload }
      case ActionType.UPDATE_NEWTABLE_HAS_MORE:
          //Important do not remove: EditAnswer <Form/> value change if state got re-render,
          if(state.isAlias === false) {
            return { ...state , hasMore: action.payload}
          } else {
            return state
          }
      //fetch get value of page
      case ActionType.GET_LISTING_RESPONSE:
        return { ...state, allAlias: action.payload};

      //edit button form open
      case ActionType.EDIT_FORM_OPEN_OR_CLOSE:
        return { ...state, isAlias: action.payload, updateAliasError: undefined };

      //edit button form open
      case ActionType.CHANGE_CLOSE_BTN_STATE:
        return { ...state, isClose: action.payload };

      case 'update_new_value':
          return {
              ...state , visual: { 
                  ...state.visual as ReportVisual , 
                  components: action.payload.newComponents}
          }
      case ActionType.HYDRATE_ANSWER_COPY:	
          return {	
              ...state , answer_copy: action.payload,	
          }

      case ActionType.HYDRATE_VISUAL_ANSWER_COPY:	
          return {	
              ...state , answer_copy: {
                  ...(state.answer_copy as any),
                  visual: action.payload,
              },
          }
      case ActionType.NEW_TABLE_SEARCH_TERM:	
          return { ...state , tableSearchTerm: action.payload }	
      case ActionType.SEARCH_ERROR:	
          return {...state, error: action.payload}
      // table
      case ActionType.UPDATE_SEARCH_TERM:
          return { ...state , visual: { ...state.visual as TableVisual , chart_options: { ...(state.visual as TableVisual).chart_options , searchedTerm: action.payload } } }

      case ActionType.UPDATE_TABLE_FILTER_INDEX:
        return {
        ...state,
          visual: {
            ...(state.visual as TableVisual),
            chart_options: {
              ...(state.visual as TableVisual).chart_options,
              filteredIndex: action.payload,
            },
          },
        };
      // circle_packing
      case ActionType.UPDATE_CIRCLE_PACKING_PADDING:
        return {
          ...state,
          visual: {
            ...(state.visual as CirclepackingVisual),
            chart_options: {
              ...(state.visual as CirclepackingVisual).chart_options,
              padding: action.payload,
            },
          },
        };

      case ActionType.UPDATE_CIRCLE_PACKING_LEAVES_ONLY:
        return {
          ...state,
          visual: {
            ...(state.visual as CirclepackingVisual),
            circlePackingLeavesOnly: action.payload,
          },
        };

      // treemap
      case ActionType.UPDATE_LABEL_SKIP_SIZE:
        return {
          ...state,
          visual: {
            ...(state.visual as TreemapVisual),
            chart_options: {
              ...(state.visual as TreemapVisual).chart_options,
              labelSkipSize: action.payload,
            },
          },
        };

      // Search and select for BarChart
      case ActionType.UPDATE_SEARCHED_AND_SELECTED:
        return {
          ...state,
          visual: {
            ...(state.visual as BarChartVisual),
            chart_options: {
              ...(state.visual as BarChartVisual).chart_options,
              searchedAndSelected: action.payload,
            },
          },
        };
      case ActionType.UPDATE_SEARCHABLES:
        return {
          ...state,
          visual: {
            ...(state.visual as BarChartVisual),
            searchables: action.payload,
          },
        };

      // Pie Chart
      case ActionType.UPDATE_INNER_RADIUS:
        return {
          ...state,
          visual: {
            ...(state.visual as PieChartVisual),
            chart_options: {
              ...(state.visual as PieChartVisual).chart_options,
              innerRadius: action.payload,
            },
          },
        };

      // Line Chart
      case ActionType.UPDATE_SEARCHABLES_LINE_CHART:
        return {
          ...state,
          visual: {
            ...(state.visual as LineChartVisual),
            searchables: action.payload,
          },
        };

      case ActionType.UPDATE_SEARCHED_AND_SELECTED_LINE_CHART:
        return {
          ...state,
          visual: {
            ...(state.visual as LineChartVisual),
            chart_options: {
              ...(state.visual as LineChartVisual).chart_options,
              searchedAndSelected: action.payload,
            },
          },
        };

      // Bar Chart
      case ActionType.UPDATE_SHOW_LEGEND:
        return {
          ...state,
          visual: {
            ...(state.visual as BarChartVisual),
            chart_options: {
              ...(state.visual as BarChartVisual).chart_options,
              showLegend: action.payload,
            },
          },
        };

      case ActionType.UPDATE_BARCHART_REVERSE:
        return {
          ...state,
          visual: {
            ...(state.visual as BarChartVisual),
            chart_options: {
              ...(state.visual as BarChartVisual).chart_options,
              reverse: action.payload,
            },
          },
        };

      case ActionType.UPDATE_FEEDBACK_CHART_OPTIONS:
        return {
          ...state,
          visual: {
            ...(state.visual as any),
            chart_options: {
              ...(state.visual as any).chart_options,
              feedback: action.payload,
            },
          },
        };
      case ActionType.UPDATE_PADDING_FOR_BAR_CHART:
        return {
          ...state,
          visual: {
            ...(state.visual as BarChartVisual),
            chart_options: {
              ...(state.visual as BarChartVisual).chart_options,
              padding: action.payload,
            },
          },
        };
      case ActionType.UPDATE_TEXT_FONT_SIZE:
        return {
          ...state,
          visual: {
            ...(state.visual as BarChartVisual),
            chart_options: {
              ...(state.visual as BarChartVisual).chart_options,
              fontSize: action.payload,
            },
          },
        };
      case ActionType.UPDATE_RANGE_FOR_BAR_VISUAL:
        return {
          ...state,
          visual: {
            ...(state.visual as BarChartVisual),
            chart_options: {
              ...(state.visual as BarChartVisual).chart_options,
              range: action.payload,
            },
          },
        };
      case ActionType.UPDATE_GROUP:
        return {
          ...state,
          visual: {
            ...(state.visual as BarChartVisual),
            chart_options: {
              ...(state.visual as BarChartVisual).chart_options,
              chartGroupMode: action.payload,
            },
          },
        };
      case ActionType.UPDATE_LAYOUT:
        return {
          ...state,
          visual: {
            ...(state.visual as BarChartVisual),
            chart_options: {
              ...(state.visual as BarChartVisual).chart_options,
              chartLayout: action.payload,
            },
          },
        };
      case ActionType.LOW_HIGH_SWITCH:
        return {
          ...state,
          visual: {
            ...(state.visual as BarChartVisual),
            chart_options: {
              ...(state.visual as BarChartVisual).chart_options,
              chartLowHighSwitch: action.payload,
            },
          },
        };

      //chartLowHighSwitch

      case ActionType.HYDRATE_ANSWER:
        return {
          ...state,
          answer_type: action.payload.answer_type,
          question: action.payload.question,
          job_id: action.payload.job_id,
          subscribed: action.payload.subscribed,
          is_starred: action.payload.is_starred,
          subscribedQuestionUtterenceId: action.payload.subscribed_id,
          fetch_history: action.payload.fetch_history,
          isHistory: action.payload.isHistory,
          time_stamp: action.payload.time_stamp,
          compute_route: action.payload.compute_route,
          utteranceId: action.payload.utteranceId,
          visual: action.payload.visual,
          pdf_status: action.payload.pdf_status,
          updateText: action.payload.updateText,
          reportExportStatus: [],
        };
      case ActionType.UPDATE_SUBSCRIPTON_STATUS:
        return { ...state, subscribed: action.payload };
      case ActionType.ADD_UTTERENCE_ID:
        return {
          ...state,
          subscribedQuestionUtterenceId: action.payload,
        };
      case ActionType.UPDATE_ANSWER_STATE:
        return { ...state, state: action.payload, reRunQuestion: false, newAliasName: null, isAlias: false };
      case ActionType.SELECTED_PREVIOUS_QUESTION_INDEX:
        return { ...state, selectedPreviousQuestion: action.payload };
      case ActionType.UPDATE_CURRENT_VISUAL:
        if(state.visual.status === action.payload.status) {
          return state
        }
        return { ...state, visual: action.payload };

        case ActionType.UPDATE_VISUAL_TYPE:
          return { ...state, visual: action.payload };
      case ActionType.UPDATE_CURRENT_VISUAL_JOB_ID_AND_COMPUTE_ROUTE:
        if(state.job_id == action.payload.job_id && state.compute_route ==   action.payload.compute_route) {
          return state
        }
        return {
          ...state,
          job_id: action.payload.job_id,
          compute_route: action.payload.compute_route,
        };
      case ActionType.CLEAR_ANSWER:
        return {
          ...state,
          state: initialState.state,
          prevAskSucceeded: initialState.prevAskSucceeded,
          question: initialState.question,
          job_id: initialState.job_id,
          utteranceId: initialState.utteranceId,
          visual: initialState.visual,
        };
      case ActionType.PRE_ASK_FAILED:
        return { ...state, prevAskSucceeded: false };
      
          case ActionType.TOGGLE_VISIBLE:
            return {
                ...state,
                isAllColumnVisible: action.payload,
              };

              case ActionType.IS_ADD_COLUMN:
                return {
                    ...state,
                    isAddColumn: action.payload,
                  };


              case ActionType.ADD_FEEDBACK_COMMENT
              :
                return {
                    ...state,
                  tableSuggestion: action.payload,
              };
              case ActionType.ADD_FEEDBACK_COMMENT
              :
                return {
                    ...state,
                    feedbackComment: action.payload,
                };
      case ActionType.UPDATE_READMORE_LESS_TOGGLE_VISIBLE:
        return { ...state, readMoreLessToggle: action.payload };
      case ActionType.TOGGLE_ANSWER_SIDEBAR:
        const showSidebar = !state.showAnswerSideBar
        return {
          ...state,
          showAnswerSideBar: showSidebar
        }

        case ActionType.UPDATE_FEEDBACK_COLUMN:
          const record_key= action.payload.record_unique_key;
          const updatedFeedback = action.payload.control_value;
          const column =  action.payload.control_type;
          const utterance_id = action.payload.utterance_id;
          if(state.utteranceId===utterance_id&&(state.visual as any).data){
            return {
              ...state,
              visual: {
                ...(state.visual as any),
                data: {
                  ...(state.visual as any).data,
                  data: (state.visual as any).data.data?.map((item:any) =>
                  item.recordUniqueId === record_key
                    ? { ...item, [column]: updatedFeedback }
                    : item
                ),
                },
              },
            };
          }else{
            return state
          }


    case ActionType.SET_SELECTED_OPTIONS:
      return { ...state, selectedOptions: action.payload }
    case ActionType.UPDATE_EXPORT_IN_PROGRESS:
        return { ...state, exportInProgress: action.payload }
    case ActionType.UPDATE_INPUT_SEARCH_VALUE_TERM:
      return { ...state, inputSearchValue: action.payload}
    case ActionType.SEARCH_VALUE_CLEARED:
      return { ...state, isSearchBoxCleared: action.payload}

    case ActionType.CHECK_AND_UPDATE_ELASTIC_INDEXED:
      return { ...state, visual :{...state.visual , is_elastic_indexed: action.payload }}
    case ActionType.UPDATE_TABLE_SEARCH_INDEXED:
      return { ...state, tableSearchIndexStatus: action.payload }
    case ActionType.SET_ANSWER_SUBSCRIPTION:
      const newSubs = {
        ...state.subscription,
        [action.payload.name]: action.payload.value,
      };
      return { ...state, subscription: newSubs };
    case ActionType.UPDATE_TABLE_CHIPS:
      return {...state, visual: {
        ...state.visual, searchResults: action.payload
      }};
      default:
        return state;
    case ActionType.UPDATE_ASKED_QUESTION_UID:
      return{...state,updateAskedQuestionUid:action.payload}
    }
}

export default reducer;