import { Colors } from "style/color";

export const configurationColors = {
    dark:{
        ConfigrationBackgroundColor:  Colors.DARK_BLUE,
        ConfigrationBorderColor: '#454557',
        WorkAlgoConfigartion:  Colors.DARK_BLUE,
        TextColorText:  Colors.WHITE,
        HeadingTextBackgroundColor:  Colors.DARK_BLUE,
        CheckBoxColor: '#454557',
        WorkersBackgroundSearch: '#323346',
        WorkersBorderSearch: '#454557',
        SearchBarTopBackground: 'rgb(50, 51, 70)',
        ExportIconColor: '#acadad',
    },
    light:{
        ConfigrationBackgroundColor: '#F3F3F3',
        ConfigrationBorderColor: '#DDDDDD',
        WorkAlgoConfigartion:  Colors.WHITE,
        TextColorText: '#828282',
        HeadingTextBackgroundColor:  Colors.WHITE,
        CheckBoxColor: '#EDEAEA',
        WorkersBackgroundSearch:  Colors.WHITE,
        WorkersBorderSearch: '#DDD',
    },
}