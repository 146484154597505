export const notificationPageColors = {
    dark:{
        NotificationItemBgColor: '#323346',
        HumaSystemNotificationIconFillColor: '#1B1C31',
        NotificationBorderColor: '#454557',
        NotificationBackgroundColor:'#1b1c31',
    },
    light:{
        NotificationItemBgColor: '#F9F9F9',
        HumaSystemNotificationIconFillColor: '#F6F6F6',
        NotificationBorderColor: '#cbcbcb',
        NotificationBackgroundColor:'#FFFFFF',
    },
}
