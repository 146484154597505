import { get, Response, post } from "makeRequest";
import { Analyzer, newWorkerObjTypes as newWorker, sqsWorkerConfig as workerConfigObj } from "types/analyzer";

type resObj = {
  status?: boolean,
  error?:string,
  message?: string
}

export function getWorkers(page:number, limit:number): Response<{duration: string;total_records: number;'workers': Array<Analyzer>}> {
  return get(`/worker?page=${page}&limit=${limit}`);
}

export function addWorker(data:newWorker): Response<resObj> {
  return post(`/add-worker`, data);
}

export function addTurboWorkers(): Response<resObj> {
  return post(`/add-worker/true`,{});
}

export function terminateWorker(data:{"job_id": string | string[]}): Response<resObj> {
  return post(`/worker-termination`, data);
}

export function sqsWorkerConfig(): Response<{'config': workerConfigObj}> {
  return get(`/sqs-worker-config`);
}

export function updateSqsWorkerConfig(data: workerConfigObj): Response<resObj> {
  return post(`/update-sqs-worker-config`, data);
}

export function refreshAnalyzerTable(): Response<resObj>{
  return post('/build-rocket-analyzer-index',{})
}