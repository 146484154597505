export const CUSTOMER_IDENTIFIER = process.env.REACT_APP_CLIENT_IDENTIFIER;
export const CUSTOMER_ID = process.env.REACT_APP_CUSTOMER_ID;

const COGNITO_REGION = process.env.REACT_APP_COGNITO_REGION!;
const USER_POOL_ID = process.env.REACT_APP_USER_POOL_ID!;
const WEB_CLIENT_ID = process.env.REACT_APP_WEB_CLIENT_ID!;
const APPSYNC_API = process.env.REACT_APP_APPSYNC_API!;

const LOCALSTACK_STATUS = process.env.REACT_APP_LOCALSTACK_STATUS!;

export const amplifyConfig = {
  "aws_appsync_graphqlEndpoint": APPSYNC_API,
  "aws_appsync_region": COGNITO_REGION,
  "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
  "aws_appsync_apiKey": "null",
  "aws_appsync_dangerously_connect_to_http_endpoint_for_testing": LOCALSTACK_STATUS === 'enabled',
};

export const authConfig = {
  endpoint: LOCALSTACK_STATUS === 'enabled' ? "http://localhost:4566" : undefined,
  region: COGNITO_REGION,
  userPoolId: USER_POOL_ID,
  userPoolWebClientId: WEB_CLIENT_ID,
};
