import { Dispatch } from "redux";
import { alert } from './customRouter';
import { addAnswerComment, newCommentType } from "services/answerComment";
import { ActionType } from '../action-types';
import { RootState } from "state/reducers";

export const addFeedback = (data: newCommentType,unique_key?:string,item?:any,cellColumnId?:string | undefined) => {
    try {
        return async (dispatch: Dispatch, getState: () => RootState) => {

            const visual = getState().answer.visual.data.data;
            const email = getState().answer.visual.up_response.email;

            if(unique_key && item && cellColumnId){
                const record = visual.find((item:any)=> {
                    return item.recordUniqueId == unique_key
                })

                const feedbackRecord = record[cellColumnId];
                if(feedbackRecord){
                  if(feedbackRecord.hasOwnProperty(item)){
                    const index = feedbackRecord[item].indexOf(email)
                    if(index !== -1){
                        feedbackRecord[item].splice(index, 1);
                        }else{
                        feedbackRecord[item].push(email);
                        let index2 =  0;

                        for (const emojikey in feedbackRecord) {
                            if (emojikey !== item) {
                              index2 = feedbackRecord[emojikey].indexOf(email);
                              if (index2 !== -1) {
                                feedbackRecord[emojikey].splice(index2, 1);
                              }
                            }
                        }
                    }
                  }
                }

                //updating the updated value back to feedback record
                record[cellColumnId] = feedbackRecord;
            }
            const response = await addAnswerComment(data);
            if (response.status == 200) {
                dispatch(updateFeedback(data.control_value, data.cellColumnId, data.record_unique_key,data.utterance_id));
                dispatch(alert("comment is successfully saved!",{ position: "bottom-left" },"success"));
            } else {
                console.log("error status code", response.status);
            }
        };
    } catch (err) {
        console.log(err);
    }
};

export const updateFeedback = (control_value:string,control_type:string | undefined, record_unique_key:string,utterance_id:string) =>{
    return({
        type: ActionType.UPDATE_FEEDBACK_COLUMN,
        payload: {
            control_value:control_value,
            control_type:control_type,
            record_unique_key:record_unique_key,
            utterance_id: utterance_id
        }
    })
}