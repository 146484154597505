import { createGlobalStyle } from "styled-components"
export const GlobalStyles = createGlobalStyle`
  body {
    // background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    transition: all 0.50s linear;
  }

  *, :after, :before {
    box-sizing: initial;
}

.dark-light-mode-outer .ui.toggle.checkbox input:checked~label:before {
  background-color: ${({ theme }) => theme.DarkLightSwitchBgColor} !important;
}

.dark-light-mode-outer .ui.toggle.checkbox label:before {
  background-color: ${({ theme }) => theme.DarkLightSwitchBgColor} !important;
}


/*----Custom ScrollBar----*/
/* width */
::-webkit-scrollbar {
  width: 8px;
  height:8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent !important;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #aaaaaa !important;
  border-radius: 5px;
  border: 1px solid  ${({theme}) => theme.ScrollBarThumb};
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: ${({theme}) => theme.ScrollBarThumbHover}; 
}
  .mt-1 {
    margin-top: 1rem;
  }
  .mt-2 {
    margin-top: 2rem;
  }
  .mr-1 {
    margin-right: 1rem;
  }

  .mr-2 {
    margin-right: 2rem;
  }
  .button_sec_right_outer.ppt {
    margin:0;
    width: 200px !important;
    display: flex  !important;
    justify-content: space-between  !important;
    padding: 5px 15px  !important;
    align-items: center  !important;
    > span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .button_sec_right_outer.qs {
    padding:5px 49px 5px 11px!important;
  }
  h2 {
    span.updated{
      font-size: 16px;
      color: #999;
      margin-left: 20px;
    }
  }
  .blinder {
    font-size: 12px;
    color: #ccc;
    animation: blinker 1s linear infinite;
  }
  @keyframes blinker {
  50% {
    opacity: 0;
  }
}

.msg-table-data-grid-column{
  max-width: 100px;
}

.recordPopup {
  .dx-overlay-content {
    overflow: visible;
    border-radius: 0;
    .dx-popup-content {
      padding: 0;
    }
  }
}

#sentry-feedback{
  bottom: 1px;
  right: 5px;
}
button{
  &:focus, &:focus-visible{
    outline: none;
  }
}
input{
  &:focus, &:focus-visible{
    outline: none;
  }
}
`;
