
import { ActionType } from '../action-types'

export const push = (path: any) => ({
	type: ActionType.REDIRECT ,
	payload: path
});

export const setInfoAlert = (message: string, option:any ={}) => ({
	type: ActionType.REDUX_ALERT_CHANGE ,
	payload: {
		type: "error" ,
		message: message,
		option: option
	}
})

export const alert = (message = "Please try again later", option:any= {}, msgType="error") => ({
	type: ActionType.REDUX_ALERT_CHANGE ,
	payload: {
		type: msgType,
		message: message,
		option: option
	}
});

export const toggleComponentLoading = (actionType: string, loading: boolean) => ({
	type: actionType,
	payload: loading
});