import { ActionType } from '../action-types'
import { UserHistoryState } from 'types/userHistory';
import { BarChartVisual } from 'types/visual';
import { removeNoCacheQuestion } from 'utils/subscribe';
import { SubscriptionAlertType } from 'services/subscribeQuestion';

export const initialState: UserHistoryState = {
    state: "View",
    pagination_limit: 20,
    data: [],
    date:[],
    index: -1,
    hasMore: true,
    loading_single_history: false,
    isSpinner: false,
    viewMoreSpinner: false,
    recentlyAskedQs: [],
    questionContext: [],
    isRecentQuestionResponse: false,
    isHistoryFound: true,
    historySearchLoader: false,
    historySearchedWord: "",
    historyCharOrder: "",
    historyOrderKey: "3",
    updatePageNumber: 2,
    hasAnimation: false,
    isDeleteSpinner: false,
    isPpt: false,
    primary_fields_value: [],
    pdf_status: "",
    isErrorWhileSearch:false,
}

const reducer = (state: UserHistoryState = initialState, action: any): UserHistoryState => {
    switch(action.type) {
        case ActionType.UPDATE_SUBSCRIPTION_ALERT_RESPONSE:
            const { id: subscribed_id,schedule_type, file_type,subscription_date, subscribed_user_timestamp, utterance_id: UtteranceId }: SubscriptionAlertType = action.payload;
            const updatedReportSubscription = {
              file_type: file_type,
              schedule_type: schedule_type,
              subscription_date: subscription_date,
              utterance_id: UtteranceId,
              subscribed_user_timestamp: subscribed_user_timestamp,
              id: subscribed_id || "",
          };
            return { ...state , data: state.data && state.data.map((utterance) => {
                if(utterance.utteranceId === UtteranceId) {
                    if (utterance.visual.report_subscription) {
                        utterance.visual.report_subscription = updatedReportSubscription;
                }}
                return utterance;
            }) }

        case ActionType.UPDATE_TITLE_IN_USER_HISTORY:
            return { ...state , data: state.data.map((utterance) => {
                if(utterance.utteranceId === action.payload.utteranceId) {
                    utterance.updateTitle = action.payload.title
                }
                return utterance;
            }) }
        case ActionType.UPDATE_HISTORY_FROM_CHAT:
            return { ...state , data:[...state.data , action.payload]  }

        case ActionType.ADD_PRIMARY_FIELDS_DATA:
            return { ...state, primary_fields_value: action.payload };
        case ActionType.IS_ERROR_WHILE_SEARCH:
            return { ...state, isErrorWhileSearch: action.payload };
        case ActionType.UPDATE_PPTX_STATUS:
            return { ...state , isPpt: action.payload }

        case  ActionType.SET_DELETE_SPINNER_VALUE:
            return { ...state , isDeleteSpinner: action.payload }

        case  ActionType.VIEW_MORE_UPDATE_PAGE:
            return { ...state , updatePageNumber: action.payload }

        case  ActionType.SEARCHED_WORD:
            return { ...state , historySearchedWord: action.payload }

            case  ActionType.SEARCHED_ORDER_KEY:
            return { ...state , historyOrderKey: action.payload }

        case  ActionType.LOADER_ON_SEARCH_RESULTS:
            return { ...state , historySearchLoader: action.payload }

        case  ActionType.SEARCH_RESULTS_DATE:
            return { ...state , date: action.payload }
            case  ActionType.SEARCH_RESULTS_HISTORY:
                return { ...state , data: action.payload }

        case  ActionType.IS_RECENT_HISTORY_QUESTION_FOUND:
            return { ...state , isHistoryFound: action.payload }

        case  ActionType.UPDATE_CONTEXT_ANIMATION:
            return { ...state , hasAnimation: action.payload }

        case  ActionType.RECENTLY_ASKED_QS_RESPONSE:
            return { ...state , isRecentQuestionResponse: action.payload }
      
        case  ActionType.SPINNER_ON_VIEW_MORE:
            return { ...state , viewMoreSpinner: action.payload }

        case  ActionType.SPINNER_ON_DELETE:
            return { ...state , isSpinner: action.payload }


        case  ActionType.QUESTION_CONTEXT_TOP_NAVBAR:
            return { ...state , questionContext: action.payload }

        case  ActionType.RECENT_HISTORY_RECORDS:
            return { ...state , recentlyAskedQs: action.payload }

        case 'update_pagination_limit':
            return { ...state , pagination_limit: action.payload }

        case ActionType.UPDATE_LOADING_SINGLE_HISTORY:
            return { ...state , loading_single_history: action.payload }

        case ActionType.UPDATE_USER_HISTORY_JOB_ID:
            let anyChange = false
            const data = state.data.map((utterance) => {
                if(utterance.utteranceId === action.payload.utteranceId) {
                    utterance.job_id = action.payload.job_id
                    anyChange = true
                } 

                return utterance;
            }) 
            if(anyChange) {
                return { ...state , data }
            } else {
                return state
            }

        case ActionType.UPDATE_HISTORY_VISUAL:
            return { ...state , data: state.data.map((element) => {
                if(element.utteranceId === action.payload.id) {
                    element.is_starred = action.payload.value;
                }
                return element;
            })
        }
        case ActionType.UPDATE_USER_HISTORY_PDF_STATUS_BY_UTTERANCE_ID:
            const { pdf_status, id } = action.payload;
            return { ...state , data: state.data.map((utterance) => {
                if(utterance.utteranceId === id) {
                    utterance.pdf_status = pdf_status
                }
                return utterance;
            }) }

        case ActionType.UPDATE_CHART_OPTION_USER_HISTORY:
            const { index, chart_options } = action.payload;
            const new_update_data = state.data[index];
            (new_update_data.visual as any).chart_options = chart_options;
            return { ...state , data: [ ...state.data.slice(0,index), new_update_data, ...state.data.slice(index+1) ] }
        case ActionType.FETCH_USER_HISTORY_COMPLETED:
            return { ...state , data: action.payload };
        case ActionType.FETCH_USER_HISTORY_DATE:
            return {...state, date: action.payload};
        case ActionType.UPDATE_HAS_MORE:
            return { ...state , hasMore: action.payload}
        case 'update_user_history':
            return {...state , data: [...state.data , ...action.payload]  }
        // case 'update_user_date':
        //     return {...state , date: [...state.date, ...action.payload] }
        case ActionType.UPDATE_FETCH_STATE:
            return { ...state , state: action.payload };
        case ActionType.UPDATE_HISTORY_INDEX:
            return { ...state , index: action.payload };
        case ActionType.ADD_HISTORY_ENTRY:
            return { ...state , index: 0 , state: 'View' , data: [ action.payload , ...state.data] }
        case ActionType.UPDATE_HISTORY:
            return { ...state , data: action.payload };
            case ActionType.UPDATE_HISTORY_DATE:
                return { ...state , date: action.payload };
        case ActionType.UPDATE_USER_HISTORY_VISUAL_BY_UTTERANCE_ID:
            return { ...state , data: state.data.map((utterance) => {
                if(utterance.utteranceId === action.payload.utteranceId) {
                    utterance.visual = action.payload.visual
                    utterance.subscribed = action.payload.subscribed
                    utterance.subscribed_id = action.payload.subscribed_id
                } 
                return utterance;
            }) }
            case ActionType.UPDATE_USER_HISTORY_VISUAL_BY_QUESTION:
                return { ...state , data: state.data.map((utterance) => {
                    if(removeNoCacheQuestion(utterance.utterance) === action.payload.utterance) {
                        utterance.subscribed = action.payload.subscribed
                        utterance.subscribed_id = action.payload.subscribed_id
                    } 
                    return utterance;
                })}
        case ActionType.UPDATE_USER_HISTORY_ENTRY:

            return { ...state , data: state.data.map((utterance) => {
                if(utterance.utteranceId === action.payload.utteranceId) {
                    utterance.status = (action.payload.visual.type === "calculation") ? "Calculating" : "Done";
                    utterance.visual = action.payload.visual
                    utterance.subscribed = action.payload.subscribed
                    utterance.thumbnail = action.payload.thumbnail
                } 
                return utterance;
            }) }
        case ActionType.UPDATE_CHART_OPTIONS:
            return { ...state , data: state.data.map((utterance) => {
                if(utterance.utteranceId === action.payload.utteranceId) {
                    (utterance.visual as BarChartVisual ).chart_options.chartLayout = action.payload.layout
                }
                return utterance;
            }) }
        default:
            return state;
    }
}

export default reducer;