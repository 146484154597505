import { Dispatch } from "redux";
import { createWebHookRequest, deleteWebHookRequest, getWebHooks, updateWebHookRequest, getWebHookByidRequest, webhookEvents, getSearchData , getFilterSuggestion, fetchAndUpdateWebhook, fetchRebuildIndexed, getSuggestions} from "services/developerSetting";
import { ActionType } from "state/action-types"
import { RootState } from "state/reducers";
import { WebHookData, WebhookObj } from "../../types/developer"
import { alert } from "./customRouter";

const PAGE_LIMIT = 50;

export const updateDeveloperTab = (currentTab: string) => {
    return {
        type: ActionType.CURRENT_DEVELOPER_TAB,
        payload: currentTab
    }
}

export const filterSearch = (data:any)=>{
    return {
        type:ActionType.FILTER_SEARCH_DATA,
        payload:data
    }
}

export const removeFilterHandle = (index:number)=>{
    return {
        type:ActionType.REMOVE_FILTER_DATA,
        payload:index
    }

}
export const clearFilterSearch = ()=>{
    return {
        type:ActionType.CLEAR_FILTER_SEARCH_DATA,
    }
}

export const customizeFilterRecords = () => {
    return {
        type:ActionType.CUSTOMIZE_FILTER_SEARCH_DATA
    }
}

export const getWebHookRecords = () => {
    return async (dispatch: Dispatch) => {
        try {
            const res = await getWebHooks()
            if (res.status = 200) {
                dispatch({ type: ActionType.GET_WEBHOOK_RECORD_IS_SUCCESS, payload: res.body })
            }
        } catch (error) {
            console.log(error)
        }
    }
}

export const setWebHookData = (updatedData: WebHookData[]) => {
    return async (dispatch: Dispatch) => {
        try {
            dispatch({
                type: ActionType.UPDATE_WEBHOOK_RECORDS_DATA,
                payload: updatedData
            })
        } catch (error) {
            dispatch(alert("Error occur while updating webhook records data"))
        }
    }
}

export const updateWebHookSwitchAction = (updatedData: WebhookObj) => {
    return async (dispatch: Dispatch) => {
        try {
             await fetchAndUpdateWebhook(updatedData);
        } catch (error) {
            dispatch(alert("something went wrong to enable or disable webhook"));
        }
    }
}

export const getSingleWebHookRecord = (id: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const res = await getWebHookByidRequest(id)
            if (res.status = 200) {
                dispatch({ type: ActionType.GET_SINGLE_WEBHOOK_RECORD_IS_SUCCESS, payload: res.body })
            }
        } catch (error) {
            console.log(error)
        }
    }
}

export const webhookLoader = (value:boolean) => {
    return{
      type: ActionType.IS_WEBHOOK_LOADER,
      payload: value
    }
  }

export const createWebHook = (value: WebHookData, hideVisibility: () => void) => {
    return async (dispatch: Dispatch) => {
        dispatch(webhookLoader(true));
        try {
            const res: any = await createWebHookRequest(value);
            if (res.status === 201) {
                hideVisibility()
                dispatch({
                    type: ActionType.CREATE_WEBHOOK_RECORD_SUCCESS,
                    payload: res.body,
                })
                dispatch(webhookLoader(false));
            }
            dispatch(webhookLoader(false));
        } catch (error) {
            dispatch(webhookLoader(false));
            console.log(error)
        }
    }
}


export const deleteWebHook = (deleteId: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const res = await deleteWebHookRequest(deleteId);
            if (res.status === 200) {
                dispatch({
                    type: ActionType.DELETE_WEBHOOK_RECORD_SUCCESS,
                    payload: deleteId,
                });
            }
        } catch (error) {
            console.log(error);
        }
    };
};


export const updateWebHook = (id: string, value: WebHookData, hideEditPannel: () => void) => {
    return async (dispatch: Dispatch) => {
        dispatch(webhookLoader(true));
        try {
            const res = await updateWebHookRequest(id, value);
            if (res.status === 200) {
                hideEditPannel()
                dispatch({
                    type: ActionType.UPDATE_WEBHOOK_RECORD_SUCCESS,
                    payload: {
                        id: id,
                        value: value
                    }
                });
                dispatch(webhookLoader(false));
            }
            dispatch(webhookLoader(false));
        } catch (error) {
            dispatch(webhookLoader(false));
            console.log(error);
        }
    };
};

export const getWebhookEvents = (isTableLoader?:boolean, user_id?: string, pageNumber?: number) => {
    return async (dispatch: Dispatch) => {
        if(isTableLoader){
            dispatch({type:ActionType.GET_FILTERED_DATA_ISPENDING})
        }else{
            dispatch(webHookEventsLoaderAction(true));
        }
        try {
            const res = await webhookEvents(user_id, pageNumber, PAGE_LIMIT);
            if (res.status === 200) {
                dispatch({
                    type: ActionType.GET_WEBHOOK_EVENTS,
                    payload: res.body.events
                });
                dispatch({
                    type:ActionType.GET_EVENTS_TOTAL_RECORDS,
                    payload: res.body.total_records
                })
                dispatch(updateRebuildIndexLoader(false));
            }
            dispatch(webHookEventsLoaderAction(false));
            dispatch({type:ActionType.GET_FILTERED_DATA_ISFAILURE});
            dispatch(updateRebuildIndexLoader(false));
        } catch (error) {
            dispatch({type:ActionType.GET_FILTERED_DATA_ISFAILURE})
            dispatch(webHookEventsLoaderAction(false));
            dispatch(updateRebuildIndexLoader(false));
            console.log(error);
        }
    };
};

export const webHookEventsLoaderAction = (value : boolean) => {
    return {
        type: ActionType.GET_WEBHOOK_EVENTS_IS_PENDING,
        payload: value
    }
}

export const getSearchRecord = (queryValue:string, page: number)=> {
    return async (dispatch:Dispatch, getState: () => RootState)=>{
        dispatch({type:ActionType.GET_WEBHOOK_EVENT_SEARCH_DATA_IS_PENDING})
        try{
            const eventsSearchUserId = getState().DeveloperReducer.eventsSearchUserId;
            const res:any = await getSearchData(queryValue, eventsSearchUserId, page, PAGE_LIMIT);

            if(res.status == 200){
                dispatch({
                  type: ActionType.GET_WEBHOOK_EVENT_SEARCH_DATA,
                  payload: res.body.events,
                });
                dispatch({
                    type:ActionType.GET_EVENTS_TOTAL_RECORDS,
                    payload: res.body.total_records
                })
            }else if(res.status === 500){
                dispatch(alert(res.body.error));
            }

        }catch(error){
            dispatch({type:ActionType.GET_WEBHOOK_EVENT_SEARCH_DATA_IS_FAILURE});
            dispatch(alert("Error has been occurred while fetching Events"));
        }
    }
}

export const fetchSuggestions = (header:string , query:string, setIsTyping?:any)=>{
    return async (dispatch:Dispatch, getState: () => RootState)=>{
        try{
            setIsTyping(true);
            dispatch({type:ActionType.GET_WEBHOOK_EVENT_SEGGESTION_IS_PENDING});
            const eventsSearchUserId = getState().DeveloperReducer.eventsSearchUserId;
            const res:any = await getSuggestions(header, query, eventsSearchUserId);
            dispatch({type:ActionType.GET_WEBHOOK_EVENT_SEGGESTION , payload: res.body});
            if(res){
                setIsTyping(false);
            }
        }catch(error){
            dispatch({type:ActionType.GET_WEBHOOK_EVENT_SEGGESTION_IS_FAILURE});
        }

    }
}
export const getfilteredRecord = (data:any, page: number)=> {
    return async (dispatch:Dispatch)=>{
        try{
            dispatch({type:ActionType.GET_FILTERED_DATA_ISPENDING})
            const res:any = await getFilterSuggestion(data, page, PAGE_LIMIT);
            if(res.status == 200){
                dispatch({
                  type: ActionType.GET_FILTERED_DATA_ISSUCCESS,
                  payload: res.body.events,
                });
                dispatch({
                    type:ActionType.GET_EVENTS_TOTAL_RECORDS,
                    payload: res.body.total_records
                })
            }else{
                dispatch(alert(res.body.error));
            }
        }catch(error){
            console.log(error)
            dispatch({type:ActionType.GET_FILTERED_DATA_ISFAILURE})
        }

    }
}

export const handleRebuildIndexAction = (eventsSearchUserId: string, currentPage: number) => {
    return async (dispatch: Dispatch<any>) => {
        try{
            dispatch(updateRebuildIndexLoader(true));
            const response = await fetchRebuildIndexed();
            if(response.status === 200){
                dispatch(getWebhookEvents(false, eventsSearchUserId.length > 0 ? eventsSearchUserId : "",currentPage));
                dispatch(updateRebuildIndexLoader(false));
                dispatch(alert("successfully rebuild webhook event indexes",{position:'bottom-left'}, 'success'));
            }else{
                dispatch(updateRebuildIndexLoader(false));
                dispatch(alert(response.body.error));
            }
        }catch(error){
            dispatch(updateRebuildIndexLoader(false));
            dispatch(alert("something went wrong to rebuild webhook events indexing"));
        }
    }
}

export const updateRebuildIndexLoader = (value: boolean) => {
    return {
        type: ActionType.UPDATE_EVENTS_REBUILD_INDEX_LOADER,
        payload: value
    }
}

export const getEventsSearchUserIdAction = (value: string) => {
    return {
        type: ActionType.FETCH_UPDATED_EVENTS_SEARCH_USER_ID,
        payload: value
    }
}
export const setSelectedUserEmailAction = (value: string) => {
    return {
        type: ActionType.UPDATE_SELECTED_USER_EMAIL_ACTION,
        payload: value
    }
}
export const setDropdownToggle = (value: boolean) => {
    return {
        type: ActionType.UPDATE_EVENTS_SEARCH_DROPDOWN_TOGGLE,
        payload: value
    }
}
export const setSubDropdown = (value: boolean) => {
    return {
        type: ActionType.UPDATE_EVENTS_SEARCH_SUB_DROPDOWN_TOGGLE,
        payload: value
    }
}
export const setSuggestionDropdown = (value: boolean) => {
    return {
        type: ActionType.UPDATE_EVENTS_SEARCH_SUGGESTION_DROPDOWN,
        payload: value
    }
}
export const setHeaders = (value: string) => {
    return {
        type: ActionType.UPDATE_EVENTS_SEARCH_HEADER,
        payload: value
    }
}
export const setSelectedOption = (value: string) => {
    return {
        type: ActionType.UPDATE_EVENTS_SEARCH_SELECTED_OPTIONS,
        payload: value
    }
}
export const setUpdateSearchTerm = (eventSearchTerm: string)=> {
    return {
        type: ActionType.UPDATE_WEBHOOK_EVENTS_SEARCH_TERM,
        payload: eventSearchTerm
    }
}
export const setOperator = (operatorSelection: string) => {
    return {
        type: ActionType.UPDATE_EVENTS_OPERATOR_SELECTION,
        payload: operatorSelection
    }
}