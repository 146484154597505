import { ActionType } from "state/action-types"
import { Dispatch  } from "redux";
import { deserializeSplats, getSplats } from "services/splat";
import { alert } from "./customRouter";
import { notifyHuma } from "services/splat";
import { notify } from "services/splat";
import { reprocessSplats } from "services/splat";
import { reprocessSingleSplat } from "services/splat";
import { updateSearchLoading } from "./AnalyzerActions";
import { RootState } from "state/reducers";


export const refreshStart = () => {
    return {
        type: ActionType.SPLAT_REFRESH_START
    }
}

export const refreshFailed = () => {
    return {
        type: ActionType.SPLAT_REFRESH_FAILED
    }
}

export const reprocessAll = (setIsReprocessingAll: any) => {
    return async (dispatch: Dispatch) => {
        try {
            setIsReprocessingAll(true);
            const { status , body } = await reprocessSplats(); 
            if(status!==200){
                dispatch(alert(body?.error))
            }
             setTimeout(function () {
             setIsReprocessingAll(false);
            }, 10000);
        } catch (error) {
            setIsReprocessingAll(false);
            dispatch(alert());
        }
    }
}

export const notifyHumaAction = (setIsNotifyingHuma: any) => {
    return async (dispatch: Dispatch) => {
        try {
            setIsNotifyingHuma(true);
            const {status,body} = await notifyHuma();
            if(status!==200){
                dispatch(alert(body?.error))
            }
            setIsNotifyingHuma(false);
        } catch (error) {
            setIsNotifyingHuma(false);
            dispatch(alert());
        }
    }
}

export const notifyAllAction = (setIsNotifyingAll: any) => {
    return async (dispatch: Dispatch) => {
        try {
            setIsNotifyingAll(true);
            const {status,body} = await notify();
            if(status!==200){
                dispatch(alert(body?.error))
            }
            setIsNotifyingAll(false);
        } catch (error) {
            setIsNotifyingAll(false);
            dispatch(alert());
        }
    }
}

 // only called on non-resolved splats
 export const runReprocessAction = (setIsReprocessing: any , splatId: any) => {
     return async (disptach: Dispatch ) => {
        // const splats = getState().splat.data;

        try {
            setIsReprocessing(true);
            const response = await reprocessSingleSplat(splatId);
            if (response.status === 200) {
                    const { prevStatus, status } = response.body;
                    if (status !== prevStatus) {
                        disptach({
                         type: ActionType.UPDATE_SPLATS_STATUS ,
                         payload: {
                             splatId: splatId,
                             status: status
                            }
                         });
                    }
                }else{
                    disptach(alert(response.body?.error));
                }
            setIsReprocessing(false)
         } catch {
            setIsReprocessing(false)
            disptach(alert())
         }
     }
  }


export const updateSplatFilter = (filter: any) => {
    return {
        type: ActionType.UPDATE_SPLAT_FILTER ,
        payload: filter
    }
}

export const fetchSplatsAction = (page_no: number, is_update:boolean, setIsBottom: any) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        try {
            if (page_no == 1) { setLoadingData(true, dispatch); }
            splatRecordLoading(true, dispatch);
            dispatch(updateSearchLoading(true))
            const splats = getState().splat;
            const response = await getSplats({
                sortKey: splats.sort.key,
                sortOrder: splats.sort.order,
                lastCreated: splats.lastCreated,
                status: undefined,
                page_no: page_no,
            });

            if (response.status === 200) {
                const { splats: newSplats } = response.body;
                if (!is_update && page_no ==1) {
                    dispatch({
                        type: ActionType.FETCH_SPLATS_COMPLETED ,
                        payload: deserializeSplats(newSplats.splats),
                        csv: newSplats.csv
                    });
                } else {
                    dispatch({
                        type: ActionType.UPDATE_SPLAT_COMPLETED ,
                        payload: deserializeSplats(newSplats.splats),
                        csv: newSplats.csv
                    });
                }
                if(response.body.splats.splats.length === 0 || response.body.splats.splats.length < 100) {
                    dispatch({
                        type: ActionType.UPDATE_SPLAT_HAS_MORE,
                        payload: false
                    });
                    setLoadingData(false, dispatch);
                    dispatch(updateSearchLoading(false))
                    return;
                }
                splatRecordLoading(false, dispatch);
                dispatch(updateSearchLoading(false))
                setLoadingData(false, dispatch);
                setIsBottom(false);
            } else {
                splatRecordLoading(false, dispatch);
                dispatch(updateSearchLoading(false))
                setLoadingData(false, dispatch);
                dispatch(refreshFailed());
                dispatch(alert("Please try to load unanswered questions later"));
            }

        } catch (error) {
            splatRecordLoading(false, dispatch);
            dispatch(updateSearchLoading(false))
            setLoadingData(false, dispatch);
            dispatch(refreshFailed())
            dispatch(alert("Please try to load unanswered questions later!"));
        }
    }
}

export const searchSplat = (search: string) => {
    return {
        type: ActionType.SEARCH_SPLATS_TERM ,
        payload: search
    }
}

export const setLoadingData =  async(loading: boolean,dispatch:Dispatch) => {
    dispatch({
    type: ActionType.SET_LOADING_SPLAT,
    payload: loading
})
}

export const splatRecordLoading = async(splatRecordLoading:boolean, dispatch:Dispatch) =>{
    dispatch({
        type: ActionType.SPLAT_RECORD_LOADING,
        payload: splatRecordLoading
    })
}
