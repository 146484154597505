import { ActionType } from "state/action-types"
import { initialStateTypes } from "types/developer"

export const initialState: initialStateTypes = {
    currentDeveloperTab: "APITOKEN",
    webHookData: [],
    singleWebHookData: {
        authorization: "",
        callback: "",
        events: "",
        name: "",
        resource: "",
        is_enabled: false,
        createdAt: 0,
        id: "",
        user_id: "",
        is_indexed: false,
    },
    webhookEvents:[],
    webhookEventsisLoaded:true,
    SearchisLoaded:false,
    suggestionList:[],
    isSuggestionLoaded:false,
    filterSearchArray:[],
    getRecordsIsLoaded:false,
    updateRecordisDone: false,
    isLoading: false,
    equalsArray: [],
    containArray:[],
    eventsRebuildIndexLoader: false,
    eventsSearchUserId: '',
    selectedUserEmail: '',
    dropdownToggle: false,
    subDropDown: false,
    suggestionDropDown: false,
    headers: "",
    selectedOption: "",
    columns:[
        {name:"Name",key:"name"},
        {name:"Resource",key:"resource"},
        {name:"Event",key:"event"},
        {name:"Callback URL",key:"callback"},
        {name:"Created At",key:"created_at"},
        {name:"Delivered at",key:"delivered_at"},
        {name:"Attempts",key:"attempts"},
        {name:"Status",key:"status"},
        {name:"Ticket Number",key:"ticket_number"},
    ],
    events_total_records: 0,
    updateSearchTerm: "",
    operator: "and",
    isWebhookLoader:false,
}

export const DeveloperReducer = (state = initialState, action: any):initialStateTypes => {
    switch (action.type) {
        case ActionType.UPDATE_EVENTS_OPERATOR_SELECTION:
            return { ...state, operator: action.payload }
        case ActionType.UPDATE_WEBHOOK_EVENTS_SEARCH_TERM:
            return { ...state, updateSearchTerm:action.payload }
        case ActionType.GET_EVENTS_TOTAL_RECORDS:
            return { ...state, events_total_records: action.payload }
        case ActionType.UPDATE_EVENTS_SEARCH_SELECTED_OPTIONS:
            return { ...state, selectedOption: action.payload }
        case ActionType.UPDATE_EVENTS_SEARCH_HEADER:
            return { ...state, headers: action.payload }
        case ActionType.UPDATE_EVENTS_SEARCH_SUGGESTION_DROPDOWN:
            return { ...state, suggestionDropDown: action.payload }
        case ActionType.UPDATE_EVENTS_SEARCH_SUB_DROPDOWN_TOGGLE:
            return { ...state, subDropDown: action.payload }
        case ActionType.UPDATE_EVENTS_SEARCH_DROPDOWN_TOGGLE:
            return { ...state, dropdownToggle: action.payload }
        case ActionType.UPDATE_SELECTED_USER_EMAIL_ACTION:
            return { ...state, selectedUserEmail: action.payload }
        case ActionType.FETCH_UPDATED_EVENTS_SEARCH_USER_ID:
            return { ...state, eventsSearchUserId: action.payload }
        case ActionType.UPDATE_EVENTS_REBUILD_INDEX_LOADER:
            return { ...state, eventsRebuildIndexLoader: action.payload }
        case ActionType.GET_WEBHOOK_EVENTS_IS_PENDING:
            return { ...state, webhookEventsisLoaded: action.payload }
        case ActionType.IS_WEBHOOK_LOADER:
            return { ...state, isWebhookLoader: action.payload }
        case ActionType.CURRENT_DEVELOPER_TAB:
            return {
                ...state,
                currentDeveloperTab: action.payload
            }
        case ActionType.GET_WEBHOOK_RECORD_IS_SUCCESS:
            return {
                ...state,
                getRecordsIsLoaded: false,
                webHookData: action.payload.webhook
            }
        case ActionType.UPDATE_WEBHOOK_RECORDS_DATA:
            return {
                ...state,
                getRecordsIsLoaded: false,
                webHookData: action.payload.map((item: any) => {
                    if (item.id === state.singleWebHookData.id) {
                        // Update is_enabled value from singleWebHookData
                        return { ...item, is_enabled: state.singleWebHookData.is_enabled };
                    }
                    return item;
                })
            }
        case ActionType.GET_SINGLE_WEBHOOK_RECORD_IS_SUCCESS:
            return {
                ...state,
                getRecordsIsLoaded: false,
                singleWebHookData: {
                    ...state.singleWebHookData,
                    authorization: action.payload.authorization,
                    callback: action.payload.callback,
                    events: action.payload.events,
                    name: action.payload.name,
                    resource: action.payload.resource,
                    is_enabled: action.payload.is_enabled,
                    createdAt: action.payload.createdAt,
                    user_id: action.payload.user_id,
                    is_indexed: action.payload.is_indexed
                }
            }
        case ActionType.UPDATE_WEBHOOK_RECORD_SUCCESS:
            return {
                ...state,
                webHookData: state.webHookData.map((item) => {
                    return item.id == action.payload.id ? { ...state.webHookData, ...action.payload.value } : item
                }),
                updateRecordisDone: false
            }
        case ActionType.CREATE_WEBHOOK_RECORD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                webHookData: [...state.webHookData, action.payload]
            }
        case ActionType.DELETE_WEBHOOK_RECORD_SUCCESS:
            return {
                ...state,
                webHookData: state.webHookData.filter((data) => data.id !== action.payload
                )
            }

        case ActionType.GET_WEBHOOK_EVENTS:
            return { ...state, webhookEvents: action.payload }

        case ActionType.GET_WEBHOOK_EVENT_SEARCH_DATA_IS_PENDING:
                return {
                    ...state , SearchisLoaded:true
                }
                case ActionType.GET_WEBHOOK_EVENT_SEARCH_DATA:
            return{
                ...state, webhookEvents:action.payload,
                SearchisLoaded:false
            }
        case ActionType.GET_WEBHOOK_EVENT_SEARCH_DATA_IS_FAILURE:
            return {
                ...state, SearchisLoaded: false
            }
        case ActionType.GET_WEBHOOK_EVENT_SEGGESTION_IS_PENDING:
            return {
                ...state,
                isSuggestionLoaded: true,
                suggestionList:[],
            }
        case ActionType.GET_WEBHOOK_EVENT_SEGGESTION:
            return {
                ...state, suggestionList: action.payload,
                isSuggestionLoaded: false
            }

        case ActionType.GET_WEBHOOK_EVENT_SEGGESTION_IS_FAILURE:
            return {
                ...state,
                suggestionList:[],
                isSuggestionLoaded: false
            }
            case ActionType.FILTER_SEARCH_DATA:
                return {
                    ...state,
                    filterSearchArray: [...state.filterSearchArray, action.payload]
                };
            case ActionType.CLEAR_FILTER_SEARCH_DATA:
                return {
                    ...state,
                    filterSearchArray:[]
                }
                case ActionType.GET_FILTERED_DATA_ISPENDING:
                return {
                    ...state,
                    SearchisLoaded:true
                }
            case ActionType.GET_FILTERED_DATA_ISSUCCESS:
                return {
                    ...state,
                    webhookEvents: action.payload,
                    SearchisLoaded:false
                }
                case ActionType.GET_FILTERED_DATA_ISFAILURE:
                    return {
                        ...state,
                        SearchisLoaded:false
                    }
        case ActionType.REMOVE_FILTER_DATA:
            return {
                ...state,
                filterSearchArray: state.filterSearchArray.filter((item, index) => {
                    return index != action.payload
                })
            }
        case ActionType.CUSTOMIZE_FILTER_SEARCH_DATA:
            const transformedEqualsArray: any[] = [];
            const transformedContainArray: any[] = [];

            state.filterSearchArray.forEach((item: string) => {
                const operators = ["=", "!=", ":", "!:"];
                let operator = "";
                let operatorIndex = item.length;

                for (const op of operators) {
                    const opIndex = item.indexOf(op);
                    if (opIndex !== -1 && opIndex < operatorIndex) {
                        operator = op;
                        operatorIndex = opIndex;
                    }
                }

                if (operator !== "") {
                    const callback = item.substring(0, operatorIndex);
                    const columnKey = state.columns.find((column)=>{
                        if(column.name===callback){
                            return column
                        }
                    })?.key
                    const url = item.substring(operatorIndex + operator.length);

                    if (operator === "=" || operator === "!=") {
                        transformedEqualsArray.push([columnKey, url, operator]);
                    } else {
                        transformedContainArray.push([columnKey, url, operator]);
                    }
                }
            });

            return {
                ...state,
                equalsArray: transformedEqualsArray,
                containArray: transformedContainArray
            };

        default:
            return state
    }
}