import { get, post, Response } from "makeRequest";
import {
  FeedbackKind,
  FeedbackSortKey,
  SerializedFeedback,
  Feedback,
  FeedbackStatus
} from "types/feedback";

export function createFeedback(
  utteranceId: string,
  utterance: string,
  kind: FeedbackKind,
  text: string
): Response {
  return post("/feedback", { utteranceId, utterance, kind, text });
}

type FeedbackPayload = {
  csv: Array<string>;
  feedback: Array<SerializedFeedback>
}

type GetFeedbackResponse = {
  feedback: FeedbackPayload;
  done: boolean;
};

export function deserializeFeedback(
  feedback: Array<SerializedFeedback>
): Array<Feedback> {
  return feedback.map((feedback) => ({
    ...feedback,
    created: new Date(feedback.created),
  }));
}

type GetFeedbackParams = {
  lastCreated?: number | null;
  sortKey: FeedbackSortKey;
  sortOrder: -1 | 1;
  page_no: number;
};

export function getFeedback({
  lastCreated,
  sortKey,
  sortOrder,
  page_no,
}: GetFeedbackParams): Response<GetFeedbackResponse> {
  const params = new URLSearchParams();

  params.append("sortKey", sortKey);
  params.append("sortOrder", String(sortOrder));

  if (lastCreated) {
    params.append("lastCreated", String(lastCreated));
  }

  return get(`/feedback?${params.toString()}&page=${page_no}&limit=100`);
}


type feedbackAddressed = {
  prevStatus: FeedbackStatus;
  status: FeedbackStatus;
  error?: string;
};

export function feedbackAddressed(id:string, status:number):Response<feedbackAddressed> {
  return post(`/feedback-addressed/${id}/${status}`, {});
}
