import { Colors } from "style/color";

export const actionBarColors = {
    dark:{
        ActionBarBackgroundColor: '#323346',
        AnalyzerEntites: '#323346',
        ActionBarBorderColor: '#454557',
        ActionBarDividerColor: '#454557',
        ActionBarTextColor: '#D7D7DA',
        ActionBarIconTitleColor:  Colors.WHITE,
        ActionBarBoxShadow: 'rgba(0, 0, 0, 0.1)',
        ActionBarIconBgColor: '#1B1C31',
        ActionBarOptionsBgColor: '#1B1C31',
        ActionBarOptionsBorderColor: '#454557',
        ActionBarFeedbackContainerBgColor: '#1B1C31',
        ActionBarFeedbackTextareaBgColor: '#323447',
        ActionBarFeedbackTextareaPlaceholderColor: '#FEFEFE',
        FeedbackPopupCancelButtonBgColor: '#007BA2',
    },
    light:{
        ActionBarBackgroundColor:  Colors.WHITE,
        AnalyzerEntites:'#f5f5f5',
        ActionBarBorderColor: '#E3E3E3',
        ActionBarDividerColor: '#C8C8C8',
        ActionBarTextColor: '#A5A5A5',
        ActionBarIconTitleColor: '#828282',
        ActionBarBoxShadow: 'rgba(16, 30, 115, 0.1)',
        ActionBarIconBgColor: '#ACADAD',
        ActionBarOptionsBgColor:  Colors.WHITE,
        ActionBarOptionsBorderColor: '#EDEAEA',
        ActionBarFeedbackContainerBgColor:  Colors.WHITE,
        ActionBarFeedbackTextareaBgColor: 'transparent',
        ActionBarFeedbackTextareaPlaceholderColor: '#ACADAD',
        FeedbackPopupCancelButtonBgColor: '#2058E8',
    },
}
