import { Reducer } from 'redux';
import { ActionType } from 'state/action-types';
interface AccessAnalyzerParameterData {
  _id: string;
  key: string;
  value: string;
  scope: string;
}
interface AnalyzerParametersState {
  analyzerParametersData: Array<AccessAnalyzerParameterData>;
  isParameterLoader:boolean;
  isParameterPageLoader:boolean;
}

export const initialState: AnalyzerParametersState = {
  analyzerParametersData:[],
  isParameterLoader:false,
  isParameterPageLoader:false
};

const AnalyzerParameterReducer: Reducer<AnalyzerParametersState> = (state = initialState, action:any):AnalyzerParametersState => {
  switch (action.type) {
    case ActionType.GET_ANALYZER_PARAMETERS:
      return { ...state, analyzerParametersData: action.payload };

    case ActionType.UPDATE_ANALYZER_PARAMETERS:
      return {
        ...state,
        analyzerParametersData: state.analyzerParametersData.map((data) =>
          data._id === action.payload?.id
            ? { ...data, ...action.payload?.data }
            : data
        ),
      };
    case ActionType.CREATE_NEW_PARAMETERS:
      return {
        ...state, analyzerParametersData: [...state.analyzerParametersData, action.payload]
      }
    case ActionType.IS_PARAMETER_LOADER:
      return {
        ...state, isParameterLoader: action.payload
      }

    case ActionType.IS_PARAMETER_PAGE_LOADER:
      return {
        ...state, isParameterPageLoader: action.payload
      }

    case ActionType.DELETE_ANALYZER_PARAMETERS:
      return {
        ...state,
        analyzerParametersData: state.analyzerParametersData.filter(
          (data) => data._id !== action.payload
        ),
      };

    default:
      return state;
  }
};

export default AnalyzerParameterReducer;