import { ActionType } from "state/action-types"

export const resetAlert = () => {
    return {
        type: ActionType.REDUX_ALERT_CHANGE ,
        payload: {
            type: "empty" ,
            message: "",
            option: {}
        }
    }
}

export const alert = ( type: string , message = "Please try again later", option: any = {}) => ({
	type: ActionType.REDUX_ALERT_CHANGE ,
	payload: {
		type: type ,
		message: message,
        option: option
	}
});

export const updateIsMenuOpen = (value: boolean) => {
    return {
        type: ActionType.UPDATE_IS_MENU_OPEN ,
        payload: value
    }
}

export const updateHideLogo = (value: boolean) => {
    return {
        type: ActionType.UPDATE_HIDE_LOGO,
        payload: value
    }
}
export const updateExplanationShown = (value: boolean) => {
    return {
        type: ActionType.UPDATE_EXPLANATION_SHOWN,
        payload: value
    }
}

export const setExpandedAnswerComponentIndex = (value: number) => {
    return {
        type: ActionType.SET_EXPANDED_ANSWER_COMPONENT_INDEX ,
        payload: value
    }
}

export const operationLayerCheckBox = (tableData:any) => {
    return {
        type: ActionType.OPERATION_LAYER_COMPARE_DATA ,
        payload: tableData
    }
}

export const updateIsExpandSideBar = (isSideBarExpand: boolean) => {
    return {
        type: ActionType.UPDATE_IS_EXPAND_SIDE_BAR ,
        payload: isSideBarExpand
    }
}

export const updatePortalType = (portalType: string) => {
    return {
        type: ActionType.UPDATE_PORTAL_TYPE,
        payload: portalType
    }
}
