import { AuditState } from "types/AuditTypes";
import { ActionType } from "../action-types";


export const initialState: AuditState = {
    logs:[],
    isButtonloader:false,
    filters: {
        email: "",
        fromDate: "",
        toDate: "",
        FilterLog:"",
    },
    isLoader: false,
    isMessage: false
   
  };
  

  const reducer = (state: AuditState = initialState, action: any): AuditState => {
    switch (action.type) {
        case ActionType.ADD_AUDIT_LOGS_TABLE:
            return {...state, logs: action.payload};

            case ActionType.ADD_AUDIT_LOGS_FILTERS:
                return {...state, filters: action.payload}

                case ActionType.IS_TABLE_LOADER:
                    return {...state, isLoader: action.payload}

                    case ActionType.IS_BUTTON_LOADER:
                        return {...state, isButtonloader: action.payload}

                        case ActionType.IS_MESSAGE:
                        return {...state, isMessage: action.payload}
         
         
        default:
            return state;

    }
};

export default reducer;