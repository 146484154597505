import { ActionType } from '../action-types';
// import { Action ,  DeleteValidDomainAction , InsertValidDomainAction } from '../actions';
import { Action } from '../actions';
// import * as userServices from "../../services/user"
import * as siteServices from "../../services/settings"

import { Dispatch  } from "redux";
import { RootState } from 'state';
import { alert } from './customRouter';

// import { SiteSettings } from '../../types/siteSettings';

export const addUserContext = (newtag: string) => {
    return async (dispatch: Dispatch , getState: any) => {
        try {
            const newObj = {id: newtag , text: newtag}
            const id = getState().siteSettings.id;

            const user_context_array = getState().quickLinks.userContext ? getState().quickLinks.userContext : []

            const data = {user_context: [...user_context_array, newObj] , action: "user_context"}
            siteServices.updateSiteSettings(id , data).then(function(res){
                if(res.status == 200) {
                    dispatch({
                        type: 'add_user_context',
                        payload: newObj
                    });

                }else{
                    dispatch(alert((res?.body as any)?.error));
                }
              });
        } catch (error) {
            dispatch(alert())
        }
    }
}

export const deleteUserContext = ( i: number) => {
    return async (dispatch: Dispatch<any> , getState: any) => {
        try {
            const state: RootState = getState();
            const id = getState().siteSettings.id;
            const newTags = state.quickLinks.userContext?.filter((tag: any, index: number) => index !== i)
            const data = {user_context: newTags  , action: "user_context"}
            siteServices.updateSiteSettings(id, data).then(function(res){    
                if(res.status == 200) {

                    dispatch({
                        type: 'remove_user_context',
                        payload: i
                    })
                }else{
                    dispatch(alert((res?.body as any)?.error));
                }
              });
        } catch(err) {
           dispatch(alert())
        }
    }
}

export const insertValidDomain = ( newtag: string) => {
    return async (dispatch: Dispatch<Action> , getState: any) => {
        try {
            const newObj = {id: newtag , text: newtag}
            const id = getState().siteSettings.id;
            const data = {valid_domains: [...getState().siteSettings.valid_domains, newObj] , action: "valid_domains"}
            siteServices.updateSiteSettings(id , data).then(function(res){
                if(res.status == 200) {
                    dispatch({
                        type: ActionType.INSERT_VALID_DOMAIN,
                        payload: {
                            id: newtag,
                            valid_domain: newObj
                        }
                    })
                  
                }else{
                    dispatch(alert(res?.body.error) as Action);
                }
              });
                       
        } catch(err) {
            console.log("error=>" , err);
        }
    }
}

export const deleteValidDomain = ( i: number) => {
    return async (dispatch: Dispatch<Action> , getState: any) => {
        try {
            const id = getState().siteSettings.id;
            const newTags = getState().siteSettings.valid_domains.filter((tag: any, index: number) => index !== i)
            const data = {valid_domains: newTags  , action: "valid_domains"}
            siteServices.updateSiteSettings(id, data).then(function(res){    
                if(res.status == 200) {
                    dispatch({
                        type: ActionType.DELETE_VALID_DOMAIN,
                        payload: i
                    })
                    
                }else{
                    dispatch(alert(res?.body.error) as Action);
                }
              });
            
        } catch(err) {
            console.log("err:" , err)
        }
    }
}
