export const removeNoCacheQuestion = (question:string) => {
    const uselessWordsArray = [
      "no cache",
      "no answer cache",
      "no queue",
      "no answer cache",
    ];
    const expStr = uselessWordsArray.join("|");
    const removestr = question.replace(new RegExp(expStr, "gi"), " ");
    const removeNoAnswer = removestr.trim();

    return removeNoAnswer;
   
  };