import { ActionType } from '../action-types';
import { Dispatch  } from "redux";
import * as QueueServices from "../../services/queue";
import { push, alert } from './customRouter';
import * as QueueJobsServices from "../../services/queueJobs";
import { getWorkerHeaderType } from "services/config";
import { getTableSearchAnswer,analyzerSearch, analyzerSuggestions  } from "services/answer";
import { refreshFailed, tableSliderData } from "state/action-creators";
import { suggestionSearchObj } from 'components/RocketAnalyzer/ElasticSearchTable';
import { headerMap } from 'components/Table/utils';
import { getLocalTime } from 'utils/getPrettyDate';
import { analyzerWorkerAndJobsExportType } from 'types/queueJobs';
import { TerminateJobPayload, Worker, job, newWorkerObjTypes } from 'types/analyzer';
import { RootState } from "state";

const RECORD_LIMIT = 200;
export const fetchWorkers = (page_no:number) => {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(updateSearchLoading(true));
            const response = await QueueServices.getWorkers(page_no, RECORD_LIMIT);

            if (response.status === 200) {
                const total_records = response.body.total_records;
                const duration = response.body?.duration;
                dispatch({
                    type: ActionType.SHOW_TOTAL_RECORDS_OF_WORKER,
                    payload: total_records
                })
                dispatch({
                    type: ActionType.SHOW_SEARCH_DURATION,
                    payload: duration
                })
                dispatch(handleAnalyzerRefresh(false));
                const { workers } = response.body;
                    dispatch({
                        type: ActionType.FETCH_WORKERS_DETAILS ,
                        payload: workers
                    });
                    dispatch(updateSearchLoading(false));
            }else{
                dispatch(updateSearchLoading(false))
                dispatch(refreshFailed());
                dispatch(alert("Please try to load Workers questions later"));
            }
        } catch(err) {
            dispatch(updateSearchLoading(false))
            dispatch(refreshFailed())
        }
    }
}

export const analyzerSearchTerm = (search_value: string | number | null | boolean) => {
    return {
        type: ActionType.SET_SEARCH_TERM_VALUE,
        payload: search_value
    }
}
export const updateNewWorkerErrorMessage = () => {
    return {
      type: ActionType.SET_ERROR_WORKERS_JOBS,
      payload: ''
    };
}

export const updateSearchLoading = (isLoading: boolean) => {
    return {
        type: ActionType.SET_ROCKET_ANALYZER_SEARCH_IN_PROGRESS,
        payload: isLoading
    }
}

export const addWorker = (data:newWorkerObjTypes, setIsSubmitting: (e: boolean) => void) => {
    return async (dispatch: Dispatch ) => {
        try {
            setIsSubmitting(true);
            const response = await QueueServices.addWorker(data)
            console.log(response.body);
            
            if (response.status === 200) {
                setIsSubmitting(false);
                dispatch(push('/rocket-analyzer'))
              } else {
                    dispatch({
                        type: ActionType.SET_ERROR_WORKERS_JOBS ,
                        payload: response.body.message
                    })
                setIsSubmitting(false);
                dispatch(alert());
              }
        } catch(err) {
            setIsSubmitting(false);
            dispatch(alert());
        }
    }
}

export const addTurboWorker = (setIsTurboLoading: (e: boolean) => void) => {
    return async (dispatch: Dispatch) => {
        try {
            setIsTurboLoading(true);
            const response = await QueueServices.addTurboWorkers()
            if (response.status === 200) {
                dispatch(push('/rocket-analyzer'))
              } else {
                  dispatch(alert());
              }
              setIsTurboLoading(false);
        } catch(err) {
            setIsTurboLoading(false);
            dispatch(alert());
        }
    }
}
export const setTerminating = (value: boolean) => {
    return {
        type: ActionType.UPDATE_TERMINATING_STATUS,
        payload: value
    }
}

export const terminateOneOrManyWorkersAction = (workerId: string | string[]) => {
    return async (dispatch: Dispatch, getState: ()=>RootState) => {
      try {
        const data = { "job_id": workerId };
        const response = await QueueServices.terminateWorker(data);

        if (response.status === 200) {
            const workers = getState().analyzer.workers;
            const filterWorkers = workers.map((worker: Worker) =>
                workerId.includes(worker.job_id) ? { ...worker, status: "TERMINATED" } : worker
            );
          dispatch({
            type: ActionType.FETCH_WORKERS_DETAILS,
            payload: filterWorkers
          });
          dispatch(setTerminating(false));
        } else {
          dispatch(alert());
          dispatch(setTerminating(false));
        }
      } catch (err) {
        dispatch(setTerminating(false));
        dispatch(alert());
      }
    };
  };

export const fetchJobs = (page:number) => {
    return async (dispatch: Dispatch ) => {
        try {
            dispatch(updateSearchLoading(true));

            const response = await QueueJobsServices.getJobs(page, RECORD_LIMIT);
                if (response.status === 200) {
                    const total_records = response.body.total_records;
                    const duration = response.body?.duration;
                    dispatch({
                        type: ActionType.SHOW_TOTAL_RECORDS_OF_WORKER,
                        payload: total_records
                    });
                    dispatch({
                        type: ActionType.SHOW_SEARCH_DURATION,
                        payload: duration
                    });
                    dispatch(handleAnalyzerRefresh(false));
                    const { jobs } = response.body;
                    dispatch({
                        type: ActionType.FETCH_JOBS_DETAILS,
                        payload: jobs
                    });
                    dispatch(updateSearchLoading(false));
                }else{
                dispatch(updateSearchLoading(false))
                dispatch(refreshFailed());
                dispatch(alert("Please try to load jobs questions later"));
                }
        } catch(err) {
            dispatch(updateSearchLoading(false));
            dispatch(refreshFailed());
        }
    }
}

export const handleAnalyzerRefresh = (value: boolean) => {
    return {
        type: ActionType.ANALYZER_REFRESH_BUTTON,
        payload: value
    }
}

export const workerQueueDetails = (setIsRefreshing:any = null) => {
    return async (dispatch: Dispatch) => {
        try {
            // if(setIsRefreshing != null) {
            //     setIsRefreshing(true);
            // }
            const response = await getWorkerHeaderType()
            if (response.status === 200) {
                dispatch({
                    type: ActionType.WORKER_QUEUE_STATUS,
                    payload: response.body
                })
                if(setIsRefreshing !== null) {
                setIsRefreshing(false);
              }
            }else{
                dispatch(alert());
            }
            if(setIsRefreshing !== null) {
                setIsRefreshing(false);
              }
        }catch (err){
            if(setIsRefreshing !== null) {
                setIsRefreshing(false);
              }
              dispatch(alert());
        }
    }
}


export const updateCurrentTab = (filter: string) => {
    return {
        type: ActionType.CURRENT_TAB ,
        payload: filter
    }
}
export const setLoadingTableData =  async(isLoading: boolean,dispatch:Dispatch) => {
        dispatch({
        type: ActionType.SET_LOADING_ELASTIC_SEARCH,
        payload: isLoading
    })
}
export const searchWorkers = (search: string) => {
    return {
        type: ActionType.SEARCH_WORKERS_JOBS ,
        payload: search
    }
}

export const dropDownOperator = (value:string) => {
    return {
        type: ActionType.SEARCH_DROPDOWN_OPERATOR,
        payload: value
    }
}


export const setEqualsSearch = (value:any) => {
    return {
        type: ActionType.EQUALS_SEARCH_QUERY,
        payload: value
    }
}
export const setAnalyzerJobsEqualsSearch = (value:any) => {
    return {
        type: ActionType.EQUALS_SEARCH_QUERY_FOR_ANALYZER,
        payload: value
    }
}


export const setContainsSearch = (value:string[]) => {
    return {
        type: ActionType.CONTAINS_SEARCH_QUERY,
        payload: value
    }
}
export const setAnalyzerJobsContainsSearch = (value:Array<string>) => {
    return {
        type: ActionType.CONTAINS_SEARCH_QUERY_FOR_ANALYZER,
        payload: value
    }
}

export const setSearchSuggestion = (value:any) => {
    return {
        type: ActionType.ANALYZER_SEARCH_SELECTED_CHIPS,
        payload: value
    }
}

export const setJobsSearchSuggestionAction = (value:any)=>{
    return{
        type:ActionType.ANALYZER_JOBS_SEARCH_SELECTED_CHIPS,
        payload:value
    }
}

export const setAnalyzerContains = (value:Array<string>) => {
    return {
        type: ActionType.ANALYZER_CONTAINS_SEARCH,
        payload: value
    }
}

export const analyzerTableRefresh = (currentPage:number, current_tab:string)=>{
    return async(dispatch: Dispatch<any>,getState: ()=>RootState) => {
      try{
        const {searchTerm: searchQuery, searchSelectedChips, searchJobsSelectedChips, sortingOrder } = getState().analyzer;
        const {columnName, sortingOrder: sortingField} = sortingOrder;
        dispatch(rebuildIndexLoaderAction(true));
        await QueueServices.refreshAnalyzerTable();
        if(!searchSelectedChips.length && !searchJobsSelectedChips.length && !searchQuery && !(sortingField && columnName)){
            if(current_tab === "WORKERS"){
                const response = await QueueServices.getWorkers(currentPage, RECORD_LIMIT);
               if(response.status === 200){
               const { workers } = response.body;
               dispatch({
                   type: ActionType.FETCH_WORKERS_DETAILS ,
                   payload: workers
               });
             }
            }else{
              const response = await QueueJobsServices.getJobs(currentPage, RECORD_LIMIT);
                if(response.status === 200){
                    const { jobs } = response.body;
                    dispatch({
                        type: ActionType.FETCH_JOBS_DETAILS,
                        payload: jobs
                    });
                }else{
                    dispatch(alert((response?.body as any)?.error))
                }
            }
        } else if ((searchSelectedChips.length || searchJobsSelectedChips.length) && !searchQuery.length) {
            dispatch(searchWithSuggestion(undefined, undefined, undefined, false, currentPage));
        } else if (searchQuery.length > 0 || (sortingField !== "" && columnName !== "")) {
            dispatch(analyzerElasticSearch(searchQuery, undefined, false, currentPage));
        }
        dispatch(rebuildIndexLoaderAction(false));
      }catch(err){
        dispatch(rebuildIndexLoaderAction(false));
        console.log(err);
      }
    }
  }

export const rebuildIndexLoaderAction = (value: boolean) => {
    return {
        type: ActionType.ANALYZER_REBUILD_INDEX_LOADER,
        payload: value
    }
}
export const elasticSearchOnTable = (searchValue?: any, searchQuery?: any) => {
    let equalsQuery: any = [];
    let containsQuery: any = [];
    return async (dispatch: Dispatch, getState: any) => {
        dispatch(searchSpinner(true))
        const tableHeaders = getState().answer?.visual?.data?.headers;
        if (searchValue) {
            searchValue.map((item: any) => {
                let header = null;
                let operator = null;
                if (item.includes("=") || item.includes("!=")|| item.includes(":") || item.includes("!:")) {

                    for (let i = 0; i < tableHeaders.length; i++) {
                        if (item.includes(tableHeaders[i])) {
                            header = tableHeaders[i];
                            const operatorIndex = item.indexOf(header) + header.length;
                            const lastSearchWord = item.slice(operatorIndex + 1).replace(/^(!=|=|!:|:)+/, "");

                            if (item.charAt(operatorIndex) === '=') {
                                operator = '=';
                                equalsQuery.push([header, lastSearchWord, operator])
                            }  else if (item.charAt(operatorIndex) === '!') {
                                if (item.charAt(operatorIndex + 1) === '=') {
                                  operator = '!=';
                                  equalsQuery.push([header, lastSearchWord, operator])
                                } else {
                                  operator = '!:';
                                  containsQuery.push([header, lastSearchWord, operator])
                                }
                              } else {
                                operator = ':';
                                containsQuery.push([header, lastSearchWord, operator])
                              }
                              dispatch(setEqualsSearch(equalsQuery));
                              dispatch(setContainsSearch(containsQuery));

                              break;
                            }
                        }
                }
            })
        }

        const equalsSearchQuery = getState().analyzer.equalsSearchInput;
        const containsSearchQuery = getState().analyzer.searchContainsInput;

        if (!searchValue) {
            equalsQuery = equalsSearchQuery;
            containsQuery = containsSearchQuery;
        }

        try {
            dispatch({
                type: ActionType.NEW_TABLE_SEARCH_TERM,
                payload: searchQuery ? searchQuery : ""
            });
            const { visual, answer_copy } = getState().answer;
            if (searchQuery != "") {
                dispatch({
                    type: ActionType.UPDATE_NEWTABLE_HAS_MORE,
                    payload: false,
                });
            } else {
                dispatch({
                    type: ActionType.HYDRATE_ANSWER,
                    payload: answer_copy,
                });
                dispatch({
                    type: ActionType.UPDATE_NEWTABLE_HAS_MORE,
                    payload: true,
                });
                dispatch(searchSpinner(false))

                return;
            }
            setLoadingTableData(true, dispatch);
            const dropdownOperator = getState().analyzer.searchDropdownOperator;
            const response = await getTableSearchAnswer(
                searchQuery,
                visual.reference_utterance_id,
                equalsQuery,
                containsQuery,
                dropdownOperator,
            );


            if (response.status === 200) {
                const addData = response.body;
                const payload = {
                    data: addData.result.data,
                    rows: addData.result.rows,
                    headers: visual.data.headers,
                    weights: visual.data.weights,
                };
                const isSearchBoxCleared = getState().answer.isSearchBoxCleared
                if(!isSearchBoxCleared){
                    dispatch({
                        type: ActionType.UPDATE_NEWTABLE_VALUE,
                        payload: payload,
                    });
                    const dataLength = response?.body?.result?.data?.length
                    dispatch(tableSliderData([0,dataLength]))
                }
            } else {
                dispatch(alert("Please try to search table answer later "));
            }
            dispatch({
                type: ActionType.SEARCH_VALUE_CLEARED,
                payload: false,
            });
            setLoadingTableData(false, dispatch);
            dispatch(searchSpinner(false))
        } catch (error) {
            dispatch(alert("No matches found. Try again with different search term."));
            setLoadingTableData(false, dispatch);
        }
    };
};


export const searchSpinner = (value:boolean) => {
    return{
        type: ActionType.IS_TABLE_SEARCH,
        payload: value
    }
    }
    
export const tableSearchTermUpdate = (value:string) => {
    return {
            type: ActionType.NEW_TABLE_SEARCH_TERM,
            payload: value
    }

}


export const setRocketAnalyzerStatus = (value:string) => {
    return {
        type:ActionType.SET_WORKER_STATUS,
        payload: value
    }
}


export const fetchSqsWorkerConfig = () => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await QueueServices.sqsWorkerConfig();
            if (response.status === 200) {
                const config_data = response.body.config;
                config_data.business_hrs.endTime = getLocalTime(config_data.business_hrs.endTime) 
                config_data.business_hrs.startTime = getLocalTime(config_data.business_hrs.startTime)
                dispatch({
                    type: ActionType.FETCH_SQS_WORKER_CONFIG,
                    payload: config_data
                })
              } else {
                  dispatch(alert());
              }
        } catch(err) {
            dispatch(alert());
        }
    }
}


async function fetchSqsConfig(dispatch: any) {
    try {
        const response = await QueueServices.sqsWorkerConfig();
        if (response.status === 200) {
            const config_data = response.body.config;
            config_data.business_hrs.endTime = getLocalTime(config_data.business_hrs.endTime)
            config_data.business_hrs.startTime = getLocalTime(config_data.business_hrs.startTime)
            dispatch({
                type: ActionType.FETCH_SQS_WORKER_CONFIG,
                payload: config_data
            })
          } else {
              dispatch(alert());
          }
    } catch(err) {
        dispatch(alert());    
    }
}


export const updateSqsWorkerConfig = (data:any) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await QueueServices.updateSqsWorkerConfig(data);
            if (response.status === 200) {
                fetchSqsConfig(dispatch);
                dispatch(push('/rocket-analyzer'))
              } else {
                    dispatch(alert());
              }
        } catch(err) {
            dispatch(alert());        
        }
    }
}

export const terminateOneOrManyJobsAction = (messageIds: string | string[]) => {
    return async (dispatch: Dispatch, getState: ()=>RootState) => {
      try {  
        const payload:TerminateJobPayload = { "id": messageIds, "status": "TERMINATED" };
  
        const response = await QueueJobsServices.terminateJob(payload);
        if (response.status === 200) {
            const queueJobs = getState().analyzer.Queue_jobs;
  
            const filterJobs = queueJobs.map((queue: job) =>
            messageIds.includes(queue.message_id) ? { ...queue, status: "TERMINATED" } : queue
            );
          dispatch({
            type: ActionType.FETCH_JOBS_DETAILS,
            payload: filterJobs
          });
          dispatch(setTerminating(false));
        } else {
          dispatch(setTerminating(false));
          dispatch(alert());
        }
      } catch (err) {
        dispatch(setTerminating(false));
        dispatch(alert());
      }
    };
  };
  

export const workerCheckbox = (value:any) => {
    return {
        type: ActionType.SET_WORKER_CHECKBOX,
        payload: value
    }
}

export const analyzerElasticSearch=(val?:any, setCurrentPage?:any, isSearch?:boolean,currentPage?:any) => {
    return async (dispatch: Dispatch, getState:()=>RootState) => {
        try {
            dispatch(updateSearchLoading(true));
            const currentTab = getState().analyzer.current_tab.toLowerCase();
            const sort = getState().analyzer.sortingOrder;
            const param: any = {
                tab: currentTab,
                limit: 200,
                page: isSearch ? 1 : currentPage,
            };
            if (sort.columnName !== "") {
                param.sort_by = sort.columnName;
                param.sort_order = sort.sortingOrder;
            }
            // Update param based on the condition
            if (val?.trim()?.length > 0) {
                param.searchQuery = val;
            }

           const response:any = await analyzerSearch(param);
           if(response.status === 200){
            const {total_records: totalRecords, duration} = response.body

            dispatch({
                type: ActionType.SHOW_TOTAL_RECORDS_OF_WORKER,
                payload: totalRecords
            });
            dispatch({
                type: ActionType.SHOW_SEARCH_DURATION,
                payload: duration
            });
            if(isSearch){
                setCurrentPage(1);
            }
            dispatch(updateSearchLoading(false));
            if(currentTab === "workers"){
                dispatch({
                    type: ActionType.FETCH_WORKER_BY_ELASTIC_SEARCH,
                    payload: response?.body?.workers,
                });
            }else{
                dispatch({
                    type: ActionType.FETCH_JOBS_BY_ELASTIC_SEARCH,
                    payload: response?.body?.jobs,
                });
            }
           }else{
            dispatch(alert(response?.body?.error))
           }
        } catch(err) {
            console.log(err)
            dispatch(updateSearchLoading(false));
        }
    }
}

export const handleWorkerClear=(currentPage:number) => {
    return async (dispatch: Dispatch<any>, getState: ()=>RootState) => {
        try {
            const {searchTerm: searchQuery, searchSelectedChips, searchJobsSelectedChips, sortingOrder } = getState().analyzer;
            const {columnName, sortingOrder: sortingField} = sortingOrder;

            dispatch(updateSearchLoading(true));
            if(searchSelectedChips.length <= 1 && searchJobsSelectedChips.length <= 1 && !searchQuery && !(sortingField && columnName)){
                const response = await QueueServices.getWorkers(currentPage, RECORD_LIMIT);
                if(response.status === 200){
                 const {total_records: totalRecords, duration} = response.body;
                 dispatch({
                     type: ActionType.SHOW_TOTAL_RECORDS_OF_WORKER,
                     payload: totalRecords
                 })
                 dispatch({
                    type: ActionType.SHOW_SEARCH_DURATION,
                    payload: duration
                });
                 dispatch(updateSearchLoading(false));
                 const { workers } = response.body;
                 dispatch({
                     type: ActionType.FETCH_WORKERS_DETAILS ,
                     payload: workers
                 });
             }
            } else if ((searchSelectedChips.length > 1 || searchJobsSelectedChips.length > 1) && !searchQuery.length) {
                dispatch(searchWithSuggestion(undefined, undefined, undefined, false, currentPage));
            } else if (searchQuery.length > 0 || (sortingField !== "" && columnName !== "")) {
                dispatch(analyzerElasticSearch(searchQuery, undefined, false, currentPage));
            }
    }catch(err){
        console.log(err);
        dispatch(updateSearchLoading(false));
    }
}
}

export const handleJobsClear=(currentPage:number) => {
    return async (dispatch: Dispatch<any>, getState: ()=>RootState) => {
        try {
            dispatch(updateSearchLoading(true));
            const {searchTerm: searchQuery, searchSelectedChips, searchJobsSelectedChips, sortingOrder } = getState().analyzer;
            const {columnName, sortingOrder: sortingField} = sortingOrder;
            dispatch(updateSearchLoading(true));
            if(searchSelectedChips.length <= 1 && searchJobsSelectedChips.length <= 1 && !searchQuery && !(sortingField && columnName)){
                const response = await QueueJobsServices.getJobs(currentPage, RECORD_LIMIT);
                if(response.status === 200){
                 dispatch(updateSearchLoading(false));
                 const { jobs, total_records, duration } = response.body;
                 dispatch({
                     type: ActionType.FETCH_JOBS_DETAILS ,
                     payload: jobs
                 });
                 dispatch({
                     type: ActionType.SHOW_TOTAL_RECORDS_OF_WORKER,
                     payload: total_records
                 });
                 dispatch({
                    type: ActionType.SHOW_SEARCH_DURATION,
                    payload: duration
                });
             }
            } else if ((searchSelectedChips.length > 1 || searchJobsSelectedChips.length > 1) && !searchQuery.length) {
                dispatch(searchWithSuggestion(undefined, undefined, undefined, false, currentPage));
            } else if (searchQuery.length > 0 || (sortingField !== "" && columnName !== "")) {
                dispatch(analyzerElasticSearch(searchQuery, undefined, false, currentPage));
            }
        } catch (err) {
            console.log(err);
            dispatch(updateSearchLoading(false));
        }
    }
}

export const analyzerElasticSuggestions = (searchObject: suggestionSearchObj) => {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(updateSearchLoading(true));
            const response: any = await analyzerSuggestions(searchObject);
            if (response.status === 200) {
                dispatch({
                    type: ActionType.UPDATE_HEADER_SUGGESTIONS,
                    payload: response?.body,
                });
            }else{
                dispatch(alert(response?.body?.error))
            }
            dispatch(updateSearchLoading(false));
        } catch (err) {
            console.log(err);
            dispatch(updateSearchLoading(false));
        }
    }

}


export const searchWithSuggestion = (value?:any, operator?:string,setCurrentPage?:any, isSearchSuggestion?:boolean, currentPage?:number) => {
    return async (dispatch: Dispatch, getState: ()=>RootState) => {
        try{
            const sort = getState().analyzer.sortingOrder;
            dispatch(updateSearchLoading(true));
            let operators = '';

            if(operator){
                operators=operator
            }else{
                operators = getState().analyzer.rocketSearchOperator;
            }
            const searchSpecialChars = /(?:=|!=|:|!:)/
            let contains:any=[];
            let equals:any=[];
            const currentTab = getState().analyzer.current_tab.toLowerCase();

            if(value){
                value.map((item: any) => {
                    const [key, value] = item.split(searchSpecialChars);
                    const formatKey = headerMap[key]
                    const matches = item.match(searchSpecialChars);
                    if (matches && (matches[0] === "=" || matches[0] === "!=")) {
                        return equals.push([formatKey,value, matches[0]]);
                    } else if (matches && (matches[0] === ":" || matches[0] === "!:"))
                        return contains.push([formatKey, value, matches[0]]);
                });
            }else{
                if(currentTab==='workers'){
                equals = getState().analyzer.workersSearchValue;
                contains = getState().analyzer.workersContainsValue;
                }else{
                    equals = getState().analyzer.analyzerEqualsSearchInput;
                    contains = getState().analyzer.analyzerSearchContainsInput;
                }
            }

            if(currentTab==='workers'){
                dispatch(setWorkersSearchAction(equals))
                dispatch(setWorkersContainsAction(contains))
            }else{
                dispatch(setAnalyzerJobsContainsSearch(contains))
                dispatch(setAnalyzerJobsEqualsSearch(equals))
            }
            //these two above dispatch are not used for now because it will just store value and in future we get this value to export pdf according to search
            const payload: any = {
                contains: contains,
                equals: equals,
                tab: currentTab,
                is_search_header: true,
                l_operator: operators,
                limit: 200,
                page: isSearchSuggestion ? 1 : currentPage,
              };

              if (sort.sortingOrder !== "" && sort.columnName !== "") {
                payload.sort_by = sort.columnName;
                payload.sort_order = sort.sortingOrder;
              }

                const result:any = await analyzerSearch(payload);
                if(result?.status === 200){
                    dispatch(updateSearchLoading(false));
                    const totalRecords = result.body.total_records;
                    const duration = result.body?.duration;
                    dispatch({
                        type: ActionType.SHOW_TOTAL_RECORDS_OF_WORKER,
                        payload: totalRecords
                    })
                    dispatch({
                        type: ActionType.SHOW_SEARCH_DURATION,
                        payload: duration
                    })
                    if(isSearchSuggestion &&setCurrentPage ){
                        setCurrentPage!(1)
                    }
                    if(currentTab === "workers"){
                        dispatch({
                            type: ActionType.FETCH_WORKER_BY_ELASTIC_SEARCH,
                            payload: result?.body?.workers,
                        });
                    }else{
                        dispatch({
                            type: ActionType.FETCH_JOBS_BY_ELASTIC_SEARCH,
                            payload: result?.body?.jobs,
                        });
                    }
                }else{
                    dispatch(alert(result?.body?.error))
                }
        }catch(err){
            console.log(err, "error here")
        }
    }
}

export const setWorkersSearchAction = (value:any) => {
    return{
        type: ActionType.SET_WORKERS_SEARCH_VALUE,
        payload:value
    }
}

export const setWorkersContainsAction=(value:any)=>{
    return{
        type: ActionType.SET_WORKERS_CONTAIN_VALUE,
        payload:value
    }
}


export const rocketSearchOperator = (operator:string) => {
    return {
        type: ActionType.SET_ROCKET_SEARCH_OPERATOR,
        payload: operator
    }
}

export const exportJobsTableCsvAction = (dataObject: analyzerWorkerAndJobsExportType, exportAnalyzerWorkerAndJobCsv: (analyzerCSVData: string[]) => void ) => {
    return async (dispatch: Dispatch) => {
        try {
            const payloadData = {
                ...dataObject,
                limit: RECORD_LIMIT
            };
            dispatch(updateRocketAnalyzerExportLoader(true));
            const response = await QueueJobsServices.fetchJobsCSV(payloadData);

            if (response.status === 200) {
                exportAnalyzerWorkerAndJobCsv(response.body.csv);
                dispatch(updateRocketAnalyzerExportLoader(false));
            } else if(response.status === 500 || response.status === 404 || response.status === 403){
                dispatch(alert("something went wrong to export csv"));
                dispatch(updateRocketAnalyzerExportLoader(false));
            }
        } catch (err) {
            dispatch(alert("something went wrong to export csv"));
            dispatch(updateRocketAnalyzerExportLoader(false));
        }
    }
}

export const updateRocketAnalyzerExportLoader = (updateValue: boolean) => {
    return {
        type: ActionType.UPDATE_ROCKET_ANALYZER_EXPORT_LOADER,
        payload: updateValue,
    }
}

export const updateRocketTableSorting = (columnName: string, sortingOrder: string) => {
    return {
        type: ActionType.UPDATE_ROCKET_ANALYZER_TABLE_SORTING,
        payload: {columnName, sortingOrder}
    }
}