import { get, get_no_auth , patch, Response, post, delete_req  } from "makeRequest";
import { GroupList, UserFromServer, UserList, UserSettings, workflowTypes } from "types/user";
// import { SiteSettings } from "types/siteSettings";

export function getUser(): Response<UserFromServer> {
  return get("/user");
}

export function getSiteSettings(): any {
  return get_no_auth("/get-site-settings");
}

export function get_app_settings(): any {
   return get("/get-app-settings");
}

export function updateSettings(newSettings: Partial<UserSettings>): Response {
  return patch("/user/settings", newSettings);
}

export function getServersStatus(): any {
  return get("/get-system-status");
}

export function startServers(): any {
  return get("/start-system");
}


// Users and groups routes
// TODO Need to mnove bewlow routes in new file
export function fetchGroupLists():Response<{"groups":GroupList}>{
  return get("/get-all-user-groups");
}

export function fetchUsersLists() : Response<{users:UserList}>{
  return get("/get-all-users");
}

export function removeUserFromGroup(group_name:string, user_ids: any): Response<{failures:{},status:Boolean}> {
  const data = {
    group_name: group_name,
    user_ids: user_ids
  };
  return post("/remove-user-from-group", data);
}

export function generateNewGroup(group_name:string, description:string): Response<{message:string,status:string}> {
  const data = {
    group_name: group_name,
    description: description
  }
  return post("/create-user-group",data);
}
export function deleteCognitoGroup( data: { group_name: string } ): Response<{message: string, status: string}> {
  return delete_req("/delete-user-group",data);
}

export function addUserInGroup(group_name:string, user_ids: any): Response<{failures:{},status:boolean}> {
  const data = {
    group_name: group_name,
    user_ids: user_ids
  }
  return post("/add-user-in-group",data);
}

export function getListOfUserInGroup(group_name: string):Response<{users:UserList}> {
  return post("/list-group-users",{group_name});
}

export function getTemplatesList(workflow_type:string):Response<{status:boolean,templates:workflowTypes,error:string}>{
  return get("/templates?workflowType="+workflow_type);
}

export function saveDefaultTemplate(template_id:string,workflow_type:string):Response<{status:boolean,msg?:string}>{
  return get(`/mark-default-template?template_id=${template_id}&workflowType=${workflow_type}`);
}
export function getLogoLink(file:any):Response<{status:boolean,image_link?:string}>{
  return post(`/upload-image`,file);
}