export const quickLinkTabColors = {
    dark:{
        QuickLinksTab: "#FFF",
        ActiveQuickColor: "#323232",
        ActiveBackgroundColor: "#FFF", 
        QuickBorderColor: "#FFF",
        TabsBackgroundColor: "#799BF1",
        TabActiveBackground: "#fff",
        QuickLinksTabColor: "#323232",
        QuickLinkTextColorTabMain: "#2058E8",
        QuickLinkTextColorTab: "#2058E8",
        TabQuestionHoverColor: "#D0D8EB",
        QuickLinkTextInnerText: "#323232",
        QuickLinkBorderBottom: "#C8C8C8",
        TherapyAreaBg: "#2058e9",
        NewStoryBgButton: "#2058e9",
        TherapyAreaBgColor: "#2058E8",
        StoryHoverFieldColor: "#323346",
        ExpandBorderColor: "#454557",
        ToggleButtonBorderColor:"#404040",
        QuicklinksUserContextBgColor:"#323346",
        QuickLinkTextColor: '#ffffff',
    },
    light:{
        QuickLinksTab: "#FFF",
        ActiveQuickColor: "#323232",
        ActiveBackgroundColor: "#FFF",   
        QuickBorderColor: "#FFF",
        TabsBackgroundColor: "#799BF1",
        TabActiveBackground: "#fff",
        QuickLinksTabColor: "#323232",
        QuickLinkTextColorTabMain: "#2058E8",
        QuickLinkTextColorTab: "#2058E8",
        TabQuestionHoverColor: "#D0D8EB",
        QuickLinkTextInnerText: "#323232",
        QuickLinkBorderBottom: "#C8C8C8",
        TherapyAreaBg: "#2058e9",
        NewStoryBgButton: "#2058e9",
        TherapyAreaBgColor: "#2058E8",
        StoryHoverFieldColor: "#fff",
        ToggleButtonBorderColor:"#eeeff2",
        QuicklinksUserContextBgColor:"#FFFFFF",
        QuickLinkTextColor: '#828282',
    },
}