import { Colors } from "style/color";

export const editQuickLinkDashboardColors = {
    dark:{
        removeIcon:  Colors.WHITE,
        AddQsPlusIconColor:  Colors.WHITE,
        CrossIconEditQs:'#323346',
        EditContextLabelColor:  Colors.WHITE,
        AddNewBtnColor: Colors.WHITE,
        UserInfoHeadingColor: Colors.AMOUR_WHITE,
        UserInputBgColor:'#1b1c31',
        UserInfoBorderColor: Colors.WHITE,
        NewCardBgColor:'#323346',
        AddTextColor: Colors.WHITE,
        QuickLinksCardBgColor:'#323346',
        QuickLinksCardInputColor:'#dadada',
        QuickLinksCardCrossColor: Colors.WHITE,
        AddNewQuestioncolor:  Colors.WHITE,
    },
    light:{
        removeIcon: '#525264',
        AddQsPlusIconColor:  Colors.WHITE,
        CrossIconEditQs: Colors.WHITE,
        EditContextLabelColor: Colors.BLACK,
        AddNewBtnColor:'#525264',
        UserInfoHeadingColor: Colors.DARK_CHARCOAL,
        UserInputBgColor:'#E2E2E2',
        UserInfoBorderColor: Colors.TRANSPARENT,
        NewCardBgColor: Colors.WHITE,
        AddTextColor:'#525264',
        QuickLinksCardBgColor:'#EFF0F2',
        QuickLinksCardInputColor: Colors.WHITE,
        QuickLinksCardCrossColor:'#57647B',
        AddNewQuestioncolor: Colors.BLACK,
    },
}