import {
  get,
  post,
  Response
} from "makeRequest";
import { chatAgents } from "types/chat";

type GetUpConfigParams = {
  nth_version?: number;
};

type AutoShutCronTime = {
  time: number;
  email: string;
};

export function getUpConfig({ nth_version }: GetUpConfigParams): any {
  const params = new URLSearchParams();

  if (nth_version != undefined && nth_version > -1) {
    params.append("nth_version", String(nth_version));
  }

  return get(`/up_config?${params.toString()}`);
}

export function postUpConfig(value: any, version_id:string): any {
  return post(`/up_config?latest_config=${version_id}`, value);
}

export function getMinVcpu(): any {
  return get("/get-compute-minimum-vcpus");
}

export function getWorkerHeaderType(): any {
  return get("/worker-queue-status");
}

export function getAutoShutCronTime(): any {
  return get("/get-autoshut-activity-check-time");
}

export function setAutoShutCronTime(value: AutoShutCronTime): any {
  return post("/set-autoshut-activity-check-time", value);
}

export function postMinVcpu(value: any): any {
  return post("/set-compute-minimum-vcpus", value);
}

export function getAnalyzerDbConfig(): any {
  return get("/db-config");
}

export function postAnalyzerDbConfig(value: any): any {
  return post("/db-config", value);
}

export function getAutoShutCronDetails(): any {
  return get("/get-autostop-cron-details");
}

export function updateAutoStopCronTime(value: AutoShutCronTime): any {
  return post("/update-autostop-cron-time", value);
}

export function updateAutoStopCronStatus(value: AutoShutCronTime): any {
  return post("/update-autostop-cron-status", {
    status: value,
  });
}

export function fetchAgentConfig(agent_name: string): Response<chatAgents> {
  return get(`/agent-config?agent_name=${agent_name}`);
}