import { ActionType } from '../action-types';
import * as userServices from "../../services/user"
import { Dispatch } from "redux";
import { alert } from './customRouter';

export const startServers = (): any => {
    return async (dispatch: Dispatch<any>) => {
        try {
            const response = await userServices.startServers();
            dispatch({
                type: ActionType.START_SERVERS,
                payload: response.body
            })
        } catch (err) {
            console.log(err)
            dispatch(alert("Please try again later (server down!)"));
        }
    }
}
