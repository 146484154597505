import { ActionType } from "state/action-types"

export const updateAllHighlightedText = (value:any) => {
    return {
        type: ActionType.UPDATE_PDF_ALLHIGHLIGHTS,
        payload: value
    }
}

export const updateHighlightText = (value: any) => {
    return {
        type: ActionType.UPDATE_HIGHLIGHT_TEXT,
        payload: value
    }
}

export const updatePdfUrl = (value: string) => {
    return {
        type: ActionType.UPDATE_PDF_URL ,
        payload: value
    }
}


export const updatePdfViewerStatus = ({loading, failure, success}: any) => {
    return {
        type: ActionType.UPDATE_PDF_VIEWER,
        payload: {
            loading, 
            failure , 
            success
        }
    }
}

export const updateHighlightPageNum = (page_numbers: any) => {
    return {
        type: ActionType.UPDATE_PDF_HIGHLIGHT_PAGE_NUMBER,
        payload: page_numbers
    }
}

export const updateAllLinkParts = (allLinksParts: any) => {
    return {
        type: ActionType.UPDATE_ALL_LINKS_PARTS,
        payload: allLinksParts
    }
}