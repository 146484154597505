import { ActionType } from '../action-types';
import { Action } from '../actions';
import * as siteServices from "../../services/settings"
import { Dispatch } from "redux";
import { SearchUIProps } from 'state'

export const updateSearchUISettings = (payload: SearchUIProps): any => {
    return async (dispatch: Dispatch<Action>, getState: any) => {
        try {
            const id = getState().siteSettings.id;
            const data = { search_ui: payload.search_ui, action: "search_ui" }
            const response = await siteServices.updateSiteSettings(id, data)
        
            if( response.status == 200 && response.body.success ){
                dispatch({
                    type: ActionType.UPDATE_SEARCH_UI_SUCCESS,
                    payload: response.body
                    
                })
            }else {
                dispatch({
                    type: ActionType.UPDATE_SEARCH_UI_FAILED,
                    //@ts-ignore
                    payload: response.body
                })
            }
            return response.body

        } catch (err) {
            console.log("error=>", err);
        }
    }
}