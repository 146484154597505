export const leftNavigationColors = {
    dark:{
        QuestionNavigationArrow: '#FFFFFF',
        LeftPanelLogoBgColor: '#1B1C31',
        LeftPanelBackgroundColor: '#1B1C31',
        systemConfigLeftBgColor: '#1B1C31',
        LeftPanelBorderColor: '#454557',  
        LeftPanelActiveBackgroundColor: '#323346',
        QuestionHoverColor:"#2058E8",
        LeftPanelTextColor: '#FFFFFF',
        LeftNavArrowIconBgColor: '#323346',
        LeftNavRelatedToBgColor: '#323346',
        RelatedQuestionKeyworkColor:'#51CDFF',
        HistoryIconClosedColor:'#B4B4B5',
        ClosedNavActiveBackgroundColor:'#323346',
        HistorySpinner: '#FFFFFF',
    },

    light:{
        QuestionNavigationArrow: '#7D7D7D',
        LeftPanelLogoBgColor: '#C4C4C4',
        LeftPanelBackgroundColor: '#FFF',
        systemConfigLeftBgColor: '#F9F9F9',
        LeftPanelBorderColor: '#EDEAEA', 
        LeftPanelActiveBackgroundColor: '#F6F6F6',
        QuestionHoverColor:"#2058E8",
        LeftPanelTextColor: '#828282',
        LeftNavArrowIconBgColor: '#FFF',
        LeftNavRelatedToBgColor: '#F6F6F6',
        RelatedQuestionKeyworkColor:'#E0A825',
        HistoryIconClosedColor:'#B4B4B5',
        ClosedNavActiveBackgroundColor:'#DEE1E6',
        HistorySpinner: '#828282',
    }
}
