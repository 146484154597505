import { ActionType } from '../action-types'
import { QuickLinks } from "types/quickLinks";

export const initialState: QuickLinks = {
    user: {},
    currentUserContext: [],
    userContext: [],
    categories: [],
    loading: false,
    isSetLoader: false,
    isUserContextLoading: false,
    showQuickLinksContext: true,
    compareAnswerView: false,
    compareDataList: [],
    jobLogsByUser: [],
    jobLogsByCLI: [],
    isJobsLoading: false,
    isBtnLoader:false,
    is_quick_link_focus_loading:false,
    is_quick_link_source_loading:false,
}

const reducer = (state: QuickLinks = initialState, action: any): QuickLinks => {
    switch (action.type) {
        case ActionType.SET_COMPARE_ANSWER_VIEW:
            return { ...state, compareAnswerView: action.payload }
        case ActionType.STORE_COMPARE_ANSWER_DATA:
            return {... state, compareDataList: action.payload}
        case ActionType.SHOW_JOB_LOGS_COMPUTED_BY_USER:
            return { ...state, jobLogsByUser: action.payload }
        case ActionType.SHOW_JOB_LOGS_COMPUTED_BY_CLI:
            return { ...state, jobLogsByCLI: action.payload }
        case ActionType.UPDATE_TO_SHOW_JOBS_LOGS:
            return { ...state, isJobsLoading: action.payload }
            case ActionType.IS_SET_BUTTON_LOADER:
                return { ...state, isBtnLoader: action.payload }
        case ActionType.SHOW_QUICK_LINK_CONTEXT:
            return {
                ...state,
                showQuickLinksContext: true
            }

        case ActionType.HIDE_QUICK_LINK_CONTEXT:
            return {
                ...state,
                showQuickLinksContext: false
            }
        case ActionType.QUICK_LINKS_LOADER_ON_PAGE_LOAD:
            return {
                ...state,
                isSetLoader: action.payload
            }

        case 'remove_user_context':
            return {
                ...state,
                userContext: state.userContext.filter((tag: any, index: number) => index !== action.payload)
            }
        case "add_user_context":
            return {
                ...state,
                userContext: [...state.userContext, action.payload]
            }
        case ActionType.UPDATE_QUICK_LINK_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case ActionType.UPDATE_CURRENT_USER_CONTEXT:
            return {
                ...state,
                currentUserContext: action.payload
            }
        case ActionType.UPDATE_USER_CONTEXT:
            return {
                ...state,
                userContext: action.payload,
                user: action.payload
            }

        case ActionType.UPDATE_QUICK_LINKS_CATEGORIES:
            return { ...state, categories: action.payload };
        case ActionType.UPDATE_USER_CONTEXT_LOADING:
            return { ...state, isUserContextLoading: action.payload }
        case ActionType.UPDATE_QUICK_LINK_FOCUS_LOADING:
            return { ...state, is_quick_link_focus_loading: action.payload }
        case ActionType.UPDATE_QUICK_LINK_SOURCE_LOADING:
            return { ...state, is_quick_link_source_loading: action.payload }
        default:
            return state;
    }
}

export default reducer;