import { ActionType } from '../action-types'
import { Dispatch  } from "redux";
import { getFeedback, deserializeFeedback , feedbackAddressed } from "services/feedback";
import { alert , toggleComponentLoading } from './customRouter';
import { setLoadingData, splatRecordLoading } from './SplatActions';
import { updateSearchLoading } from './AnalyzerActions';
import { RootState } from 'state/reducers';

export const fetchFeedbacks = (page_no: number, is_update:boolean, setIsBottom:any) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch(toggleComponentLoading(ActionType.UPDATE_FEEDBACK_LOADING, true));
    const feedback = getState().feedback;
    try {
      if (page_no == 1) { setLoadingData(true, dispatch); }
      splatRecordLoading(true, dispatch);
      dispatch(updateSearchLoading(true))
      const response = await getFeedback({
        sortKey: feedback.sort.key,
        sortOrder: feedback.sort.order,
        lastCreated: null,
        page_no: page_no,
      });

      let lastCreated = feedback.lastCreated;

      if (response.status === 200) {
        const { feedback: newFeedback } = response.body;

        if (newFeedback.feedback.length) {
          lastCreated = newFeedback.feedback[newFeedback.feedback.length - 1].created;
        }

        if (!is_update && page_no == 1) {
          dispatch({
            type: ActionType.FETCH_FEEDBACK_COMPLETED,
            payload: {
              lastCreated: lastCreated,
              data: deserializeFeedback(newFeedback.feedback),
              csv: newFeedback.csv
            }
          });
        } else {
          dispatch({
            type: ActionType.UPDATE_FEEDBACK_COMPLETED,
            payload: {
              lastCreated: lastCreated,
              data: deserializeFeedback(newFeedback.feedback),
              csv: newFeedback.csv
            }
          });
        }
        if (response.body.feedback.feedback.length === 0 || response.body.feedback.feedback.length < 100) {
          dispatch({
            type: ActionType.UPDATE_FEEDBACK_HAS_MORE,
            payload: false
          });
          setLoadingData(false, dispatch);
          splatRecordLoading(false, dispatch);
          dispatch(updateSearchLoading(false))
          return;
        }
        setIsBottom(false)
      } else {
        splatRecordLoading(false, dispatch);
        dispatch(updateSearchLoading(false))
        setLoadingData(false, dispatch);
        dispatch(alert("Please try to load feedback later"));
        setIsBottom(false)
      }
      setLoadingData(false, dispatch);
      splatRecordLoading(false, dispatch);
      dispatch(updateSearchLoading(false))
    } catch (error) {
      setLoadingData(false, dispatch);
      splatRecordLoading(false, dispatch);
      dispatch(updateSearchLoading(false))
      dispatch(alert("Please try to load feedback later .!"));
    }

    dispatch(toggleComponentLoading(ActionType.UPDATE_FEEDBACK_LOADING , false ));
  }
}

export const updateFeedbackStatusAction = ({feedbackId, feedbackStatus , setStatusProcessing}: any) => {
    return async (dispatch: Dispatch , getState: () => RootState) => {

        try {
            setStatusProcessing(true);
            const feedbacks = getState().feedback.data;
            dispatch(toggleComponentLoading( ActionType.UPDATE_STATUS_PROCESSING , true ))

            const newfeedbacks = feedbacks.map((feedback: any) => {

                if(feedback.id ===feedbackId) {
                    feedback.status = feedbackStatus;
                }
                return feedback;

            })
            const response = await feedbackAddressed(feedbackId, feedbackStatus);
            if (response.status === 200) {
              const { prevStatus, status } = response.body;
              if (status !== prevStatus) {
                  dispatch({
                      type: ActionType.UPDATE_FEEDBACK_STATUS ,
                      payload: {
                          feedbacks: newfeedbacks
                      }
                  });
              }
            }else{
              dispatch(alert(response.body.error))
            }
            dispatch(toggleComponentLoading( ActionType.UPDATE_STATUS_PROCESSING , false ))
            setStatusProcessing(false);
          } catch {
              dispatch(toggleComponentLoading( ActionType.UPDATE_STATUS_PROCESSING , false ))
            setStatusProcessing(false);
          }
    }
}