// import { ActionType } from "state/action-types"

export const inputHasChanged = ( actionType: string , value: string  , valid: boolean , error: string) => {
    return {
        type: actionType,
        payload: {
            value,
            valid,
            error,
        }
    }
	// const returnInputState = (dispatch, value, valid, validLabel) => {
	// 	dispatch({ 
	// 		type 		: actionType,
	// 		payload 	: {
	// 			value,
	// 			valid,
	// 			validLabel,
	// 		}
	// 	});	

	}

    // export const deleteValidDomain = (id: string): DeleteValidDomainAction => {
    //     return {
    //         type: ActionType.DELETE_VALID_DOMAIN ,
    //         payload: id
    //     }
    // }