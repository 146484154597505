import React from "react";
import { css } from "@gact/css";
import * as Sentry from "@sentry/react";
import sadCat from "assets/sad-cat.png";

const className = css`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  min-height: 100%;
  padding-bottom: 70px;
  background: var(--midnight);

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .cat-image {
    height: 200px;
    width: 200px;
  }

  .title {
    color: #fff;
    font-family: 'Roboto', sans-serif;
    font-size: 60px;
    text-align: center;
  }

  .text {
    width: min(575px, 100vw - 60px);
    color: #ffffff;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
  }

  .text-group {
    margin-bottom: 20px;
  }

  .back-button {
    margin-top: 20px;
    border-radius: 25px;
    background: #00a651;
    height: 50px;
    width: 300px;
    border: none;
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
    color: #fff;
  }

  .back-button-with-details {
    margin-top: 40px;
  }

  .back-button:active {
    outline: none;
  }
  .overlay{
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100vh;
    background: #c3c3c3;
    width: 100%;
    opacity: 0.3;
    z-index: 9999;
  }
`;

type AppErrorBoundaryState = {
  hasError: boolean;
};

export default class AppErrorBoundary extends React.Component<
  { children: React.ReactNode},
  AppErrorBoundaryState
> {
  state = { hasError: false };

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, info: any) {
    const originalPushState = window.history.pushState;
    window.history.pushState = new Proxy(window.history.pushState, {
      apply: (target, thisArg, argArray:any) => {
        this.setState({ hasError: false });
        window.history.pushState = originalPushState;
        return target.apply(thisArg, argArray);
      },
    });
    Sentry.captureException(error, { extra: info });
    console.log(info.componentStack, error);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className={className}>
          <div className="container">
            <img className="cat-image" src={sadCat} alt="sad cat"></img>
            <h1 className="title">Uh Oh!</h1>
            <div className="text">
              <div className="text-group">
                <div>We seem to have encountered an issue</div>
                <div>Please click on the reload application button.</div>
              </div>
            </div>
            <button
              className="back-button back-button-with-details"
              onClick={() => {
                // navigate to the home page
                window.location.assign('/')
              }}
            >
              Reload the application
            </button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
