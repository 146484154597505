import {Dispatch} from "redux";
import {ActionType} from "state/action-types";
import {
    compileFileOutput,
    createTemplate,
    deleteTemplate,
    draftIntentTemplate,
    fetchFile,
    fetchFolders,
    fetchUpConfig,
    getConfigNodesApi,
    getConfigVersionsApi,
    getDraftTemplates,
    getNodeIntentsApi,
    renameTemplate,
    saveFiles,
    uploadDocxReport,
    uploadNewFolderFile,
} from "services/reportConfig";
import {alert} from "./customRouter";
import {compiledFileResponse, filesWithContent, folder, intent, template} from "types/reportConfig";
import {downloadReportDocx} from "makeRequest";
import { RootState } from "state/reducers";


const limit = 10;
export const getConfigVersions = () => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await getConfigVersionsApi();
            if (response?.status === 200) {
                dispatch({
                    type: ActionType.SET_CURRENT_REPORT_CONFIG_NODES,
                    payload: {
                        id: "",
                        node_intents:[]
                    },
                });
                dispatch({
                    type: ActionType.SHOW_REPORT_ERROR_MESSAGE,
                    payload: {
                        error_message: "",
                        markdown_error: ""
                    }
                })
                const config = response?.body?.up_config
                const version = config?.[0]
                if(version){
                    const res = await getConfigNodesApi(version.id);
                    if (res?.status === 200) {
                        dispatch({
                            type: ActionType.SET_REPORT_CONFIG_VERSIONS,
                            payload: config,
                        });
                        dispatch({
                            type: ActionType.SET_CURRENT_REPORT_CONFIG_VERSION,
                            payload: {
                                id: version.id,
                                config_nodes: res?.body?.nodes
                            },
                        });
                    } else if (res.status === 400 || res.status === 500 || res.status === 404) {
                        dispatch(alert(res.body?.error));
                    }
                }
            } else if (response.status === 400 || response.status === 500 || response.status === 404) {
                dispatch(alert(response.body?.error));
            }
        } catch (err) {
            dispatch(alert());
        }
    };
};

export const setJsonDiffWarningMessage = (message: string) => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: ActionType.SET_JSON_DIFF_WARNING_MESSAGE,
            payload: message,
        });
    };
};
export const resetConfigNodesAction = () => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: ActionType.SET_CURRENT_REPORT_CONFIG_NODES,
            payload: {
                id: "",
                node_intents:[]
            },
        });
    }
}
export const reportConfigReload = () => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: ActionType.SET_CURRENT_REPORT_CONFIG_VERSION,
            payload: {
                id: "",
                config_nodes: []
            },
        });
        dispatch({
            type: ActionType.SET_CURRENT_REPORT_CONFIG_NODES,
            payload: {
                id: "",
                node_intents:[]
            },
        });
    };
};
export const getConfigNodes = (config_id:string) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await getConfigNodesApi(config_id);
            if (response?.status === 200) {
                dispatch({
                    type: ActionType.SET_CURRENT_REPORT_CONFIG_VERSION,
                    payload: {
                        id: config_id,
                        config_nodes: response?.body?.nodes
                    },
                });
            } else if (response.status === 400 || response.status === 500 || response.status === 404) {
                dispatch(alert(response.body?.error));
            }
        } catch (err) {
            dispatch(alert());
        }
    };
};
export const getNodeIntents = (node_id:string,node:string) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await getNodeIntentsApi(node_id,node);
            if (response?.status === 200) {
                dispatch({
                    type: ActionType.SET_CURRENT_REPORT_CONFIG_NODES,
                    payload: {
                        id: node,
                        node_intents:response?.body?.node_intents
                    },
                });
                dispatch({
                    type: ActionType.SHOW_REPORT_ERROR_MESSAGE,
                    payload: {
                        error_message: "",
                        markdown_error: ""
                    }
                })
            } else if (response.status === 400 || response.status === 500 || response.status === 404) {
                dispatch(alert(response.body?.error));
            }
        } catch (err) {
            dispatch(alert());
        }
    };
};
export const getDraftIntentTemplates = (intent:intent) => {
    return async (dispatch: Dispatch,getState: () => RootState) => {
        try {
            if(intent?.name){
                const response = await getDraftTemplates(intent.name,intent?.templates&&intent?.templates[0]?.id||"");
                if (response?.status === 200) {
                    const current_config_nodes = getState().report.current_config_nodes;
                    dispatch({
                        type: ActionType.SET_CURRENT_REPORT_CONFIG_NODES,
                        payload: {
                            ...current_config_nodes,
                            node_intents:current_config_nodes.node_intents.map((intentItem:intent)=>{
                                if(intent.name===intentItem.name){
                                    return { ...intentItem, templates: response.body.intent_templates }
                                }
                                return intentItem;
                            })
                        },
                    });
                    return resetCurrentTemplate()
                } else if (response.status === 400 || response.status === 500 || response.status === 404) {
                    return dispatch(alert(response.body?.error));
                }
            }
            return dispatch(alert());
        } catch (err) {
            return dispatch(alert());
        }
    };
};
export const createNewTemplate = (newTemplateName: string,intent:any) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        try {
            const payload = {
                template_name: newTemplateName,
            };
            const response = await createTemplate(payload);
            if (response?.status === 201) {
                const current_config_nodes = getState().report.current_config_nodes
                dispatch({
                    type: ActionType.SET_CURRENT_REPORT_CONFIG_NODES,
                    payload: {
                        ...current_config_nodes,
                        node_intents: current_config_nodes.node_intents.map((node_intent:any)=>{
                            if(node_intent.name === intent.name && node_intent.path === intent.path){
                                node_intent.templates.unshift({
                                    id: response.body.id,
                                    name: response.body.name,
                                    version: response.body.version,
                                    templates:response.body.report_config,
                                });
                            }
                            return node_intent
                        })
                    },
                });
                return dispatch(alert(response.body?.message, {position: "bottom-left"}, "success"));
            } else if (response.status === 400 || response.status === 500 || response.status === 404) {
                return dispatch(alert(response.body?.error));
            }
        } catch (error) {
            dispatch(alert());
            return null;
        }
    };
};
export const renameTemplateItem = (template: template, newName: string) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        try {
            const payload = {
                template_id: template.id,
                template_name: newName,
                config_id: getState().report.current_version.id,
                intent_path: template.intent_path,
                is_draft_template: template.is_draft_template,
            };
            const response = await renameTemplate(payload);
            if (response?.status === 200) {
                const current_config_nodes = getState().report.current_config_nodes
                const updatednodeIntents = current_config_nodes.node_intents.map((intent: intent) => {
                    if(intent.name===template.intent){
                        return {
                            ...intent,
                            templates: intent.templates?.map((templateItem)=>{
                                if (templateItem.id === template.id) {
                                    return {...templateItem, name: newName , template_name: newName};
                                }
                                return templateItem;
                            })
                        }
                    }
                    return intent
                });
                const currentTemplate = getState().report.currentTemplate
                if(currentTemplate.id===template.id){
                    dispatch({
                        type: ActionType.SET_EDITOR_REPORT_TEMPLATE,
                        payload:{
                            ...createTemplate,
                            name: newName,
                            template_name: newName,
                        }
                    })
                }
                dispatch({
                    type: ActionType.SET_CURRENT_REPORT_CONFIG_NODES,
                    payload: {
                        ...current_config_nodes,
                        node_intents: updatednodeIntents
                    },
                });
                return dispatch(alert(response.body?.message, {position: "bottom-left"}, "success"));
            } else if (response.status === 400 || response.status === 500 || response.status === 404) {
                return dispatch(alert(response.body?.error));
            }
        } catch (error) {
            console.log(error)
            dispatch(alert());
            return null;
        }
    };
};
export const deleteTemplateItem = (template: template) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        try {
            const payload = {
                template_id: template.id,
                config_id: getState().report.current_version.id,
                intent_path: template.intent_path,
                is_draft_template: template.is_draft_template,
            };
            const response = await deleteTemplate(payload);
            if (response?.status === 200) {
                const current_config_nodes = getState().report.current_config_nodes
                const updatednodeIntents = current_config_nodes.node_intents.map((intent: intent) => {
                    if(intent.name===template.intent){
                        return {
                            ...intent,
                            templates: intent.templates?.filter((templateItem) => templateItem.id !== template.id)
                        }
                    }
                    return intent
                });

                dispatch({
                    type: ActionType.SET_CURRENT_REPORT_CONFIG_NODES,
                    payload: {
                        ...current_config_nodes,
                        node_intents: updatednodeIntents
                    },
                });
                return dispatch(alert(response.body?.message, {position: "bottom-left"}, "success"));
            } else if (response.status === 400 || response.status === 500 || response.status === 404) {
                return dispatch(alert(response.body?.error));
            }
        } catch (error) {
            dispatch(alert());
            return null;
        }
    };
};
export const setCurrentTemplate = (template: template) => {
    return async (dispatch: Dispatch) => {
        try {
            dispatch({
                type: ActionType.SET_EDITOR_REPORT_TEMPLATE,
                payload: template,
            });
            if (template.id && !template?.templates) {
                dispatch(editorEditorSidebarLoading(true));
                const response = await fetchFolders(template.id);
                if (response?.status === 200) {
                    const currentTemplate = {
                        ...template,
                        templates: response?.body?.report_config?.templates,
                    };
                    dispatch({
                        type: ActionType.SET_EDITOR_REPORT_TEMPLATE,
                        payload: currentTemplate,
                    });
                } else if (response.status === 400 || response.status === 500 || response.status === 404) {
                    dispatch(alert(response.body?.error));
                }
                dispatch(editorEditorSidebarLoading(false));
            }
        } catch (error) {
            console.log(error)
            dispatch(alert());
            dispatch(editorEditorSidebarLoading(false));
        }
    };
};
export const setCurrentFolder = (FolderName: string) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        const templateFolder = getState().report?.currentTemplate?.templates?.find(
            (templateFolderItem: folder) => templateFolderItem.name === FolderName
        );
        dispatch({
            type: ActionType.SET_CURRENT_EDITOR_FOLDER,
            payload: templateFolder,
        });
    };
};
export const getFileContent = (file: filesWithContent) => {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(editorEditorSidebarLoading(true));
            if(file&&file.id){
                const response = await fetchFile(file.id);
                if (response?.status === 200) {
                    const file_content = response.body.file_content;
                    dispatch({
                        type: ActionType.SET_SELECTED_FILE_DATA,
                        payload: {
                            id:file.id,
                            file_id:file.id,
                            name:file.name,
                            file_name:file.name,
                            type: file.type,
                            content_type: file.type,
                            file_content,
                        },
                    });
                } else if (response.status === 400 || response.status === 500 || response.status === 404) {
                    dispatch(alert(response.body?.error));
                }
                return dispatch(editorEditorSidebarLoading(false));
            }
        } catch (err) {
            dispatch(alert());
            return dispatch(editorEditorSidebarLoading(false));
        }
    };
};
export const updateSelectedFile = (file: filesWithContent) => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: ActionType.SET_SELECTED_FILE_DATA,
            payload: file,
        });
    };
};
export const hydrateSelectedFile = () => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        const currentFolder = getState().report.currentFolder;
        const inputData = getState().report.inputData;
        const updatedFolder = {
            ...currentFolder,
            templates: currentFolder?.templates.map((fileItem: filesWithContent) => {
                if ((fileItem.id === inputData.id)||(inputData.type==="docx"&&fileItem.type==="docx")) {
                    return inputData;
                }
                return fileItem;
            }),
        };
        dispatch({
            type: ActionType.SET_CURRENT_EDITOR_FOLDER,
            payload: updatedFolder,
        });
        const currentTemplate = getState().report.currentTemplate
        return dispatch({
            type: ActionType.SET_EDITOR_REPORT_TEMPLATE,
            payload: {
                ...currentTemplate,
                templates: currentTemplate?.templates?.map((folder:folder)=>{
                    if(updatedFolder.name===folder.name){
                        return updatedFolder
                    }
                    return folder
                })
            },
        });
    };
};
export const updateInputFile = (updatedContent: string) => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: ActionType.UPDATE_INPUT_FILE_DATA,
            payload: updatedContent,
        });
    };
};
export const updateOutput = (output: compiledFileResponse) => {
    return {
        type: ActionType.SET_OUTPUT_DATA,
        payload: output,
    };
};
export const compileFile = () => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        try {
            dispatch(editorUpdateLoading(true));
            dispatch(updateOutput({presigned_url: "", data: ""}));
            const currentTemplate = getState().report.currentTemplate;
            const selectedTemplateFolder = getState().report.currentFolder;
            const selectedFolderName = selectedTemplateFolder.name;
            const selectedFile = getState().report.inputData;
            // sending all files of the current selected folder as payload to compile single file
            let payloadFiles = selectedTemplateFolder.templates;
            if (selectedFile.id !== "") {
                payloadFiles = selectedTemplateFolder.templates.map((file: filesWithContent) => {
                    if (file.id === selectedFile.id) {
                        return selectedFile;
                    }
                    return file;
                });
            }
            const payload = {
                compilation_type: selectedFolderName,
                folder_name: selectedFolderName,
                template_id: currentTemplate.id,
                templates: payloadFiles,
            };
            dispatch({
                type: ActionType.SHOW_REPORT_ERROR_MESSAGE,
                payload: {
                    error_message: "",
                    markdown_error: ""
                }
            })
            const response = await compileFileOutput(payload);
            if (response.status === 200) {
                dispatch(updateOutput(response.body));
            } else if (response.status === 400 || response.status === 500 || response.status === 404) {
                dispatch({
                    type: ActionType.SHOW_REPORT_ERROR_MESSAGE,
                    payload: response.body
                })
                dispatch(alert(response.body?.error));
            }
            dispatch(editorUpdateLoading(false));
        } catch (err) {
            dispatch(updateOutput({presigned_url: "", data: ""}));
            dispatch(editorUpdateLoading(false));
            dispatch(alert());
        }
    };
};
export const saveFile = () => {
    return async (dispatch: Dispatch<any>, getState: any) => {
        try {
            dispatch({
                type: ActionType.UPDATE_SAVE_PROCESSING,
                payload: true,
            });
            const currentTemplate = getState().report.currentTemplate;
            const payload = {
                config_id: getState().report.current_version.id,
                intent:currentTemplate.intent,
                intent_path:currentTemplate.intent_path,
                template_id:currentTemplate.id,
                template_name:currentTemplate.name,
                template_version:currentTemplate.version,
                files: currentTemplate.templates
            };
            const response = await saveFiles(payload);
            if (response.status === 200) {
                dispatch(alert(response.body?.message, {position: "bottom-left"}, "success"));
            } else if (response.status === 400 || response.status === 500 || response.status === 404) {
                dispatch(alert(response.body?.error));
            }
            return dispatch({
                type: ActionType.UPDATE_SAVE_PROCESSING,
                payload: false,
            });
        } catch (err) {
            dispatch(alert());
            return dispatch({
                type: ActionType.UPDATE_SAVE_PROCESSING,
                payload: false,
            });
        }
    };
};
export const draftTemplate = () => {
    return async (dispatch: Dispatch<any>, getState: any) => {
        try {
            const currentTemplate = getState().report.currentTemplate;
            const payload = {
                config_id: getState().report.current_version.id,
                intent:currentTemplate.intent,
                intent_path:currentTemplate.intent_path,
                template_id:currentTemplate.id,
                template_name:currentTemplate.name,
                template_version:currentTemplate.version,
                files: currentTemplate.templates,
                is_draft_template: currentTemplate.is_draft_template,
            };
            const response = await draftIntentTemplate(payload);
            if (response.status === 201) {
                const resData = response.body;
                const newTemplate = {
                    id:resData.id,
                    name:resData.name,
                    version:resData.version,
                    template_name:resData.name,
                    templates:resData.report_config,
                    is_draft_template:resData.is_draft,
                }
                const current_config_nodes = getState().report.current_config_nodes;
                dispatch({
                    type: ActionType.SET_CURRENT_REPORT_CONFIG_NODES,
                    payload: {
                        ...current_config_nodes,
                        node_intents: current_config_nodes.node_intents.map((intentItem:intent)=>{
                            if(currentTemplate.intent&&currentTemplate.intent === intentItem.name){
                                let newTemplateList = intentItem.templates||[];
                                if(currentTemplate.is_draft_template&&intentItem.templates){
                                    newTemplateList = intentItem.templates.filter((templateItem)=>templateItem.id!==currentTemplate.id);
                                }
                                return { ...intentItem, templates: [ ...newTemplateList, newTemplate ] }
                            }
                            return intentItem;
                        })
                    },
                });

                 dispatch(alert(response.body?.message, {position: "bottom-left"}, "success"));
                 return {...newTemplate,
                    intent:currentTemplate.intent,
                    intent_path:currentTemplate.intent_path,
                }
            } else if (response.status === 400 || response.status === 500 || response.status === 404) {
                return dispatch(alert(response.body?.error));
            }
            return null;
        } catch (err) {
            dispatch(alert());
            return null;
        }
    };
};
export const editorEditorSidebarLoading = (value: boolean) => {
    return {
        type: ActionType.UPDATE_FOLDERS_LOADING,
        payload: value,
    };
};
export const editorUpdateLoading = (value: boolean) => {
    return {
        type: ActionType.UPDATE_OUTPUT_LOADING,
        payload: value,
    };
};
export const resetOutput = () => {
    return async (dispatch: Dispatch) => {
        dispatch(updateOutput({presigned_url: "", data: ""}));
    };
};
export const resetCurrentTemplate = () => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: ActionType.SET_EDITOR_REPORT_TEMPLATE,
            payload: {
                id:"",
                name:"",
                version:"",
                template_name:"",
                templates:[],
            },
        });
        dispatch(updateOutput({presigned_url: "", data: ""}));
        dispatch({
            type: ActionType.SET_CURRENT_EDITOR_FOLDER,
            payload: {
                name: "",
                templates: [],
                files: [],
            },
        });
        dispatch({
            type: ActionType.SET_SELECTED_FILE_DATA,
            payload: {id:"",name:"",type:"",file_id:"",file_content:"",file_name:"",content_type:""},
        });
    };
};
export const downloadDocxReport = () => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        try {
            const file_id = getState().report.inputData.id;
            if (file_id) {
                await downloadReportDocx(file_id);
            }
            return null;
        } catch (error) {
            dispatch(alert());
            return null;
        }
    };
};
export const uploadDocxFile = (file: File) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        try {
            const file_id = getState().report.inputData.id;
            if (file_id) {
                const response = await uploadDocxReport(file_id, file);
                if (response.status === 200) {
                    dispatch({
                        type: ActionType.SET_SELECTED_FILE_DATA,
                        payload: {...response.body.file_data,is_changed:true},
                    });
                    hydrateSelectedFile();
                    return dispatch(alert(response.body?.message, {position: "bottom-left"}, "success"));
                } else if (response.status === 400 || response.status === 500 || response.status === 404) {
                    return dispatch(alert(response.body?.error));
                }
            }
            return null;
        } catch (error) {
            dispatch(alert());
            return null;
        }
    };
};
export const uploadNewTemplateFolderFile = (file_type:string,file_name:string,folder:folder, file?: File) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        try {
            if (file_name&&file_type&&folder) {
                const payload = new FormData()
                payload.append('file',file||new File([""],file_name))
                const currentTemplate = getState().report.currentTemplate;
                const currentFolder = getState().report.currentFolder;
                const fileAlreadyExists = currentFolder.templates.find((file:filesWithContent)=>file.name===file_name);
                if(fileAlreadyExists){
                    return dispatch(alert("File name already exists."));
                }
                const response = await uploadNewFolderFile(payload,file_type,file_name,folder.name,folder.type,currentTemplate.id);
                if (response.status === 200) {
                    const currentTemplate = getState().report.currentTemplate
                    const currentFolder = getState().report.currentFolder
                    if(currentTemplate.id){
                        const newFile = {
                            ...response.body.file_data,
                            type:file_type,
                            name:file_name,
                            is_changed:true,
                        }
                        const updatedTemplate = {
                            ...currentTemplate,
                            templates: currentTemplate?.templates?.map((templateFolder:folder)=>{
                                if(templateFolder.name===folder.name){
                                    const updatedFolder = {
                                        ...templateFolder,
                                        templates: [...templateFolder.templates,newFile]
                                    }
                                    if(currentFolder.name&&currentFolder.name===templateFolder.name){
                                        dispatch({
                                            type: ActionType.SET_CURRENT_EDITOR_FOLDER,
                                            payload: updatedFolder
                                        });
                                    }
                                    return updatedFolder
                                }
                                return templateFolder
                            }),
                        };
                        dispatch({
                            type: ActionType.SET_EDITOR_REPORT_TEMPLATE,
                            payload: updatedTemplate,
                        });
                    }
                    return dispatch(alert(response.body?.message, {position: "bottom-left"}, "success"));
                } else if (response.status === 400 || response.status === 500 || response.status === 404) {
                    return dispatch(alert(response.body?.error));
                }
            }
            return null;
        } catch (error) {
            dispatch(alert());
            return null;
        }
    };
};

export const updateActionValue = (value: boolean) => {
    return {
        type: ActionType.UPDATE_ACTION_VALUE,
        payload: value
    }
}

export const getConfigVersionActions = (page?: number, isLoading?:boolean| any) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        try {
            const state = getState().report.versions;
            if(isLoading == true){
                dispatch(showUpConfigLoaderAction(true));
            }
            dispatch(viewMoreSpinnerAction(true));
            const response = await getConfigVersionsApi(page, limit);
            if (response?.status === 200) {
                const config = response?.body?.up_config;
                const updatedData = [...state , ...config];
                if(page == 1){
                    dispatch({
                        type: ActionType.SET_REPORT_CONFIG_VERSIONS,
                        payload: config,
                    });

                    const version = config?.[0];
                    if(version){
                        dispatch(getUPConfigActions(version.id));
                        return true;
                    }
                }else if(page && page > 1){
                    dispatch({
                        type: ActionType.SET_REPORT_CONFIG_VERSIONS,
                        payload: updatedData,
                    });
                }else if(config.length === 0 || config.length < 10){
                    dispatch({
                        type: ActionType.UPDATE_VERSION_HAS_MORE,
                        payload: false
                    });
                }
                dispatch(viewMoreSpinnerAction(false));
                return true;
            } else if (response.status === 400 || response.status === 500 || response.status === 404) {
                dispatch(alert(response.body?.error));
                dispatch(viewMoreSpinnerAction(false));
                dispatch(showUpConfigLoaderAction(false));
            }
        } catch (err) {
            dispatch(alert());
            dispatch(viewMoreSpinnerAction(false));
            dispatch(showUpConfigLoaderAction(false));
        }
        return false
    };
};

export const getUPConfigActions = (selectedId: string) => {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(viewMoreSpinnerAction(true));
            const response: any = await fetchUpConfig(selectedId);
            if(response.status == 200){
                dispatch({
                    type: ActionType.SET_UP_CONFIG_NODES,
                    payload: {
                        id: selectedId,
                        up_config: response.body?.up_config?.payload
                    }
                });
                dispatch(showUpConfigLoaderAction(false));
                dispatch(viewMoreSpinnerAction(false));
            }else{
                dispatch(alert(response.body.error));
                dispatch(viewMoreSpinnerAction(false));
                dispatch(showUpConfigLoaderAction(false));
            }
        }catch(error){
            dispatch(viewMoreSpinnerAction(false));
            dispatch(showUpConfigLoaderAction(false));
            dispatch(alert("error has been occured to fetch configs"));
        }
    }
}

export const viewMoreSpinnerAction = (value: boolean) => {
    return {
        type: ActionType.UPDATE_VIEW_MORE_SPINNER,
        payload: value
    }
}
export const showUpConfigLoaderAction = (value: boolean) => {
    return {
        type: ActionType.UPDATE_UP_CONFIG_PAGE_LOADER,
        payload: value
    }
}