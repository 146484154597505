import { Dispatch  } from "redux";
import { RootState } from "state";
import { ActionType as TypeAction } from 'state/action-types'
import { fetchAuditResults } from "services/audit";
import { convertDateToMilliseconds } from "components/Audit/utils/convertDate";
import { auditTableFilters } from "components/Audit/types";
import { alert } from "./customRouter";


export const auditLogs=(value:Array<object>)=>{
    return{
        type:TypeAction.ADD_AUDIT_LOGS_TABLE,
        payload:value
    }
}
export const fetchAuditTable = (email:string, fromDate:string, toDate:string,FilterLog:string,currentPage:number,setIsBottomReached?:React.Dispatch<any>) => {
    return async (dispatch: Dispatch , getState: () => RootState)=>{
        try{
            const prevData = getState().audit.logs
            dispatch(showDataMessage(false));
            dispatch(isTableLoader(true));
            const convertedFromDate =convertDateToMilliseconds(fromDate);
            const convertToDate = convertDateToMilliseconds(toDate);
            const limit = 50;

            const filters:auditTableFilters = {
                email: email,
                fromDate: fromDate,
                toDate: toDate,
                FilterLog: FilterLog,
            }
            dispatch(auditFilters(filters))

            const response = await fetchAuditResults({email,convertedFromDate,convertToDate,limit,currentPage,FilterLog,logs:[]})
            if(response?.status===200){
                const logs =response?.body?.logs;
                const updatedLogs = [...prevData, ...logs]
                if (!logs.length) {
                    if(currentPage!== 1){
                        dispatch(showDataMessage(false));
                        dispatch(auditLogs(updatedLogs))
                    }else{
                        dispatch(showDataMessage(true));
                        dispatch(auditLogs(logs))
                    }
                    dispatch(isTableLoader(false));
                    dispatch(isButtonLoader(false));
                    if(setIsBottomReached){
                        setIsBottomReached(true);
                    }
                    return;
                }else{
                    if(currentPage === 1){
                        dispatch(auditLogs(logs))
                    }else{
                        dispatch(auditLogs(updatedLogs))
                    }
                    dispatch(isTableLoader(false))
                    dispatch(isButtonLoader(false));
                }
            }else{
                dispatch(alert(response.body?.error))
            }
            if(setIsBottomReached){
                setIsBottomReached(false);
            }
            dispatch(isButtonLoader(false));
        }catch(err){
            dispatch(isTableLoader(false))
            console.log(err)
        }
    }
}

export const auditFilters = (data:auditTableFilters) => {
    return {
        type: TypeAction.ADD_AUDIT_LOGS_FILTERS,
        payload: data,
      };
}

export const isTableLoader = (value:boolean) => {
    return {
        type: TypeAction.IS_TABLE_LOADER,
        payload: value,
    }
}

export const isButtonLoader = (value:boolean) => {
    return{
        type: TypeAction.IS_BUTTON_LOADER,
        payload: value
    }
}
export const showDataMessage = (value:boolean) => {
    return{
        type:TypeAction.IS_MESSAGE,
        payload: value
    }
}