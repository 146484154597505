import React from "react";
import { useTypedSelector } from "hooks/use-typed-selector";

function FeedbackActionOverlay() {
  const activeAction = useTypedSelector(({ actionBar }) => actionBar.activeAction);
  const {
    isAddContextBox,
    isEditUserContextTab,
    // isEditQuickLinkQsStatus,
  } = useTypedSelector(({ quickLinksEdit }) => quickLinksEdit);

  return (
    <>
      {activeAction === "POSITIVE_FEEDBACK" ||
      activeAction === "NEGATIVE_FEEDBACK" ||
      activeAction === "EXPORT" ? (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 99,
          }}
        ></div>
      ) : (
        ""
      )}

      {isAddContextBox || isEditUserContextTab ? (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 5,
          }}
        ></div>
      ) : (
        ""
      )}
    </>
  );
}

export default FeedbackActionOverlay;