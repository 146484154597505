import { combineReducers } from 'redux';
// import validDomainsReducer from './validDomainsReducer';
import appSettingsReducer, {initialState as InitialSiteSettingsState} from "./AppSettingsReducer";
import authReducer, {INITIAL_STATE as InitialAuthState} from "./AuthReducer";
import commonReducer, {initialState as InitialCommonState} from "./CommonReducer";
import notificationReducer, {initialState as InitialNotificationsState} from "./NotificationReducer";

import feedbackReducer, {initialState as InitialFeedbackState} from "./FeedbackReducer";
import splatReducer, {initialState as InitialSplatState} from "./splatReducer";

import userHistoryReducer, {initialState as InitialUserHistoryState} from "./UserHistoryReducer";
import questionNavigationReducer, {initialState as InitialQuestionNavigationState} from "./QuestionNavigationReducer";
import quickLinksReducer, {initialState as InitialQuickLinksState} from "./QuickLinksReducer";
import questionBarReducer, {initialState as InitialQuestionBarState} from "./QuestionBarReducer";

import answerReducer, {initialState as InitialAnswerState} from "./AnswerReducer";
import pdfReducer, {initialState as InitialPdfViewerState} from "./PdfViewerReducer";

import actionBarReducer, {initialActionBar as InitialActionBarState} from "./ActionBarReducer";
import RocketAnalyzerReducer, {initial_state as InitialAnalyzerState} from "./RocketAnalyzerReducer";
import serversOnOffReducer, {initialState as InitialServersOnOffState} from "./ServersStatusReducer";

import ServiceWorkerReducer, {initialState as InitialServiceWorkerState} from "./ServiceWorkerReducer";
import SubscriptionHistoryReducer, {
    initialState as InitialSubscriptionHistoryState,
} from "./SubscriptionHistoryReducer";
import WorkflowReducer, {initialState as InitialWorkflowsState} from "./WorkflowReducer";
import ChatReducer, {initialState as InitialChatState} from "./chatReducer";
import FavouriteReducer, {initialState as InitialFavouriteBarState} from "./FavouriteReducer";
import TokenReducer, {initialState as InitialTokenState} from "./TokenReducer";
import AnalyzerParameterReducer, {initialState as InitialAnalyzerParametersState} from "./AnalyzerParameterReducer";
import {DeveloperReducer, initialState as InitialDeveloperReducerStateState} from "./developerSettingReducer";
import AuditReducer, {initialState as InitialAuditState} from "./AuditReducer";
import EditModeReducer, {initialState as InitialQuickLinksEditState} from "./EditModeReducer";
import ReportConfigEditorReducer, {initialState as InitialReportConfigEditorState} from "./ReportConfigEditorReducer";
import AgentReducer , {initialState as AgentsInitilState} from "./AgentReducer";
import ChatConfigurationReducer, {initialState as ChatConfigInitialState} from "./ChatConfigurationReducer";
import RunTestReducer, {initialState as RunTestInitialState} from "./RunTestReducer";
import {ActionType} from "state/action-types";

const reducers = (state:any, action:any) => {
    if (action.type === ActionType.REDUX_STATE_RESET) {
        state = {
            ...ReduxInitialState,
            siteSettings: state.siteSettings,
        };
    }

    return combineReducers({
        siteSettings: appSettingsReducer,
        auth: authReducer,
        common: commonReducer,
        notifications: notificationReducer,
        feedback: feedbackReducer,
        splat: splatReducer,
        userHistory: userHistoryReducer,
        questionNavigation: questionNavigationReducer,
        quickLinks: quickLinksReducer,
        questionBar: questionBarReducer,
        answer: answerReducer,
        pdfViewer: pdfReducer,
        actionBar: actionBarReducer,
        serversOnOff: serversOnOffReducer,
        analyzer: RocketAnalyzerReducer,
        serviceWorker: ServiceWorkerReducer,
        subscriptionHistory: SubscriptionHistoryReducer,
        workflows: WorkflowReducer,
        chat: ChatReducer,
        FavouriteBar: FavouriteReducer,
        token: TokenReducer,
        analyzerParameters: AnalyzerParameterReducer,
        DeveloperReducer: DeveloperReducer,
        audit: AuditReducer,
        quickLinksEdit: EditModeReducer,
        report: ReportConfigEditorReducer,
        agent: AgentReducer,
        chatConfiguration: ChatConfigurationReducer,
        runTest: RunTestReducer,
    })(state, action);
};
export const ReduxInitialState = {
    siteSettings: InitialSiteSettingsState,
    auth: InitialAuthState,
    common: InitialCommonState,
    notifications: InitialNotificationsState,
    feedback: InitialFeedbackState,
    splat: InitialSplatState,
    userHistory: InitialUserHistoryState,
    questionNavigation: InitialQuestionNavigationState,
    quickLinks: InitialQuickLinksState,
    questionBar: InitialQuestionBarState,
    answer: InitialAnswerState,
    pdfViewer: InitialPdfViewerState,
    actionBar: InitialActionBarState,
    serversOnOff: InitialServersOnOffState,
    analyzer: InitialAnalyzerState,
    serviceWorker: InitialServiceWorkerState,
    subscriptionHistory: InitialSubscriptionHistoryState,
    workflows: InitialWorkflowsState,
    chat: InitialChatState,
    FavouriteBar: InitialFavouriteBarState,
    token: InitialTokenState,
    analyzerParameters: InitialAnalyzerParametersState,
    DeveloperReducer: InitialDeveloperReducerStateState,
    audit: InitialAuditState,
    quickLinksEdit: InitialQuickLinksEditState,
    report: InitialReportConfigEditorState,
    agent: AgentsInitilState,
    chatConfiguration: ChatConfigInitialState,
    runTest: RunTestInitialState,
};

export default reducers;

export type RootState = ReturnType<typeof reducers>;
