import { Colors } from "style/color";

export const chatInputColors = {
  dark: {
    chatInputBackgroundColor: Colors.BALTIC_SEA,
    chatItemBorderColor: Colors.CHARCOAL_GREY,
    chatFocusButtonColor: Colors.LIGHT_BLACK,
    focusSelectedBackground: Colors.ASHY_BlACK,
    focusSelectedColor: Colors.WHITE,
    focusListHoverColor: Colors.BLACK_EEL_GREY,
    InputSentIconBackground: Colors.DARK_JUNGLE_GREEN,
    ButtonSelectOutline: Colors.TABLE_BLACK,
    svgFill: Colors.WHITE,
    SourceListBorderColor: Colors.LIGHT_BLACK,
    SourceListTextColor: Colors.DEBUG_BTN_COLOR,
    chatFocusButtonTextColor: Colors.LEGACY_BLUE,
    SuggestedQuestionsWrapperButtonBorder: Colors.LIGHT_BLACK,
    InputSendAndDisableIconColor: Colors.WHITE,
  },

  light: {
    chatInputBackgroundColor: Colors.WHITE,
    chatItemBorderColor: Colors.TRANSPARENT,
    chatFocusButtonColor: Colors.LIGHT_SEASHELL_BLUE,
    focusSelectedBackground: Colors.ASH_GREY,
    focusSelectedColor: Colors.BLUE,
    focusListHoverColor: Colors.CHARCOAL_GREY,
    InputSentIconBackground: Colors.LAVENDER,
    ButtonSelectOutline: Colors.SILVER,
    svgFill: Colors.BLUE,
    SourceListBorderColor: Colors.SORCE_LIST_BORDER_BLUE,
    SourceListTextColor: Colors.COMET,
    chatFocusButtonTextColor: Colors.LEGACY_BLUE,
    SuggestedQuestionsWrapperButtonBorder: Colors.BORDER_LIGHT_GREY,
    InputSendAndDisableIconColor: Colors.LAVENDER,
  },
};
