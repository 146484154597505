export function convertDateToMilliseconds(dateString:string) 
{

    const dateObject = Date.parse(dateString);
    return dateObject;
}

 export function convertTimeStamp(timestamp:number)
 {
const date = new Date(timestamp);
const month = date.getMonth() + 1; // Months are zero-based, so add 1
const formattedDate = `${month}/${date.getDate()}/${date.getFullYear()}`;
return formattedDate;
 }