import { Dispatch } from "redux";
import { RootState } from "state";
import { newChatAction, sendMessageAction, scrollMessagesListToBottomAction } from "./ChatActions";
import { Message } from "types/message";
import { debounce } from "lodash";
export const submitQueryActionIMPL = (q: string) => {
  return async (dispatch: Dispatch<any>, getState: () => RootState) => {
    try {
      const selectedFocus = getState().agent.selectedCommand?.is_visible ? getState().agent.selectedCommand?.display || "": "";
      const currentChatId = getState().chat.current_thread_details?.id;
      const selected_agents = getState().agent.selected_agent;
      const selected_sources = getState().agent.selected_sources;
      const topic = q;
      const current_chat_is_processed = getState().chat.current_thread_details?.is_chat_processed;
      const messages_processing_status = getState().chat.messages?.map((message: Message) => {
        return (message?.response?.response_component?.processing_status?.state==="done"
          ||message?.response?.response_component?.processing_status?.state==="blocked"
      ||message?.response?.response_component?.processing_status?.state==="failure")?"done":"not_done";
  });
  const isChatEnable = (current_chat_is_processed !== false) && (!messages_processing_status.includes("not_done"));

      //! #fix_this_later selected_agent?.agent_name is not considerting that agent_name can be empty
      if (topic) {
        if (!currentChatId ||(currentChatId && !isChatEnable)) {
          dispatch(
            newChatAction({
              topic,
              agent: selected_agents?.agent_name||"",
              focus: ( getState().agent?.selectedCommand?.is_visible ? selectedFocus : ""),
              sources: selected_sources,
            })
          );
        } else {
          
          dispatch(sendMessageAction({
            chat_id: currentChatId,
            is_new_chat: false,
            sources: selected_sources,
            focus: selectedFocus||"",
            message_intent: "question",
            user_message: {
              author: {
                role: "user",
                tool: "",
                metadata: "",
              },
              content:{
                type:"text",
                message:topic||"",
                metadata:""
              },
              metadata:""
            },
            message_id: "",
            question_id: "",
          })
          );
        }
        scrollMessagesListToBottomAction(true);
      }
    } catch (error) {
      console.log("Unhandled promise rejection:", error);
    }
  };
};

export const submitQueryAction = (() => {
  const debouncedAction = debounce((q: string, dispatch: Dispatch<any>) => {
    dispatch(submitQueryActionIMPL(q));
  }, 600);

  const actionCreator = (q: string) => {
    return (dispatch: Dispatch<any>) => {
      debouncedAction(q, dispatch);
    };
  };

  actionCreator.cancel = debouncedAction.cancel;
  actionCreator.flush = debouncedAction.flush;

  return actionCreator;
})();