import { Dispatch } from "redux";
import { ActionType } from '../action-types';
import * as userServices from "../../services/user"
import { alert} from './customRouter';
import { logOutAndClearData as signOutUser } from "./AuthActions";

export const getServersStatus = (fromIntercepter = false): any => {
    return async (dispatch: Dispatch<any>) => {
        try {
            const response = await userServices.getServersStatus();
            const results = response.body;

            dispatch({
                type: ActionType.FETCH_SYSTEM_STATUS_COMPLETED,
                payload: response.body
            })
            if (fromIntercepter && results && !results.are_all_services_available) {
                signOutUser()
                dispatch(alert("Please sign in and wait for system services to start again."));
            }
        } catch (err) {
            console.log(err)
            dispatch(alert("Please try again later (server down!)"));
        }
    }
}
