import React, { useState } from "react";
import styled from "styled-components";

import useInterval from "hooks/useInterval";
import range from "utils/range";

const Container = styled.div`
  display: flex;
`;

type KeyProps = {
  isActive: boolean;
}

const Key = styled.div<KeyProps>`
  width: 10px;
  height: 50px;
  margin-right: 5px;
  background: ${props => props.isActive ? '#72aaff' : '#787878'};

  &:last-child {
    margin-left: 0;
  }
`

type Props = {
  keys: number;
};

export default function LoadingPiano({ keys }: Props) {
  const [activeIndex, setActiveIndex] = useState(0);

  useInterval(function () {
    setActiveIndex((activeIndex + 1) % keys);
  }, 50);

  return (
    <Container>
      {range(keys).map(function (_, i) {
        return (
          <Key key={i} isActive={activeIndex === i} />
        );
      })}
    </Container>
  );
}
