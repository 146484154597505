import { post } from "makeRequest";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type ResponseSuccess<T> = {
    status: 200;
    body: {
        success: boolean,
        file: {
            name: string,
            id: string,
            path: string
        }
    };
  };
  
  type ResponseFailure = {
    status: 400 | 500 | 401;
    body: {
      success: boolean,
      errorCode: number;
      message: string;
    };
  };
  
  export type Response<T = {}> = Promise<ResponseSuccess<T> | ResponseFailure>;
export function uploadPptTemplate( file: File ): Promise<Response> {
    const payload = new FormData()
    payload.append('file',file)
    return post("/ppt/upload", payload);
}