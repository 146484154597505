export const operationLayerColors = {
    dark:{
        OperationLayerTextColor: '#ffffff',
        OperationContentTextColor: '#ffffff',
        OperationTableContentTextColor: '#464657',
        OperationTableEvenRowTextColor: '#5c5e6d',
        OperationTableTextTextColor: '#ffffff',
        OperationMainDashboardDivBorderColor:'1px dashed #454557',
        OperationDataBackgroundColor: '#1b1c31',
        OperationRightBarBackground: '#1b1c31',
        OperationRightSideBackground:'#323347',
        OperationMainLeftDivBorderColor:'1px solid #5c5e6d',
        OperationRightBorder:'1px dashed #454557',
        OperationCheckBoxbackground:'#AEBE37',
        OpertaionBackground:'#000000',
        OperationSpanBrdr:'#AEBE37',
        OperationTextColor:'#AEBE37',
        HeadingSpanColor:'#FFFFFF',
        OpertaionTextText:'#FFFFFF',
        ExplanationTextCode:'#FFFFFF',
        TextCodeColor:'#FFFFFF',
        OperationExplanationBottom:'#1b1c31',
        OperationLayerTimeBackgroundColor:'#000000',
    },
    light:{
        OperationLayerTextColor: '#828282',
        OperationContentTextColor: '#525264',
        OperationTableContentTextColor: '#eff0f4',
        OperationTableEvenRowTextColor: '#fafafc',
        OperationTableTextTextColor: '#525264',
        OperationMainDashboardDivBorderColor:'1px dashed #e5e5e5',
        OperationDataBackgroundColor: '#EFF0F4',
        OperationRightBarBackground: '#f9f9f9',
        OperationRightSideBackground:'#ffffff',
        OperationMainLeftDivBorderColor:'1px solid #dfdfdf',
        OperationRightBorder:'1px solid #e5e5e5',
        OperationCheckBoxbackground:'#AEBE37',
        OpertaionBackground:'#ffffff',
        OperationSpanBrdr:'#525264',
        OperationTextColor:'00A651',
        HeadingSpanColor:'#00A651',
        OpertaionTextText:'#525264',
        ExplanationTextCode:'#525264',
        TextCodeColor:'#525264',
        OperationExplanationBottom:'#eff0f4',
        OperationLayerTimeBackgroundColor:'#c4c4c4',
    },

}