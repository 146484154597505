import { Action  } from '../actions';
import * as siteServices from "../../services/settings"
import { Dispatch  } from "redux";

export const updateEmailTemplates = ( email_templates: any) => {
    return async (dispatch: Dispatch<Action> , getState: any) => {
        try {
            const id = getState().siteSettings.id;
            const data = {sendrid_dynamic_templates: email_templates , action: "sendrid_dynamic_templates"}
            const  res =  await siteServices.updateSiteSettings(id, data)
            return res;

        } catch(err) {
            console.log("error=>" , err);
        }
    }
}

export const updateDomainFederation = ( federated_key: any) => {
    return async (dispatch: Dispatch<Action> , getState: any) => {
        try {
            const id = getState().siteSettings.id;
            const data = {federated_key_templates: federated_key , action: "federated_keys"}
            const  res =  await siteServices.updateSiteSettings(id, data);
            return res;

        } catch(err) {
            console.log("error=>" , err);
        }
    }
}

export const addEmailTemplate = (newTemplate: any) => {
    return async (dispatch: Dispatch<Action> , getState: any ) => {
        try {
            const id = getState().siteSettings.id;
            const data = {email_template: newTemplate , action: "email_template"}
            const  res =  await siteServices.updateSiteSettings(id, data);
            return res;
        } catch(err) {
            console.log('error' , err);
        }
    }
}

export const updateChatTemplates = (chat_templates: any) => {
    return async(dispatch: Dispatch, getState: any) => {
        try{
            const id = getState().siteSettings.id;
            const data = {feature_config: chat_templates , action: "feature_config"}
            const  res =  await siteServices.updateSiteSettings(id, data)
            return res;
        }catch(error){
            console.log('error' , error);
        }
    }
}
export const updatePageUiTemplates = (ui_templates: any) => {
    return async(dispatch: Dispatch, getState: any) => {
        try{
            const id = getState().siteSettings.id;
            const data = {landing_page_ui_config: ui_templates , action: "landing_page_ui_config"}
            const  res =  await siteServices.updateSiteSettings(id, data)
            return res;
        }catch(error){
            console.log('error' , error);
        }
    }
}

export const updateQuickLinksSettings = (home_page_config: any) => {
    return async(dispatch: Dispatch, getState: any) => {
        try{
            const id = getState().siteSettings.id;
            const data = {home_page_config: home_page_config , action: "home_page_config"}
            const  res =  await siteServices.updateSiteSettings(id, data)
            return res;
        }catch(error){
            console.log('error' , error);
        }
    }
}
