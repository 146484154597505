export const upConfigSelectColors = {
    dark:{
        selectVersionDiv: "#323346",
        selectTextColor: "#fff",
        selectBorder: "#5e5a5a",
        selectHoverBg: "#1c1c31",
        selectedActiveIndex: "#1c1c31",
        selectedActiveBorderColor: "#ffffff",
    },
    light:{
        selectVersionDiv: "#fff",
        selectTextColor: "#222",
        selectBorder: "#e3e3e3",
        selectHoverBg: "#0074d91a",
        selectedActiveIndex: "#3983f7",
        selectedActiveBorderColor: "#3983f7",
    },
}