import { ActionType } from "../action-types";
import { WorkflowState } from "types/workflow";

export const initialState: WorkflowState = {
  value: false,
  toggle_spinner: false,
  types: [],
  current_workflows: [],
  selectedWorkFlowQuestions:[],
  selectedWFIndex: 0,
  showWorkFlowQuestion:false,
  showWorkFlowHeader:true,
  workFlowHeaderLoader:false,
  workFlowQuestionPageLoader:false,
  selectedWorkFlowName:"",
  indexValue: -1,
};

const reducer = (
  state: WorkflowState = initialState,
  action: any
): WorkflowState => {
  switch (action.type) {


    case 'update_workflow_visual':
      return {...state , current_workflows: state.current_workflows.map((value , index) => {
          if(index === action.payload.workflowIndex) {
             value.utterances.map((v , i) => {
                if(i === action.payload.utteranceIndex) {
                  v.visual = action.payload.visual;
                }
             })
          }


        return value;
      })}

    case ActionType.SAVE_WORKFLOW_QUESTIONS:
      const findCurrentWorkFlowIndex = (data:any) => data.workflow == action.payload.workflow;
      const getWFIndex = state.current_workflows.findIndex(findCurrentWorkFlowIndex);
      if(getWFIndex !== -1){
        const newArray = [...state.current_workflows];
        newArray[getWFIndex].utterances = action.payload.utterances;
        return { 
          ...state, //copying the orignal state
          current_workflows: newArray, //reassingning current_workflows to new array
         }
      }else{
        return { ...state, current_workflows: [...state.current_workflows, action.payload] };
      }
      case ActionType.SAVE_EXISTING_WORKFLOWS:
        return{...state, current_workflows:action.payload}
      
    case ActionType.SET_TOGGLE:
      return { ...state, value: action.payload };
    case ActionType.GET_WORKFLOW_TYPES:
      return { ...state, types: action.payload };
    case ActionType.SET_LOADING_SPINNER_FOR_SUBSCRIBED:
      return { ...state, toggle_spinner: action.payload };
    case ActionType.UPDATE_UTTERANCE_DATE:
      return {
        ...state, current_workflows: [...action.payload.data]
      }
    case ActionType.SHOW_WORKFLOW_QUESTION:
      return {...state , showWorkFlowQuestion:action.payload};
    case ActionType.WORKFLOW_HEADER_LOADING:
      return {...state , workFlowHeaderLoader:action.payload};
    case ActionType.WORKFLOW_QUESTION_PAGE_LOADING:
      return {...state , workFlowQuestionPageLoader:action.payload};
    case ActionType.SELECTED_WORKFLOW_INDEX:
        return{...state,selectedWFIndex:action.payload};
    case ActionType.SELECTED_WORKFLOW_QUESTIONS:
        return{...state,selectedWorkFlowQuestions:action.payload };
    case ActionType.SHOW_WORKFLOW_HEADER:
        return{...state, showWorkFlowHeader:action.payload};
    case ActionType.SELECTED_WOKRFLOW_NAME:
        return{...state, selectedWorkFlowName:action.payload};
    case ActionType.SELECTED_WOKRFLOW_INDEX:
        return{...state, indexValue:action.payload};
    case ActionType.UPDATE_USER_WORKFLOW_VISUAL_BY_UTTERANCE_ID:
      return {
          ...state,
          current_workflows: state.current_workflows.map((workflow) => {
              return {
                  ...workflow,
                  utterances: workflow.utterances.map((utterance) => {
                      if (utterance.utteranceId === action.payload.utteranceId) {
                          return {
                              ...utterance,
                              visual: action.payload.visual,
                              subscribed: action.payload.subscribed,
                              subscribed_id: action.payload.subscribed_id,
                              is_starred: action.payload.is_starred,
                          };
                      }
                      return utterance;
                  })
              };
          })
      };

    default:
      return state;
  }
};

export default reducer;
