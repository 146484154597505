import React from "react";
import { useTypedSelector } from "hooks/use-typed-selector";
import { RootState } from "state";
import { toasterMessage } from "utils/toasterMessage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ToastErrorIcon from "assets/dynamicSvg/ToastErrorIcon";

const ReduxAlert: React.FC = React.memo(() => {
  const redux_alert = useTypedSelector(({ common }: RootState) => common.redux_alert);
  const redux_alert_position = useTypedSelector(({ common }: RootState) => common.redux_alert.option);

  if (redux_alert.type === "empty") {
    return null;
  }

  if (redux_alert.type === "error") {
    toasterMessage("error", redux_alert.message);
  }

  if (redux_alert.type === "success") {
    toasterMessage("success", redux_alert.message);
  }

  if (redux_alert.type === "info") {
    toasterMessage("info", redux_alert.message);
  }

  if (redux_alert.type === "warning") {
    toasterMessage("warning", redux_alert.message);
  }

  return (
    <ToastContainer
      autoClose={false}
      toastStyle={{ backgroundColor: `${redux_alert.type === "success" ? "#3498db" : redux_alert.type === "error" ? "#D66982" : "#FFAF0D"}`,
       color: "#FFFFFF", fontSize: "16px", fontFamily: "'Roboto'",marginBottom:redux_alert_position.marginBottom || ""}}
      position={redux_alert_position.position || "bottom-left"}
      icon={<ToastErrorIcon />}
    />
  );
});

ReduxAlert.displayName = 'ReduxAlert';

export default ReduxAlert;
