import { BrowserRouter as Router } from 'react-router-dom';
import React from 'react';
interface HistoryProviderProps {
  children: React.ReactNode;
}

const HistoryProvider: React.FC<HistoryProviderProps> = ({ children }) => {
  return <Router>{children}</Router>;
};

export default HistoryProvider;