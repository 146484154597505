import { Colors } from "style/color";

export const suggestionInputColors = {
    dark:{
        SUGGESTION_BACKGROUND_COLOR: Colors.AMOUR_WHITE,
        SUGGESTION_BG_COLOR: Colors.BALTIC_SEA,
        suggestionInputBorderColor: Colors.DavyGrey,
        SEARCH_SUGGESTION_TEXT_COLOR: Colors.DEBUG_BTN_COLOR,
        SUGGESTION_BACKGROUND__HOVER_COLOR: Colors.DavyGrey,
    },

    light:{
        SUGGESTION_BACKGROUND_COLOR: Colors.LavenderMist,
        SUGGESTION_BG_COLOR: Colors.WHITE,
        suggestionInputBorderColor: Colors.LIGHT_GREY,
        SEARCH_SUGGESTION_TEXT_COLOR: Colors.DARK_CHARCOAL,
        SUGGESTION_BACKGROUND__HOVER_COLOR: Colors.LIGHT_SEASHELL_BLUE,
    }
};
