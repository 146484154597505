import { Sentiment } from "components/Visual/Breakdown/types";
import { JsonObject } from "type-fest";
import { FeedbackKind } from "types/feedback";
import { Node, Edge } from 'reactflow';
import { JSXElementConstructor, ReactElement } from "react";

export const  VISUAL_TYPES = {
  BAR_CHART : 'bar_chart',
  CALCULATION : "calculation",
  CHOROPLETH : "choropleth",
  CIRCLE_PACKING : "circle_packing",
  DASHBOARD : "dashboard",
  HIERARCHIACAL_LIST : "hierarchical_list",
  LINE_CHART : "line_chart",
  PIE_CHART : "pie_chart",
  SCATTER_PLOT : "scatter_plot",
  STRING : "string",
  SUGGESTED_QUESTION : "suggested_questions",
  SWARM_PLOT : "swarm_plot",
  TABLE : "table",
  TREEMAP : "treemap",   
}
export type Basis = {
  explanationHuman: string;
  explanationTech: string;
};

export enum pptStatus {
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCEEDED = 'SUCCEEDED',
  SUBMITTED ='SUBMITTED',            
}

type LogVisual = {
  logs:any,
  statusReason:string;
  exitCode: string | number;

}
export type SuggestionQuestionProps = {
  utterance: string,
  keywords: Array<string>
}

export type SuggestionSetProps = {
  title: string,
  keywords: Array<string>,
  questions: Array<SuggestionQuestionProps>,
  type: string
}

export type SideSuggestedQuestionsProps = {
  relatedQuestions: Array<SuggestionSetProps>;
}

export type ErrorVisual = {
  type: "error";
  title: string;
  shortAnswer: string;
  data: string;
  relatedQuestions: Array<SuggestionSetProps>;
  logs?: LogVisual | any;
  visual_error?: boolean;
};

export type CalculationVisual = {
  type: "calculation";
  title: string;
  shortAnswer: string;
  ppt_s3_file: string;
  status?: 'SUBMITTED' | 'PENDING' | 'RUNNABLE' | 'STARTING' | 'RUNNING';
  relatedQuestions: Array<SuggestionSetProps>;
};

export type JSONVisual = {
  type: "json";
  title: string;
  shortAnswer: string;
  data: JsonObject;
  basis: Basis;
};

export type NoAnswerVisual = {
  type: "no_answer";
  shortAnswer: string;
  suggestedQuestions: Array<string>;
};

export type StringVisual = {
  type: "string";
  title: string;
  shortAnswer: string;
  data: string;
  basis: Basis;
  suggestedQuestions: Array<string>;
  isMarkdown: boolean | null;
  status?:string;
};

export type ChoroplethData = Array<{ id: string; value: number }>;

export type ChoroplethVisual = {
  type: "choropleth";
  title: string;
  shortAnswer: string;
  ppt_s3_file: string;
  data: ChoroplethData;
  basis: Basis;
  suggestedQuestions?: Array<string>;
  nextQuestionTemplate: string | null;
  chart_options: {
    feedback?: boolean;
  }
  report_info?: {
    title?: string;
    description?: string;
    relative_position?: "left" | "right" | "bottom" | "above";
  }
};

export type Option = { id: string; label?: string; value: number | [] }
export type BreakdownTableDataType = { data: Option[] };

export type BreakdownVisual = {
  type: "breakdown" | "breakdown_table";
  title: string;
  shortAnswer: string;
  ppt_s3_file: string;
  data: BreakdownTableDataType | Option[];
  basis: Basis;
  suggestedQuestions: string[];
  csv: string[];
  nextQuestionTemplates?: Array<string | null>;
  nextQuestionTemplate?: string | null;
  relatedQuestions?: SideSuggestedQuestionsProps;
  basisClicked?: boolean;
  hasHighlighting?: boolean;
  up_response?: any;
  utterance?: string;
  layout?: "vertical" | "horizontal";
  legend_color? : {
    [key in Sentiment]: string
  };
  chart_options?: {
    chartLayout?: "vertical" | "horizontal";
  }
};

export type PieChartVisual = {
  type: "pie_chart";
  title: string;
  shortAnswer: string;
  ppt_s3_file: string;
  data: Array<{ id: string; label: string; value: number }>;
  basis: Basis;
  suggestedQuestions: Array<string>;
  csv: Array<string>;
  chart_options: {
    range?: Array<number>;
    innerRadius?: number; 
  }
  nextQuestionTemplate?: string | null;
  legend_color? : any;
  report_info?: {
    title?: string;
    description?: string;
    relative_position?: "left" | "right" | "bottom" | "above";
  }
};

export type SimpleBarChartData = Array<{ label: string; value: number }>;

export type Group = {
  index: string;
  [key: string]: string | number;
};


// chart option done

enum BarWidth {
  THIN=1,
  THICK,
  THICKEST
}

enum LabelSize {
  SMALL=1,
  LARGE=2
}

enum Order {
  SMALL=1,
  LARGE=2
}

export type BarChartVisual = {
  type: "bar_chart";
  title: string;
  shortAnswer: string;
  ppt_s3_file: string;
  data: SimpleBarChartData | Array<Group>;
  searchables?: Array<{title: string, value: string}>
  labels: Array<string> | null;
  basis: Basis;
  suggestedQuestions?: Array<string>;
  csv: Array<string>;
  layout: "vertical" | "horizontal";
  groupMode: "stacked" | "grouped" | null;
  nextQuestionTemplate: string | null;
  relatedQuestions?: SideSuggestedQuestionsProps;
  basisClicked?: boolean;
  yAxisLegend?: string;

  report_info?: {
    title?: string;
    description?: string;
    relative_position?: "left" | "right" | "bottom" | "above";
  }


  chart_options: {
    range?: Array<number>
    fontSize?: number;
    padding?: number;
    reverse?: boolean;
    feedback?: FeedbackKind;
    chartLayout?: "vertical" | "horizontal";
    chartGroupMode?: "stacked" | "grouped" | null;
    searchedAndSelected?: Array<{title: string, value: string}>;
    showLegend?: boolean;
    chartLowHighSwitch?: string | undefined;
    barWidth?: BarWidth,
    labelSize?: LabelSize,
    order?: Order
  }
};

export type ScatterPlotData = Array<{
  id: string;
  data: Array<{
    x: number | string; // | Date;
    y: number | string; // | Date;
  }>;
}>;

export type ScatterPlotVisual = {
  type: "scatter_plot";
  title: string;
  shortAnswer: string;
  data: ScatterPlotData;
  basis: Basis;
  suggestedQuestions: Array<string>;
};

export type SwarmPlotVisual = {
  type: "swarm_plot";
  title: string;
  shortAnswer: string;
  ppt_s3_file: string;
  basis: Basis;
  suggestedQuestions: Array<string>;
  data: Array<Record<string, number | string>>;
  labels: Array<string>;
  sizeKey: string | null;
  groupKey: string;
  valueKey: string;
  csv: Array<string>;
};

export type Point = {
  x: string;
  y: number;
};

export type Line = {
  id: string;
  data: Array<Point>;
};

export type LineChartVisual = {
  type: "line_chart";
  title: string;
  shortAnswer: string;
  ppt_s3_file: string;
  data: Array<Line>;
  labels: Array<string> | null;
  basis: Basis;
  suggestedQuestions: Array<string>;
  csv: Array<string>;
  nextQuestionTemplate: string | null;
  searchables?: Array<{title: string, value: string}>;
  
  report_info?: {
    title?: string;
    description?: string;
    relative_position?: "left" | "right" | "bottom" | "above";
  }
  // range?: Array<number>;
  chart_options: {
    range?: Array<number>;
    searchedAndSelected?: Array<{title: string, value: string}>;
  }

  up_response?: any;
};

export type Table = {
  primary_fields: (string | ReactElement<any, string | JSXElementConstructor<any>>)[];
  headers: Array<string>;
  rows: Array<Array<string | number>>;
  weights: Array<number>;
  data: Array<any>
};

export type TableVisual = {
  type: "table";
  title: string;
  shortAnswer: string;
  ppt_s3_file: string;
  data: Table;
  subscribed:boolean;
  basis: Basis;
  suggestedQuestions?: Array<string>;
  csv: Array<string>;
  nextQuestionTemplates: Array<string | null>;
  isMarkdown: boolean | null;
  highlightText: { [key: string]: string[][] } | null;
  highlightPageNum?: any;
  relatedQuestions?: SideSuggestedQuestionsProps;
  basisClicked?: boolean;
  hasHighlighting?: boolean;
  chart_options: {
    feedback?: boolean;
    searchedTerm?: string;
    filteredIndex?: Array<number>;
  }
  up_response?: any;
  is_elastic_indexed: Boolean;
};

type NestedSuggestions = Array<{ title: string; suggestions: Array<string> }>;

type Suggestions = Array<string> | NestedSuggestions;

export type SuggestedQuestionsVisual = {
  type: "suggested_questions";
  title: string;
  shortAnswer: string;
  ppt_s3_file: string;
  data: Array<SuggestionSetProps>;
  chart_options: {
    feedback?: boolean
  }
};

export function isSimpleSuggestions(
  suggestions: Suggestions
): suggestions is Array<string> {
  return suggestions.length === 0 || typeof suggestions[0] === "string";
}

export type NoVisual = {
  type: "none";
  is_elastic_indexed: boolean,
};

type Insight = {
  title: string;
  metadata: Array<string>;
};

type InsightList = {
  title: string;
  insights: Array<Insight>;
};

export type InsightVisual = {
  type: "hierarchical_list";
  title: string;
  shortAnswer: string;
  ppt_s3_file: string;
  data: Array<InsightList>;
  csv: Array<string>;
  suggestedQuestions: Array<string>;
  basis: Basis;
  activeGroupIndex: number;
  activeInsightIndex: number;
};

type TreeMapEntry = {
  label: string;
  value: number;
};

export type TreeMap = {
  label: string;
  children: Array<TreeMap | TreeMapEntry>;
};

export type TreemapVisual = {
  type: "treemap";
  title: string;
  shortAnswer: string;
  data: TreeMap;
  basis: Basis;
  suggestedQuestions: Array<string>;
  // labelSkipSize?: number; 
  chart_options?: {
    labelSkipSize?: number;
    feedback?: FeedbackKind
  }
};

type CirclePackingEntry = {
  label: string;
  value: number;
}

export type CirclePacking = {
  label: string;
  children: Array<CirclePacking | CirclePackingEntry>;
}

export type CirclepackingVisual = {
  type: "circle_packing";
  title: string;
  shortAnswer: string;
  ppt_s3_file: string;
  basis: Basis;
  suggestedQuestions: Array<string>;
  data: CirclePacking;
  
  circlePackingLeavesOnly: boolean;
  chart_options: {
    padding?: number;
    feedback?: FeedbackKind;
  }
}
// React-Flow code starts here
export interface FlowData {
  nodes: Node[]
  edges: Edge[]
}
export interface Data {
  label: string
  value: string
  color: string
}

export type FlowVisual = {
  type: "flow";
  title: string;
  shortAnswer: string;
  ppt_s3_file: string;
  basis: Basis;
  suggestedQuestions: Array<string>;
  data: FlowData;
}

export interface MarkDownData {
  Cell: string[];
}

export type MarkDownVisual = {
  type: "markdown";
  title: string;
  shortAnswer: string;
  ppt_s3_file: string;
  basis: Basis;
  suggestedQuestions: Array<string>;
  data: MarkDownData;
};

export type RMarkdownVisual = {
  type: "rmarkdown",
  title: string;
  shortAnswer: string;
  ppt_s3_file: string;
  basis: Basis;
  suggestedQuestions: Array<string>;
  data: MarkDownData;
  viz_subtype:string;
  error_details: string;
  visual_error?: boolean;
}

export type DashboardComponent =
  | StringVisual
  | PieChartVisual
  | LineChartVisual
  | BreakdownVisual
  | SuggestedQuestionsVisual
  | ChoroplethVisual
  | BarChartVisual
  | TreemapVisual
  | TableVisual
  | CirclepackingVisual
  | DashboardVisual;

export type DashboardLayout = Array<Array<number | null>>;

export type DashboardVisual = {
  type: "dashboard";
  title: string;
  shortAnswer: string;
  ppt_s3_file: string;
  components: Array<DashboardComponent>;
  layout: DashboardLayout;
  suggestedQuestions: Array<string>;
};

export type ReportLayout = Array<Array<number | null >>;

export type ReportComponent =
  | StringVisual
  | PieChartVisual
  | BreakdownVisual
  | LineChartVisual
  | SuggestedQuestionsVisual
  | ChoroplethVisual
  | BarChartVisual
  | TreemapVisual
  | TableVisual
  | CirclepackingVisual
  | DashboardVisual;

export type ReportVisual = {
  type: "report";
  title: string;
  shortAnswer: string;
  ppt_s3_file: string;
  components: Array<ReportComponent>;
  layout: ReportLayout;
  suggestedQuestions?: Array<string>;
  relatedQuestions?: SideSuggestedQuestionsProps | null;
  up_response?: any;
  utteranceId: string;
  searchables?: Array<{title: string, value: string}>

  chart_options: {
    reverse?: boolean;
    showLegend?: boolean;
    searchedAndSelected?: Array<{title: string, value: string}>;
  }
}

type common  = {
  up_response?: any,
  export_types?: Array<string>;
  report_subscription?: {
    id?:string,
    file_type: Array<string>,
    schedule_type?: string,
    subscription_date:string,
    subscribed_user_timestamp: number|string,
    utterance_id: string
  },
  status?:string,
  data?:any,
  type?:string,
  searchResults?:Array<string>,
  visual_id?:string,
}

export type Visual = common
  & ( NoAnswerVisual
  | StringVisual
  | JSONVisual
  | PieChartVisual
  | BreakdownVisual
  | LineChartVisual
  | BarChartVisual
  | SuggestedQuestionsVisual
  | TableVisual
  | TreemapVisual
  | InsightVisual
  | ScatterPlotVisual
  | SwarmPlotVisual
  | DashboardVisual
  | ChoroplethVisual
  | CalculationVisual
  | CirclepackingVisual
  | ErrorVisual
  | ReportVisual
  | NoVisual
  | FlowVisual
  | MarkDownVisual
  | RMarkdownVisual
  );
