import { SplatSortKey } from './splat'

export enum FeedbackKind {
  NEGATIVE = 0,
  POSITIVE,
}

export enum FeedbackStatus {
  SUBMITTED = 0,
  ADDRESSED,
  IGNORED
}

type FeedbackCore = {
  id: string;
  utteranceId: string;
  utterance: string;
  userEmail: string;
  kind: FeedbackKind;
  status: FeedbackStatus;
  text: string;
};

export type FeedbackSortKey = "QUESTION" | "KIND" | "REPORTED" | "STATUS";

export type Feedback = FeedbackCore & {
  created: Date;
};

export type SerializedFeedback = FeedbackCore & {
  created: number;
};

// export type SplatSortKey = "QUESTION" | "USER" | "REPORTED" | "STATUS";

export  interface FeedbackState {
  loading: boolean,
  data: Array<Feedback>,
  csv: Array<string>,
  lastCreated: number | null;
  sort: {
      key: SplatSortKey;
      order: -1 | 1;
  };
  statusProcessing: boolean;
  hasMore: boolean;
}