import moment from "moment";

export function formateHistoryDate(dateString: any) {
  if (dateString === "") {
      return "";
  }else {
    const dt =moment(moment(dateString).format("YYYY-MM-DD HH:mm:ss"));
    return `${dt.format('dddd')} ${moment(dateString).format("MM/DD/YYYY")}`;
  }
}

export function changeDateFormat(str: any) {
    return moment
      .unix(str)
      .format("YYYY-MM-DD")
      .toString();
  }

  export function getTimeRecentHistory(date: Date): string {
    const formatTiem = moment(date).format('LT');
    return formatTiem;
  }