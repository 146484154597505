import { post, get, patch, Response } from "makeRequest";

export function get_user_context(): any {
  return get("/get-user-context");
}

export function updateSiteSettings(id:string, newsettings: any): Response<{error: string, success: boolean,message:string}> {
    return post("/update-app-settings", { newsettings , "_id": id });
  }

export function get_compare_answer(id: string): Response {
  return get(`/get-answer-difference?question_id=${id}`);
}
export function updateReference(refUtteranceId:string, latestUtteranceId: string):Response {
  const requestBody = { reference_utterance_id: refUtteranceId, latest_utterance_id: latestUtteranceId};
  return patch("/update-answer-reference",requestBody);
}
export function getJobLogs(utteranceId: string, latest_answer_compute_route: string, latest_answer_job_id: string): Response {
  const requestBody = { utterance_id: utteranceId, job_id: latest_answer_job_id, compute_route: latest_answer_compute_route};
  return post("/get-job-logs",requestBody);
}
export function getJobLogsCLI(refUtteranceId: string, reference_answer_compute_route: string, reference_answer_job_id: string): Response {
  const requestBody = { utterance_id: refUtteranceId, job_id: reference_answer_job_id, compute_route: reference_answer_compute_route};
  return post("/get-job-logs",requestBody);
}

export function customerNoticeService(): Response {
  return patch("/user/customer-notice");
}