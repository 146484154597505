import { ActionType } from "state/action-types";
import { chatConfigurationState } from "types/chatConfigTypes";

export const initialState: chatConfigurationState = {
  agents: [],
  focus: [],
  sources_alias: [],
  sources: [],
  is_create_open: false,
  schema: {},
  is_loading_form: false,
  is_attach_focus: false,
  attach_schema: {},
  focus_intents: [],
  edit_schema: {},
  update_tree:0,
  attach_menu_data: {
    root_node: "",
    parent_node: "",
    parent_node_type: "",
    form_title: "",
  },
  edit_form_nodes_data: {
    node_id: "",
    node_type: "",
    parent_node: "",
    root_node: ""
  },
  page: 1,
  has_next_page: false,
  forms: {},
  is_config_changed: false,
  active_afs_tab: "agents",
  is_chat_config_loader: false,
  is_reset_state_for_loading: false,
};

const reducer = (state: chatConfigurationState = initialState, action: any): chatConfigurationState => {
  switch (action.type) {
    case ActionType.UPDATE_RESET_STATE_FOR_LOADING:
      return {...state, is_reset_state_for_loading: action.payload};
    case ActionType.REFETCH_CHAT_CONFIG_LOADER:
      return {...state, is_chat_config_loader: action.payload};
    case ActionType.UPDATE_SETTINGS_AGENTS_LIST:
      return {...state, agents: action.payload};
    case ActionType.UPDATE_IS_CONFIG_CHANGE:
      return {...state, is_config_changed: action.payload};
    case ActionType.UPDATE_IS_EDIT_FORM_OPEN:
      return {...state,
        edit_form_nodes_data: action.payload,
        is_create_open: false,
        attach_menu_data: {
          root_node: "",
          parent_node: "",
          parent_node_type: "",
          form_title: "",
        },
      };

    case ActionType.UPDATE_IS_CREATED_OPEN:
    return {...state,
       is_create_open: action.payload,
       edit_form_nodes_data: {
        node_id: "",
        node_type: "",
        parent_node: "",
        root_node: ""
      },
      attach_menu_data: {
        root_node: "",
        parent_node: "",
        parent_node_type: "",
        form_title: "",
      },}
    case ActionType.FETCH_FORM_SCHEMA_FOR_CHAT_CONFIG:
      return {...state, schema: action.payload};

    case ActionType.FETCH_EDIT_AGENT_SCHEMA:
      return {...state, edit_schema: action.payload};

    case ActionType.UPDATE_IS_LOADING:
      return {...state, is_loading_form: action.payload};

    case ActionType.UPDATE_AGENTS_LIST:
      return {...state, agents: [...state.agents, ...action.payload]};

    case ActionType.SET_PAGE_NUMBER_ROOT_NODE:
      return {...state, page:action.payload};

    case ActionType.SET_HAS_NEXT_PAGE_ROOT_NODE:
      return {...state, has_next_page: action.payload};

    case ActionType.UPDATE_FOCUS_LIST:
      return {...state, focus: action.payload};

    // console.log("action.payload", action.payload);
      //  const newFocus = state.agents.map((agent) => {
      //   if (agent._id === action.payload.parent_node_id) {
      //     return {
      //       ...agent,
      //       focus: action.payload.focus
      //     };
      //   }
      //   return agent;
      // } );

      // return {...state, agents: newFocus};

    case ActionType.UPDATE_SOURCES_ALIAS_LIST:
      return {...state, sources_alias: action.payload};

    case ActionType.UPDATE_SOURCES_LIST:
      return {...state, sources: action.payload};

    case ActionType.UPDATE_IS_ATTACH_FOCUS:
      return {...state, is_attach_focus: action.payload};

    case ActionType.FETCH_ATTACH_NODE_SCHEMA:
      return {...state, attach_schema: action.payload}

    case ActionType.UPDATE_FOCUS_INTENTS_LIST:
      return {...state, focus_intents: action.payload}

    case ActionType.OPEN_ATTACH_MENU:
      return {
        ...state,
        attach_menu_data: action.payload,
        is_create_open: false,
        edit_form_nodes_data: {
          node_id: "",
          node_type: "",
          parent_node: "",
          root_node: ""
        },
      }

    case ActionType.UPDATE_TREE:
      return {...state, update_tree: Math.random()*10000}

    case ActionType.CHANGE_AFS_TAB:
      return {...state, active_afs_tab: action.payload};

    case ActionType.ADD_NEW_ROW_DATA_IN_FOCUS_LIST:
      return {
        ...state,
        focus: [action.payload, ...state.focus]
      };
    case ActionType.UPDATE_GRID:
      return {
        ...state,
        forms: {...state.forms, [action.payload.name]: action.payload.data}
      };

    // case ActionType.ADD_NEW_ROW_DATA_IN_FOCUS_LIST:
    //   return {
    //     ...state,
    //     focus: state.focus.map((focus) => {
    //       if (focus._id === action.payload.parent_node) {
    //         return {
    //           ...focus,
    //           focus: [action.payload, ...state.focus]
    //         };
    //       }
    //       return focus;
    //     })
    //   };

    case ActionType.CREATE_NEW_AGENT_LIST_IN_FORM_FIELD:
      return {
        ...state,
        agents: [action.payload, ...state.agents]
      }
    default:
      return state;
  }
};

export default reducer;
