import { delete_req, get, patch, Response } from "makeRequest";
import { Notification } from "types/notification";

type GetNotificationResponse = {
  notifications: Array<Notification>;
};

export function getNotifications(): Response<GetNotificationResponse> {
  return get(`/notification`);
}

export function deleteNotification(notificationId: string): Response {
  return delete_req(`/notification/${notificationId}`);
}

export function markNotificationAsRead(notificationId: string): Response {
  return patch(`/notification/${notificationId}`);
}
export function clearNotifications(tabValue: string): Response {
  return patch(`/clear-notification?tab=${tabValue}`);
}