import { ActionType } from "../action-types";
import { QuestionBarState } from "types/QuestionBar";

export const initialState: QuestionBarState = {
  shouldFocusInput: false,
  isInputFocused: false,
  inputPlaceholder: "Ask a question or enter a disease...",
  questionInput: {
    input: "",
    recognizingFragment: "",
    hasSpeech: false,
    hasSpeechRecently: false,
  },
  suggestions: {
    content: [],
    index: -1,
  },
  SearchSuggestions: [
    {
      title: "getting",
      questions: ["Loading..."],
    },
  ],
  openSearchSuggestion: false,
  current_type: "",
  showSubscribedAnswer: false,
};

const reducer = (
  state: QuestionBarState = initialState,
  action: any
): QuestionBarState => {
  switch (action.type) {
    case ActionType.BLUE_QUESTION_INPUT:
      return { ...state, isInputFocused: false };
    case ActionType.FOCUS_QUESTION_INPUT:
      return { ...state, isInputFocused: true, shouldFocusInput: false };
    case ActionType.RESET_QUESTION_INPUT:
      return { ...state, questionInput: initialState.questionInput };
    case ActionType.HYDRATE_QUESTION_INPUT:
      return {
        ...state,
        shouldFocusInput: true,
        questionInput: action.payload,
      };
      case ActionType.HYDRATE_COPY_QUESTION_INPUT:
        return {
          ...state,
          shouldFocusInput: false,
          questionInput: action.payload,
        };
    case ActionType.SET_QUESTION_INPUT_PLACEHOLDER:
      return { ...state, inputPlaceholder: action.payload };
    case ActionType.STREAM_RECOGNIZING_FRAGMENT:
      return {
        ...state,
        questionInput: {
          ...state.questionInput,
          hasSpeechRecently: true,
          recognizingFragment: action.payload,
        },
      };
    case ActionType.STREAM_SPEECH_INPUT:
      return { ...state, questionInput: action.payload };
    case ActionType.STREAM_SPEECH_INPUT_FROM_COMMAND_MODE:
      return { ...state, questionInput: action.payload };
    case ActionType.STREAM_QUESTION_INPUT:
      return {
        ...state,
        questionInput: { ...state.questionInput, input: action.payload },
        suggestions: { ...state.suggestions, index: -1 },
      };
    case ActionType.LOAD_SUGGESTIONS:
      return {
        ...state,
        suggestions: {
          ...state.suggestions,
          content: action.payload,
          index: -1,
        },
      };
    case ActionType.CLEAR_SUGGESTIONS:
      return {
        ...state,
        suggestions: { ...state.suggestions, content: [], index: -1 },
      };
    case ActionType.UPDATE_SUGGESTION_INDEX:
      return {
        ...state,
        suggestions: { ...state.suggestions, index: action.payload },
      };
    case ActionType.ADD_SEARCH_SUGGESTION:
      return {
        ...state,
        SearchSuggestions: action.payload,
      };
    case ActionType.OPEN_SEARCH_SUGGESTION:
      return {
        ...state,
        openSearchSuggestion: action.payload,
      };

    case ActionType.CHANGE_TOP_NAV_TYPE:
      return { ...state, current_type: action.payload };
    case ActionType.SHOW_ANSWER_ON_SUBSCRIBED_ANSWER:
      return { ...state, showSubscribedAnswer: action.payload };
    default:
      return state;
  }
};

export default reducer;
