import { get, post, Response , delete_req } from "makeRequest";
interface AnalyzerParams {
  key: string;
  value: string;
}
type updateParams = {
  id: string;
  data: AnalyzerParams;
};
type createParameter = {
  parameter: AnalyzerParams;
}
interface ParameterKeys {
  _id: string;
  key: string;
  value: string;
}

type getParameter = {
 parameters: Array<ParameterKeys>,
 error:string,
}
type deleteParameter = {
  message: string,
  status:boolean,
  error:string,
}
export function updateParameter(value: updateParams):Response<{status: boolean}>  {
  return post("/update-parameter", value);
}

export function accessAllParameters(): Response<getParameter> {
  return get("/get-all-parameters");
}

export function deleteParameter(_id: string): Response<deleteParameter> {
  return delete_req(`/delete-parameter/${_id}`);
}

export function createParameters(value: createParameter):Response<{id: string, error: string}>  {
  return post("/create-parameter", value);
}