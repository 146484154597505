import { Dispatch } from "redux";
import { ActionType } from "state/action-types";

export const toggleShowLogs = (messageId: string) => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: ActionType.TOGGLE_MESSAGE_LOGS_VISIBILITY,
            payload: messageId,
        }); 
    }
}