
import { FIELDS } from "./main"
export const getUiSchema = () => ({
  "ui:order": [
    FIELDS.GENERAL_QUESTION,
    FIELDS.SEARCH_TERM,
    FIELDS.RULE_NAME,
    FIELDS.ABSTRACT_RULE,
    FIELDS.DATABASE,
    FIELDS.ALGORITHM,
    FIELDS.RULE_TYPE,
    FIELDS.SCOPE,
    FIELDS.RELATED_DISTANCE,
    "*",
  ],
  [FIELDS.SEARCH_TERM]: {
    // "ui:title": "Full Text Search Terms",
    "ui:title": "What keywords are required for this search",
    "ui:widget": "textarea",
    "ui:help": "Please enter your search terms with boolean logic. i.e. (\"M6-C\" OR \"M6 C\") AND \"cervical\".",
    "ui:options": {
      rows:6
    }
  },
  [FIELDS.RULE]: {
    // "ui:title": "Full Text Search Terms",
    "ui:title": "What keywords are required for this search",
    "ui:widget": "textarea",
    "ui:help": "Please enter your search terms with boolean logic. i.e. (\"M6-C\" OR \"M6 C\") AND \"cervical\".",
    "ui:options": {
      rows:6
    }
  },
  [FIELDS.RULE_NAME]: {
    // "ui:title": "Search Name",
    "ui:title": "What name would you like to save this query as?",
    "ui:help": "Create a name for the topic you would like to search",
  },
  [FIELDS.ABSTRACT_RULE]: {
    "ui:title": "Abstract Search String",
    "ui:widget": "textarea",
    "ui:help": "Used only for the document abstract. Example: (\"M6-C\" OR \"M6 C\") AND \"cervical\".",
    "ui:options": {
      rows:6
    }
  },
  [FIELDS.ALGORITHM]: {
    "ui:title": "Algorithm Version",
    "ui:help": "The version of the algorithm used to narrow results and identify document zones.  Generally, leave this as is.  Example: v4."
  },
  [FIELDS.DATABASE]: {
    "ui:title": "Database Version",
    "ui:help": "A string of databases separated with commas.  Example:  \"pmc, pubmed, embase\" or \"pmc\"."
  },
  [FIELDS.RELATED_DISTANCE]: {
    "ui:title": "Related Distance",
    "ui:help": "A parameter use in the Algorithm Version of the distance between a hit and surrounding text to use to narrow results."
  },
  [FIELDS.SCOPE]: {
    "ui:title": "Scope"
  },
  [FIELDS.TYPE]: {
    "ui:title": "Type"
  },
  [FIELDS.FOLLOW_UP]: {
    "ui:title": "Follow Up"
  },
  [FIELDS.INTENTED_USE]: {
    "ui:title": "Intented Use"
  },
  [FIELDS.PATIENT_POPULATION]: {
    "ui:title": "Patient Population"
  },
  [FIELDS.GENERAL_QUESTION]: {
    // "ui:title": "General Question",
    "ui:title": "For this search, what are you specifically looking for?",
    "ui:widget": "textarea",
    "ui:help": 'Enter a question about your topic of interest. Example: "What is the efficacy of [insert medical device] for those that are diagnosed with heart disease?',
    "ui:options": {
      rows:6
    }
  },
  [FIELDS.DEBUG]: {
    "ui:title": "Debug"
  },
  [FIELDS.RULE_TYPE]: {
    "ui:autofocus": true,
    "ui:title": "Rule Type",
    "ui:default": "family-name",
    "ui:disabled": true
  },
  [FIELDS.USER_ID]: {
    'ui:title': 'User ID',
    "ui:disabled": true
  }
  })

  export const getEditOrder = () => {
    const orders = [
      FIELDS.RULE,
      FIELDS.RULE_NAME,
      FIELDS.ABSTRACT_RULE,
      FIELDS.RULE_TYPE,
      FIELDS.SCOPE,
      FIELDS.ALGORITHM,
      FIELDS.DATABASE,
      FIELDS.RELATED_DISTANCE,
   
      "*"
    ]
    return orders
  }