export const newWorkerColor = {
    dark:{
        NewWorkerHeaderBackgroundColor: '#323346',
        NewWorkerLogoColor: '#FFFFFF',
        NewWorkerInputFieldBackgroundColor: '#1B1C31',
        NewWorkerInputFieldBorderColor: '#454557',
        NewWorkerTextColor: '#FFFFFF',
        NewWorkerBorderColor:'#454557',
        workerIconBgColor: '#000',
    },
    light:{
        NewWorkerHeaderBackgroundColor: '#FAFAFB',
        NewWorkerLogoColor: '#525264',
        NewWorkerInputFieldBackgroundColor: '#FFFFFF',
        NewWorkerInputFieldBorderColor: '#DDDDDD',
        NewWorkerTextColor: '#413f3f',
        NewWorkerBorderColor:'#DDDDDD',
        workerIconBgColor: '#EFF3FD',
    },
}
