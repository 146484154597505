import { alert } from "./state/action-creators/customRouter";
import { CONNECTION_STATE_CHANGE, ConnectionState } from '@aws-amplify/pubsub';
import { Amplify, Hub } from 'aws-amplify';
import { store as reduxStore } from './state';
import * as serviceWorker from "./serviceWorker";
import { amplifyConfig, authConfig } from "config";
import { setSubscriptionAction } from "state/action-creators/EventReceiverActions";

export const applyAmplifyHubListener= ()=>{
    Hub.listen('api', (data: any) => {
        const { payload } = data;
        try {
          if (payload.event === CONNECTION_STATE_CHANGE) {
            payload.data.connectionState as ConnectionState;
          }
          else if(ConnectionState.ConnectionDisrupted == payload.data.connectionState) {
        
            reduxStore.dispatch(alert("something went wrong in connection"));
          }

          if(payload.data.connectionState == "ConnectedPendingNetwork"||ConnectionState.ConnectionDisrupted == payload.data.connectionState){
            reduxStore.getState().chat.subscription.eventReceiver?.unsubscribe();
            reduxStore.dispatch(setSubscriptionAction({ name: "eventReceiver", value: null }));
          }
          // if(payload.data.connectionState == "ConnectionDisrupted" || payload.data.connectionState == "ConnectedPendingNetwork"){
          //   console.log("now i am in error")
          //   reduxStore.dispatch(setSubscriptionAction({ name: "eventReceiver", value: null }));
          // } 
          console.log('[Pubs/subs]', payload.event, payload.data.connectionState);
        } catch (error) {
          console.log(error);
        }
    });
}
export const registerServiceWorker = () => {
  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  // serviceWorker.unregister();
  serviceWorker.register({
    onSuccess: () => {
      reduxStore.dispatch({ type: 'SW_INIT' })
    },
    onUpdate: registration => {
      reduxStore.dispatch({ type: 'SW_UPDATE', payload: registration })
    },
  });
  
};

export const setupAmplifyConfig = () => {
  // adding amplify configuration
  Amplify.configure({
    Auth: authConfig,
    ...amplifyConfig
  });
}

export default function AppSetup() {
    applyAmplifyHubListener();
    setupAmplifyConfig();
    registerServiceWorker();
}