import { get, post, Response } from "makeRequest";
import { AnswerRequestContext } from "types/quickLinks";
import { AnswerResponse } from "./answer";

export function getWorkFlowTypes(): Response<any> {
  return get("/workflows-type");
}

export function getWorkflowTypes(
  utterance: string,
  userEmail: string,
  context: AnswerRequestContext
): Response<AnswerResponse> {
  return post("/answer", { utterance, userEmail, context });
}

export function runWorkflows(workFlowName: string): Response<any> {
  return post(`/run-workflows`, { workflow_name: workFlowName });
}

export function fetchExistingWF(): Response<any> {
  return get(`/workflow-status`);
}

export function workflowCompleteAsync(
  utternace_id: string,
  workflow: string
): Response<any> {
  return post("/process-workflow", {
    utternace_id: utternace_id,
    workflow: workflow,
  });
}
