import { ActionType } from '../action-types';
import { getUserHistory  } from "services/history";
import { Dispatch  } from "redux";
import { alert, push } from './customRouter';
import { RootState } from 'state';
import { deleteUserHistory , getUserHistoryAnswer } from "services/history";
import { clearAnswer , changeCurrentVisual , updateCurrentVisualJobId, updateExportInProgress, tableSliderData } from './AnswerActions';
import { updateExplanationShown } from './CommonActions';
import { completeAsync } from "services/answer";
import { fetchHistoryRecords } from 'services/history';
import { compareQuestions } from './SubscriptionHistoryAction';
import { formatWorkFlowData } from './WorkflowActions';
import { hideQuickLinkContext, showQuickLinkContext } from './QuickLinksActions';
import { pptStatus } from 'types/visual';
import { disabledButtonAction, setPdfWaveLoaderAction, setpptKey } from './ActionBarActions';
import { UserHistoryEntry } from 'types/userHistory';


const setFetchState = (state: string) => {
    return {
        type: ActionType.UPDATE_FETCH_STATE,
        payload: state
    }
}
const updateStoriesHandleClick = (value: boolean) =>{
    return {
      type:ActionType.UPDATE_STORIES_HANDLE_CLICK,
      payload: value
    }
  }

/**
 * 
 * TODO: this function is only running on first load.
 * TODO: we can add a check if see if uh is present if present update the hitory limit.
 * TODO: and after userHistory is update update the index with fetched data.
 * 
 * 
 **/ 
export const fetchUserHistory = (page_no: number) => {
    
    return async (dispatch: Dispatch<any>) => {
       
        try {
            dispatch(setFetchState('Fetch'));
            const params = new URLSearchParams(document.location.search);
            const index = params.get("index");
            const historySearchString = "";
            const historyOrderKey = "";
            let limit = 20;
            if(index) {
                const index_num = parseInt(index);
                limit = index_num > 20 ? index_num : 20;
                dispatch({
                    type: 'update_pagination_limit',
                    payload: limit
                })
            }
            //incrementing limit + 1 becuase we start from 0. and backend start with 1.
            dispatch(errorWhileSearch(false));
            dispatch({
                type:'update_has_more',
                payload: true
            });
            limit = limit + 1;
            const response = await getUserHistory(page_no, limit, historySearchString, historyOrderKey);
            if (response.status === 200) {
               dispatch(recentlQuestionResponse(true));
               dispatch({
                    type: ActionType.FETCH_USER_HISTORY_COMPLETED,
                    payload:response.body.history
                               
                });
                
                dispatch({
                    type:ActionType.FETCH_USER_HISTORY_DATE,
                    payload:response.body.date
                });

                const index =  params.get("index");
                const utteranceForHistory = params.get("uh");
                const utteranceId = params.get("utteranceId");
                if( utteranceForHistory && utteranceId) {
                    try {
                        if(index && response.body.history[parseInt(index)].utteranceId != utteranceId) {
                            dispatch(push(`/?u=${utteranceForHistory}`));
                        } else {
                            dispatch(updateLoadingSingleHistory(true));
                            const res: any = await getUserHistoryAnswer({
                                utterance_id: utteranceId,
                                "utterance" : utteranceForHistory
                            });
                            const subscribed = res.body.visual.subscribed
                            const subscribed_id = res.body.visual.subscribed_id
                            dispatch(updateUserHistoryVisualByUtteranceId(utteranceId , res.body.visual,subscribed,subscribed_id));
                            if(index){
                                dispatch( visitHistoryItemAction(parseInt(index)) );
                            }else{
                                const visual = res.body.visual
                                dispatch( visitHistoryItemAction(-1,{
                                    utterance:utteranceForHistory ,
                                    utteranceId,
                                    visual,
                                    job_id:null,
                                    compute_route:"",
                                    subscribed,
                                    subscribed_id,
                                    is_starred: visual?.is_starred,
                                    answer_type: "",
                                    pdf_status: visual?.pdf_status,
                                    updateTitle:"",
                                    status: false,
                                }));
                            }
                            const dataLength = res?.body?.visual?.data?.length
                            dispatch(tableSliderData([0,dataLength]))
                        }
                    } catch (error) {
                        dispatch(push(`/?u=${utteranceForHistory}`));
                    }
                }
                // if uh is present in URL fetch history items and update the index.
                //if response has no more data, set hasMore to false
                if(response.body.history.length === 0 || response.body.history.length < limit) {
                    dispatch({
                        type:ActionType.UPDATE_HAS_MORE,
                        payload: false
                    });
                }
                dispatch(setFetchState('View'));
            }
        } catch (error) {
            dispatch(setFetchState('View'));
            dispatch(alert("Error in fetching user history"));
            dispatch(errorWhileSearch(false));
        }

    }
}

export const updateIsPpt = (key: any) => {
    return {
        type: ActionType.UPDATE_PPTX_STATUS,
        payload: key
    }

}


export const recentlQuestionResponse = (loading: boolean) => {
    return {
        type: ActionType.RECENTLY_ASKED_QS_RESPONSE,
        payload: loading
    }
}

export const updateUserHistory = (page_no: number) => {
    return async (dispatch: Dispatch , getState: () => RootState) => {
        try {
            const state = getState();
            const historySearchString = state.userHistory.historySearchedWord;
            const historyOrderKey = state.userHistory.historyOrderKey;
            const limit = getState().userHistory.pagination_limit + 1;
            const response = await getUserHistory(page_no,limit,historySearchString,historyOrderKey);
            const historyDates = getState().userHistory.date;
            if (response.status === 200) {
                const pageIncrement = page_no + 1;
                dispatch(updateRecentHistoryPage(pageIncrement));
                const dates = response.body.date;
                if(response.body.date.length === 0){
                    dispatch(isViewMore(false))
                }
                dispatch(isViewMoreSpinner(false));
                dispatch({
                    type: "update_user_history" ,
                    payload:  response.body.history,
                });
                dates.map(e => {
                    if (!historyDates.includes(e)) {
                        historyDates.push(e)
                   }
                })

                dispatch({
                    type:ActionType.FETCH_USER_HISTORY_DATE,
                    payload: historyDates
                })
                if(response.body.history.length === 0 || response.body.history.length < getState().userHistory.pagination_limit) {
                    dispatch({
                        type:ActionType.UPDATE_HAS_MORE,
                        payload: false
                    });
                }
            }
        } catch (error) {
            dispatch(alert("Error has been occured in updating user history"));
        }
    }
}
export const isViewMore = (value:boolean)=>{
    return {
            type: ActionType.IS_RECENT_HISTORY_QUESTION_FOUND,
            payload: value
    }
}

export const getAndUpdateUserHistoryItem = ({utteranceId , utterance}: any) => {
    return async (dispatch: Dispatch) => {
        try {
            const res: any = await getUserHistoryAnswer({
                utterance_id: utteranceId,
                "utterance" : utterance
            });
            const subscribed = res.body.visual.subscribed
            const subscribed_id = res.body.visual.subscribed_id;
           dispatch(updateUserHistoryVisualByUtteranceId(utteranceId ,res.body.visual,subscribed,subscribed_id));
           dispatch(updateStoriesHandleClick(false));
           const dataLength = res?.body?.visual?.data?.data?.length || res?.body?.visual?.data?.length
           dispatch(tableSliderData([0,dataLength]))
           return res.body.visual;
            
        } catch (error) {
            dispatch( alert("History not found") );
        }
    }
}

export const updateLoadingSingleHistory = (loading: boolean) => {
    return {
        type: ActionType.UPDATE_LOADING_SINGLE_HISTORY,
        payload: loading
    }
}


/**
 *Todo move this function to workflow aciton as a cleanup step
 * 
**/
export const updateWorkflowVisualByUtteranceId = (utteranceId: string, visual: any,subscribed?:boolean ,subscribed_id?:string,is_starred?:boolean) => {
    return {
        type: ActionType.UPDATE_USER_WORKFLOW_VISUAL_BY_UTTERANCE_ID,
        payload: {
            utteranceId: utteranceId ,
            visual: visual,
            subscribed:subscribed,
            subscribed_id:subscribed_id,
            is_starred:is_starred
        }
    }
}

export const setAnswerForWorkflowSliderNew = (workflowIndex: number , utteranceIndex: number) => {
    return async (dispatch: Dispatch , getState: () => RootState) => {
        try {
            dispatch(updateLoadingSingleHistory(true));
            const selectedUtterence = getState().workflows.current_workflows[workflowIndex].utterances[utteranceIndex];
            const question = selectedUtterence.utterance;
            const utteranceId = selectedUtterence.utteranceId;
            let visual;visual
            if(selectedUtterence.visual == undefined || selectedUtterence.visual as any == 'None' || !selectedUtterence.visual) {
                const res: any = await getUserHistoryAnswer({
                    "utterance_id": selectedUtterence.utteranceId,
                    "utterance" : selectedUtterence.utterance
                });
                dispatch({
                    type: 'update_workflow_visual',
                    payload: {
                         workflowIndex: workflowIndex,
                         utteranceIndex: utteranceIndex,
                         visual: res.body.visual
                    }
                })
                 visual = res.body.visual;
                 const subscribed = res.body.visual.subscribed;
                 const subscribed_id = res.body.visual.subscribed_id;
                 const is_starred = res.body.visual.is_starred;
                 const dataLength = visual?.data?.data?.length
                 dispatch(updateWorkflowVisualByUtteranceId(utteranceId , visual,subscribed,subscribed_id,is_starred))
                 dispatch(tableSliderData([0,dataLength]))

            } else {
                visual = (selectedUtterence.visual as any);
            }
            dispatch({
                type: ActionType.HYDRATE_ANSWER ,
                payload: {
                    question , 
                    utteranceId , 
                    subscribed: visual.subscribed,
                    subscribed_id: visual.subscribed_id,
                    visual , 
                    job_id: '' ,
                    compute_route: "",
                    isHistory: false,
                    reportExportStatus: [],
                }
            });
            dispatch({
                type: ActionType.HYDRATE_ANSWER_COPY ,
                payload: {
                    question ,
                    utteranceId ,
                    subscribed: visual.subscribed,
                    subscribed_id: visual.subscribed_id,
                    visual ,
                    job_id: '' ,
                    compute_route: "",
                    isHistory: false,
                    reportExportStatus: [],
                }
            });
            dispatch(updateLoadingSingleHistory(false));
        } catch (error) {
            dispatch(updateLoadingSingleHistory(false));
            dispatch(alert("Error has been occured in setAnswerForWorkflowSliderNew"));

        }
    }
}


/**
 *!Delete this function when done with it temp funciton
 *TOdo delete below variable when done and tested below funciton is replace by new funciton.
 **/
export const setAnswerForWorkflowSlider = (index: number) => {
    return async (dispatch: Dispatch , getState: any) => {
        try {
            dispatch(updateLoadingSingleHistory(true));
            dispatch({
                type: ActionType.UPDATE_HISTORY_INDEX ,
                payload: index
            });
            // dispatch(compareQuestions(false));
            const { utterance: question , utteranceId , visual , job_id, compute_route ,subscribed ,subscribed_id, is_starred}  = getState().userHistory.data[index]

            if(visual == "None" || visual == undefined){
                const res: any = await getUserHistoryAnswer({
                    utterance_id: utteranceId,
                    "utterance" : question
                });

                const isHistory = true;

                dispatch(updateUserHistoryVisualByUtteranceId(utteranceId , res.body.visual,subscribed,subscribed_id));
                const dataLength = res?.body?.visual?.data?.data?.length
                dispatch(tableSliderData([0,dataLength]))
                const {  visual }  = getState().userHistory.data[index]

                dispatch({
                    type: ActionType.HYDRATE_ANSWER ,
                    payload: {
                        question , 
                        utteranceId , 
                        subscribed,
                        subscribed_id,
                        visual , 
                        job_id ,
                        compute_route,
                        isHistory,
                        is_starred,
                        reportExportStatus: [],
                    }
                });

            } else {
                const isHistory = true;
                dispatch({
                    type: ActionType.HYDRATE_ANSWER ,
                    payload: {
                        question ,
                        utteranceId ,
                        subscribed,
                        subscribed_id,
                        visual ,
                        job_id ,
                        compute_route,
                        isHistory,
                        is_starred,
                        reportExportStatus: [],
                    }
                });

            }
            dispatch(updateLoadingSingleHistory(false));
        } catch (error) {
            dispatch(alert("Error has been occured in AnswerForWorkflowSlider"));
            dispatch(updateLoadingSingleHistory(false));
        }
    }

}




export const visitHistoryItemAction = (index: number,history?: any) => {
    return async (dispatch: Dispatch, getState: any) => {
        try {
            dispatch({
                type: ActionType.UPDATE_UUID_CASE,
                payload: null
            })
            dispatch(hideQuickLinkContext())
            dispatch(updateLoadingSingleHistory(true));
            dispatch({
                type: ActionType.UPDATE_HISTORY_INDEX ,
                payload: index
            });
            dispatch(compareQuestions(false));

            let question, utteranceId, visual, job_id, compute_route, subscribed, subscribed_id, is_starred, answer_type, pdf_status, updateTitle, answer_status;
            if (index!==-1) {
                ({
                    utterance: question,
                    utteranceId,
                    visual,
                    job_id,
                    compute_route,
                    subscribed,
                    subscribed_id,
                    is_starred,
                    answer_type,
                    pdf_status,
                    updateTitle,
                    status: answer_status,
                } = getState().userHistory.data[index]);
            } else if(history){
                ({
                    utterance: question,
                    utteranceId,
                    visual,
                    job_id,
                    compute_route,
                    subscribed,
                    subscribed_id,
                    is_starred,
                    answer_type,
                    pdf_status,
                    updateTitle,
                    status: answer_status,
                } = history);
            }
            const newPdf_status = pdf_status ? pdf_status: "";
            if(['in progress', 'submitted'].includes(newPdf_status)){
            dispatch(disabledButtonAction(true));
            dispatch(setPdfWaveLoaderAction(true));
            }else{
                dispatch(disabledButtonAction(false));
                dispatch(setPdfWaveLoaderAction(false));
            }

            const isSubscribed = subscribed;
            const isStar = is_starred;
            const params = new URLSearchParams({ uh: question , utteranceId: utteranceId  , index : index.toString() });
            dispatch(push(`/?${params}`));

            if(visual == "None" || visual == undefined || (visual.type == 'table' && !visual.data)){
                    const { body, status } = await getUserHistoryAnswer({
                        utterance_id: utteranceId,
                        "utterance" : question
                    });
                    const currentItemIndex = getState().userHistory.index;
                    const { utteranceId:currentUtteranceId } = getState().userHistory.data[currentItemIndex];

                    const newVisual = body.visual;
                    const is_pptx = newVisual.ppt_status;
                    const ppt_path = newVisual.ppt_s3_file;
                    const dataLength = newVisual?.data?.length || newVisual?.data?.data?.length

                    const subscribed = newVisual.subscribed;
                    const subscribed_id = newVisual.subscribed_id;
                    const isHistory = true;
                    const is_starred = newVisual.is_starred;
                    dispatch(updateUserHistoryVisualByUtteranceId(utteranceId , newVisual,subscribed,subscribed_id));
                    const {  visual }  = getState().userHistory.data[currentItemIndex]

                    if(currentUtteranceId===body.visual.utteranceId){

                        dispatch(tableSliderData([0,dataLength]))
                        if(is_pptx ===pptStatus.FAILED || is_pptx ===false){
                            dispatch(updateExportInProgress(false))
                        }
                        if(is_pptx ===pptStatus.IN_PROGRESS|| is_pptx ===pptStatus.SUBMITTED){
                            dispatch(updateExportInProgress(true))
                        }
                        if(is_pptx ===pptStatus.SUCCEEDED){
                            dispatch(setpptKey(ppt_path));
                            dispatch(updateExportInProgress(false))

                        }

                        if(status !== 200){
                            dispatch(push("/recently-asked"));
                        }

                        dispatch({
                            type: ActionType.HYDRATE_ANSWER ,
                            payload: {
                                question ,
                                utteranceId ,
                                subscribed,
                                subscribed_id,
                                visual ,
                                job_id ,
                                compute_route,
                                isHistory,
                                is_starred,
                                answer_type,
                                pdf_status:newPdf_status,
                                updateText: "",
                                reportExportStatus: [],
                            }
                        });

                        dispatch({
                            type: ActionType.HYDRATE_ANSWER_COPY ,
                            payload: {
                                question ,
                                utteranceId ,
                                subscribed,
                                subscribed_id,
                                visual ,
                                job_id ,
                                compute_route,
                                isHistory,
                                is_starred,
                                answer_type,
                                pdf_status:newPdf_status,
                                updateText: "",
                                reportExportStatus: [],
                            }
                        });
                        dispatch(updateLoadingSingleHistory(false));
                    }

            } else {
                const subscribed =isSubscribed;
                const isHistory  = true;
                const is_starred = isStar;
                const updateText = answer_status == 'Done' ? "" : updateTitle;

                dispatch({
                    type: ActionType.HYDRATE_ANSWER ,
                    payload: {
                        question ,
                        utteranceId ,
                        visual ,
                        subscribed,
                        subscribed_id,
                        job_id ,
                        compute_route,
                        isHistory,
                        is_starred,
                        answer_type,
                        pdf_status:newPdf_status,
                        updateText : updateText,
                        reportExportStatus: [],
                    }
                });

                dispatch({
                    type: ActionType.HYDRATE_ANSWER_COPY ,
                    payload: {
                        question ,
                        utteranceId ,
                        visual ,
                        subscribed,
                        subscribed_id,
                        job_id ,
                        compute_route,
                        isHistory,
                        is_starred,
                        answer_type,
                        pdf_status:newPdf_status,
                        updateText: updateText,
                        reportExportStatus: [],
                    }
                });
                dispatch(updateLoadingSingleHistory(false));
            }
            dispatch({
                type: ActionType.UPDATE_NEWTABLE_HAS_MORE,
                payload: true
            });
        } catch (error) {
            dispatch(alert("Error has been occured while fetching answer"));
            const prevUtteranceId = getState().answer.utteranceId;
            const history = getState().userHistory.data;
            const prevIndex = history.findIndex((item:UserHistoryEntry)=>item.utteranceId===prevUtteranceId);
            if(prevIndex>-1){
                const prevUtterance = history[prevIndex].utterance
                dispatch({
                    type: ActionType.UPDATE_HISTORY_INDEX ,
                    payload: prevIndex
                });
                const params = new URLSearchParams({ uh: prevUtterance , utteranceId: prevUtteranceId  , index : prevIndex.toString() });
                dispatch(push(`/?${params}`));
            }else{
                dispatch(showQuickLinkContext());
            }
            dispatch(updateLoadingSingleHistory(false));
        }
    }
}

export const goToNextHistoryItemAction = () => {
    return (dispatch: Dispatch<any>, getState: () => RootState) => {
        const index = getState().userHistory.index;
        if(index === 0 || index<0) {
            return;
        }

        dispatch(visitHistoryItemAction(index - 1));
    }
}

export const goToPrevHistoryItemAction = () => {
    return (dispatch: Dispatch<any>, getState: () => RootState) => {
        const history = getState().userHistory;
        if (history.index === history.data.length - 1) {
            return;
        }
        dispatch(visitHistoryItemAction(history.index + 1));
    }
}

export const deleteHistoryQuestionAction = (index: any ) => {
    return async (dispatch: Dispatch<any> , getState: () => RootState) => {
    const state = getState();
        try {
            const utteranceId = state.userHistory.data[index].utteranceId;
            const compute_route = state.userHistory.data[index].compute_route;
            const job_id = state.userHistory.data[index].job_id;
            const data = {
                utteranceId,
                compute_route,
                job_id
            }
            dispatch(spinnerOnDelete(true))
            const response = await deleteUserHistory(data);
            if (response.status) {
                dispatch(setDeleteSpinner(false));

                let historyQuesData = [...state.userHistory.data];
                if (index > -1) {
                    historyQuesData.splice(index, 1);
                }
                historyQuesData = [...historyQuesData];
                dispatch({
                    type: ActionType.UPDATE_HISTORY,
                    payload: historyQuesData
                });
                if(utteranceId === state.answer.utteranceId){
                    if(state.questionBar.current_type == '/'){
                        dispatch(push('/'));
                        dispatch(setHistoryIndex(-1));
                        dispatch(showQuickLinkContext())
                    }else{
                        dispatch(push('/recently-asked'));
                        dispatch(setHistoryIndex(-1));
                    }
                }else if(index < state.userHistory.index){
                    dispatch(setHistoryIndex(state.userHistory.index-1));
                    dispatch(visitHistoryItemAction(state.userHistory.index-1));
                }
                if(historyQuesData && historyQuesData.length > 0) {
                    dispatch( updateExplanationShown(false) );
                } else {
                    dispatch( push('/'));
                    dispatch( clearAnswer() );
                }
                dispatch(spinnerOnDelete(false))
                const userData = state.userHistory
                if(userData.data.length == 20){
                    dispatch(updateUserHistory(userData.updatePageNumber));
                    dispatch(isViewMoreSpinner(true));
                }
            } else {
                dispatch(alert("Error in deleting History"));
            }
        } catch (error) {
            dispatch(alert("Error in deleting History"));
        }
    }
}

export const setDeleteSpinner = (value:boolean)=>{
    return{
        type: ActionType.SET_DELETE_SPINNER_VALUE,
        payload: value
    }
}


export const addHistoryEntry = (answer: any) => {
    return {
        type: ActionType.ADD_HISTORY_ENTRY,
        payload: {
            ...answer , created: new Date()
        }
    }
}


export const updateUserHistoryEntry = (utteranceId: string, visual: any ) => {
    return {
        type: ActionType.UPDATE_USER_HISTORY_ENTRY,
        payload: {
            utteranceId: utteranceId ,
            visual: visual,
            thumbnail:visual.thumbnail
        }
    }
}


export const updateUserHistoryVisualByUtteranceId = (utteranceId: string, visual: any,subscribed?:boolean ,subscribed_id?:string) => {
    return {
        type: ActionType.UPDATE_USER_HISTORY_VISUAL_BY_UTTERANCE_ID,
        payload: {
            utteranceId: utteranceId ,
            visual: visual,
            subscribed:subscribed,
            subscribed_id:subscribed_id,
        }
    }
}
export const updateUserHistoryVisualByQuestion = (utterance?: string,subscribed?:boolean , subscribed_id?:string ) => {
    return {
        type: ActionType.UPDATE_USER_HISTORY_VISUAL_BY_QUESTION,
        payload: {
            utterance: utterance ,
            subscribed:subscribed,
            subscribed_id:subscribed_id,
        }
    }
}

export const updateUserHistoryJobId = (utteranceId: string, job_id: any ) => {
    return {
            type: ActionType.UPDATE_USER_HISTORY_JOB_ID,
            payload: {
                utteranceId: utteranceId ,
                job_id: job_id
        }
    }
}

export const checkForAsyncCompletions = () => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const state = getState();
        try {
            const utteranceIds = state.userHistory.data
                .filter((e) => e.status === "Calculating")
                .map((e) => e.utteranceId);
            if (utteranceIds.length === 0) {
                return true;
            }
            const response = await completeAsync(utteranceIds);
            // ignore failure, the user doesn't/shouldn't be aware
            if (response.status === 200) {
                const currentUtteranceId = getState().answer.utteranceId;
                const { completed } = response.body;
                for (const { utteranceId, visual, job_id, compute_route, status} of completed) {
                    if (currentUtteranceId === utteranceId) {
                        dispatch(updateCurrentVisualJobId(job_id, compute_route))
                        dispatch(changeCurrentVisual(visual))
                        if(status == "Done") {
                            dispatch({
                                type: ActionType.HYDRATE_VISUAL_ANSWER_COPY,
                                payload: visual
                            });
                        }
                        const is_pptx = (visual as any).ppt_status;
                        const ppt_path = (visual as any).ppt_s3_file;


                        if(is_pptx === pptStatus.FAILED || is_pptx ===false){
                            // dispatch(updateIsPpt(false));
                            dispatch(updateExportInProgress(false));
                        }
                        if(is_pptx === pptStatus.IN_PROGRESS || is_pptx ===pptStatus.SUBMITTED && !state.answer.exportInProgress){
                            // dispatch(updateIsPpt(true));
                            dispatch(updateExportInProgress(true));
                        }
                        if(is_pptx === pptStatus.SUCCEEDED){
                            dispatch(setpptKey(ppt_path));
                            // dispatch(updateIsPpt(false));
                            dispatch(updateExportInProgress(false));
                        }

                    }
                }
                for (const { utteranceId, visual, job_id } of completed) {
                    dispatch(updateUserHistoryJobId(utteranceId, job_id))
                    dispatch(updateUserHistoryEntry(utteranceId, visual));
                }

                for (const result of completed) {
                    if (result.utterance_type === "workflow") {
                        const ndata = formatWorkFlowData(result,state.workflows.current_workflows)
                        dispatch({
                            type: ActionType.UPDATE_UTTERANCE_DATE,
                            payload: {
                                data:ndata
                            }
                          });
                    }
                }
                if(!state.answer.hasMore){
                    dispatch({
                        type: ActionType.UPDATE_NEWTABLE_HAS_MORE,
                        payload: true
                    });
                }
                return true;
            } else {
                dispatch(alert("Error while checking asyncCompletion"));
                return true;
            }

        } catch (error) {
            // dispatch(alert("Error from useHIstory action"));
            return true;
        }
    }
}

export function recentQuestions(){
    return async (dispatch: Dispatch<any>) => {
        try {
            const response = await fetchHistoryRecords();
            if(response.status === 200){
                dispatch({
                    type: ActionType.RECENT_HISTORY_RECORDS,
                    payload: response.body
                });
            }
        }catch (error) {
            dispatch(alert("Error in fetching recentQuestion"));
        }
    }
}

export function spinnerOnDelete(data:boolean){
    return {
        type: ActionType.SPINNER_ON_DELETE,
        payload: data
    }

}

export function isViewMoreSpinner(data:boolean){
    return {
        type : ActionType.SPINNER_ON_VIEW_MORE,
        payload: data
    }
}

type props ={
    searchValue?: string,
    orderKey?:string,
}
export function searchOnRecentlyAsked({searchValue,orderKey}:props){
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const state = getState();
        const searchWord = state.userHistory.historySearchedWord;
        const sortOrderKey = state.userHistory.historyOrderKey;

        if(!orderKey){
            orderKey = sortOrderKey;
        }

        if(!searchValue){
            searchValue = searchWord;
        }

        try {
            dispatch(recentlQuestionResponse(false));
            dispatch({
                type:ActionType.UPDATE_HAS_MORE,
                payload: true
            });
            dispatch(isViewMore(true))
            dispatch(setLoaderOnSearch(true));
            dispatch(errorWhileSearch(false));
            const page_no = 1;
            const limit = state.userHistory.pagination_limit + 1;
            const response = await getUserHistory(page_no, limit, searchValue, orderKey);
            if(response.status === 200){
                if(response.body.history.length == 0){
                    dispatch({
                        type:'update_has_more',
                        payload: false
                    });
                }
                dispatch(recentlQuestionResponse(true));

                const date = response.body.date;
                const history = response.body.history;
                dispatch(updateRecentHistoryPage(2));
                dispatch(setLoaderOnSearch(false));
                dispatch(updateDate(date));
                dispatch(updateHistory(history));
            }else{
                dispatch({
                    type:'update_has_more',
                    payload: false
                });
                dispatch(alert("Something went wrong while searching."))
                dispatch(setLoaderOnSearch(false));
                dispatch(recentlQuestionResponse(false));
                dispatch(errorWhileSearch(true));
            }
        } catch (error) {
            dispatch({
                type:'update_has_more',
                payload: false
            });
            dispatch(alert())
            dispatch(setLoaderOnSearch(false));
            dispatch(recentlQuestionResponse(false));
            dispatch(errorWhileSearch(true));
            dispatch(updateHistory([]));
        }
    }
}

export function errorWhileSearch(err:boolean){
    return{
        type: ActionType.IS_ERROR_WHILE_SEARCH,
        payload: err
    }
}
export function updateDate(date:Array<string>){
    return{
        type: ActionType.SEARCH_RESULTS_DATE,
        payload: date
    }
}

export function updateHistory(data:Array<object>){
    return{
        type: ActionType.SEARCH_RESULTS_HISTORY,
        payload: data
    }
}

export function storeHistorySearchWord(value: string){
    return{
        type: ActionType.SEARCHED_WORD,
        payload: value
    }
}

export function storeOrderKey(value: string){
    return{
        type: ActionType.SEARCHED_ORDER_KEY,
        payload: value
    }
}

export function setLoaderOnSearch(value:boolean){
    return {
        type : ActionType.LOADER_ON_SEARCH_RESULTS,
        payload: value
    }
}

export function clearHistorySearchInput(){
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const state = getState();
        const searchValue = state.userHistory.historySearchedWord;
        const orderKey = state.userHistory.historyOrderKey;
       dispatch(searchOnRecentlyAsked({searchValue,orderKey}));
    }
}

export function updateRecentHistoryPage(pageNumber:number){
    return {
            type: ActionType.VIEW_MORE_UPDATE_PAGE,
            payload: pageNumber,
           }
}

export function updateContextAnimation(hasAnimation: boolean){
    return {
        type: ActionType.UPDATE_CONTEXT_ANIMATION,
        payload: hasAnimation
    }
}

export const setHistoryIndex = (index:number)=>{
    return ({
      type: ActionType.UPDATE_HISTORY_INDEX,
      payload: index,
    })
  }



  export const visitChatVisual = (analyzerHistoryItem:any) => {
    return async (dispatch: Dispatch) => {

        try {
            // dispatch({
            //     type: ActionType.CHECKING_PDF_STATUS,
            //     payload: '',
            // });
            dispatch(compareQuestions(false));

            const { utterance: question ,
                utteranceId ,
                visual , job_id, compute_route ,subscribed ,
                subscribed_id,
                is_starred,
                answer_type,
                pdf_status,
            }  = analyzerHistoryItem;
            // const isSubscribed = subscribed;
            // const isStar = is_starred;
            const isHistory  = true;
            const newPdf_status = pdf_status ? pdf_status: "";
            dispatch({
                type: ActionType.HYDRATE_ANSWER ,
                payload: {
                    question ,
                    utteranceId ,
                    visual ,
                    subscribed,
                    subscribed_id,
                    job_id ,
                    compute_route,
                    isHistory,
                    is_starred,
                    answer_type,
                    pdf_status:newPdf_status,
                    reportExportStatus: [],
                }
            });

            dispatch({
                type: ActionType.HYDRATE_ANSWER_COPY ,
                payload: {
                    question ,
                    utteranceId ,
                    visual ,
                    subscribed,
                    subscribed_id,
                    job_id ,
                    compute_route,
                    isHistory,
                    is_starred,
                    answer_type,
                    pdf_status:newPdf_status,
                    reportExportStatus: [],
                }
            });
            dispatch({
                type: ActionType.UPDATE_NEWTABLE_HAS_MORE,
                payload: true
            });
            dispatch(updateLoadingSingleHistory(false));
            if(['in progress', 'submitted'].includes(newPdf_status)){
            dispatch(disabledButtonAction(true));
            dispatch(setPdfWaveLoaderAction(true));
            }else{
                dispatch(disabledButtonAction(false));
                dispatch(setPdfWaveLoaderAction(false));
            }
        } catch (error) {
            dispatch(alert("Error has been occured while fetching answer"));
            dispatch(updateLoadingSingleHistory(false));
        }
    }
}
