import { useNavigate } from "react-router-dom";
export default function useHistory() {
  const navigate = useNavigate();
  const push = (to: string, replace?: string) => {
    navigate(to, { replace: replace ? true : false });
  };
  const back = () => {
    navigate(-1);
  };
  return { push, back, history: { location: window.location } };
}