import { ActionType } from "state/action-types"
import { Dispatch  } from "redux";
import { getNotifications ,  deleteNotification, markNotificationAsRead, clearNotifications, } from "services/notification";
import { alert } from "./customRouter";
import { DispatchType } from "state/store";

export const fetchNotifications = () => {
    return async (dispatch: Dispatch) => {
        try {
            dispatch({type:ActionType.FETCH_NOTIFICATION_LOADING_STATE , payload:true})
            const notificationResponse: any = await getNotifications();
            dispatch({
                type: ActionType.FETCH_NOTIFICATIONS_COMPLETED ,
                payload: notificationResponse.body.notifications
            });

        } catch (error) {
            dispatch({type:ActionType.FETCH_NOTIFICATION_LOADING_STATE , payload:false})
            dispatch(alert());
        }
	}
}

export const ClearNotifications = (activeTab: string) => {
    const tabValue = activeTab.toLowerCase()
    return async(dispatch: DispatchType) => {
        try {
            dispatch({
                type: ActionType.CLEAR_NOTIFICATION_IS_LOADING,
                payload:true
            })
            const response = await clearNotifications(tabValue);
            if(response.status == 200){
                dispatch(fetchNotifications())
            }
        } catch (err) {
            dispatch({
                type: ActionType.CLEAR_NOTIFICATION_IS_LOADING,
                payload:false
            })
            console.log(err)
        }
    }
}

export const updateNotificationHeaderState = (state: any) => {
    return {
        type: ActionType.UPDATE_NOTIFICATION_HEADER_STATE ,
        payload: state
    }
}


export const deleteNotificationAction = (notification: any) => {
    return async (dispatch: Dispatch) => {
        const response = await deleteNotification(notification.id);
        if (response.status === 200) {
                dispatch({
                    type: ActionType.DELETE_NOTIFICATION_COMPLETED ,
                    payload: notification.id
                });
            }
       
	}

}

export const markNotificationRead = (notification: any) => {
    return async (dispatch: Dispatch) => {
        const response = await markNotificationAsRead(notification.id);
         if (response.status === 200) {
            dispatch({
                type: ActionType.MARK_NOTIFICATION_READ ,
                payload: notification.id
            });
            return true;
     
                } else {
                    return false;
                } 
    }
}

export const toShowNotificationAnswer = (value: boolean) => {
    return {
        type: ActionType.SHOW_NOTIFICATION_ANSWER,
        payload: value
    }
}