export const rightNavigationColors={
    dark:{
        RightPanelIconColor: '#FFF',
        RightPanelBackgorundColor:'#1B1C31',
        RightPanelHoverColor:'#464763',
        RightPanelBorderColor: '#454557',
        RightPanelCloseIconColor: '#323346',
    },

    light:{
        RightPanelIconColor: '#828282',
    RightPanelBackgorundColor:'#FFF',
    RightPanelHoverColor:'#F6F6F6',
    RightPanelBorderColor: '#EDEAEA',
    RightPanelCloseIconColor: '#FFF',
    }
}
