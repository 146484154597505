export const rocketAnalyzerColors = {
    dark:{
        RocketAnalyzerText: '#ffffff',
        ConfigartionColor: '#323346',
        WorkersBackgroundTable: '#1b1c31',
        WorkersBorderTable: '#1B1C31',
        WorkersBorderBottom: '#454557',
        ButtonColorLink: '#ffffff',
        BackgroundConfigartionColor: '#323346',
        ReguralWorkerConfigreation: '#1B1C31',
        WorkerRequirement: '#323346',
        WorkersTextTextTxt: '#FFFFFF',
        AddnewWorkerText: '#c8c8c8',
        AddnewWorkerTextPlaceholder: '#757575',
        AnaylzerDashboardBorderColor:'#454557',
    },
    light:{
        RocketAnalyzerText: '#828282',
        ConfigartionColor: '#F3F3F3',
        WorkersBackgroundTable: '#FFFFFF',
        WorkersBorderTable: '#FFFFFF',
        WorkersBorderBottom: '#E8E8E8',
        ButtonColorLink: '#ffffff',
        BackgroundConfigartionColor: '#ffffff',
        ReguralWorkerConfigreation: '#F3F3F3',
        WorkerRequirement: '#ffffff',
        WorkersTextTextTxt: '#828282',
        AddnewWorkerText: '#333',
        AddnewWorkerTextPlaceholder: '#757575',
        AnaylzerDashboardBorderColor:'#ddddddd9',
    },
}
