import {useEffect} from "react";
import * as Sentry from "@sentry/react";
export const useHumaLogs = () => {
    useEffect(() => {
        const url = window.location.href;
        const {hostname, searchParams} = new URL(url);
        const debugParam = searchParams.get("debug");
        const isDebug = debugParam !== null || hostname == "localhost";
        const Logger = window.console;
        const prevent = () => {
            return false;
        };
        const SentryLog = (error: Error) => {
            Sentry.captureException(error, { extra: { params: error?.message , url } });
            return false;
        };
        const log = isDebug ? Logger.log : prevent;
        const logError = isDebug ? Logger.error : SentryLog;

        const customLog = {...Logger, log: log, error: logError};
        window.console = customLog;
    }, []);
};
