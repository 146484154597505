import LSR_UI_SCHIMA from './lsr.json'
import TAR_UI_SCHIMA from './tar.json'
import KEY_TAKEWAY_UI_SCHEMA from './key_takeaway.json'
import { getUiSchema, getEditOrder }  from './literature-review-schema'

export const OP_TYPE = {
  INSERT: 'insert',
  UPDATE: 'update'
} 

export const FIELDS = {
RULE_TYPE : "rule_type",
RULE_NAME : "rule_name",
SEARCH_TERM : "search",
ABSTRACT_RULE : "abstract_rule",
ALGORITHM : "algorithm_version",
DATABASE : "database_version",
RELATED_DISTANCE : "related_distance",
DEBUG : "debug_list",
SCOPE : "scope",
USER_ID : "user_id",
GENERAL_QUESTION : "general_question",
TYPE : "type",
RULE: "rule",
FOLLOW_UP: 'follow_up',
INTENTED_USE: 'intented_use',
PATIENT_POPULATION: 'patient_population',
READ_ONLY:'read_only'
}

export const RULE_TYPE = {
  LSR: 'lsr',
  TAR: 'tar',
  SEARCH: 'search',
  MAUDE_PUBMED: 'maude_pubmed',
  KEY_TABKEAWAY: 'key_takeaway',
  LITERATURE_REVIEW_PUBLIC: 'LitRevPublic',
  LITERATURE_REVIEW_PRIVATE: "LitRviewInclPrivate",
  LITERATURE_REVIEW_KEY_TAKEAWAY_PUBLIC: "LitReviewKeyTakeawayPublic",
  LITERATURE_REVIEW_KEY_TAKEAWAY_PRIVATE: "LitReviewKeyTakeawayInclPrivate",
}

export const getAliasTypeLabel = (key:string) => {
  let label = key
  if( key === RULE_TYPE.LITERATURE_REVIEW_KEY_TAKEAWAY_PRIVATE) {
    label = 'Literature Review Keytakeway Private'
  }
  if( key === RULE_TYPE.LITERATURE_REVIEW_KEY_TAKEAWAY_PUBLIC) {
    label = 'Literature Review Keytakeway Public'
  }
  if( key === RULE_TYPE.LITERATURE_REVIEW_PUBLIC) {
    label = 'Literature Review Public'
  }
  if( key === RULE_TYPE.LITERATURE_REVIEW_PRIVATE) {
    label = 'Literature Review Private'
  }
      
  return label
}
  
export const getUISchemaByRuleType = (type:string, isEdit?:boolean) => {
  const orders = getEditOrder()
  switch(type){
    case RULE_TYPE.TAR:
      return TAR_UI_SCHIMA
    case RULE_TYPE.LSR:
      if(isEdit){
        return {...LSR_UI_SCHIMA, "ui:order": orders}
      }
      return LSR_UI_SCHIMA
    case RULE_TYPE.KEY_TABKEAWAY:
      return KEY_TAKEWAY_UI_SCHEMA
    case RULE_TYPE.SEARCH:
    case RULE_TYPE.MAUDE_PUBMED:
    case RULE_TYPE.LITERATURE_REVIEW_KEY_TAKEAWAY_PUBLIC:
    case RULE_TYPE.LITERATURE_REVIEW_KEY_TAKEAWAY_PRIVATE:
    case RULE_TYPE.LITERATURE_REVIEW_PRIVATE:
    case RULE_TYPE.LITERATURE_REVIEW_PUBLIC:
      return getUiSchema()
    default:
    return defaultSchema
  }
}
export const defaultSchema = {
"ui:order": [
  "rule_type",
  "rule_name",
  "rule",
  "scope",
  "abstract_rule",
  "algorithm_version",
  "database_version",
  "*"
],
  rule_name: {
    'ui:title': 'Rule Name',
  },
  abstract_rule: {
    'ui:title': 'Abstract Rule',
  },
  algorithm_version: {
    'ui:title': 'Algorithm Version',
  },
  database_version: {
    'ui:title': 'Database Version',
  },
  related_version: {
    'ui:title': 'Related Distance',
  },
  rule_type: {
    'ui:autofocus': true,
    'ui:title': 'Rule Type',
    "ui:default": "family-name",
    "ui:disabled": true
  },
  user_id: {
    'ui:title': 'User ID',
    "ui:disabled": true
  }
}
export const defaultAdvanceFields= [
  FIELDS.USER_ID,
  FIELDS.TYPE,
  FIELDS.ABSTRACT_RULE,
  FIELDS.ALGORITHM,
  FIELDS.DATABASE,
  FIELDS.DEBUG,
  FIELDS.RELATED_DISTANCE,
  FIELDS.RULE_TYPE,
  FIELDS.SCOPE
];

export const excludeFormSchema = ['_id','inserted_date','is_deleted','is_anonymous']