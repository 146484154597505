import { Dispatch } from "redux";
import { ActionType } from "state/action-types";
import { deleteToken, generateNewToken, tokens } from "services/token";

export const generateToken = (value: any, setIsDisable:any) => {
  return async (dispatch: Dispatch) => {
    try {
      setIsDisable(true);
      const response = await generateNewToken(value);
      if(response.status == 200) {
        dispatch(updateToken(response.body))
        dispatch(handleToShowPages(false));
        setIsDisable(false);
      } else if(response.status == 409) {
        setIsDisable(false);
        dispatch(updateIsNameExists(true));
        dispatch({
          type:ActionType.SET_TOKEN_ERROR_MESSAGE,
          payload:response.body.error_message
        })
        // dispatch(alert(response.body.error_message));
      }
    } catch(error) {
      setIsDisable(false);
      console.log(error);
    }
  };
};

export const fetchTokens = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: ActionType.IS_SHOW_TOKEN_LOADER,
        payload: true,
      });
      const response = await tokens();
      if (response.status === 200) {
        dispatch({
          type: ActionType.GET_TOKENS,
          payload: response.body.user_tokens,
        });
        dispatch({
          type: ActionType.IS_SHOW_TOKEN_LOADER,
          payload: false,
        });
        dispatch({
          type:ActionType.SET_TOKEN_ERROR_MESSAGE,
          payload:""
        })
      }
    } catch (error) {
      dispatch({
        type: ActionType.IS_SHOW_TOKEN_LOADER,
        payload: false,
      });
      console.log(error);
    }
  };
};


export const deleteTokenAction = (token_id:string) => {
  return async (dispatch: Dispatch) => {
    try {
      const res = await deleteToken(token_id);
      if (res.status === 200) {
        dispatch({
          type: ActionType.DELETE_TOKEN,
          payload: token_id,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const updateToken = (token:any) => {
  return {
    type: ActionType.ADD_TOKEN,
    payload: token,
  };
};
export const updateIsNameExists = (value:boolean) => {
  return {
    type: ActionType.IS_TOKEN_NAME_EXISTS,
    payload: value,
  };
};

export const updateExpiration = (value: any) => {
  console.log(value, "vlaue for expiration time");
  return {
    type: ActionType.SET_EXPIRATION_TIME,
    payload: value,
  };
};

export const handleToShowPages = (value: boolean) => {
  return {
    type: ActionType.UPDATE_PAGES_FOR_TOKEN,
    payload: value
  }
}