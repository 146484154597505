import { get, post, Response } from "makeRequest";

export function generateNewToken(value: any):Response<any>  {
  return post("/generate-access-token", value);
}

export function tokens(): Response<any> {
  return get("/get-user-access-tokens");
}

export function deleteToken(token_id: string): Response<any> {
  return post("/delete-access-token", { token_id });
}
