import moment from "moment";
function getCalenderDate(date: Date): string {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = (date.getFullYear() + "").slice(2);
  return `${month}/${day}/${year}`;
}

export function getTime(date: Date): string {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return `${hours > 12 ? hours : hours}:${
    minutes < 10 ? "0" : ""
    }${minutes} ${hours >= 12 ? "PM" : "AM"}`;
}

function isToday(calenderDate: string): boolean {
  return getCalenderDate(new Date()) === calenderDate;
}

function isYesterday(calenderDate: string): boolean {
  const yesterday = new Date();
  // make it yesterday
  yesterday.setDate(yesterday.getDate() - 1);
  return getCalenderDate(yesterday) === calenderDate;
}

export default function getPrettyDate(date: Date): string {
  const time = getTime(date);
  const calenderDate = getCalenderDate(date);
  if (isToday(calenderDate)) {
    return `Today ${time}`;
  } else if (isYesterday(calenderDate)) {
    return `Yesterday ${time}`;
  } else {
    return `${calenderDate} ${time}`;
  }
}

export function convertSecondsToHoursOrMinutes(
  seconds: any 
  ) {
  try {
    if (seconds == null || seconds === "None" || seconds === "none") {
      return "";
    }

    if (seconds < 1) {
      // If job finished in milliseconds less than second
      const milliseconds = Math.round(seconds * 1000);
      return `${milliseconds} milliseconds`;
    }

    if (seconds < 60) {
     // If job finished in a seconds less than minute
      return `${Math.round(seconds)} seconds`;
    }

    if (seconds < 3600) {
       // If job finished in minutes less than second
      const minutes = Math.floor(seconds / 60);
      return `${minutes} minutes`;
    }

    // if seconds are greater than one hour
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${hours}.${minutes} hrs`;
  } catch (error) {
    console.error("An error occurred:", error);
    return "";
  }
}
// convert date into yyyy/mm/dd format
export function convertDate(str: string) {
  const date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}

export function alanyzerTimestamp(timestamp: number) {
  if(timestamp){
    const date = new Date(timestamp);
    const dateFromat = date.toLocaleString();
    return dateFromat;
  }
  else{
    return ''
  }
}

export  const calculateTimeDifference = (createdAt: string, deliveredAt: string) => {
  const startTime: any = new Date(createdAt);
  const endTime: any = new Date(deliveredAt);
  const timeDiffMilliseconds = endTime - startTime;
  const timeDiffSeconds = timeDiffMilliseconds / 1000;
  return convertSecondsToHoursOrMinutes(timeDiffSeconds)
};
export const getLocalTime = (utcTime:string) => {
  return moment.utc(utcTime,"HH:mm").local().format('HH:mm')

}
export function detectUserTimeZone(utcTime:any) {
  const utcDate = new Date(`1970-01-01T${utcTime}:00Z`);
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const formatter = new Intl.DateTimeFormat('en-US', {
    timeZone: userTimeZone,
    hour: 'numeric',
    minute: 'numeric',
    hour12: false, // Use 24-hour format
  });

  const localTimeStr = formatter.format(utcDate);
  return localTimeStr;
}

export function getUtcTime(hrs:string,mm:string) {
  const currentDate = new Date();
  const startTimeUtc = new Date(
    currentDate.getUTCFullYear(),
    currentDate.getUTCMonth(),
    currentDate.getUTCDate(),
    parseInt(hrs, 10), // Convert to integer
    parseInt(mm, 10), // Convert to integer
    0, // Seconds
    0 // Milliseconds
  );
  const utcHours = startTimeUtc.getUTCHours();
  const utcMinutes = startTimeUtc.getUTCMinutes();
  const formattedUtcHours = utcHours < 10 ? `0${utcHours}` : `${utcHours}`;
  const formattedUtcMinutes = utcMinutes < 10 ? `0${utcMinutes}` : `${utcMinutes}`;

  return `${formattedUtcHours}:${formattedUtcMinutes}`;
}