
import produce from 'immer'
import { ActionType } from '../action-types';
import { Action } from '../actions';

interface ServersStatus {
    are_all_services_available: boolean,
    are_rds_instances_shutting_down: boolean,
    are_rds_instances_starting_up: boolean,
    are_servers_shuting_down: boolean,
    are_servers_starting_up: boolean,
    error: null,
    rdsStatus: Array<{
        engine: string,
        status: string,
    }>,
    results: Array<{ desiredCount?: number, health_status?: string, runningCount?: number, serviceName?: string }>
    status:boolean
}

export const initialState :ServersStatus = {
    are_all_services_available: false,
    are_rds_instances_shutting_down: false,
    are_rds_instances_starting_up: false,
    are_servers_shuting_down: false,
    are_servers_starting_up: false,
    error: null,
    rdsStatus: [],
    results :[],
    status: false
}

const reducer = produce((state:ServersStatus = initialState, action: Action ):ServersStatus | void  => {
    switch (action.type) {
        case ActionType.FETCH_SYSTEM_STATUS_COMPLETED:
            state = action.payload
            return {...state};
        default: 
            return state;
    }    
});

export default reducer;
