
import { ActionType } from "state/action-types";

interface inputField {
	value: string,
	valid: boolean,
	error: string
}

enum passwordResetState {
	START = 'START',
	EMAIL_SENT = 'EMAIL_SENT',
	CREATE_NEW_PASSWORD = 'CREATE_NEW_PASSWORD'
}



export type EmailSettings = {
	unsubscriptionGroups: Array<{
		groupId: number;
		subscribed: boolean;
		name: string;
		description: string
	}>
}

export type UserSettings = {
	chatInterfaceMode: "Regular" | "Minimal" | "Beginner",
	chatSuggestions: boolean;
	hideChatButtons: boolean;
	shouldExpandDiscoveryPanel: boolean;
	email: EmailSettings;
	user_group: Array<string>;
	is_api_enabled: string;
	theme: "light" | "dark";
	chatAgents: Array<string>;
	defaultChatAgent: string;
};


export type User = {
	user_id: string;
	authenticated: boolean;
	internal: boolean;
	isDemoUser: boolean;
	email: string;
	settings: UserSettings;
	role: string;
};
interface AuthState {
	user: User,
	signInEmailData: inputField,
	signInPasswordData: inputField,

	registerEmailData: inputField,
	registerPasswordData: inputField,
	accountToConfirm: {
		email: string,
		password: string
	},

	// check this field
	// verifyEmailData: inputField,

	verifyCodeData: inputField,

	signedIn: boolean,
	signInFormLoading: boolean,
	registerFormLoading: boolean,
	confirmAccountFormLoading: boolean,

	forgotEmailData: inputField,

	resetPasswordCode: inputField,

	resetPasswordData: inputField,


	startPasswordResetLoading: boolean,
	changePasswordLoading: boolean,
	passwordResetState: passwordResetState,
	// signedInUser	: null,
	path: string,
	redirect: boolean,
	appLoaded: boolean,
	group_users_list: Array<any>,
	isGroupLoader: boolean,
	isApiCalled: boolean;
}

export const INITIAL_STATE: AuthState = {

	user: {
		email: "",
		isDemoUser: false,
		internal: false,
		authenticated: false,
		role: 'customer',
		settings: {
			chatInterfaceMode: "Regular",
			chatSuggestions: true,
			hideChatButtons: false,
			shouldExpandDiscoveryPanel: true,
			email: {
				unsubscriptionGroups: []
			},
			user_group: [],
			is_api_enabled: "",
			theme: "light",
			chatAgents: [],
			defaultChatAgent: ''
		},
		user_id: ""
	},
	//Form
	signInEmailData: {
		value: '',
		valid: false,
		error: ""
	},
	signInPasswordData: {
		value: '',
		valid: false,
		error: ""
	},

	registerEmailData: {
		value: '',
		valid: false,
		error: ""
	},
	registerPasswordData: {
		value: '',
		valid: false,
		error: ""
	},

	accountToConfirm: {
		email: "",
		password: "",
	},

	verifyCodeData: {
		value: '',
		valid: false,
		error: ""
	},
	//User
	signedIn: false,
	signInFormLoading: false,
	registerFormLoading: false,
	confirmAccountFormLoading: false,

	// resetPassword

	forgotEmailData: {
		value: '',
		valid: false,
		error: ''
	},

	resetPasswordCode: {
		value: '',
		valid: false,
		error: ''
	},

	resetPasswordData: {
		value: '',
		valid: false,
		error: ''
	},


	startPasswordResetLoading: false,
	changePasswordLoading: false,

	passwordResetState: passwordResetState['START'],

	// signedInUser	: null,
	path: "",
	redirect: false,
	appLoaded: false,
	group_users_list: [],
	isGroupLoader: false,
	isApiCalled: true,
};

export default (state: AuthState = INITIAL_STATE, action: any): AuthState => {
	switch (action.type) {
		case ActionType.UPDATE_THEME:
			return {
				...state,
				user: {
					...state.user,
					settings: {
						...state.user.settings,
						theme: action.payload.theme
					}
				}
			};
		//LOADING EVENTS START HERE
		case ActionType.SIGN_IN_LOADING_CHANGE:
			return { ...state, signInFormLoading: action.payload }
		case ActionType.REGISTER_LOADING_CHANGE:
			return { ...state, registerFormLoading: action.payload }
		case ActionType.CONFIRM_ACCOUNT_FORM_LOADING_CHANGE:
			return { ...state, confirmAccountFormLoading: action.payload }
		case ActionType.STATE_PASSWORD_RESET_LOADING:
			return { ...state, startPasswordResetLoading: action.payload }
		case ActionType.APP_LOADED:
			return { ...state, appLoaded: action.payload }
		case ActionType.CHANGE_PASSWORD_LOADING:
			return { ...state, changePasswordLoading: action.payload }
		// LOADING EVENTS ENDS HERE

		case ActionType.UPDATE_PASSWORD_RESET_STATE:
			return { ...state, passwordResetState: action.payload }
		case ActionType.REDIRECT:
			return { ...state, redirect: true, path: action.payload }
		case ActionType.RESET_REDIRECT:
			return { ...state, path: "", redirect: false }

		// INPUT FIELD CHANGE EVENTS START HERE
		case ActionType.SIGN_IN_EMAIL_CHANGED:
			return { ...state, signInEmailData: action.payload };
		case ActionType.SIGN_IN_PASSWORD_CHANGED:
			return { ...state, signInPasswordData: action.payload };
		case ActionType.VERIFY_CODE_CHANGED:
			return { ...state, verifyCodeData: action.payload };
		case ActionType.ACCOUNT_TO_CONFIRM:
			return { ...state, accountToConfirm: action.payload };
		case ActionType.REGISTER_EMAIL_CHANGED:
			return { ...state, registerEmailData: action.payload };
		case ActionType.REGISTER_PASSWORD_CHANGED:
			return { ...state, registerPasswordData: action.payload };

		case ActionType.FORGOT_EMAIL_CHANGED:
			return { ...state, forgotEmailData: action.payload }
		case ActionType.RESET_PASSWORD_CODE_CHANGE:
			return { ...state, resetPasswordCode: action.payload }
		case ActionType.RESET_PASSWORD_DATA_CHANGE:
			return { ...state, resetPasswordData: action.payload }

		case ActionType.SIGN_IN_USER_SUCCESS:
			return {
				...state,
				signInEmailData: INITIAL_STATE.signInEmailData,
				signInPasswordData: INITIAL_STATE.signInPasswordData,
				signedIn: true,
				user: action.payload,
			};
		case ActionType.SIGN_OUT_USER_SUCCESS:
			return {
				...state,
				signedIn: false,
				user: action.payload
			}
		case ActionType.REGISTER_USER_SUCCESS:
			return {
				...state,
				registerEmailData: INITIAL_STATE.registerEmailData,
				registerPasswordData: INITIAL_STATE.registerPasswordData,
			};
		case ActionType.PASSWORD_RESET_COMPLETED:
			return {
				...state,
				forgotEmailData: INITIAL_STATE.forgotEmailData,
				resetPasswordCode: INITIAL_STATE.resetPasswordCode,
				resetPasswordData: INITIAL_STATE.resetPasswordData
			};
		case ActionType.UPDATE_LOCAL_USER_SETTING:
			return { ...state, user: { ...state.user, settings: action.payload } }

		case ActionType.GET_LIST_OF_USERS_FROM_GROUP:
			return { ...state, group_users_list: action.payload }
		case ActionType.REMOVE_USER_FROM_GROUPS:
			const updatedGroupUsersList = state.group_users_list.filter((user: any) => !action.payload.includes(user.user_id));
			return {
				...state,
				group_users_list: updatedGroupUsersList
			};
		case ActionType.UPDATE_GROUP_LIST_USER_LOADER_ACTION:
			return { ...state, isGroupLoader: action.payload }
		case ActionType.UPDATE_ACTION_TO_CALL_API_ONCE:
			return { ...state, isApiCalled: action.payload }
		default:
			return state;
	}
};
