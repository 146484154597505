import React from "react";

export const supportsStringRender = parseInt((React.version || '16').slice(0, 2), 10) >= 16

export function removeSpecialChars(str:string) {
    return str.replace(/(?!\w|\s)./g, '')
      .replace(/\s+/g, ' ')
      .replace(/^(\s*)([\W\w]*)(\b\s*$)/g, '$2');
  }

export function camelize(str:string) {
    return removeSpecialChars(str).replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
}

export const scrollBarWidth = () => {
    const scrollDiv = document.createElement("div");
    scrollDiv.setAttribute(
      "style",
      "width: 100px; height: 100px; overflow: scroll; position:absolute; top:-9999px;"
    );
    document.body.appendChild(scrollDiv);
    const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    document.body.removeChild(scrollDiv);
    return scrollbarWidth;
};


export const reorder = (
    list: Array<string>,
    startIndex: number,
    endIndex: number
): string[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export const cleanHeaders = (headers:any)=> {
    return headers.map((h: any) => {
        return h.replace(/[^a-zA-Z0-9 ]/g, " ").toLowerCase();
    });
}

// export const cleanHeadersNew = (headers: any): any => {
//     return headers.map((h: string) => {
//       const formattedHeader: string = h.replace(/\s+/g, '').replace(/[/?]/g, '').replace(/^(.)(.*)$/, (_, firstChar, rest) => {
//         return firstChar.toLowerCase() + rest;
//       });
//       return formattedHeader;
//     });
//   };

export const isControlColumn = (accessor:any, up_response:any)=> {
    const annotation_fields = up_response?.data?.output_hints?.annotation_fields;
    const updatedFields = annotation_fields?.map((field:any) => ({
        ...field,
        name: field.name.split(" ").join("")
      }));
    const matchingField = updatedFields?.find((e:any)=> e.name === accessor); 
    return {isControlCol: Boolean(matchingField), controlData: matchingField};
}

export const headerMap: Record<string, string> = {
    "Job Id": "job_id",
    "Memory": "worker_memory",
    "Idle Time": "idle_time",
    "VCPU": "worker_vcpu",
    "GPU": "gpu",
    "Type": "type",
    "Status": "status",
    "Start Date Time": "start_date_time",
    "Execution Timeout": "execution_timeout",
    "Reason": "reason",
    "Action": "action",
    "User Email":"user_email",
    "Utterance":"utterance",
    "Queue Name":"queue",
    "Submitted Time":"submitted_date_time",
    "Job Execution Time":"job_execution_time",
    "Processing Time":"processing_time",
    "Wait Time in Queue":"wait_time_in_queue",
    "Message ID":"message_id",
    "Job ID":"worker_id",
  };
  interface StatusColors {
    [key: string]: string;
  }
  export function getStatusColor(status: string) {
    const statusColors: StatusColors = {
      SUCCEEDED: "#00cc00",
      FAILED: "#ff0000",
      TERMINATED: "#ff0000",
      RUNNING_LISTENING: "#007bff",
      RUNNING_PROCESSING: "#007bff",
      STARTING: "#007bff",
      RUNNING: "#007bff",
      PENDING: "#ffdab9",
      SUBMITTED: "#646464",
    };

    return statusColors[status] || "";
  }
export function getLogsLevelColors(logLevel: string){
  const logLevelColors: StatusColors = {
    INFO: "#00cc00",
    WARNING: "#ffcc00",
    DEBUG: "#3399ff",
    ERROR: "#ff0000",
    CRITICAL: "#ff3300"
  }
  return logLevelColors[logLevel] || "";
}
interface columnName  {
  [key: string]: string;
}
export const keyMap:columnName = {
  "Rule Type": "rule_type",
  "Rule Name":"rule_name",
  "Search Terms":"search",
  "Scope":"scope",
  "Actions":"actions",
}

export type AliasColumnType = {
  [key: string]: any;
  rule_type: string;
  rule_name: string;
  search: string;
  scope: string;

};