export const citatoinColors = {
    dark:{
        citationBtnBGColor: "#292929",
        BtnBorderColor: "#585858",
        TextColor: "#ffffff",
        HoverBgColor: "#4a4a4a",
        ButtonColor: "#0A0A0A",
        PdfBgColor:"rgb(54, 53, 55)",
        ActionButtonColor:"#515151",
        HoverActionButtonColor:"#515151",
        ActionButtonTextcolor:"#ffffff",
        backBtnbgColor:"#0a0a0a",
        DownloadButtonColor:"#515151",
        backBtnborderColor:"#0a0a0a",
        DownloadButtonHoverColor:"#515151",
    },
    light:{
        citationBtnBGColor: "#fffff",
        BtnBorderColor: "#727272",
        TextColor: "#727272",
        HoverBgColor: "#585858",
        ButtonColor: "#ffffff",
        PdfBgColor:"#ffffff",
        ActionButtonColor:"#007aa0",
         HoverActionButtonColor:"#ffffff",
         ActionHoverButtonTextcolor:"#007aa0",
        backBtnbgColor:"#727272",
        DownloadButtonColor:"#007AA0",
        backBtnborderColor:"#585858",
        DownloadButtonHoverColor:"#036c8d",
    }
}