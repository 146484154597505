export const recentlySidebarColors = {
    dark:{
        RecentlyContent: "#323232",
        LeftSideBarBackground: "#FFFFFF",
        QuestionHoverbackground: "#D0D8EB",
        SideBarArrowIcon: "#787878",
        RecentlyIconColor: "#fff",
        HistorySpinnerSidebar: "#2058E8",
        AnaylyzingTextColor: "#FFFFFF",
        RecentlyBorder: "#C8C8C8",
        SpinnerCircleColor: "#FFFFFF",
        SubsciptionNoBorder: "#505050",
        LeftPanelSidebarBackgroundColor: "#FFFFFF",
        RecentlyBackgroundColor: "#799BF1",
        TextColorHistory: "#FFFFFF",
        StroyTextToolTip: "#FFFFFF",
        MainBackgroundWrap: "#3B3D5A",
        RecentlyDateColor: "#787878",
        WrapperBackgroundColor: "#212B36",
    },
    light:{
        RecentlyContent: "#323232",
        LeftSideBarBackground: "#fff",
        QuestionHoverbackground: "#D0D8EB",
        SideBarArrowIcon: "#787878",
        RecentlyIconColor: "#fff",
        HistorySpinnerSidebar: "#2058E8",
        AnaylyzingTextColor: "#787878",
        RecentlyBorder: "#C8C8C8",
        SpinnerCircleColor: "#ffffff",
        SubsciptionNoBorder: "#B4B4B4",
        LeftPanelSidebarBackgroundColor: "#FFFFFF",
        RecentlyBackgroundColor: "#799BF1",
        TextColorHistory: "#FFFFFF",
        StroyTextToolTip: "#323232",
        MainBackgroundWrap: "#E6E6E6",
        RecentlyDateColor: "#787878",
        WrapperBackgroundColor: "#e1e1e1",
    }
}