import { ActionType } from '../action-types';
import * as pptServies from "../../services/ppt"
import * as siteServices from "../../services/settings"
import { Dispatch } from "redux";
import { alert } from './customRouter';
import { PptTemplateProps } from 'state'

export const uploadPptTemplate = (payload: { id: string, file: File }): any => {
    return async (dispatch: Dispatch<any>) => {
        const { status, body } = await pptServies.uploadPptTemplate( payload.file );
        const { success , ...data } = body
        if( status == 200 && success ){
            dispatch({
                type: ActionType.UPLOAD_PPT_TEMPLATE_SUCCESS,
                payload: {
                    id: payload.id,
                    ...data
                }
            })
        }else {
            dispatch({
                type: ActionType.UPLOAD_PPT_TEMPLATE_FAILED,
                payload: {}
            })
        }
        try {
        } catch (err) {
            console.log("Error:", err);
            dispatch(alert("Please try again later (server down!)"))

        }
    }
}


export const updatePptTempalte = (payload: { pptTemplates: PptTemplateProps[] }): any => {
    return async (dispatch: Dispatch<any>, getState: any) => {
        try {
            const id = getState().siteSettings.id;
            const data = { ppt_templates: payload.pptTemplates, action: "ppt_templates" }
            const response = await siteServices.updateSiteSettings(id, data)
        
            if( response.status == 200 && response.body.success ){
                dispatch({
                    type: ActionType.UPDATE_PPT_TEMPLATE_SUCCESS,
                    payload: response.body
                    
                })
                dispatch(alert(response.body?.message, {position: "bottom-left"}, "success"));
            }else {
                dispatch({
                    type: ActionType.UPDATE_PPT_TEMPLATE_FAILED,
                    //@ts-ignore
                    payload: response.body
                })
                dispatch(alert(response.body.error))
            }
            return response.body

        } catch (err) {
            console.log("error=>", err);
        }
    }
}