    import { ActionType } from '../action-types';
    import { Dispatch  } from "redux";
    import { RootState } from "state";
    import { SubscriptionAlertType, addSubscriptionAlert, getSubscribedQuestions, updateSubscriptionAlert } from 'services/subscribeQuestion';
    import { alert } from "./customRouter";
    import { fetchSubscribeQuestion, getSubscribedQuestionvisual, subscribeQuestion, unSubscribeQuestion,fetchSubscribeQuestionMonthDates ,getSelectedQuestion, getSearchResults} from 'services/subscribeQuestion';
    import { addSubscribedQuestionUtterenceID, hydrateAnswerAction, setSubscribedQuestionCurrentState, subscribeCurrentQuestion, updateAnswerState, updateExportInProgress } from './AnswerActions';
    import { updateLoadingSingleHistory, updateUserHistoryVisualByQuestion, updateUserHistoryVisualByUtteranceId } from './UserHistoryActions';
    import { loadingSpinnerForSubscribed } from './WorkflowActions';
    import moment from 'moment';
    import { setLoaderOnSearch } from './UserHistoryActions';
    import { SubscriptionFilters } from 'types/subscriptonHistory';
    import {pptStatus} from 'types/visual';
    import { updateUserChatHistoryVisualByQuestion } from './ChatActions';
    import { disabledButtonAction, setPdfWaveLoaderAction, setpptKey } from './ActionBarActions';

    export const subscribeQuestionAction = (utterance:string,visual_id:string | undefined) => {
      return async (dispatch: Dispatch ,getState: () => RootState) => {
        const state = getState();
        const utteranceId =  state.answer.utteranceId;
        const visual = state.answer.visual;

        const subscriptionList = state.subscriptionHistory.data;
          try {
            subscribeButtonLoading(dispatch,true)
              const  response = await subscribeQuestion(
                  utterance,
                  visual_id,
              )
              if(response.status === 200){
              const  epochDateTimeStamp =  moment(new Date()).valueOf();
                const newList = {
                  _id:response.body.Id,
                  date_time: epochDateTimeStamp,
                  question:utterance,
                  utterance:utterance,
                  thumbnail:response.body.thumbnail
                }
                const newsubscriptionList = subscriptionList.filter((list) => list.utterance !==  utterance)
                newsubscriptionList.unshift(newList);
                dispatch(subscribeCurrentQuestion(true))
                dispatch(addSubscribedQuestionUtterenceID(response.body.Id))
                dispatch(updateUserHistoryVisualByQuestion(utterance,true,response.body.Id));
                dispatch(updateUserChatHistoryVisualByQuestion(utterance,true,response.body.Id));
                updateSubsciptionList(dispatch,newsubscriptionList);
                dispatch(userSubscriptionListIndex(newsubscriptionList.length - 1))
                dispatch(updateUserHistoryVisualByUtteranceId(utteranceId, visual, true))
                dispatch({
                  type:ActionType.UPDATE_SUBSCRIPTION_ALERT_RESPONSE,
                  payload:{
                    id:"",
                    file_type: [],
                    schedule_type: "daily",
                    subscribed_user_timestamp: "",
                    subscription_date: "",
                    utterance_id: ""
                  }
                })
                subscribeButtonLoading(dispatch,false)
              }
              if(response.status === 500){
                dispatch( alert("Question is not update please try again later."));
                subscribeButtonLoading(dispatch,false)
              }
          } catch (error) {
             subscribeButtonLoading(dispatch,false)
             dispatch( alert("Question is not update please try again later."));
          }
      }
  }
  export const updateSubsciptionList = (dispatch:Dispatch,subscriptionList:any) =>{
    dispatch({
      type:ActionType.UPDATE_SUBSCRIPTION_LIST,
      payload:[...subscriptionList]
    })
  }

  export function spinnerOnUnsubscribed(value:boolean){
    return {
        type: ActionType.SPINNER_ON_DELETE,
        payload: value
    }
}
  export const unsubscribeQuestion = (_id: string,question:string,subscription_type?:string) => {
    return async (dispatch: Dispatch ,getState: () => RootState) => {
      const state = getState();

      const subscriptionList = state.subscriptionHistory.data;
      const subscribeListIndex = state.subscriptionHistory.subscriptionListIndex;
      const setIndex = state.subscriptionHistory.setIndex;
      try {
        subscribeButtonLoading(dispatch,true)
        const response = subscription_type ? await unSubscribeQuestion(_id, subscription_type) : await unSubscribeQuestion(_id);
        if (response.status == 200) {

          dispatch(spinnerOnUnsubscribed(false))
            const newList = subscriptionList.filter((list) => list.utterance !==  question)
             if(subscribeListIndex != 0){
              dispatch(userSubscriptionListIndex(subscribeListIndex -1))
            }
            if(subscriptionList.length == 1){
              dispatch(unSubscibeQuestionIndex(undefined));
            }
            if(setIndex){
              dispatch(setSubscribeIndex(-1));
            }
          updateSubsciptionList(dispatch,newList);
          dispatch(subscribeCurrentQuestion(false))
          subscribeButtonLoading(dispatch,false)
          dispatch(updateUserHistoryVisualByQuestion(question,false,undefined));
          dispatch(updateUserChatHistoryVisualByQuestion(question,false,undefined));
          dispatch(addSubscribedQuestionUtterenceID(undefined))
          dispatch({
            type:ActionType.UPDATE_SUBSCRIPTION_ALERT_RESPONSE,
            payload:{
              id:"",
              file_type: [],
              schedule_type: "daily",
              subscribed_user_timestamp: "",
              subscription_date: "",
              utterance_id: ""
            }
          })
        }
      } catch (error) {
        dispatch(alert("question is not unsubscribe please try again later."));
        subscribeButtonLoading(dispatch,false)
      }
    };
  };

  export const unSubscibeQuestionIndex = (index:number|undefined ) =>{
    return {
      type: ActionType.UNSUBSCRIBE_QUESTION_INDEX,
      payload: index,
    }
  }

  export const getSubscribedQuestionsCharts = () => {
    return async (dispatch: Dispatch) => {
      try {
        setDashboardSubscribeQuestionChartLoading(true, dispatch);
         const response = await getSubscribedQuestionvisual();
         if (response.status == 200) {
          dispatch({
            type: ActionType.SET_SUBSCRIBE_QUESTION_CHARTS,
            payload: response.body,
          });
        }
        if(response.status == 500){
          dispatch(alert("Error occur while fetching subscribed utterance"));
        }
        setDashboardSubscribeQuestionChartLoading(false, dispatch);
      } catch (error) {
        setDashboardSubscribeQuestionChartLoading(true, dispatch);
        dispatch(alert("questions is not get please try again later."));
      }
    };
  };

  export const updateSubscribeChartStatus = (value:boolean) =>{
    return {
      type: ActionType.SET_ISSUBSCRIBE,
      payload: value,
    }
  };



    export const updateSubscriptionHistory = (
      question: string,
      utteranceId: string
    ) => {
      return {
        type: ActionType.UPDATE_SUBSRIPTION_HISTORY,
        payload: {
          question: question,
          utteranceId: utteranceId,
        },
      };
    };


    export const getSubscriptionHistory = () => {
      return async (dispatch: Dispatch) => {
        try {
          setLoadingSubscription(true, dispatch);
          const response = await getSubscribedQuestions();
          if (response.status == 200) {
            getSubscriptionResponse(dispatch,true);
            dispatch({
              type: ActionType.SUBSCRIPTION_HISTORY,
              payload: response.body
            });
          }
          if(response.status == 500){
            dispatch(alert("Error occur while fetching subscribed utterance"));
          }
          // dispatch(unSubscibeQuestionIndex(undefined));
          setLoadingSubscription(false, dispatch);
        } catch (error) {
          dispatch(unSubscibeQuestionIndex(undefined))
          setLoadingSubscription(false, dispatch);
          dispatch(
            alert("Could not set loading subscription Please try again later.")
          );
        }
      };
    };

    export const subscribeButtonLoading = (dispatch:Dispatch,loading:boolean) => {
      dispatch({
        type: ActionType.SET_SUBSCRIBE_BUTTON_LOADING,
        payload: loading,
      });
    }

    export const getSubscribedQuestionHistory = async (dispatch: Dispatch) => {
      try {
        
        setLoadingSubscription(true, dispatch);
        const response = await getSubscribedQuestions();
        if (response.status == 200) {
         
          dispatch({
            type: ActionType.SUBSCRIPTION_HISTORY,
            payload: response.body,
          });
        }
        dispatch(unSubscibeQuestionIndex(undefined))
        setLoadingSubscription(false, dispatch);
      } catch (error) {
        setLoadingSubscription(false, dispatch);
        dispatch(unSubscibeQuestionIndex(undefined))
        dispatch(
          alert("Could not update subscription history please try again later.")
        );
      }
    };

    export const getSubscriptionResponse = (dispatch:Dispatch,data:boolean) => {
      dispatch({
        type: ActionType.DISPLAY_SUBSCRIBED_QUESTIONS,
        payload: data,
      });
    }


    export const setDashboardSubscribeQuestionChartLoading = (loading:boolean,dispatch:Dispatch)=>{
        dispatch({
          type: ActionType.SET_DASHBOARD_QUESTION_CHART_LOADING,
          payload:loading,
        })
    }

    export const setLoadingSubscription = async (
      loading: boolean,
      dispatch: Dispatch
    ) => {
      dispatch({
        type: ActionType.SET_LOADING_SUBSCRIPTION_LIST,
        payload: loading,
      });
    };

    export const fetchUserSubscribedQuestion = (subscribed_id:string,type:undefined|string=undefined) =>{
      return async (dispatch: Dispatch ,getState: () => RootState) => {
        try{
          // dispatch(updateAnswerState("Fetch"));
          dispatch(updateLoadingSingleHistory(true));
          const response = await fetchSubscribeQuestion(subscribed_id,type);
          if(response.status === 200){
            const state: RootState = getState();
            const currentItemIndex = state.subscriptionHistory.setIndex;
            const {_id:currentItemId} = state.subscriptionHistory.data[currentItemIndex];
            const visual = response.body.visual;
            const subscribedId = visual.type === 'rmarkdown'
            ? visual.report_subscription?.id|| response.body.subscribed_id : response.body.subscribed_id;
            if(currentItemId===subscribedId){
              const is_pptx = response.body.visual.is_pptx;
              const ppt_path = response.body.visual.ppt_s3_file
  
              if(is_pptx ===pptStatus.FAILED || is_pptx ===false){
                dispatch(updateExportInProgress(false));
              }
              if(is_pptx ===pptStatus.IN_PROGRESS ||is_pptx ===pptStatus.SUBMITTED){
                  dispatch(updateExportInProgress(true));
              }
              if(is_pptx ===pptStatus.SUCCEEDED){
                dispatch(setpptKey(ppt_path));
                  dispatch(updateExportInProgress(false));
              }
  
                const question = response.body.utterance;
                const utteranceId = response.body.utteranceId;
                const subscribed = true;
                const subscribed_id = response.body.subscribed_id;
                const visual = response.body.visual;
                const isHistory = false;
                const time_stamp = response.body.time_stamp;
                const fetch_history = response.body.fetch_history;
                const is_starred = response.body.visual.is_starred;
                const newPdf_status = response.body.visual.pdf_status ? response.body.visual.pdf_status: "";
                dispatch(updateUseSubscribeVisualByUtteranceId(utteranceId, visual, subscribed, subscribed_id, is_starred));
                dispatch(currentQuestionTimestamp(time_stamp));
                dispatch(compareQuestionsSnapshot(time_stamp,time_stamp));
                dispatch(
                  hydrateAnswerAction({
                    question,
                    utteranceId,
                    visual,
                    subscribed,
                    subscribed_id,
                    fetch_history,
                    isHistory,
                    time_stamp,
                    is_starred,
                    pdf_status:newPdf_status,
                    reportExportStatus: [],
                  })
                );
                dispatch({
                  type: ActionType.HYDRATE_ANSWER_COPY ,
                  payload: {
                    question,
                    utteranceId,
                    visual,
                    subscribed,
                    subscribed_id,
                    fetch_history,
                    isHistory,
                    time_stamp,
                    is_starred,
                    pdf_status:newPdf_status,
                    reportExportStatus: [],
                  }
                });
              //  if(){ dispatch(push('/subscription-answer'))}
                dispatch(loadingSpinnerForSubscribed(false));
                if(['in progress', 'submitted'].includes(newPdf_status)){
                  dispatch(disabledButtonAction(true));
                  dispatch(setPdfWaveLoaderAction(true));
                }else{
                    dispatch(disabledButtonAction(false));
                    dispatch(setPdfWaveLoaderAction(false));
                }
                dispatch(setSubscribedQuestionCurrentState("current"));
                dispatch(updateLoadingSingleHistory(false));
                dispatch(updateAnswerState("View"));
            }
           }
           if(response.status == 500){
            dispatch(alert("Error occur while fetching subscribed utterance"));
            dispatch(setSubscribedQuestionCurrentState("current"));
            dispatch(updateLoadingSingleHistory(false));
            dispatch(updateAnswerState("View"));
           }
        }
        catch(error:any){
          dispatch(updateAnswerState("View"));
          dispatch(updateLoadingSingleHistory(false));
          dispatch( alert("Selected answer is not update please try again later."));
        }
      }
    }
    export const updateUseSubscribeVisualByUtteranceId = (utteranceId: string, visual: any,subscribed?:boolean ,subscribed_id?:string,is_starred?:boolean) => {
      return {
          type: ActionType.UPDATE_USER_SUBSCRIPTION_VISUAL_BY_UTTERANCE_ID,
          payload: {
            utteranceId: utteranceId ,
            visual: visual,
            subscribed: subscribed,
            subscribed_id: subscribed_id,
            is_starred: is_starred,
          }
      }
    }
    export const updateCurrentSelectedMonth = (selectedMonth: Date) => {
      return {
        type: ActionType.CURRENT_SELECTED_MONTH,
        payload: selectedMonth
      }
    }

    export const getSubscribedQuestiondate = (question:string,month:any) => {
      return async (dispatch: Dispatch) => {
        try{
          dispatch({
            type:ActionType.SET_SELECTED_MONTH_CHANGED_QUESTION_DATES,
            payload:""
          })
          updateCalendarLoader(dispatch,true);
           const response = await fetchSubscribeQuestionMonthDates(question,convertChangedMonth(month));
           if(response.status === 200){
            dispatch({
              type:ActionType.SET_SELECTED_MONTH_CHANGED_QUESTION_DATES,
              payload:response.body

            })
            dispatch(updateCurrentSelectedMonth(month));
            updateCalendarLoader(dispatch,false);
           }
           if(response.status === 500){
            dispatch( alert("Time line is not update please try again later."));
            updateCalendarLoader(dispatch,false);
           }
        }
        catch(err){
          updateCalendarLoader(dispatch,false);
          dispatch( alert("Time line is not update please try again later."));
        }
      }

    }

    export const updateCalendarLoader = (dispatch:Dispatch,loader:boolean) =>{
      dispatch({
        type:ActionType.SET_CALENDAR_LOADING,
        payload:loader,
      })
    }
    export const openCalendar = (value:string | undefined) =>{
      return{
        type:ActionType.SHOW_CALENDAR,
        payload:value
      }
    }

      export const getSelectedDateQuestion = (_id:string,timestamp:number) =>{
        return async (dispatch: Dispatch,getState: () => RootState) => {
          const state = getState();
          const subscribed_id = state.answer.subscribedQuestionUtterenceId;
          const fetch_history = state.answer.fetch_history;
          const calendarType = state.subscriptionHistory.calendarOpen;
          const  leftQuestionDate = state.subscriptionHistory.compareQuestionDates.leftQuestionDate;
          const  rightQuestionDate =state.subscriptionHistory.compareQuestionDates.rightQuestionDate;
          const  leftQuestionSnapshot = state.subscriptionHistory.compareQuestionSelectedSnapshot.leftQuestionSnapshot;
          const  rightQuestionnapshot =state.subscriptionHistory.compareQuestionSelectedSnapshot.rightQuestionSnapshot;
          try{
            if(calendarType == "TIMELINE"){
              answerChartLoading(dispatch,true);
            } else if(calendarType == "LEFTSIDE_QUESTION_TIMELINE"){
             dispatch(questionLeftSideLoading(true));
            }else{
              dispatch(questionRightSideLoading(true));
            }
          
          const response = await getSelectedQuestion(_id);
          const question = response.body.utterance;
          const utteranceId = response.body.utteranceId;
          const subscribed = true;
          const visual = response.body.visual;
          const isHistory = false;
          const time_stamp = response.body.time_stamp;
          const is_starred = response.body.visual.is_starred;
          if(response.status === 200){
            if(calendarType == "LEFTSIDE_QUESTION_TIMELINE"){
            dispatch(updateLeftQuestionVisual(visual));
            dispatch(questionLeftSideLoading(false));
            dispatch(compareQuestionsDate(timestamp,rightQuestionDate))
            dispatch(compareQuestionsSnapshot(timestamp,rightQuestionnapshot))
            }else if(calendarType == "RIGHTSIDE_QUESTION_TIMELINE"){
            dispatch(updateRightQuestionVisual(visual));
            dispatch(questionRightSideLoading(false));
            dispatch(compareQuestionsDate(leftQuestionDate,timestamp))
            dispatch(compareQuestionsSnapshot( leftQuestionSnapshot,timestamp))
            }
            else{
           dispatch(currentQuestionTimestamp(timestamp));
            dispatch(hydrateAnswerAction({
              question,
              utteranceId,
              subscribed,
              subscribed_id,
              fetch_history,
              visual,
              isHistory,
              time_stamp,
              is_starred
              }));
            answerChartLoading(dispatch,false);
            }
          }
          if(response.status === 500){
            dispatch( alert("Question is not update please try again later."));
            answerChartLoading(dispatch,false);
            dispatch(questionRightSideLoading(false));
            dispatch(questionLeftSideLoading(false));
          }
          }
          catch(err){
            answerChartLoading(dispatch,false);
            dispatch(questionRightSideLoading(false));
            dispatch(questionLeftSideLoading(false));

            dispatch( alert("Question is not update please try again later."));
            console.log(err)
          }
        }
      }

      export const answerChartLoading = (dispatch:Dispatch,loading:boolean) =>{
        dispatch({
          type:ActionType.CHART_LOADING,
          payload:loading,
        })

      }

   export const currentQuestionTimestamp = ( timeStamp: number |undefined) => {
        return{
          type:ActionType.CURRENT_QUESTIION_TIMESTAMP,
          payload: timeStamp
        }
      } 

    export const compareQuestions = (value:boolean) =>{
      return{
        type:ActionType.COMPARE_QUESTIONS,
        payload:value
      }
    }

    export const updateLeftQuestionVisual = (value:any) =>{
      return{
        type:ActionType.LEFT_QUESTION_VISUAL,
        payload:value
      }
    }

    export const updateRightQuestionVisual = (value:any) =>{
      return{
        type:ActionType.RIGHT_QUESTION_VISUAL,
        payload:value
      }
    }
    export const questionLeftSideLoading = (loading:boolean) =>{
      return{
        type:ActionType.LEFT_QUESTION_LOADING,
        payload:loading
      }
    }
    export const questionRightSideLoading = (loading:boolean) =>{
      return{
        type:ActionType.RIGHT_QUESTION_LOADING,
        payload:loading
      }
    }
    export const compareQuestionsDate =(leftQuestionDate: number| undefined,
      rightQuestionDate: number|undefined,) =>{
        return{
          type:ActionType.COMPARE_QUESTION_DATE,
          payload:{
            leftQuestionDate:leftQuestionDate,
            rightQuestionDate:rightQuestionDate,
          }
        }
          }
    export const compareQuestionsSnapshot =(leftQuestionSnapshot: number| undefined,
      rightQuestionSnapshot: number|undefined,) =>{
        return{
          type:ActionType.COMPARE_QUESTION_SNAPSHOTS,
          payload:{
            leftQuestionSnapshot:leftQuestionSnapshot,
            rightQuestionSnapshot:rightQuestionSnapshot,
          }
        }
          }

      export  const userSubscriptionListIndex = (index:number) =>{
            return{
              type:ActionType.USER_SUBSCIPTION_LIST_INDEX,
              payload:index,
            }
          }
    export function convertChangedMonth(str:any) {
      const date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2);
      return [date.getFullYear(), mnth].join("-");
    }
    export function subscriptionFilters({searchValue, sortKey}:SubscriptionFilters){
      return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const state = getState();
        const fetchSortKey = state.subscriptionHistory.setSortKey;
        const fetchSearchValue = state.subscriptionHistory.searchValue;
        try {
          dispatch(errorOnSearch(false));
          dispatch(setLoaderOnSearch(true));
          if(!sortKey){
            sortKey = fetchSortKey;
          }
          if(!searchValue){
            searchValue = fetchSearchValue;
          }
          const response = await getSearchResults({searchValue, sortKey});
          if(response.status == 200){
            dispatch(updateSubscriptionData(response.body))
            dispatch(setLoaderOnSearch(false));
          }

        }catch(error){
          console.log(error);
          dispatch(errorOnSearch(true));
          dispatch(updateSubscriptionData([]))
          dispatch(alert("Please try again"));
        }
      }
    }

    export function errorOnSearch(value:boolean){
        return{
          type:ActionType.ERROR_SUBSCRIPTION_SEARCH,
          payload:value
        }
    }

    export function updateSubscriptionData(data:object){

      return {
        type: ActionType.UPDATE_SUBSCRIPTION_DATA,
        payload: data
      }
    }

    export function storeSearchValue(value:string){
      return{
        type: ActionType.UPDATE_SEARCH_VALUE,
        payload: value
      }
    }
    export function storeSortKey(value:string){
      return{
        type: ActionType.UPDATE_SORT_VALUE,
        payload: value
      }
    }

    export function clearSearchInput(){
      return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const state = getState();
        const sortKey = state.subscriptionHistory.setSortKey;
        const searchValue = state.subscriptionHistory.searchValue;

        dispatch(subscriptionFilters({searchValue,sortKey}))
      }
    }

  export const setSubscribeIndex = (index:number)=>{
    return ({
      type: ActionType.SET_QUESTION_INDEX,
      payload: index,
    })
  }

export const reportSubscriptionAlertLoaderAction = (alertValue: string) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: ActionType.UPDATE_REPORT_SUBSCRIPTION_ALERT_LOADER,
      payload: alertValue
    })
    if(alertValue==="Saved!"){
      setTimeout(()=>dispatch({
        type: ActionType.UPDATE_REPORT_SUBSCRIPTION_ALERT_LOADER,
        payload: ""
      }),1500)
    }
  }
}

export const showDateAction = (dateValue: string) => {
  return {
    type: ActionType.SHOW_REPORT_SUBSCRIBED_DATE,
    payload: dateValue
  }
}

export const toggleExportAction = (iconName: string) => {
  return {
    type: ActionType.TOGGLE_SUBSCRIBED_REPORT_ICON,
    payload: iconName
  };
};

export const subscriptionAlertAction = (payloadData: SubscriptionAlertType, setOpenSubscriptionBox: (e: any) => void) => {
  return async (dispatch: Dispatch<any>,getState:any) => {
    try {
      dispatch(reportSubscriptionAlertLoaderAction("Saving your changes..."));
      const response = await addSubscriptionAlert(payloadData);

      if (response.status == 200) {
        const subscriptionList = getState().subscriptionHistory.data;
        const visual = getState().answer.visual;
        const  epochDateTimeStamp =  moment(new Date()).valueOf();
        const newList = {
          _id:response.body.subscription.id,
          date_time: epochDateTimeStamp,
          question:payloadData.utterance,
          utterance:payloadData.utterance,
          thumbnail:response.body.subscription.thumbnail,
          type: "report_alert"
        }
        const newsubscriptionList = subscriptionList.filter((list:any) => list.utterance !==  payloadData.utterance)
        newsubscriptionList.unshift(newList);
        dispatch(subscribeCurrentQuestion(true))
        updateSubsciptionList(dispatch,newsubscriptionList);
        dispatch(userSubscriptionListIndex(newsubscriptionList.length - 1))
        dispatch(updateUserHistoryVisualByQuestion(payloadData.utterance,true,response.body.subscription.id));
        dispatch(updateUserChatHistoryVisualByQuestion(payloadData.utterance,true,response.body.subscription.id));
        updateSubsciptionList(dispatch,newsubscriptionList);
        dispatch(userSubscriptionListIndex(newsubscriptionList.length - 1))
        dispatch(updateUserHistoryVisualByUtteranceId((payloadData.id as string), visual, true))
        setOpenSubscriptionBox(false);
        dispatch({
          type: ActionType.UPDATE_SUBSCRIPTION_ALERT_RESPONSE,
          payload: response.body.subscription
        })
        dispatch({
          type: ActionType.UPDATE_SUBSCRIPTON_STATUS,
          payload: response.body.status
        })
        dispatch(reportSubscriptionAlertLoaderAction("Saved!"));
        dispatch(addSubscribedQuestionUtterenceID(response.body.subscription.id))
        if(response.body.message){
          dispatch(alert(`${response.body.message}`,  { position: "bottom-left" },"success"));
        } else{
          dispatch(alert(`${response.body.error}`,  { position: "bottom-left" },"error"));
        }
      } else {
        dispatch(alert(response.body.error));
        dispatch(reportSubscriptionAlertLoaderAction("Saved!"));
      }
    } catch (error) {
      dispatch(reportSubscriptionAlertLoaderAction("Saved!"));
      console.log(error);
    }
  }
}
export const updateSubscriptionAlertAction = (payloadData: SubscriptionAlertType, setOpenSubscriptionBox: (e: any)=> void) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      subscribeButtonLoading(dispatch,true);
      dispatch(reportSubscriptionAlertLoaderAction("Saving your changes..."));
      const response = await updateSubscriptionAlert(payloadData);
      if (response.status == 200) {
        setOpenSubscriptionBox(false);
        dispatch(addSubscribedQuestionUtterenceID(response.body.subscription.id))
        dispatch({
          type: ActionType.UPDATE_SUBSCRIPTION_ALERT_RESPONSE,
          payload: response.body.subscription
        });
        dispatch({
          type: ActionType.UPDATE_SUBSCRIPTON_STATUS,
          payload: response.body.status
        });
        dispatch({
          type:ActionType.TOGGLE_SUBSCRIPTION_UPDATE_BUTTON,
          payload:false,
        })
        dispatch(reportSubscriptionAlertLoaderAction("Saved!"));
        subscribeButtonLoading(dispatch,false);
        if(response.body.message){
          dispatch(alert(`${response.body.message}`,  { position: "bottom-left" },"success"));
        } else{
          dispatch(alert(`${response.body.error}`,  { position: "bottom-left" },"error"));
          subscribeButtonLoading(dispatch,false);
        }
      } else {
        dispatch(alert(response.body.error));
        subscribeButtonLoading(dispatch,false);
        dispatch(reportSubscriptionAlertLoaderAction("Saved!"));
      }
    } catch (error) {
      console.log(error);
      subscribeButtonLoading(dispatch,false);
      dispatch(reportSubscriptionAlertLoaderAction("Saved!"));
    }
  }
}

export const toggleUpdateSubscriptionButton = (isShow:boolean) =>{
   return{
    type:ActionType.TOGGLE_SUBSCRIPTION_UPDATE_BUTTON,
    payload:isShow,
  }

}