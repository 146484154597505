import React , { useEffect } from 'react';
import styled from "styled-components";
import { useDispatch } from 'react-redux';
import HumaLogoIcon from 'assets/dynamicSvg/HumaLogo';

//alert styled component There is a new version available. fixed position bottom centered with a button to reload the page.
const DivContainer = styled.div`
  width: 380px;
  height: 59px;
  border-radius: 3px;
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  right: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.FrontReloadBackgroundColor};
  border: 1px solid ${({ theme }) => theme.ActionBarBorderColor};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`;

const Content = styled.p`
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 18px;
padding: 0px 10px;
color: ${({ theme }) => theme.ActionBarTextColor};
margin: 0px;
`;

//styling for bulue update button bordered cornors
const Button = styled.button`
  border: 1px solid ${({ theme }) => theme.ActionBarBorderColor};
  border-radius: 10px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  background-color: #007BA2;
  color: #fff;
  cursor: pointer;
`;

type Props = {
  text?: string,
  buttonText?: string,
  type: string,
  onClick?: () => void,
}


const ServiceWorkerAlert = ({ text, buttonText, type, onClick }: Props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!onClick) {
      const timer = setTimeout(() => {
        dispatch({ type });
      }, 6000);
      return () => clearTimeout(timer);
    }
  }, []);

  return (
    <DivContainer className="alert">
      <HumaLogoIcon width="30" height="30" />
     <Content>{text}</Content>  {buttonText && <Button onClick={onClick}>{buttonText}</Button>}
    </DivContainer>
  );
};

export default ServiceWorkerAlert;
