import { get, post, Response, delete_req } from "makeRequest";
import { getParameter, WebHookData, webhookEvent, WebhookObj } from "types/developer";

export function updateWebHookRequest(id:string , value: WebHookData): Response<{ status: boolean }> {
    return post(`/update/${id}/webhook`, value);
}

export function getWebHooks(): Response<getParameter> {
    return get("/get-webhook");
}

export function deleteWebHookRequest(_id: string): Response<string> {
    return delete_req(`/delete/${_id}/webhook`);
}

export function createWebHookRequest(value: WebHookData) {
    return post("/create-webhook", value);
}

export function getWebHookByidRequest(id: string): Response<getParameter> {
    return get(`/get/${id}/webhook`);
}


export function webhookEvents(user_id?: string, page?:number, limit?: number): Response<{
    total_records: number;"events":webhookEvent
}> {
    const path = user_id && user_id.length ? `/webhook-events?filtered_user=${user_id}&page=${page}&limit=${limit}` : `/webhook-events?page=${page}&limit=${limit}`;
    return get(path);
}

//webhook event search requests

export  function getSearchData (searchQuery:string, user_id: string, page?: number, limit?: number){
    const path = user_id && user_id.length ? `/get-query-webhook-suggestion?search_query=${searchQuery}&filtered_user=${user_id}&page=${page}&limit=${limit}` : `/get-query-webhook-suggestion?search_query=${searchQuery}&page=${page}&limit=${limit}`;
    return get(path);
}

export  function getSuggestions (header:string , query:string, user_id: string){
    const path = user_id && user_id.length ? `/get-webhook-suggestion?header=${header}&query=${query}&filtered_user=${user_id}` : `/get-webhook-suggestion?header=${header}&query=${query}`;
    return get(path);
}
export  function getFilterSuggestion (data:any, page: number, limit: number){
    data.page = page;
    data.limit = limit;
    return  post(`/get-condition-header-data` , data);
}

export function fetchAndUpdateWebhook(data: WebhookObj){
    return post("/edit-webhook-state",data);
}
export function fetchRebuildIndexed(): Response<{status: boolean, error: string}>{
    return get("/rebuild-webhook-index");
}