import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export const toasterMessage = (type:string,message:any, options={}) => {
    if(type == "error" ) return toast.error(message,options)
    if(type == "success") return toast.success(message, options)
    if(type == "info") return toast.info(message, options)
    if(type == "warning") return toast.warning(message, options)
}

