import { ActionType } from '../action-types';
import { Dispatch  } from "redux";
import { fetchSuggestions } from "services/autosuggest";
import { updateAnswerState, setSubscribedQuestionCurrentState, handleReadMoreToggle, updateExportInProgress, tableSliderData } from './AnswerActions';
import { generateAnswer, getQuickLinks, loadNewSuggestions , generateAnswerQueryEditor, getReportViewerUrl } from "services/answer";
import { hydrateAnswerAction , prevAskFailed , clearAnswer} from './AnswerActions';
import { addHistoryEntry, updateLoadingSingleHistory } from  './UserHistoryActions';
import { alert , setInfoAlert , push } from './customRouter';
import { updateCurrentUserContext , updateQuickLinksCategories , updateQuickLinkLoading, handleCompareAnswer, showQuickLinkContext } from "./QuickLinksActions";
import { getLatestUserContext } from "services/usercontext";
import { CUSTOMER_ID } from 'config';
import { compareQuestions, compareQuestionsSnapshot, currentQuestionTimestamp, openCalendar, questionRightSideLoading } from './SubscriptionHistoryAction';
import { RootState } from 'state/reducers';
import { convertDate } from 'utils/getPrettyDate';
import moment from 'moment';
import { changeDateFormat } from 'utils/ConvertDate';
import { pptStatus } from "types/visual";
import { setpptKey } from './ActionBarActions';
import { nanoid } from "nanoid";
import { askQuestionSetIntoChat } from 'services/EditQuickLinkDashboard';

export const loadSearchSuggestions = ()=>{
    return async(dispatch: Dispatch) => {
        try{
            const response = await loadNewSuggestions();
            if(response.status === 200){
               dispatch({
                   type:ActionType.ADD_SEARCH_SUGGESTION,
                   payload:response.body
            })
            }else{
                dispatch(alert("Error occur while fetching sugggestions"));
            }
        }
        catch(err){
            dispatch(alert("Error occur while fetching sugggestions"));
        }
    }
}

const loadSuggestions = (suggestionsContent: Array<string>) => {
    return {
        type: ActionType.ADD_SEARCH_SUGGESTION ,
        payload: suggestionsContent
    }
}

export const clearSuggestions = () => {
    return {
        type: ActionType.CLEAR_SUGGESTIONS
    }
}

export const blurQuestionInput = () => {
    return {
        type: ActionType.BLUE_QUESTION_INPUT
    }
}

export const focusQuestionInput = () => {
    return {
        type: ActionType.FOCUS_QUESTION_INPUT
    }
}

export const resetQuestionInput = () => {
    return {
        type: ActionType.RESET_QUESTION_INPUT
    }
}

export const hydrateQuestionInput = (input: string) => {
    return {
        type: ActionType.HYDRATE_QUESTION_INPUT ,
        payload: {
            input: input,
            recognizingFragment: "",
            hasSpeech: false,
            hasSpeechRecently: false,
          }
    }
}

export const hydrateCopyQuestionInput = (input: string) => {
    return {
        type: ActionType.HYDRATE_COPY_QUESTION_INPUT ,
        payload: {
            input: input,
            recognizingFragment: "",
            hasSpeech: false,
            hasSpeechRecently: false,
          }
    }
}

export const setQuestionInputPlaceholder = (placeholder: string) => {
    return { 
        type: ActionType.SET_QUESTION_INPUT_PLACEHOLDER,
        payload: placeholder
    }
}

export const streamRecognizingFragment = (fragment: string) => {
    return {
        type: ActionType.STREAM_RECOGNIZING_FRAGMENT,
        payload: fragment
    }
}

export const streamSpeechInput = (speechInput: string) => {
    return (dispatch: Dispatch , getState: () => RootState) => {
        const input = getState().questionBar.questionInput.input

        dispatch({
            type: ActionType.STREAM_SPEECH_INPUT,
            payload: {
                hasSpeech: true,
                hasSpeechRecently: false,
                recognizingFragment: "",
                input: input + speechInput,
                }
        })
    }
}

export const streamSpeechInputFromCommandMode = (speechInput: string) => {
    return {
        type: ActionType.STREAM_SPEECH_INPUT_FROM_COMMAND_MODE,
        payload: {
                  hasSpeech: true,
                  hasSpeechRecently: false,
                  input: speechInput,
                  recognizingFragment: "",
                }
    }
}

export const fetchSuggestionsFunc = (term:string) => {
    return async (dispatch: Dispatch) => {
        const q_input = term;
        try {
            const response = await fetchSuggestions(q_input);
            if (response.status === 200) {
                if(response.body.length !=0){
                    dispatch(loadSuggestions(response.body));
                    dispatch(focusQuestionInput());
                }else{
                    dispatch(blurQuestionInput());
                }
            }
            else {
                dispatch(clearSuggestions());
            }
        } catch (error) {
            console.log('Error occur while fetching sugggestions',error)
        }
    }
}

export const streamQuestionInput = (input: string) => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: ActionType.STREAM_QUESTION_INPUT ,
            payload: input
        });
        if(!input) {
            dispatch(clearSuggestions());
            return
        }
    }
}

export const handleInternalCommand = (dispatch: Dispatch , question: string): boolean => {
        // question maybe a special internal command
        const command = question
          .toLowerCase()
          .trim()
          .replace(".", "");

        if (command === "hide logo") {
          localStorage.setItem("hideLogo", "");
          dispatch({
             type: ActionType.UPDATE_HIDE_LOGO ,
             payload: true
         })

          return true;
        } else if (command === "show logo") {
          localStorage.removeItem("hideLogo");
          dispatch({
            type: ActionType.UPDATE_HIDE_LOGO ,
            payload: false
          });

          return true;
        } else if (command === "home" || command === "back" || command === "bach") {
          dispatch(clearAnswer()); 
          return true;
        }

    return false;
}


export const askQuestionQueryEditorAction = (question: string , data: any, context = { quickLink: [] } , preventVisualUpdate = false) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState();
        const readMoreLess = state.answer.readMoreLessToggle;

        dispatch(handleReadMoreToggle(readMoreLess));
        dispatch(updateSearchSuggestionState(false));
        dispatch(resetQuestionInput());
        dispatch(clearSuggestions());
        dispatch(setSubscribedQuestionCurrentState("current"));
        dispatch(tableSliderData([]));

        if (handleInternalCommand(dispatch, question)) {
        return;
        }

        if (!preventVisualUpdate) {
            dispatch(updateAnswerState('Fetch'));
        }

        try {
            const userEmail = getState().auth.user.email;
            dispatch(openCalendar(undefined));
            dispatch(compareQuestions(false));
            dispatch(currentQuestionTimestamp(undefined));
            dispatch(questionRightSideLoading(true));
            dispatch(handleCompareAnswer(false));
            const response = await generateAnswerQueryEditor(question, data, userEmail, context);

            if(response.status === 200) {
                dispatch(updateLoadingSingleHistory(false));

                dispatch(questionRightSideLoading(false));
                const answer:any = response.body;
                const { utterance, utteranceId, visual, subscribed, fetch_history, time_stamp, subscribed_id } = response.body;

                const is_pptx = (visual as any).ppt_status;
                const ppt_path = (visual as any).ppt_s3_file;

                    if(is_pptx ===pptStatus.FAILED || is_pptx ===false){
                        dispatch(updateExportInProgress(false));
                    }
                    if(is_pptx ===pptStatus.IN_PROGRESS || is_pptx ===pptStatus.SUBMITTED){
                        dispatch(updateExportInProgress(true));
                    }
                    if(is_pptx ===pptStatus.SUCCEEDED){
                        dispatch(setpptKey(ppt_path));
                        dispatch(updateExportInProgress(false));
                    }

                const isHistory = false;

                if ("thumbnail" in visual) {
                    answer['thumbnail'] = visual['thumbnail']
                } else {
                    answer['thumbnail'] = ""
                }

                dispatch(currentQuestionTimestamp(time_stamp));
                dispatch(compareQuestionsSnapshot(time_stamp,time_stamp));

                if (!preventVisualUpdate) {
                  dispatch(
                    hydrateAnswerAction({
                      question: utterance,
                      utteranceId,
                      visual,
                      subscribed,
                      subscribed_id,
                      fetch_history,
                      isHistory,
                      time_stamp,
                    })
                  );

                  dispatch({
                    type: ActionType.HYDRATE_ANSWER_COPY,
                    payload: {
                      question,
                      utteranceId,
                      subscribed,
                      subscribed_id,
                      visual,
                      isHistory,
                    }
                  });
                }
                dispatch({
                    type: ActionType.UPDATE_NEWTABLE_HAS_MORE,
                    payload: true
                });
                let historyDate = [...state.userHistory.date];
                const currentDate = new Date();
                const  epochDateTimeStamp =  moment(currentDate).unix();

                const convertIntoSring = currentDate.toString();
                const formatDate = convertDate(convertIntoSring);
                const checkDateInHistory =  historyDate.find(element => changeDateFormat(element) == formatDate);
                if (!checkDateInHistory) {
                  historyDate.unshift(epochDateTimeStamp);
                  historyDate = [...historyDate];
                  dispatch({
                    type: ActionType.UPDATE_HISTORY_DATE,
                    payload: historyDate,
                  });
                }
                dispatch(addHistoryEntry(answer));

                if(answer.event && answer.event.length > 0) {
                    answer.event.forEach((event:string) => {
                        if(event == "update_quicklink") {
                            getQuickLinkss(dispatch);
                        } else if(event == "go_home"){
                            dispatch(clearAnswer());
                        }
                    });
                }

                if(answer.up_response && answer.up_response.event && answer.up_response.event.length > 0 ) {
                    // runWorkFlow()
                }
            }
            else if(response.status===500){
                dispatch(showQuickLinkContext())
                dispatch(push('/'));
            }
            else {
               dispatch(prevAskFailed());
               dispatch(alert(response.body.message))
            }

        } catch (error) {
            dispatch(prevAskFailed());
            dispatch(setInfoAlert("The system is working on your answer, please check back later"));
        }
            dispatch(updateAnswerState('View'));
    }
}

export const showQuestionTitleOnEmptyScreenAction = (question: string) => {
    return {
        type: ActionType.UPDATE_QUESTION_TITLE_FOR_EMPTY_SCREEN,
        payload: question
    }
}

export const askQuestionAction = (question: string , context = { quickLink: [] } , preventVisualUpdate = false) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState();
        const readMoreLess = state.answer.readMoreLessToggle;
        dispatch(showQuestionTitleOnEmptyScreenAction(question));
        dispatch(handleReadMoreToggle(readMoreLess));
        dispatch(updateSearchSuggestionState(false));
        dispatch(resetQuestionInput());
        dispatch(clearSuggestions());
        dispatch(setSubscribedQuestionCurrentState("current"));
        dispatch(tableSliderData([]));

        if (handleInternalCommand(dispatch, question)) {
        return;
        }

        if (!preventVisualUpdate) {
            dispatch(updateAnswerState('Fetch'));
        }

        try {
            const userEmail = getState().auth.user.email;
            dispatch(openCalendar(undefined));
            dispatch(compareQuestions(false));
            dispatch(currentQuestionTimestamp(undefined));
            dispatch(questionRightSideLoading(true));
            dispatch(handleCompareAnswer(false));
            const uId = nanoid();
            dispatch({
                type: ActionType.UPDATE_UUID_CASE,
                payload: uId
            })
            const response = await generateAnswer(question, userEmail, context, uId);
            dispatch(updateAnswerState('View'));
            if(response.status === 200) {
                dispatch(updateLoadingSingleHistory(false));

                dispatch(questionRightSideLoading(false));
                const answer:any = response.body;
                const { utterance, utteranceId, visual, subscribed, fetch_history, time_stamp, subscribed_id } = response.body;
                const is_pptx = (visual as any).ppt_status;
                const ppt_path = (visual as any).ppt_s3_file;

                    if(is_pptx ===pptStatus.FAILED || is_pptx ===false){
                        dispatch(updateExportInProgress(false));
                    }
                    if(is_pptx ===pptStatus.IN_PROGRESS || is_pptx ===pptStatus.SUBMITTED){
                        dispatch(updateExportInProgress(true));
                    }
                    if(is_pptx ===pptStatus.SUCCEEDED){
                        dispatch(setpptKey(ppt_path));
                        dispatch(updateExportInProgress(false));
                    }

                const isHistory = false;

                if ("thumbnail" in visual) {
                    answer['thumbnail'] = visual['thumbnail']
                } else {
                    answer['thumbnail'] = ""
                }

                dispatch(currentQuestionTimestamp(time_stamp));
                dispatch(compareQuestionsSnapshot(time_stamp,time_stamp));
                const page = getState().questionBar.current_type;
                if (!preventVisualUpdate&&page!=="subscription"&&page!=="favorite"&&page!=="workflow"&&page!=="chat") {
                  dispatch(
                    hydrateAnswerAction({
                      question: utterance,
                      utteranceId,
                      visual,
                      subscribed,
                      subscribed_id,
                      fetch_history,
                      isHistory,
                      time_stamp,
                    })
                  );

                  dispatch({
                    type: ActionType.HYDRATE_ANSWER_COPY,
                    payload: {
                      question,
                      utteranceId,
                      subscribed,
                      subscribed_id,
                      visual,
                      isHistory,
                    }
                  });
                }
                dispatch({	
                    type: ActionType.UPDATE_NEWTABLE_HAS_MORE,	
                    payload: true	
                });
                let historyDate = [...state.userHistory.date];
                const currentDate = new Date();
                const  epochDateTimeStamp =  moment(currentDate).unix();

                const convertIntoSring = currentDate.toString();
                const formatDate = convertDate(convertIntoSring);
                const checkDateInHistory =  historyDate.find(element => changeDateFormat(element) == formatDate);
                if (!checkDateInHistory) {
                  historyDate.unshift(epochDateTimeStamp);
                  historyDate = [...historyDate];
                  dispatch({
                    type: ActionType.UPDATE_HISTORY_DATE,
                    payload: historyDate,
                  });
                }
                dispatch(addHistoryEntry(answer));

                if(answer.event && answer.event.length > 0) {
                    answer.event.forEach((event:string) => {
                        if(event == "update_quicklink") {
                            getQuickLinkss(dispatch);
                        } else if(event == "go_home"){
                            dispatch(clearAnswer());
                        }
                    });
                }
            }
            else if(response.status===500){
                dispatch(showQuickLinkContext())
                dispatch(push('/'));
            }
            else {
               dispatch(prevAskFailed());
               dispatch(alert(response.body.message))
            }

        } catch (error) {
            dispatch(prevAskFailed());
            dispatch(setInfoAlert("The system is working on your answer, please check back later"));
        }
            dispatch(updateAnswerState('View'));
    }
}

 export const  updateSearchSuggestionState = (value:boolean) =>{
    return {
        type:ActionType.OPEN_SEARCH_SUGGESTION,
        payload:value,
    }
}

async function  getQuickLinkss(dispatch: any) {
    dispatch(updateQuickLinkLoading(true))
    const res = await getLatestUserContext();
    if(res.status === 200) {
        if (CUSTOMER_ID == '009' || CUSTOMER_ID == '018') {
            dispatch (updateCurrentUserContext(res.body['therapy_area']) );
        } else if (CUSTOMER_ID == '022') {
            dispatch (updateCurrentUserContext(res.body['intervention_area']) );
        } else if (CUSTOMER_ID == '021') {
            dispatch (updateCurrentUserContext(res.body['drugs']) );
        }
        const response = await getQuickLinks();
        if (response.status === 200) {
            dispatch(updateQuickLinkLoading(false))
           dispatch(updateQuickLinksCategories(response.body.categories))
          }
    }
}

export const changeTopNavType = (data: string) =>{

    return {
         type: ActionType.CHANGE_TOP_NAV_TYPE ,
         payload: data
     }
}

 export const showAnswerOnSubscribedAnswer = (value : boolean) => {
    return {
        type: ActionType.SHOW_ANSWER_ON_SUBSCRIBED_ANSWER,
        payload: value
    }
}

export const subscribedReportAlertViewerAction = (export_id: string | null, setPreSignedUrl: (e: any) => void) => {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(handleReportViewLoaderAction(true));
            const response = await getReportViewerUrl(export_id);
            if(response.status === 200){
                setPreSignedUrl(response.body.presigned_url);
                dispatch(handleReportViewLoaderAction(false));
            }else{
                dispatch(alert(response.body.error));
                dispatch(handleReportViewLoaderAction(false));
            }
        }catch(error){
            console.log("Error: ",error);
            dispatch(handleReportViewLoaderAction(false));
        }
    }
}
export const handleReportViewLoaderAction = (value: boolean) => {
    return {
        type: ActionType.UPDATE_SUBSCRIBED_REPORT_VIEWER_LOADER,
        payload: value
    }
}

export const handleSubmitQuestionSetIntoChatAction = (question_set: any) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await askQuestionSetIntoChat(question_set);
            if(response.status === 200){
                dispatch(alert(response.body.message, { position: "bottom-left" },"success"));
            } else{
                dispatch(alert(response.body.error));
            }
        } catch (error) {
            console.log("Error: ",error);
        }
    }
}