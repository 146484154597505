import { gql } from "@apollo/client";

export const getMessages = gql`
    query getMessages($chatId: String!, $page: Int, $limit: Int, $offset: Int, $sort: Int) {
    getMessages(chat_id: $chatId, page:$page, limit: $limit, offset: $offset, sort: $sort){
      chat_details{
        id
        topic
        users {
          user_id
          name
          email
          chat_role
        }
        agent
        created_at
        access_scope
        is_closed_chat
        is_chat_processed
        is_pinned_chat
      }
      page
      has_next_page
      messages {
        id
        created_at
        sender {
          user_id
          email
          name
          chat_role
        }
        focus
        sources
        message_versions {
          id
          created_at
          user_message {
            author {
              role
              tool
              metadata
            }
            content {
              type
              message
              metadata
            }
            metadata
          }
          message_intent
          question_version_number
          sender {
            user_id
            email
            name
            chat_role
          }
        }
        response {
          id
          response_versions
          parent_message_details {
          id
          created_at
          user_message {
            author {
              role
              tool
              metadata
            }
            content {
              type
              message
              metadata
            }
            metadata
          }
          message_intent
          question_version_number
          sender {
            user_id
            email
            name
            chat_role
          }
        }
        response_component {
          id
          processing_status {
            state
            reason
          }
          answer_version_number
          created_at
          feedback {
            text
            reaction
          }
          fragments {
            id
            author {
              role
              tool
              metadata
            }
            recepient
            content
            answer_id
            sub_message_id
            created_at
            readout_title
            processing_status {
              state
              reason
            }
            deltas {
              delta_id
              delta
              delta_type
              debug_logs {
                debug_message
                debug_type
                debug_time
              }
              delta_data_type
              created_at
              updated_at
            }          
          }
        }
        }
      }
    }
  }
`;