import { ActionType } from "state/action-types";
import { Command, chatAgents } from "types/chat";
import {  fetchChatCommands } from "services/answer";
import { alert } from "./customRouter";
import { Dispatch } from "redux";
import { RootState } from "state/reducers";
import { refetchChatConfigLoaderAction } from "./ChatConfigurationAction";


  export const getChatCommands = (chat_config_agent_reset_loading?:boolean) => {
    return async (dispatch: Dispatch<any>,  getState: () => RootState) => {
      try {
        if(chat_config_agent_reset_loading){
          dispatch(resetAgentLoadingState(true));
        }
        dispatch(refetchChatConfigLoaderAction(true));
        const page = getState().agent.page;
        const response = await fetchChatCommands(page);
        if (response?.status === 200 && response?.body?.agent_tree) {
          dispatch(resetAgentLoadingState(false));
          dispatch(refetchChatConfigLoaderAction(false));
          dispatch(updateChatConfigAgent(response?.body?.agent_tree));
          dispatch({
            type: ActionType.SET_HAS_NEXT_PAGE,
            payload: response?.body?.has_next_page
          })
          dispatch({
            type: ActionType.SET_PAGE_NUMBER,
            payload: response?.body?.page+1
          })
          if(response?.body?.agent_tree?.length && page == 1){
            const defaultAgent = response?.body?.agent_tree.find((agent:chatAgents)=>agent.is_default_agent);
            await dispatch({
                type:ActionType.ADD_AGENT,
                payload:defaultAgent
              })

            await dispatch({
              type: ActionType.SET_ACTIVE_AGENT,
              payload:defaultAgent
            })

            const default_focus = defaultAgent?.config.commands?.find((command:Command)=>command.is_default_focus);
            if(default_focus){
              dispatch(setSelectedCommand(default_focus))
            }

            const default_selected_sources = default_focus?.default_sources || [];
            if(default_selected_sources.length){
              dispatch({
                type:'SET_SELECTED_SOURCES',
                payload:default_selected_sources
              })
            }
          }
          return true
        } else if (response.status === 400 || response.status === 500 || response.status === 404) {
          dispatch(alert(response.body?.error));
          dispatch(resetAgentLoadingState(false));
          dispatch(refetchChatConfigLoaderAction(false));
        }
      } catch (err) {
        console.log(err);
        dispatch(resetAgentLoadingState(false));
        dispatch(refetchChatConfigLoaderAction(false));
      }
      return false
    };
  };

  export const resetAgentLoadingState = (is_reset_state: boolean) => {
    return {
      type: ActionType.UPDATE_RESET_STATE_FOR_LOADING,
      payload: is_reset_state,
    }
  }
  export const updateChatConfigAgent = (agent: any) => {
    return {
      type: ActionType.SET_CHAT_CONFIG,
      payload: agent,
    }
  }
  export const setSelectedCommand = (command: Command | null) => {
    return async (dispatch: Dispatch ,  getState: () => RootState) => {
      const selected_agent = getState().agent.selected_agent;
      const {dataSources } = selected_agent?.config || {};
      const sourceCommands: Command[] | undefined =    (command?.works_with?.map((source_id: string) => {
           return dataSources?.find((source: Command) => source.id === source_id) || null
         }).filter(item => item) as Command[])

          dispatch({
            type: ActionType.SET_SELECTED_COMMAND,
            payload: command,
          });

          if(sourceCommands && sourceCommands.length > 0){
            dispatch({
              type: ActionType.SET_CHAT_INPUT_SOURCES,
              payload: sourceCommands,
            });


          } else if (command){
            dispatch({
              type: ActionType.SET_CHAT_INPUT_SOURCES,
              payload: [],
            });
          } else {
            dispatch({
              type: ActionType.SET_CHAT_INPUT_SOURCES,
              payload: dataSources || [],
            });
          }

    }
  }

  export const setSources = (sources: Command[]) => {
    return {
      type: ActionType.SET_CHAT_INPUT_SOURCES,
      payload: sources,
    }
  }

  export const removeSelectedSource = (source: string) => {
    return {
      type: 'REMOVE_SELECTED_SOURCE',
      payload: source,
    }
  }

  export const addSelectedSource = (source: Command) => {
    return (dispatch: Dispatch, getState: () => RootState) => {
      const { selected_sources } = getState().agent; // replace with actual path to selected sources in your state
  
      if (!selected_sources.includes(source.id)) {
        dispatch({
          type: 'ADD_SELECTED_SOURCE',
          payload: source.id,
        });
      }
    };
  };

// export const setFocus = (focus: string) => {
//   return {
//     type: ActionType.SET_FOCUS,
//     payload: focus,
//   }
// }

// export const SourceAction = (sources: any) => {
//   return {
//     type: ActionType.SELECT_SOURCE,
//     payload: sources,
//   }
// }

export const setActiveAgent = (agent: chatAgents) => {
  return {
    type: ActionType.SET_ACTIVE_AGENT,
    payload: agent,
  }
}

export const resetAgent = () => {
  return {
    type: 'RESET_AGENT',
  }
}

export const resetFocus = () => {
  return {
    type: 'RESET_FOCUS',
  }
}

export const resetSources = () => {
  return {
    type: 'RESET_SOURCES',
  }
}

export const setSelectedSources = (updated_selected_sources: string[]) => {
  return {
    type: 'SET_SELECTED_SOURCES',
    payload: updated_selected_sources,
  }
}

export const updatePageNumber = (value:number)=>{
  return{
    type: ActionType.SET_PAGE_NUMBER,
    payload: value
  }
}