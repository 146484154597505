import { ActionType } from '../action-types'
import { PdfViewer } from 'types/pdfViewer';


export const initialState: PdfViewer = {
    allHighlightedText: [],
    highlightText: [],
    pdfURL: "",
    allLinksParts: [],
    highlightPageNum: [],
    highlightStatus: {
        loading: false,
        success: false,
        failure: false,
    },
}

const reducer = (state: PdfViewer = initialState, action: any): PdfViewer => {
    switch(action.type) {
        case ActionType.UPDATE_PDF_HIGHLIGHT_PAGE_NUMBER:
            return { ...state, highlightPageNum: action.payload}
        case ActionType.UPDATE_PDF_ALLHIGHLIGHTS:
            return { ...state, allHighlightedText: action.payload}
        case ActionType.UPDATE_HIGHLIGHT_TEXT:
            return { ...state , highlightText: action.payload };
        case ActionType.UPDATE_PDF_URL:
            return { ...state , pdfURL: action.payload };
        case ActionType.UPDATE_PDF_VIEWER:
            return { ...state , highlightStatus: action.payload }
        case ActionType.UPDATE_ALL_LINKS_PARTS:
            return { ...state ,  allLinksParts: action.payload }
        default:
            return state;
    }
}

export default reducer;