import { useEffect } from "react";
import { useActions } from "./use-actions";
import { useHistory } from "great-history";
import { useTypedSelector } from "./use-typed-selector";
import { fetchInterceptor } from "utils/fetchInterceptor";

export default function useSiteSettings() {
  const { redirect, path } = useTypedSelector(({ auth }) => auth);
  const { push } = useHistory();
  const {
    getSiteSetting,
    checkUserAuth,
    resetRedirect,
    updateHideLogo,
    getServersStatus,
  } = useActions();

  useEffect(() => {
    if (redirect) {
      push(path);
      resetRedirect();
    }
  }, [redirect]);

  const getSiteSettingEffect = async () => {
    await getSiteSetting();
    checkUserAuth();
  };

  useEffect(function() {
    fetchInterceptor(getServersStatus);
    getSiteSettingEffect();
    if (localStorage.getItem("hideLogo") !== null) {
      updateHideLogo(true);
    }
  }, []);
  
}