import { FavouriteState } from "types/FavouriteTypes";
import { ActionType } from "../action-types";

export const initialState: FavouriteState = {
  showSubscribedAnswer: false,
  favouriteStarItems: [],
  loadingSpinnerForFavourite: false,
  spinnerToUnfavourite: false,
  favResponse: false,
  currentFavouriteIndex:-1,
};

const reducer = (state: FavouriteState = initialState, action: any): FavouriteState => {
  switch (action.type) {
    case ActionType.UPDATE_FAVOURITE_STAR_ITEMS:
      return { ...state, favouriteStarItems: action.payload };
    case ActionType.LOADING_SPINNER_FOR_FAVOURITE:
      return { ...state, loadingSpinnerForFavourite: action.payload };
    case ActionType.UPDATE_FAVOURITE_LIST:
      return { ...state, favouriteStarItems: action.payload }
    case ActionType.UPDATE_SPINNER_FOR_UNFAVOURITE:
      return { ...state, spinnerToUnfavourite: action.payload }
    case ActionType.UPDATE_FAVOURITE_RESPONSE:
      return { ...state, favResponse: action.payload }
    case ActionType.SHOW_ANSWER_ON_SUBSCRIBED_ANSWER:
      return { ...state, showSubscribedAnswer: action.payload };
    case ActionType.UPDATE_USER_HISTORY_PDF_STATUS_BY_UTTERANCE_ID:
      const { pdf_status, id } = action.payload;
      return {
        ...state, favouriteStarItems: state.favouriteStarItems.map((utterance) => {
          if (utterance.utteranceId === id) {
            utterance.pdf_status = pdf_status
          }
          return utterance;
        })
      }
    case ActionType.UPDATE_SELECTED_FAVOURITE_INDEX:
      return { ...state, currentFavouriteIndex: action.payload };
    case ActionType.UPDATE_CHART_OPTION_FAVORITE_HISTORY:
      const { index, chart_options } = action.payload;
      const new_update_data = state.favouriteStarItems[index];
      (new_update_data.visual as any).chart_options = chart_options;
      return { ...state , favouriteStarItems: [ ...state.favouriteStarItems.slice(0,index), new_update_data, ...state.favouriteStarItems.slice(index+1) ] }

    case ActionType.UPDATE_USER_FAVOURITE_VISUAL_BY_UTTERANCE_ID:
      return { ...state , favouriteStarItems: state.favouriteStarItems.map((utterance) => {
          if(utterance.utteranceId === action.payload.utteranceId) {
              utterance.visual = action.payload.visual
              utterance.subscribed = action.payload.subscribed
              utterance.subscribed_id = action.payload.subscribed_id
              utterance.is_starred = action.payload.is_starred
          }
          return utterance;
      }) }
    default:
      return state;
  }
};

export default reducer;