import { ActionType  as TypeAction} from '../action-types'
import { ActionBarState } from 'types/actionBar';

export const initialActionBar: ActionBarState = {
  activeAction: null,
  export: {
    type: "EXCEL",
  },
  feedback: {
    text: "",
    error: "",
    justSubmitted: null,
  },
  addToStory: {
    activeSelectionItemIndex: 0, // guaranteed to always have at least one story
    justAdded: false,
  },
  columnsFilterClicked: false,
  workerColumnsFilterClicked: false,
  queueJobsColumnsFilterClicked: false,
  preGlobalFilteredRows:0,
  rows:0,
  totalColumnsWidth: 0,
  hideActionBar: false ,
  autoSave: false,
  dataTransforming: false,
  actionBarOptionsExpanded: false,
  splatColumnsFilterClicked: false,
  pptPath: "",
  pdfPath:"",
  isAdjustWidth:false,
  isWaveLoader: false,
  isPdfWaveLoader:false,
  rmarkdownExportType: "pdf",
};


const reducer = (state: ActionBarState = initialActionBar, action: any): ActionBarState => {
    switch(action.type) {
        case TypeAction.SET_AND_UPDATE_REPORT_MARKDOWN_EXPORT:
            return { ...state, rmarkdownExportType: action.payload }
        case TypeAction.SET_KEY_PPTX_DOWNLOAD:
            return { ...state , pptPath: action.payload }
        case TypeAction.SET_PDF_WAVE_LOADER:
            return { ...state, isPdfWaveLoader: action.payload }
        case TypeAction.SET_WAVE_LOADER:
            return {...state, isWaveLoader:action.payload}
        case TypeAction.SET_PDF_DOWNLOAD_KEY:
            return {...state , pdfPath: action.payload}
        case TypeAction.IS_ADJUST_WIDTH:
            return {...state, isAdjustWidth:action.payload }
        case 'action_bar_options_expanded':
            return { ...state , actionBarOptionsExpanded: action.payload };
        case TypeAction.UPDATE_DATA_TRANSFORMING:
            return { ...state , dataTransforming: action.payload }
        case TypeAction.UPDATE_AUTO_SAVE:
            return { ...state , autoSave: action.payload }
        case TypeAction.HIDE_ACTION_BAR:
            return { ... state , hideActionBar: action.payload }
        case TypeAction.UPDATE_TOTAL_COLUMNS_WIDTH:
            return { ... state , totalColumnsWidth: action.payload }
        case TypeAction.UPDATE_COLUMNS_FILTER_CLICKED:
            return { ...state , columnsFilterClicked: action.payload }
        case TypeAction.WORKER_UPDATE_COLUMNS_FILTER_CLICKED:
                return { ...state , workerColumnsFilterClicked: action.payload }
        case TypeAction.QUEUEJOBS_UPDATE_COLUMNS_FILTER_CLICKED:
                return { ...state , queueJobsColumnsFilterClicked: action.payload }
        case TypeAction.UPDATE_PRE_GLOBAL_FILTERED_ROWS:
            return {...state ,preGlobalFilteredRows:action.payload }
        case TypeAction.UPDATE_ROWS:
            return {...state, rows:action.payload }
        case TypeAction.UPDATE_EXPORT_TYPE:
            return {  ...state , export: action.payload };
        case TypeAction.UPDATE_ACTION_FEEDBACK_ERROR:
            return { ...state , feedback: { ...state.feedback , error: action.payload } }
        case TypeAction.UPDATE_ACTIVE_ACTION:
            return { ...state , activeAction: action.payload }
        case TypeAction.UPDATE_FEEDBACK_TEXT:
            return { ...state , feedback: { ...state.feedback , error: "" , text: action.payload } }
        case TypeAction.CLEAR_CURRENT_ACTION:
            return { ...state , activeAction: null }
        case TypeAction.UPDATE_FEEDBACK_JUST_SUBMITTED:
            return { ...state , feedback: { ...state.feedback , justSubmitted: action.payload} }
        case TypeAction.SPLAT_COLUMNS_FILTER_CLICKED:
            return { ...state , splatColumnsFilterClicked: action.payload }
        default:
            return state;
    }
}

export default reducer;