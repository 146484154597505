import { get, post, Response } from "makeRequest";
import { TerminateJobPayload } from "types/analyzer";
import { analyzerWorkerAndJobsExportType, QueueJobs } from "types/queueJobs";


export function getJobs(page:number, limit:number): Response<{duration: string; total_records: number;'jobs': Array<QueueJobs>}> {
  return get(`/jobs?page=${page}&limit=${limit}`);
}

export function terminateJob(data:TerminateJobPayload):Response {
  return post('/update-queue-job-status', data)
}

export function fetchJobsCSV(csvData: analyzerWorkerAndJobsExportType): Response<{csv: Array<string>}> {
  return post(`/export-rocket-analyzer`, csvData);
}