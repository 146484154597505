import { Colors } from "style/color"

export const recentlyTopQuestionColors = {
    dark:{
        RecentlyAskedTop:"#212B36",
        RecentlyAskedtext:"#fff",
        RecentlyAskedDate:"#505050",
        RecentlyTextDateTop:"#FFFFFF",
        StoryHeaderText:"#FFFFFF",
        SubsciptionQuestionsBG:"#212B36",
        SubcriptionBrdrTop:"#505050",
        QuickLinkBackground: Colors.COARSE_WOOL,
        WorkflowLoaderHeading: "#FFFFFF",
        LoaderMainBackground: "#212b36",
        LoaderBackgroundColor: "#212b36",
        CloseIconColor: "#fff",
        TextColorRecentlyAsked: "#fff",
        ArrowColorEnable: "#FFFFFF",
        SubscribedAnswerExpandColor: "#212b36",
        ToggleIconTopBar: "#fff",
        RecentlyNotFoundText: "#323232",
        displayText:"#fff",
    },
    light:{
        RecentlyAskedTop:"#FFFFFF",
        RecentlyAskedtext:"#414B54",
        RecentlyAskedDate:"#B4B4B4",
        RecentlyTextDateTop:"#646464",
        StoryHeaderText:"#323232",
        SubsciptionQuestionsBG:"#FFFFFF",
        SubcriptionBrdrTop:"#B4B4B4",
        QuickLinkBackground: Colors.GAINSBORO,
        WorkflowLoaderHeading: "#323232",
        LoaderMainBackground: "#fff",
        LoaderBackgroundColor: "#fff",
        CloseIconColor: "#323232",
        TextColorRecentlyAsked: "#fff",
        ArrowColorEnable: "#323232",
        SubscribedAnswerExpandColor: "#fff",
        ToggleIconTopBar: "#828282",
        RecentlyNotFoundText: "#323232",
        displayText:"#323232",
    },
}