import { ActionType } from '../action-types';
import { Dispatch  } from "redux";
import { RootState } from 'state/reducers';
import { getFavouriteItems, getStarHistoryItems } from 'services/favouriteServices';
import { alert } from './customRouter';
import { hydrateAnswerAction, setSubscribedQuestionCurrentState, tableSliderData, updateAnswerState, updateExportInProgress } from './AnswerActions';
import { getUserHistoryAnswer } from 'services/history';
import { compareQuestionsSnapshot, currentQuestionTimestamp } from './SubscriptionHistoryAction';
import { pptStatus } from 'types/visual';
import { disabledButtonAction, setPdfWaveLoaderAction, setpptKey } from './ActionBarActions';
import { updateLoadingSingleHistory } from './UserHistoryActions';

export const starHistoryItems = (utterance_id: any, is_star?:boolean) => {
    return async(dispatch: Dispatch<any>,getState: () => RootState) => {
        const state = getState();
        try{
            const favouriteList = state.FavouriteBar.favouriteStarItems;
            const currentActiveIndex = state.FavouriteBar.currentFavouriteIndex;
            const response = await getStarHistoryItems(utterance_id,!!is_star);
            if(response.status === 200){
                dispatch(spinnerForUnfavourite(false));
                if(!is_star){
                    const newList = favouriteList.filter((list:any) => list.utteranceId !== utterance_id )
                    if(currentActiveIndex){
                        dispatch(updateActiveFavouriteIndex(-1));
                        // dispatch(showAnswerOnSubscribedAnswer(false));  this will show the favorite page if uncomment the line
                    }
                    updateFavouriteList(dispatch,newList);
                    dispatch(spinnerForUnfavourite(false));
                    dispatch(updateVisual(false));
                    dispatch(updateHistoryVisual(false,utterance_id));
                    dispatch(updateChatHistoryVisual(false,utterance_id));
                }else{
                    if(state.questionBar.current_type === "favorite"){
                        dispatch(fetchFavourites());
                    }
                    dispatch(updateVisual(true));
                    dispatch(updateHistoryVisual(true,utterance_id));
                    dispatch(updateChatHistoryVisual(true,utterance_id));
                }
            }else{
                dispatch(alert("Error occur while fetching star items"));
            }
        }
        catch(err){
            dispatch(alert("Error occur while fetching star items"));
        }
    }
}

export const spinnerForUnfavourite = (value : any) => {
    return {
        type: ActionType.UPDATE_SPINNER_FOR_UNFAVOURITE ,
        payload: value
    }
}

export const fetchFavourites = () => {
    return async(dispatch: Dispatch) => {
        try{
            dispatch(loadingSpinnerForFavourite(true));
            dispatch(getFavouriteResponse(true));
            const response = await getFavouriteItems();
            const favouriteHistoryItems = response.body.history;
            if(response.status === 200){
                dispatch({
                    type: ActionType.UPDATE_FAVOURITE_STAR_ITEMS ,
                    payload: favouriteHistoryItems
                });
                dispatch(loadingSpinnerForFavourite(false));
                dispatch(getFavouriteResponse(false));
            }else{
                dispatch(alert("Error occur while fetching star items"));
            }
        }
        catch(err){
            dispatch(loadingSpinnerForFavourite(false));
            dispatch(alert("Error occur while fetching star items"));
        }
    }
}

export const visitFavouriteAction = (index:number) =>{
    return async (dispatch: Dispatch,getState: () => RootState) => {
      try{
        dispatch(updateLoadingSingleHistory(true));
        // dispatch(updateAnswerState("Fetch"));
        const { utterance: question , utteranceId }  = getState().FavouriteBar.favouriteStarItems[index]
        const response: any = await getUserHistoryAnswer({
                        utterance_id: utteranceId,
                        "utterance" : question
                    });
         if (response.status === 200) {
            const { favouriteStarItems , currentFavouriteIndex }  = getState().FavouriteBar
             const {utteranceId: currentUtteranceId} = favouriteStarItems[currentFavouriteIndex];
             if(currentUtteranceId===response.body.visual.utteranceId){
                 const is_pptx = response.body.visual.is_pptx;
                 const ppt_path = response.body.visual.ppt_s3_file;
                 if (is_pptx === pptStatus.FAILED || is_pptx === false) {
                     dispatch(updateExportInProgress(false));
                 }
                 if (is_pptx === pptStatus.IN_PROGRESS || is_pptx === pptStatus.SUBMITTED) {
                     dispatch(updateExportInProgress(true));
                 }
                 if (is_pptx === pptStatus.SUCCEEDED) {
                     dispatch(setpptKey(ppt_path));
                     dispatch(updateExportInProgress(false));
                 }

                 const question = getState().FavouriteBar.favouriteStarItems[index].utterance;
                 const utteranceId = getState().FavouriteBar.favouriteStarItems[index].utteranceId;
                 const subscribed = response.body.visual.subscribed;
                 const subscribed_id = response.body.visual.subscribed_id;
                 const visual = response.body.visual;
                 const answer_type = response.body.answer_type;
                 const isHistory = false;
                 const time_stamp = response.body.time_stamp;
                 const fetch_history = response.body.fetch_history;
                 const is_starred = true;
                 const newPdf_status = response.body.visual.pdf_status ? response.body.visual.pdf_status : "";
                 dispatch(updateUseFavouriteVisualByUtteranceId(utteranceId , visual,subscribed,subscribed_id,is_starred));
                 dispatch(currentQuestionTimestamp(time_stamp));
                 dispatch(compareQuestionsSnapshot(time_stamp, time_stamp));
                 dispatch(
                     hydrateAnswerAction({
                         question,
                         utteranceId,
                         visual,
                         subscribed,
                         subscribed_id,
                         fetch_history,
                         isHistory,
                         time_stamp,
                         is_starred,
                         answer_type,
                         pdf_status: newPdf_status,
                         reportExportStatus: [],
                     })
                 );
                 dispatch({
                     type: ActionType.HYDRATE_ANSWER_COPY,
                     payload: {
                         question,
                         utteranceId,
                         visual,
                         subscribed,
                         subscribed_id,
                         fetch_history,
                         isHistory,
                         time_stamp,
                         is_starred,
                         answer_type,
                         pdf_status: newPdf_status,
                         reportExportStatus: [],
                     },
                 });
                 if (["in progress", "submitted"].includes(newPdf_status)) {
                     dispatch(disabledButtonAction(true));
                     dispatch(setPdfWaveLoaderAction(true));
                 } else {
                     dispatch(disabledButtonAction(false));
                     dispatch(setPdfWaveLoaderAction(false));
                 }

                 const dataLength = response?.body?.visual?.data?.data?.length;
                 dispatch(tableSliderData([0, dataLength]));
                 dispatch(setSubscribedQuestionCurrentState("current"));
                 dispatch(updateAnswerState("View"));
                 dispatch(updateLoadingSingleHistory(false));
             }
         }
         if(response.status == 500){
          dispatch(alert("Error occur while fetching subscribed utterance"));
          dispatch(setSubscribedQuestionCurrentState("current"))
          dispatch(updateAnswerState("View"));
          dispatch(updateLoadingSingleHistory(false));
         }
      }
      catch(error:any){
        dispatch(updateAnswerState("View"));
        dispatch(updateLoadingSingleHistory(false));
        dispatch( alert("Selected answer is not update please try again later."));
      }
    }
  }

  export const updateUseFavouriteVisualByUtteranceId = (utteranceId: string, visual: any,subscribed?:boolean ,subscribed_id?:string,is_starred?:boolean) => {
    return {
        type: ActionType.UPDATE_USER_FAVOURITE_VISUAL_BY_UTTERANCE_ID,
        payload: {
            utteranceId: utteranceId ,
            visual: visual,
            subscribed:subscribed,
            subscribed_id:subscribed_id,
            is_starred:is_starred
        }
    }
}
export const loadingSpinnerForFavourite = (value : boolean) => {
    return {
        type: ActionType.LOADING_SPINNER_FOR_FAVOURITE ,
        payload: value
    }
}

export const updateHistoryVisual = (value : any, id:any) => {
    return {
        type: ActionType.UPDATE_HISTORY_VISUAL ,
        payload: { value:value, id:id }
    }
}

export const updateChatHistoryVisual = (value : any, id:any) => {
    return {
        type: ActionType.UPDATE_CHAT_HISTORY_VISUAL ,
        payload: { value:value, id:id }
    }
}
export const getFavouriteResponse = (value:boolean) =>{
    return {
      type:ActionType.UPDATE_FAVOURITE_RESPONSE,
      payload: value
    }
  }
export const updateFavouriteList = (dispatch:Dispatch,favouriteList:any) =>{
    dispatch({
      type:ActionType.UPDATE_FAVOURITE_LIST,
      payload:[...favouriteList]
    })
  }

export const updateVisual = (value : any) => {
    return {
        type: ActionType.UPDATE_VISUAL ,
        payload: value
    }
}
export const updateActiveFavouriteIndex = (index : number) => {
    return {
        type: ActionType.UPDATE_SELECTED_FAVOURITE_INDEX ,
        payload: index
    }
}

