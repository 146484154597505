import { get, post, download_file ,Response, delete_req } from "makeRequest";
import { AnswerStatus } from "types/userHistory";
import { AnswerRequestContext } from "types/quickLinks";
import { QuickLinks } from "types/quickLinks";
import { Visual } from "types/visual";
import { postNew } from "makeRequest";
import { suggestionSearchObj } from "components/RocketAnalyzer/ElasticSearchTable";
import { AgentConfig } from "types/chat";

export function getCSVForIndex(
  utterance_id: string,
  indexes: Array<number>
): Promise<Response> {
  return post("/download-csv", { utterance_id, indexes });
}
export type analyzerSearchValue={
  [key:string] : string | boolean | number

}

export function getS3Object(path: string, question?:string, extension?:string): Promise<any> {
  return download_file("/download-file", { path }, question, extension );
}

export function downloadPpt(id:any):Response<any>{
return post("/export-aysnc",id);
// return post("/export/async-pptx",id);
}
export function getExcelForIndex(
  utterance_id: string,
  indexes: Array<number>
): Promise<Response> {
  return post("/download-excel", { utterance_id, indexes });
}

export function getQuickLinks(): Response<QuickLinks> {
  return get(`/quick-links`);
}

export type AnswerResponse = {
  utteranceId: string;
  utterance: string;
  subscribed: boolean;
  subscribed_id?: string;
  fetch_history?: Array<string>;
  time_stamp?: number;
  visual: Visual;
  status: AnswerStatus;
  event?: string[];
  message: any;
};

export type previousAnswerResponse = {
  body: AnswerResponse;
  status: number;
};

export type previousBodyResponse = {};

export type FollewedResponse = {
  utterence: string;
};

export type SubscriptionList = {
  body: Array<ArrayResponse>;
  status: number;
};
export type ArrayResponse = {
  question: string;
  utteranceId: string;
};

export function generateAnswer(
  utterance: string,
  userEmail: string,
  context: AnswerRequestContext,
  uId: string
): Response<AnswerResponse> {
  return post("/answer", { utterance, userEmail, context, uId });
}

export function generateAnswerQueryEditor(
  utterance: string,
  data: any,
  userEmail: string,
  context: AnswerRequestContext
): Response<AnswerResponse> {
  return post("/answer", { utterance, userEmail, context , alias_data: data });
}

export type CompleteAsyncResponse = {
  completed: Array<{
    utteranceId: string;
    visual: Visual;
    job_id: string;
    compute_route?: string;
    status?: string;
    utterance_type?: string;
    subscribed?: string;
    subscribed_id?: string;
    thumbnail?:string;
  }>;
};

export type suggestions = [
  {
    title: string;
    questions: Array<string>;
  }
];

export function updateChartOptions(
  visual_id: string,
  chart_options: any
): Response<{ status: boolean }> {
  return post("/update-visual-settings", { visual_id, chart_options });
}

export function completeAsync(
  utteranceIds: Array<string>
): Response<CompleteAsyncResponse> {
  return post("/answer/complete-async", { utteranceIds });
}
interface JobLogsData {
  job_id: string;
  compute_route: string | null;
}
interface LogEvent {
  eventId: string;
  ingestionTime: number;
  logStreamName: string;
  message: string;
  timestamp: number;
}
type LogEventObject = {
  events: LogEvent[];
};
export function getJobLogs(data: JobLogsData): Response<LogEventObject> {
  return post("/get-job-logs", data);
}
export function getDifferentVisual(
  question: string,
  utterance_id: string,
  new_chart_type: string,
  possibleTypes: Array<string>
): Response<AnswerResponse> {
  return post("/answer/transform-chart", {
    utterance_id,
    new_chart_type,
    possibleTypes,
  });
}

export function getSubscribedQuestions(): Response<SubscriptionList> {
  return get("/fetch-subscribed-questions");
}

export function getPreviousAnswer(value: String): Response<any> {
  return post("/fetch-previous-history", { id: value });
}

export function getNewTableAnswer(
  page_number: number,
  utteranceId: any
): Response<any> {
  return post("/table-pagination", { page_number, utteranceId });
}
export function getTableSearchAnswer(
  searchQuery: string,
  reference_utterance_id: string,
  equalsquery: any,
  containsQuery: any,
  dropdownOperator: string,
): Response<any> {
  if(!searchQuery ){
      return post(
        `/table-elastic-data`, {"is_search_header":true,
          "utterance_id":reference_utterance_id,
          "equals":equalsquery.length>0 ? equalsquery :[],
          "contains":containsQuery.length>0 ? containsQuery :[] ,
          "l_operator":dropdownOperator}
      );
  } else
  return post(
    `/table-elastic-data`,{
    "searchQuery":searchQuery,
    "utterance_id":reference_utterance_id}
  );
}

export function getAliasSchema():Response<any> {
  return get("/get-alias-schema")
}
export function createAlias(value: Object): Response<any> {
  return post("/create-alias", value);
}

export function getAlias(page?: number, limit?: number): Response<any> {
  return get(`/get-alias?page=${page}&limit=${limit}`);
}

export function deleteAlias(rule_name: string): Response<any> {
  return post("/delete-alias",{"rule_names":rule_name});
}

export function updateAlias(data: any): Response<any> {
  return post("/update-alias", data);
}
export function loadNewSuggestions(): Response<suggestions> {
  return get("/suggestion-elastic-data");
}

export function getAliasByName(rule_name:string): Response<any> {
  return get(`/get-alias-by-name?rule_name=${rule_name}`)
}

export function submitHtmlToAdobe(data?:any, utterance_id?:string, visual_type?:string): Response<any>{
  return postNew(`/export-pdf/${utterance_id}`,data? data : null, visual_type||"");
}

export function getPdfStatus(utterance_id:string):Response<any>{
  return post("/check-pdf-status",{"utterance_id":utterance_id});
}

export function tableSuggestions(reference_utterance_id:string,searchResults:any,inputSearchValue:string): Response<suggestions> {
  return get(`/table-suggestion?header=${inputSearchValue}&query=${searchResults}&utterance_id=${reference_utterance_id}`);
}

export function getPpt(value:any){
  return post("/export-ppt",value);
}



export function analyzerSearch(value:any):Response<analyzerSearchValue>{
  return post("/get-rocket-analyzer-elastic-data",value)
}

export function analyzerSuggestions({header, tab, searchQuery}:suggestionSearchObj){
  return get(`/rocket-analyzer-suggestion?header=${header}&query=${searchQuery}&tab=${tab}`);
}

export function fetchingTableIndexedStatus(utterance_id: string): Response<any>{
  return get(`/table-indexing-async?utterance_id=${utterance_id}`);
}

export function aliasSuggestion(query:any, header:any):Response<any>{
  return get(`/get-intent-alias-elastic-suggestion?header=${header}&query=${query}`)
}

export function aliasSearch(payload:any):Response<any>{
  return post(`/get-intent-alias-elastic-data`,payload)
}

export function deleteAllChatHistory(): Response<any>{
  return delete_req("/clear-chat-v2");
}

export function getReportViewerUrl(export_id: string | null): Response<{presigned_url: string, error: string}>{
  return get(`/report-viewer?export_id=${export_id}`);
}
export function fetchChatCommands(page:number): Response<AgentConfig> {
  return get(`/get-agent-tree?page=${page}&limit=5`)
}

export function fetchOperations(body:{utterance_id: string}): Response<any> {
  return post(`/fetch-operations`,body);
}