import { post ,Response } from "makeRequest";
import { ExportStatusTypes } from "types/answer";

type PdfRequestType = {
    file_type: string;
    utterance_id: string;
}
type ResponseType = {
   status: string;
   error: string;
   message?: string;
   export_id?: string;
   s3_path?: string;
   utterance_id: string;
}

export function fetchRMarkdownPdfExportRequest(requestData: PdfRequestType): Response<ResponseType> {
   return post(`/submit-export-request`,requestData);
}

export function fetchRMarkdownExportStatus(export_id: Array<string>): Response<Array<ExportStatusTypes>> {
   const bodyPayload = {export_ids: export_id}
   return post(`/get-export-status`,bodyPayload);
}