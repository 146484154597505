// libraries
import React, { lazy, Suspense } from "react";
import themes from "devextreme/ui/themes";
import  { ThemeProvider } from "styled-components";
//hooks
import { useTypedSelector } from 'hooks/use-typed-selector';
import { useLoadSentry } from "./hooks/use-external-script";
import useSiteSettings from "hooks/use-site-settings";
import { useHumaLogs } from "hooks/use-huma-logger";
import useRouteChangeEffect from "hooks/useRouteChangeEffect";
// styles
import { GlobalStyles } from "./components/globalStyles";
import { lightTheme, darkTheme } from "./Themes";
import { AppContainer } from "style/styles";
// internal components
import AppLoading from "components/AppLoading";
import ServiceWorker from "components/ServiceWorker";
import ReduxAlert from "components/common/ReduxAlert";
import AppErrorBoundary from "components/AppErrorBoundary";
import SentryFeedback from "components/common/SentryFeedback";
import FeedbackOverlay from "./components/common/FeedbackActionOverlay";
// lazy loaded components
const Account = lazy(() => import('components/Account/Account'));
const EffectThread = lazy(() => import('components/EffectThread'));
const Main = lazy(() => import("components/Main"));


export default function App() {
  useHumaLogs();
  useLoadSentry();
  useSiteSettings();
  useRouteChangeEffect();
  const {appLoaded: app_loaded } = useTypedSelector(({ auth }) => auth);
  const authenticated = useTypedSelector(({ auth }) => auth.user.authenticated);
  const theme = useTypedSelector(({ auth }) => auth.user.settings.theme);
  // Determine the theme mode
  const themeMode = theme === "light" ? lightTheme : darkTheme;
  // Set DevExtreme theme
  themes.current(theme === "light"? 'generic.light' : 'generic.dark')

  if (!app_loaded) {
    return <AppLoading />;
  }

  return (
    <ThemeProvider theme={themeMode}>
      <>
        <ServiceWorker />
        <GlobalStyles />
        <AppErrorBoundary>
          <FeedbackOverlay />
          <AppContainer>
            <ReduxAlert />
            <Suspense fallback={<AppLoading />}>
              {authenticated ? (
                <>
                  <EffectThread />
                  <Main />
                </>
              ) : (
                <Account />
              )}
            </Suspense>
            <SentryFeedback />
          </AppContainer>
        </AppErrorBoundary>
      </>
    </ThemeProvider>
  );
}