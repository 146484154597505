import { get, Response, post, delete_req } from "makeRequest";
import { Entity } from "types/chatConfigTypes";
import { AgentsList } from "types/chatConfigTypes";
import { CitationRecord } from "types/chatv2";

export interface AgentsResponse {
  status: number;
  error: string;
  root_nodes: Array<AgentsList>;
  has_next_page: boolean;
  total_pages: number;
  current_page: number;
}

export interface FocusResponse {
  status: number;
  error: string;
  child_nodes: Array<FocusList>;
  total_pages: number;
}

export type FocusList = {
  attach_suggestion_menu:boolean;
  created_at:string;
  created_by:string;
  description: string;
  updated_at: string | number;
  updated_by: string;
  is_deleted: boolean;
  type: string;
  placeholder: string;
  include_dynamic_knowledge_base: boolean;
  node_type: string;
};
export type FetchResponse = false | {
  data: AgentsList[];
  total_pages: number;
}
// export function getAgentSourcesMock(): any {
//   return Promise.resolve({
//     status: 200,
//     error: '',
//     body: Sources,
//   });
// }



export function getNewAgents(
  page?: number,
  limit?: number
): Response<AgentsResponse> {
  return get(`/get-root-nodes?page=${page}&limit=${limit}`);
}

export function getChildNodes(
  page?: number,
  limit?: number,
  parent_node?: string,
  parent_node_type?: string,
  root_node?: string,
): Response<FocusResponse> {
  return get(`/get-child-nodes?page=${page}&limit=${limit}&root_node=${root_node}&parent_node=${parent_node}&parent_node_type=${parent_node_type}`);
}

// export function getNewSources(
//   page?: number,
//   limit?: number
// ): Response<{ status: number; error: string }> {
//   if(true){
//     return getAgentSourcesMock();
//   }
//   return get(`/get-sources-list?page=${page}&limit=${limit}`);
// }

export interface FormResponse {
  status: boolean;
  error: string;
  message: string;
  form_schema: object;
  data?: any;
}
export type ChildNodeFocus = {
  id: string,
  value: string,
  label: string
}
export type ChildNodesFocusResponse = {
  child_nodes: ChildNodeFocus[],
  status: boolean,
  error: string,
  message: string,
  has_next_page: boolean,
  page: number
}
export type ChildNodeSources = {
  id: string,
  value:string,
  label:string
}
export type ChildNodesSourcesResponse = {
    child_nodes: ChildNodeSources[],
    has_next_page: boolean,
    page: number
}
export function getFormSchema(): Response<FormResponse> {
  return get(`/chat-config-create-schema`);
}

export function createConfigEntity(form_data: Entity): Response<FormResponse> {
  return post(`/create-config-entity`, form_data);
}
export type AttachNodeSchemaPayload = {
  parent_node: string,
  parent_node_type: string,
  root_node: string
}
export function getAttachNodeSchema(params:AttachNodeSchemaPayload): Response<FormResponse> {
  const { parent_node, parent_node_type, root_node } = params;
  return get(`/attach-node-schema?parent_node=${parent_node}&parent_node_type=${parent_node_type}&root_node=${root_node}`);
}

export function createConfigNode(form_data: Entity): Response<FormResponse> {
  return post(`/attach-chat-config-node`, form_data);
}
export type getEditSchemaPayload = {node_id:string,node_type:string,parent_node:string,root_node:string}
export function getEditAgentSchema(params:getEditSchemaPayload): Response<FormResponse> {
  const { node_id, node_type, parent_node, root_node } = params;
  return get(`/edit-node-schema?node_id=${node_id}&node_type=${node_type}&parent_node=${parent_node}&root_node=${root_node}`);
}

export function updateEditData(update_edit_data: any): Response<any> {
  return post(`/edit-node`, update_edit_data);
}

export function deleteNode(delete_node:{node_id: string, node_type: string, root_node_id: string,parent_node:string}): Response<any> {
  return delete_req(`/remove-chat-config-node?node_id=${delete_node.node_id}&node_type=${delete_node.node_type}&root_node=${delete_node.root_node_id}&parent_node=${delete_node.parent_node}`);
}

export function getChildNodesFocusService(root_id:string,parent_name:string,page:number,limit:number): Response<ChildNodesFocusResponse>{
  return get(`/get-focus-nodes?root_id=${root_id}&parent_name=${parent_name}&page=${page}&limit=10`)
}

export function getChildNodesSourceService(root_id:string,parent_name:string,page:number,limit:number):Response<ChildNodesSourcesResponse>{
  return get(`/get-source-nodes?root_id=${root_id}&parent_name=${parent_name}&page=${page}&limit=10`)
}
type getoptionsResponse = {
  total_pages: number,
  options: Array<FocusOrSourceListItem>
}
export function getNodeByOption(filter_type:string,page:number):Response<getoptionsResponse>{
  return get(`/get-config-options?filter_type=${filter_type}&page=${page}&limit=10`)
}

type FocusOrSourceListItem = {
  name: string,
  description: string,
  created_at: string,
  created_by: string,
  updated_at: string,
  updated_by: string,
  type: string,
  placeholder: string,
  is_luis_intent: boolean,
  _id: string,
}

type EditNodeSchema = {
  node_id:string,
  node_type: string
}
interface editNodePayload extends EditNodeSchema {
  payload: any
}
export function getEditIndividualSchema(payload:EditNodeSchema): Response<FormResponse>{
  const { node_id, node_type } = payload;
  return get(`/edit-schema?node_id=${node_id}&node_type=${node_type}`)
}

export function editIndividualNode(payload:editNodePayload):Response<ChildNodesSourcesResponse>{
  return post(`/edit-individual-node`,payload)
}

export function deleteIndividualNode(payload:EditNodeSchema): Response<any> {
  const { node_id, node_type } = payload;
  return delete_req(`/delete-individual-node?node_id=${node_id}&node_type=${node_type}`);
}

export interface CitationResponse {
  records: CitationRecord;
  status: boolean;
  error?: string;
}

export function getCitationService(href: string): Response<CitationResponse> {
  const params = href.split("?")?.[1];
  return get(`/source-viewer?${params}`);
}

