import { Question, QuickLinks, RunTest } from 'types/runTestTypes';
import { ActionType } from '../action-types';
import { flatten } from 'components/QuickLinks/ToggleModeDashbord/components/RunTestQuickLinks/utils';

export const initialState: RunTest = {
    payloads: [],
    has_more_payloads: true,
    runs: [],
    has_more_runs: true,
    configName: '',
    quickLinks:[],
    is_context_loader: false,
    selected_tab_id: '',
    user_context_tabs: [{
        id: '',
        name: '',
        visibility: 0,
        created_by: '',
        created_at: 0,
        position: 0,
        quickLinks: [],
        selectAllChecked: false
    }],
    context_label: '',
    is_save_loader: false,
    test_payload: [],
    run_detail: [],
    has_more_results: true,
    comparison_results: [{
        id: '',
        test_details_id: '',
        chat_id: '',
        reference_chat_id: '',
        current_approval_status: false,
        current_lock_status: false,
        payload_id: '',
        component_id: '',
        result: {
            qualitative_analysis: {
                status: '',
                description: '',
            },
            quantitative_analysis: '',
            description: '',
        }
    }],
    reference_messages: [],
    latest_messages: [],
    has_more_messages: false,
    batch_current_chat_thread: null,
    reference_chat_thread_details: null,
    isSidebarOpen: false,
    lock_and_approval_history_logs: [],
    update_reference_button_details: [],
    is_common_loader: false,
    issue_info_value: '',
    lock_and_approval: '',
    batch_utterance_activity: [],
    has_more_activity: true,
    duration: 1,
    unselected_utterance_ids: [],
    users_email_list: [],
    saved_scheduler_payload: {
        notify_emails: [],
        schedule_frequency: '',
        schedule_date: ''
    },
    use_cache: false,
}

const reducer = (state: RunTest = initialState, action: any): RunTest => {
    switch (action.type) {
        case ActionType.SET_RUN_TEST_CONFIG_NAME:
            return {
                ...state,
                configName: action.payload,
            };
        case ActionType.SET_RUN_TEST_QUICK_LINKS_DATA:
            return {
                ...state,
                runs: action.payload,
            };
        case ActionType.SET_RUN_TEST_QUICK_LINKS_HAS_MORE:
            return {
                ...state,
                has_more_runs: action.payload,
            };
        case ActionType.IS_RUN_TEST_CONTEXT_LOADER:
            return {
                ...state,
                is_context_loader: action.payload,
            };

        case ActionType.RUN_TEST_SELECTED_TAB_ID:
            return {
                ...state,
                selected_tab_id: action.payload,
            };
        case ActionType.GET_USER_CONTEXT_TABS:
            return {
                ...state,
                user_context_tabs: action.payload,
            };
        case ActionType.GET_UPDATED_RUN_TEST_CONTEXT_LABEL:
            return {
                ...state,
                context_label: action.payload,
            };
        case ActionType.SET_QUICK_LINK_TEST_PAYLOADS_DATA:
            return {
                ...state,
                payloads: action.payload,
            };
        case ActionType.SET_QUICK_LINK_TEST_PAYLOADS_HAS_MORE:
            return {
                ...state,
                has_more_payloads: action.payload,
            };
        case ActionType.TOGGLE_SELECT_ALL_QUESTIONS:
            const updatedQuickLinks = state.user_context_tabs.find(tab => tab.id === state.selected_tab_id)?.quickLinks?.map(category => {
                const updatedSuggestions = category.suggestions.map(suggestion => {
                    if (typeof suggestion === 'object' && suggestion !== null) {
                        if ('questions' in suggestion && Array.isArray(suggestion.questions)) {
                            // Handle group or question set category
                            const updatedQuestions = suggestion.questions.map((question: Question) => ({
                                ...question,
                                is_selected: action.payload
                            }));
                            return {
                                ...suggestion,
                                is_selected: updatedQuestions.every(q => q.is_selected),
                                questions: updatedQuestions
                            };
                        } else {
                            // Handle single question
                            return {
                                ...suggestion,
                                is_selected: action.payload
                            };
                        }
                    }
                    return suggestion;
                });
                return {
                    ...category,
                    is_selected: updatedSuggestions.every(s => s.is_selected),
                    suggestions: updatedSuggestions
                };
            }) as QuickLinks[];
            const allSelected = updatedQuickLinks?.every(category => category.is_selected);
            return {
                ...state,
                quickLinks: updatedQuickLinks,
                user_context_tabs: state.user_context_tabs.map(tab => {
                    if (tab.id === state.selected_tab_id) {
                        return {
                            ...tab,
                            quickLinks: updatedQuickLinks,
                            selectAllChecked: allSelected
                        };
                    }
                    return tab;
                })
            };

        case ActionType.UPDATE_RUN_TEST_QUICK_LINKS:
            const newQuickLinks = action.payload.categories;
            const checkAllSelected = flatten(newQuickLinks).every(suggestion => (suggestion as Question).is_selected===true);
            let updatedUserContextTabs = state.user_context_tabs;

            // Only update the quickLinks for the selected tab if it hasn't been initialized yet
            if (action.payload.current_tab_id) {
                updatedUserContextTabs = state.user_context_tabs.map(tab => {
                    if (tab.id === action.payload.current_tab_id) {
                        return {
                            ...tab,
                            quickLinks: newQuickLinks,
                            selectAllChecked: checkAllSelected
                        };
                    }
                    return tab;
                });
            }

            return {
                ...state,
                quickLinks: newQuickLinks,
                user_context_tabs: updatedUserContextTabs
            };

        case ActionType.IS_RUN_TEST_SAVE_LOADER:
            return {
                ...state,
                is_save_loader: action.payload,
            };
        case ActionType.UPDATE_RUN_TEST_PAYLOAD:
            return {
                ...state,
                test_payload: action.payload,
            };
        case ActionType.UPDATE_RUN_TEST_RUN_DETAILS:
            return {
                ...state,
                run_detail: action.payload,
            };
        case ActionType.UPDATE_RUN_TEST_RUN_DETAILS_HAS_MORE:
            return {
                ...state,
                has_more_results: action.payload,
            };
        case ActionType.UPDATE_QUICK_LINK_COMPARISON_RESULTS:
            return {
                ...state,
                comparison_results: action.payload,
            };
        case ActionType.UPDATE_RUN_TEST_HAS_MORE_MESSAGES:
            return {
                ...state,
                has_more_messages: action.payload,
            };
        case ActionType.SET_RUN_TEST_LATEST_MESSAGES:
            return {
                ...state,
                latest_messages: action.payload,
            };
        case ActionType.SET_RUN_TEST_REFERENCE_MESSAGES:
            return {
                ...state,
                reference_messages: action.payload,
            };
        case ActionType.SET_BATCH_CURRENT_CHAT_THREAD:
            return {
                ...state,
                batch_current_chat_thread: action.payload,
            };
        case ActionType.UPDATE_REFERENCE_CHAT_THREAD_DETAILS:
            return {
                ...state,
                reference_chat_thread_details: action.payload,
            };
        case ActionType.UPDATE_CURRENT_APPROVAL_STATUS:
            return {
                ...state,
                comparison_results: state.comparison_results.map((item: any) => ({
                    ...item,
                    current_approval_status: action.payload,
                })),
            };
        case ActionType.UPDATE_CURRENT_LOCK_STATUS:
            return {
                ...state,
                comparison_results: state.comparison_results.map((item: any) => ({
                    ...item,
                    current_lock_status: action.payload,
                })),
            };
        case ActionType.SET_IS_SIDEBAR_OPEN:
            return {
                ...state,
                isSidebarOpen: action.payload,
            };
        case ActionType.UPDATE_LOCK_AND_APPROVAL_HISTORY_LOGS:
            return {
                ...state,
                lock_and_approval_history_logs: action.payload,
            };
        case ActionType.UPDATE_REFERENCE_BUTTON_DETAILS:
            return {
                ...state,
                update_reference_button_details: action.payload,
            };
        case ActionType.SET_COMMON_LOADER_FOR_RUN_TEST_TABLE:
            return {
                ...state,
                is_common_loader: action.payload,
            };
        case ActionType.SAVE_ISSUE_INFO_ACTION:
            return {
                ...state,
                issue_info_value: action.payload,
            };
        case ActionType.UPDATE_ACTION_NAME_OF_LOCK_AND_APPROVAL:
            return {
                ...state,
                lock_and_approval: action.payload,
            };
        case ActionType.RESET_RUN_TEST_STATE:
            return {
                ...state,
                payloads: [],
                runs: [],
                has_more_payloads: true,
                has_more_runs: true,
                run_detail: [],
                has_more_results: true,
            };
        case ActionType.UPDATE_BATCH_UTTERANCE_ACTIVITY:
            return {
                ...state,
                batch_utterance_activity: action.payload,
            };
        case ActionType.UPDATE_BATCH_ACTIVITY_DURATION:
            return {
                ...state,
                duration: action.payload,
            };
        case ActionType.UPDATE_BATCH_ACTIVITY_UNSELECTED_ITEMS:
            return {
                ...state,
                unselected_utterance_ids: action.payload,
            };
        case ActionType.UPDATE_BATCH_UTTERANCE_ACTIVITY_HAS_MORE:
            return {
                ...state,
                has_more_activity: action.payload,
            };
        case ActionType.GET_USERS_EMAIL_LIST_FOR_SCHEDULER:
            return {
                ...state,
                users_email_list: action.payload,
            };
        case ActionType.SAVE_SCHEDULER_PAYLOAD:
            return {
                ...state,
                saved_scheduler_payload: action.payload,
            };
        case ActionType.UPDATE_USE_CACHE:
            return{
                ...state,
                use_cache: action.payload,
            }
        default:
            return state;
    }
}

export default reducer;
