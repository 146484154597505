import { ActionType } from '../action-types';
import { getJobLogs, getJobLogsCLI, get_compare_answer, get_user_context, updateReference  } from "services/settings";
import { Dispatch } from 'redux';
import { alert } from './customRouter';
import { updateLoadingSingleHistory } from './UserHistoryActions';

export const showQuickLinkContext = () => {
    return {
        type: ActionType.SHOW_QUICK_LINK_CONTEXT
    }
}
export const hideQuickLinkContext = () => {
    return {
        type: ActionType.HIDE_QUICK_LINK_CONTEXT
    }
}
export const updateQuickLinksCategories = (categories: Array<any>) => {
    return {
        type: ActionType.UPDATE_QUICK_LINKS_CATEGORIES,
        payload: categories
    }
}

export const fetchUserContext = () => {
    return async (dispatch: any) => {
        try {
            const response = await get_user_context();
          if(response.status == 200) {
            dispatch(updateUserContext(response.body.user_context));
          }
          } catch (error) {
            dispatch(alert());
            
          }
    }
}

export const updateUserContext = (userContext: Array<{id: string, text: string}>) => {
    return {
        type: ActionType.UPDATE_USER_CONTEXT,
        payload: userContext
    }
}

export const updateCurrentUserContext = (currentUserContext: Array<string>) => {
    return {
        type: ActionType.UPDATE_CURRENT_USER_CONTEXT,
        payload: currentUserContext
    }
}


export const updateQuickLinkLoading = (loading: boolean) => {
    return {
        type: ActionType.UPDATE_QUICK_LINK_LOADING,
        payload: loading
    }
}

export const updateUserContextLoading = (loading: boolean) => {
    return {
        type: ActionType.UPDATE_USER_CONTEXT_LOADING,
        payload: loading
    }
}

export const isSetLoader = (data:boolean) =>{
   return{
        type: ActionType.QUICK_LINKS_LOADER_ON_PAGE_LOAD,
        payload: data
    };
}

export const fetchCompareAnswer = (id: string) =>{
    return async (dispatch: Dispatch) => {
        try {
            const response: any = await get_compare_answer(id);
          if(response.status == 200) {
            dispatch({
                type: ActionType.STORE_COMPARE_ANSWER_DATA,
                payload: response.body
            })
            dispatch(updateLoadingSingleHistory(false));
            dispatch(handleCompareAnswer(true));
          }
          else if(response.status === 404 || response.status === 500){
            dispatch(handleCompareAnswer(false));
            dispatch(updateLoadingSingleHistory(false));
            dispatch(showQuickLinkContext());
            dispatch(alert(response.body?.error));
          }
          dispatch(updateLoadingSingleHistory(false));
          } catch (error) {
            dispatch(alert());
          }
    }
}

export const handleCompareAnswer = (value: boolean) => {
        return {
          type: ActionType.SET_COMPARE_ANSWER_VIEW,
          payload: value
    }
}
export const updateAnswerReference = (refUtteranceId: string, latestUtteranceId: string) => {
    return async (dispatch: Dispatch) => {
        try{
            
            const res = await updateReference(refUtteranceId, latestUtteranceId);
            if (res.status == 200){
                dispatch(alert(`Successfully update answer reference.`,  { position: "bottom-left" },"success"));
                dispatch(isSetBtnLoader(false))
            }
        }catch(err){
            dispatch(alert("Error occur while updating answer reference."));
            dispatch(isSetBtnLoader(false))
        }
    }
}

export const isSetBtnLoader = (data:boolean) => {
return {
    type: ActionType.IS_SET_BUTTON_LOADER,
    payload: data
}

}


export const fetchJobLogsByUser = (utteranceID: string, latest_answer_compute_route: string, latest_answer_job_id: string) => {
    return async (dispatch: Dispatch) => {
        try{
            dispatch(loadingJobLogs(true));
            const res: any = await getJobLogs(utteranceID, latest_answer_compute_route, latest_answer_job_id);
            const payload = res.body.events;
            if (res.status == 200){
                dispatch({
                    type: ActionType.SHOW_JOB_LOGS_COMPUTED_BY_USER,
                    payload: payload
                })
                dispatch(loadingJobLogs(false));
            }
        }catch(err){
            dispatch(alert("Error occur while fetching computed logs by user."));
        }
    }
}
export const fetchJobsLogsComputedByCLI = (refUtteranceId: string, reference_answer_compute_route: string, reference_answer_job_id: string) => {
    return async (dispatch: Dispatch) => {
        try{
            const res: any = await getJobLogsCLI(refUtteranceId, reference_answer_compute_route, reference_answer_job_id);
            const payload= res.body.events;
            if (res.status == 200){
                dispatch({
                    type: ActionType.SHOW_JOB_LOGS_COMPUTED_BY_CLI,
                    payload: payload
                })
            }
        }catch(err){
            dispatch(alert("Error occur while fetching computed logs by cli."));
        }
    }
}
export const loadingJobLogs = (value: boolean) => {
    return {
        type: ActionType.UPDATE_TO_SHOW_JOBS_LOGS,
        payload: value
    }
}
export const loadingQuickLinkFocusAction = (value: boolean) => {
    return {
        type: ActionType.UPDATE_QUICK_LINK_FOCUS_LOADING,
        payload: value
    }
}
export const loadingQuickLinkSourceAction = (value: boolean) => {
    return {
        type: ActionType.UPDATE_QUICK_LINK_SOURCE_LOADING,
        payload: value
    }
}