import { alert } from "./customRouter";
import { Dispatch } from "redux";
import { ActionType } from "../action-types";
import { newTableRecordLoading } from "./AnswerActions";
import { AttachNodeSchemaPayload, createConfigEntity, createConfigNode, deleteIndividualNode, deleteNode, editIndividualNode, getAttachNodeSchema, getChildNodes, getEditAgentSchema, getEditIndividualSchema, getEditSchemaPayload, getFormSchema, getNewAgents, getNodeByOption, updateEditData } from "services/chatConfigurationServices";
import { AgentsList, Entity } from "types/chatConfigTypes";
import { RootState } from "state/reducers";
import { getChatCommands } from "./AgentActions";

const LIMIT = 10;
export const fetchAgentsAction = (page_no: number) => {
  return async (dispatch: Dispatch) => {
    try {
        const response = await getNewAgents(page_no, LIMIT);
        if (response && response.status === 200) {
          return {
            data: response.body.root_nodes,
            total_pages: response.body.total_pages
          }
        } else {
          dispatch(alert(`Please try to load agents later`));
        }
    } catch (error) {
      dispatch(alert(`Please try to load agents later!`));
    }
    return false
  };
};

export const fetchSettingsAgentsAction = (page_no: number) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    try {
        const state = getState().chatConfiguration.agents;
        newTableRecordLoading(true, dispatch);
        const response = await getNewAgents(page_no, LIMIT);
        if (response && response.status === 200) {
          const payload = response.body.root_nodes;
          if(payload.length){
            const uniqueNewAgents = payload.filter(newAgent =>
              !state.some((existingAgent: any) => existingAgent._id === newAgent._id)
            );
            dispatch(appendAgentListAction(uniqueNewAgents));
            dispatch({
              type: ActionType.SET_HAS_NEXT_PAGE_ROOT_NODE,
              payload: response.body.has_next_page,
            })
            dispatch({
              type: ActionType.SET_PAGE_NUMBER_ROOT_NODE,
              payload: response.body?.current_page+1,
            })
          }

          newTableRecordLoading(false, dispatch);
          return {
            data: response.body.root_nodes,
            total_pages: response.body.total_pages
          }
        } else {
          dispatch(alert(`Please try to load agents later`));
        }
    } catch (error) {
      dispatch(alert(`Please try to load agents later!`));
    }
    newTableRecordLoading(false, dispatch);
    return false
  };
};

export const appendAgentListAction = (payload: AgentsList[] = []) => {
  return {
    type: ActionType.UPDATE_AGENTS_LIST,
    payload: payload,
  }
}

export const fetchFocusAction = (page_no: number, parent_node_id: string, parent_node_type: string, root_node?: string) => {
  return async (dispatch: Dispatch) => {
    try {
        const response = await getChildNodes(page_no, LIMIT, parent_node_id, parent_node_type, root_node);
        if (response && response.status === 200) {
          const payload = response.body.child_nodes;
          return {data:payload, total_pages: response.body.total_pages};
        } else if(response.status === 400 || response.status === 500) {
          dispatch(alert(response.body.error));
        }
        return null;
    } catch (error) {
      dispatch(alert(`Please try to load agents later!`));
      return null;
    }
  };
};

export const fetchSourceAliasAction = (page_no: number, parent_node: string, parent_node_type: string, root_node?: string) => {
  return async (dispatch: Dispatch) => {
    try {
        const response = await getChildNodes(page_no, LIMIT, parent_node, parent_node_type, root_node);
        if (response && response.status === 200) {
          const payload = response.body.child_nodes;

          if (payload.length){
            // dispatch({
            //   type: ActionType.UPDATE_SOURCES_ALIAS_LIST,
            //   payload: payload,
            // });
            return {data:payload, total_pages: response.body.total_pages};
          }

        } else if(response.status === 400 || response.status === 500){
          dispatch(alert(response.body.error));
        }
        return null;
    } catch (error) {
      dispatch(alert(`Please try to load agents later!`));
      return null;
    }
  };
};

export const fetchFocusIntentsAction = (page_no: number, parent_node: string, parent_node_type: string, root_node?: string) => {
  return async (dispatch: Dispatch) => {
    try {
        const response = await getChildNodes(page_no, LIMIT, parent_node, parent_node_type, root_node);
        if (response && response.status === 200) {
          const payload = response.body.child_nodes;
          if (payload.length){
            // dispatch({
            //   type: ActionType.UPDATE_FOCUS_INTENTS_LIST,
            //   payload: payload,
            // });
            return {data:payload, total_pages: response.body.total_pages};
          }
        } else if(response.status === 400 || response.status === 500){
          dispatch(alert(response.body.error));
          return null;
        }
    } catch (error) {
      dispatch(alert(`Please try to load agents later!`));
    }
    return null;
  };
};

export const fetchSourcesAction = (page_no: number, parent_node: string, parent_node_type: string, root_node?: string) => {
  return async (dispatch: Dispatch) => {
    try {
        const response = await getChildNodes(page_no, LIMIT, parent_node, parent_node_type, root_node);
        if (response && response.status === 200) {
          const payload = response.body.child_nodes;

          if (payload.length){
            return {data:payload, total_pages: response.body.total_pages};
          }
          return null;
        } else if(response.status === 400 || response.status === 500){
          dispatch(alert(response.body.error));
        }
        return null;
    } catch (error) {
      dispatch(alert(`Please try to load agents later!`));
      return null;
    }
  };
};

export const setIsCreateOpenAction = (isCreated: boolean) => {
  return {
    type: ActionType.UPDATE_IS_CREATED_OPEN,
    payload: isCreated
  }
}
export const setActiveTab = (tab:string) => {
  return {
    type: ActionType.CHANGE_AFS_TAB,
    payload: tab
  }
}

export const createConfigFormSchemaAction = () => {
  return async (dispatch: Dispatch) => {
    try {
        dispatch(showLoadingAction(true));
        const response = await getFormSchema();
        if (response && response.status === 200) {
          const payload = response.body.form_schema;
          dispatch({
            type: ActionType.FETCH_FORM_SCHEMA_FOR_CHAT_CONFIG,
            payload: payload,
          })

          dispatch(showLoadingAction(false));
        } else {
          dispatch(showLoadingAction(false));
          dispatch(alert(response.body.message));
          dispatch(openEditFormAction({ node_id: '', node_type: '',parent_node:'',root_node:"" }))
        }
    } catch (error) {
      dispatch(showLoadingAction(false));
      dispatch(openEditFormAction({ node_id: '', node_type: '',parent_node:'',root_node:"" }))
      dispatch(alert(`Error to load form schema`));
    }
  };
};

export const showLoadingAction = (isLoading: boolean) => {
  return {
    type: ActionType.UPDATE_IS_LOADING,
    payload: isLoading
  }
}

export const submitConfigFormAction = (form_data: Entity) => {
  return async (dispatch: any) => {
    try {
        const response = await createConfigEntity(form_data);
        if (response && response.status === 200) {
          const payload = response.body.data;
          if(payload.node_type === "agent"){
            dispatch({
              type: ActionType.CREATE_NEW_AGENT_LIST_IN_FORM_FIELD,
              payload: payload,
            })
          }
          dispatch(setIsCreateOpenAction(false));
          dispatch(updateIsConfigChangeAction(true));
          dispatch(alert(response.body.message, {position: "bottom-left"}, 'success'));
          return true;
        } else if(response.status === 400 || response.status === 500) {
          dispatch(alert(response.body.message));
        }
        return false
    } catch (error) {
      dispatch(alert(`Error while submitting the form`));
      return false
    }
  };
};

export const openAttachFocusAction = (is_attach_focus: boolean) => {
  return {
    type: ActionType.UPDATE_IS_ATTACH_FOCUS,
    payload: is_attach_focus
  }
}
export const openAttachAction = (data: {root_node?: string,parent_node: string,parent_node_type: string, form_title?: string}) => {
  return {
    type: ActionType.OPEN_ATTACH_MENU,
    payload: data
  }
}
export const fetchAttachNodeSchemaAction = (params:AttachNodeSchemaPayload) => {
  return async (dispatch: Dispatch) => {
    try {
        dispatch(showLoadingAction(true));
        const response = await getAttachNodeSchema(params);
        if (response && response.status === 200) {
          const payload = response.body.form_schema;

          dispatch({
            type: ActionType.FETCH_ATTACH_NODE_SCHEMA,
            payload: payload,
          })

          dispatch(showLoadingAction(false));
        } else if(response.status === 400 || response.status === 500) {
          dispatch(showLoadingAction(false));
          dispatch(openAttachFocusAction(false));
          dispatch(alert(response.body.message));
          dispatch(openEditFormAction({ node_id: '', node_type: '',parent_node:'',root_node:"" }))
        } else {
          dispatch(showLoadingAction(false));
          dispatch(alert(response.body.message));
          dispatch(openEditFormAction({ node_id: '', node_type: '',parent_node:'',root_node:"" }))
        }
    } catch (error) {
      dispatch(showLoadingAction(false));
      dispatch(openEditFormAction({ node_id: '', node_type: '',parent_node:'',root_node:"" }))
      dispatch(alert(`Error to load attach focus form schema`));
    }
  };
}

export const submitAttachFormAction = (form_data: any) => {
  return async (dispatch: any) => {
    try {
        const response = await createConfigNode(form_data);
        if (response && response.status === 200 || response.status === 201) {
          dispatch({
            type: ActionType.UPDATE_TREE,
          })
          dispatch(updateIsConfigChangeAction(true));
          dispatch(openAttachFocusAction(false));
          dispatch(alert(response.body.message, {position: "bottom-left"}, 'success'));
          return true;
        } else if(response.status === 400 || response.status === 500) {
          dispatch(alert(response.body.error));
        }
    } catch (error) {
      dispatch(alert(`Error while submitting the attach form`));
    }
    return false;
  };
}

export const fetchAgentEditSchemaAction = (params:getEditSchemaPayload) => {
  return async (dispatch: Dispatch) => {
    try {
        dispatch(showLoadingAction(true));
        let response;
        if(params.root_node){
          response = await getEditAgentSchema(params);
        }else{
          response = await getEditIndividualSchema({
            node_id: params.node_id,
            node_type: params.node_type,
          });
        }
        if (response && response.status === 200) {
          const payload = response.body.form_schema;

          dispatch({
            type: ActionType.FETCH_EDIT_AGENT_SCHEMA,
            payload: payload,
          })

          dispatch(showLoadingAction(false));
        } else {
          dispatch(showLoadingAction(false));
          dispatch(openEditFormAction({ node_id: '', node_type: '',parent_node:'',root_node:"" }))
          dispatch(alert(response.body.message));
        }
    } catch (error) {
      dispatch(showLoadingAction(false));
      dispatch(openEditFormAction({ node_id: '', node_type: '',parent_node:'',root_node:"" }))
      dispatch(alert(`Error to load attach focus form schema`));
    }
  };
}

export const updateGrid = (name:string,data:undefined|{success:boolean|string}) => {
  return {
    type: ActionType.UPDATE_GRID,
    payload: {name, data}
  }
}

export const openEditFormAction = (edit_form_data: getEditSchemaPayload) => {
  return {
    type: ActionType.UPDATE_IS_EDIT_FORM_OPEN,
    payload: edit_form_data
  }
}

export const submitEditFormAction = (form_data: any) => {
  return async (dispatch: any) => {
    try {
        let response;
        if(form_data.root_node){
          response = await updateEditData(form_data);
        }else{
          response = await editIndividualNode({
            node_id:form_data.node_id,
            node_type:form_data.node_type,
            payload: form_data.payload,
          });
        }
        if (response && response.status === 200) {
          const payload = response.body;

          dispatch({
            type: ActionType.FETCH_EDIT_AGENT_SCHEMA,
            payload: payload,
          })
          dispatch(updateIsConfigChangeAction(true));
          return true;
        } else if(response.status === 400 || response.status === 500) {
          dispatch(alert(response.body.message));
        } else {
          dispatch(alert(response.body.message));
        }
    } catch (error) {
      dispatch(alert(`Error while submitting the edit form data.`));
    }
    return false;
  };
}

export const deleteNodeAction = (form_data: {node_id: string, node_type: string, root_node_id: string, parent_node:string}) => {
  return async (dispatch: any) => {
    try {
        let response;
        if(form_data.root_node_id){
          response = await deleteNode(form_data);
        } else {
          response = await deleteIndividualNode({
            node_id: form_data.node_id,
            node_type: form_data.node_type,
          });
        }
        if (response && response.status === 200) {
          dispatch(updateIsConfigChangeAction(true));
          dispatch(alert(response.body.message, {position: "bottom-left"}, 'success'));
        } else {
          dispatch(alert(response.body.message));
        }
        return true;
    } catch (error) {
      dispatch(alert(`Error while submitting the edit form data.`));
      return false;
    }
  };
}

export const updateIsConfigChangeAction = (is_config_changed: boolean) => {
  return {
    type: ActionType.UPDATE_IS_CONFIG_CHANGE,
    payload: is_config_changed
  }
}


export const refetchChatConfiguration = () => {
  return async (dispatch: Dispatch) => {
    dispatch(appendAgentListAction([]));
    dispatch({
      type: ActionType.REFETCH_CHAT_CONFIG,
      payload: []
    });
    dispatch(fetchAgentsAction(1) as any);
    dispatch({
      type: ActionType.SET_PAGE_NUMBER,
      payload: 1
    });
    dispatch(getChatCommands(true)as any);
    dispatch(updateIsConfigChangeAction(false));
  }}

export const refetchChatConfigLoaderAction = (is_loading:boolean) => {
  return {
    type: ActionType.REFETCH_CHAT_CONFIG_LOADER,
    payload: is_loading
  }
}
export const fetchByOption = (page_no: number, filter_type:string) => {
  return async (dispatch: Dispatch) => {
    try {
        const response = await getNodeByOption(filter_type, page_no);
        if (response && response.status === 200) {
          const payload = response.body.options;
          if (payload.length){
            return {data:payload, total_pages: response.body.total_pages};
          }
          return null;
        } else if(response.status === 400 || response.status === 500){
          dispatch(alert(response.body.error));
        }
        return null;
    } catch (error) {
      dispatch(alert(`Please try to load agents later!`));
      return null;
    }
  };
};
