import React from "react";
import styled from "styled-components";
import LoadingPiano from "components/common/LoadingPiano";

import humaLogo from "assets/general/powered-by-huma-logo-light.svg";

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #ffffff;
`;

export const HumaLogo = styled.img`
  position: fixed;
  bottom: 25px;
  right: 30px;
  width: 130px;
  height: 40px;
`;

export default function AppLoading() {
  return (
    <Container>
      <LoadingPiano keys={10} />
      <HumaLogo
        src={humaLogo}
        alt="powered by huma"
      />
    </Container>
  );
}