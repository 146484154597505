import { ActionType } from '../action-types'
import { subscriptionHistory } from 'types/subscriptonHistory';

export const initialState: subscriptionHistory = {
    data: [],
    subscriptionListIndex: -1,
    loading: false,
    subscribeButtonLoading:false,
    subscribeQuestionChartsPayload:[],
    isSubscribed:false,
    unSubscribeQuestionIndex:undefined,
    subscribedChartLoading:false,
    subscribedQuestionDates:[],
    subscribeCalenderLoading:false,
    calendarOpen:undefined,
    selectedCurrentMonth: undefined,
    chartLoading:false,
    currentSelectedQuestionTimestamp:undefined,
    compareQuestion:false,
    searchValue: "",
    isSubscriptionResponse: false,
    isSubscriptionUpdated:false,
    setSortKey : "3",
    questionLeftSideVisual: {
    type: "none",
    is_elastic_indexed:false
    },
    questionRightSideVisual: {
    type:"none",
    is_elastic_indexed:false
    },
    setIndex:-1,
    questionLeftSideLoading:false,
    questionRightSideLoading:false,
    compareQuestionDates:{
    leftQuestionDate: undefined,
    rightQuestionDate: undefined,
    },
    compareQuestionSelectedSnapshot:{
        leftQuestionSnapshot:undefined,
        rightQuestionSnapshot:undefined
    },
    errorSubscriptionSearch:false


}

const reducer = (state: subscriptionHistory = initialState, action: any): subscriptionHistory => {
    switch (action.type) {
      case ActionType.UPDATE_SORT_VALUE:
        return { ...state, setSortKey: action.payload };
      case ActionType.UPDATE_SEARCH_VALUE:
        return { ...state, searchValue: action.payload };
      case ActionType.UPDATE_SUBSCRIPTION_DATA:
        return { ...state, data: action.payload.data };
      case ActionType.DISPLAY_SUBSCRIBED_QUESTIONS:
        return { ...state, isSubscriptionResponse: action.payload };
      case ActionType.UPDATE_SUBSRIPTION_HISTORY:
        return { ...state, data: state.data.concat([action.payload]) };
      case ActionType.SUBSCRIPTION_HISTORY:
        return { ...state, data: action.payload.data };
      case ActionType.SET_LOADING_SUBSCRIPTION_LIST:
        return { ...state, loading: action.payload };
      case ActionType.SET_SUBSCRIBE_BUTTON_LOADING:
        return { ...state, subscribeButtonLoading: action.payload };
      case ActionType.SET_SUBSCRIBE_QUESTION_CHARTS:
        return { ...state, subscribeQuestionChartsPayload: action.payload };
      case ActionType.SET_ISSUBSCRIBE:
        return { ...state, isSubscribed: action.payload };
        case ActionType.TOGGLE_SUBSCRIPTION_UPDATE_BUTTON:
          return { ...state, isSubscriptionUpdated:action.payload }
      case ActionType.UNSUBSCRIBE_QUESTION_INDEX:
        return { ...state, unSubscribeQuestionIndex: action.payload };
      case ActionType.SET_DASHBOARD_QUESTION_CHART_LOADING:
        return { ...state, subscribedChartLoading: action.payload };
      case ActionType.SET_SELECTED_MONTH_CHANGED_QUESTION_DATES:
        return { ...state, subscribedQuestionDates: action.payload };
      case ActionType.SET_CALENDAR_LOADING:
        return { ...state, subscribeCalenderLoading: action.payload };
      case ActionType.SHOW_CALENDAR:
        return { ...state, calendarOpen: action.payload };
      case ActionType.CURRENT_SELECTED_MONTH:
        return { ...state, selectedCurrentMonth: action.payload };
      case ActionType.CHART_LOADING:
        return { ...state, chartLoading: action.payload };
      case ActionType.CURRENT_QUESTIION_TIMESTAMP:
        return { ...state, currentSelectedQuestionTimestamp: action.payload };
      case ActionType.COMPARE_QUESTIONS:
        return { ...state, compareQuestion: action.payload };
      case ActionType.LEFT_QUESTION_VISUAL:
        return { ...state, questionLeftSideVisual: action.payload };
      case ActionType.RIGHT_QUESTION_VISUAL:
        return { ...state, questionRightSideVisual: action.payload };
      case ActionType.LEFT_QUESTION_LOADING:
        return { ...state, questionLeftSideLoading: action.payload };
      case ActionType.RIGHT_QUESTION_LOADING:
        return { ...state, questionRightSideLoading: action.payload };
      case ActionType.COMPARE_QUESTION_DATE:
        return { ...state, compareQuestionDates: action.payload };
      case ActionType.COMPARE_QUESTION_SNAPSHOTS:
        return { ...state, compareQuestionSelectedSnapshot: action.payload };
      case ActionType.UPDATE_SUBSCRIPTION_LIST:
        return { ...state, data: action.payload };
      case ActionType.USER_SUBSCIPTION_LIST_INDEX:
        return { ...state, subscriptionListIndex: action.payload };
      case ActionType.SET_QUESTION_INDEX:
        return { ...state, setIndex: action.payload };
      case ActionType.UPDATE_USER_HISTORY_PDF_STATUS_BY_UTTERANCE_ID:
        const { pdf_status, utterance_id } = action.payload;
        return {
          ...state, data: state.data.map((utterance) => {
            if (utterance._id === utterance_id) {
              utterance.pdf_status = pdf_status
            }
            return utterance;
          })
        }
      case ActionType.UPDATE_CHART_OPTION_SUBSCRIPTION_HISTORY:
        const { index, chart_options } = action.payload;
        const new_update_data = state.data[index];
        if (new_update_data && new_update_data.visual && chart_options) {
          (new_update_data.visual as any).chart_options = chart_options;
        }
        return { ...state , data: [ ...state.data.slice(0,index), new_update_data, ...state.data.slice(index+1) ] }

      case ActionType.UPDATE_USER_SUBSCRIPTION_VISUAL_BY_UTTERANCE_ID:
        return { ...state , data: state.data.map((utterance) => {
            if(utterance._id === action.payload.subscribed_id) {
                utterance.visual = action.payload.visual
                utterance.subscribed = action.payload.subscribed
                utterance.subscribed_id = action.payload.subscribed_id
                utterance.is_starred = action.payload.is_starred
            }
            return utterance;
        }) }
      case ActionType.ERROR_SUBSCRIPTION_SEARCH:
        return {...state,errorSubscriptionSearch:action.payload}
      default:
        return state;
    }
}

export default reducer;