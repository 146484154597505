import {delete_req, get, post, Response} from "makeRequest";
import {
    compileFilePayload,
    fetchFoldersResponse,
    filesWithContent,
    compiledFileResponse,
    createTemplateResponse,
    saveFileResponse,
    uploadDocxFileResponse,
    versions,
    config_nodes,
    node_intent,
    saveFilePayload,
    template,
    draftTemplateResponse
} from "types/reportConfig";

export function getConfigVersionsApi(page?:number, limit?: number): Response<versions> {
    return get(`/get-config-versions?page=${page}&limit=${limit}`);
}
export function getConfigNodesApi(config_id:string): Response<config_nodes> {
    return get(`/get-config-nodes?config_id=`+config_id);
}
export function getNodeIntentsApi(config_id:string,node:string): Response<node_intent> {
    return get(`/get-node-intents?config_id=`+config_id+`&node=`+node);
}
export function getDraftTemplates(intent:string,template_id?:string): Response<{intent_templates:Array<template>}> {
    return get('/get-draft-template?intent='+intent+ '&template_id=' + template_id);
}
export function createTemplate(body: {template_name: string}): Response<createTemplateResponse> {
    return post(`/create-template`, body);
}
export function renameTemplate(body: {template_id: string; template_name: string}): Response<{message: string}> {
    return post(`/rename-template`, body);
}
export function deleteTemplate(body:{template_id: string}): Response<{message: string}> {
    return delete_req(`/delete-template`, body);
}
export function fetchFolders(template_id: string): Response<fetchFoldersResponse> {
    return get(`/get-template?template_id=` + template_id);
}
export function fetchFile(file_id: string): Response<filesWithContent> {
    return get(`/get-template-file?file_id=` + file_id);
}
export function compileFileOutput(body: compileFilePayload): Response<compiledFileResponse> {
    return post(`/compile-files`, body);
}
export function saveFiles(body: saveFilePayload): Response<saveFileResponse> {
    return post(`/save-compiled-files`, body);
}
export function draftIntentTemplate(body: saveFilePayload): Response<draftTemplateResponse> {
    return post(`/draft-template`, body);
}
export function uploadDocxReport(file_id:string,file: File): Response<uploadDocxFileResponse> {
    const payload = new FormData()
    payload.append('file',file)
    return post(`/upload-file?file_id=`+file_id, payload);
}
export function uploadNewFolderFile(body: FormData, file_type:string,file_name:string,folder_name:string,folder_type:string,template_id:string): Response<uploadDocxFileResponse> {
    return post('/upload-new-file?file_type='+file_type+"&file_name="+file_name+"&folder_name="+folder_name+"&folder_type="+folder_type+"&require_metadata="+true+"&template_id="+template_id, body);
}

export const fetchUpConfig = (selectedId: string): Response<{up_config: versions, error: string}> => {
    return get(`/get-up-config?config_id=${selectedId}`);
}