export enum Colors {
  DARK_BLUE = "#1B1C31",
  WHITE = "#FFFFFF",
  AMOUR_WHITE = "#ECECEC",
  BLACK = "#000000",
  BLACK_SHADE = "#0a0a0a",
  COARSE_WOOL = "#343541",
  DARK_CHARCOAL = "#323232",
  BLACK_GRAY = "#262631",
  LIGHT_BLUE = "#799BF1",
  SNOWFLAKE = "#F0F0F0",
  SHARK_FIN = "#969696",
  GAINSBORO = "#E1E1E1",
  MORE_THAN_A_WEEK = "#8C8C8C",
  ELEMENTAL_GREY = "#A0A0A0",
  PUNCH_OUT_GLOVE = "#6690FC", // PUNCH OUT GLOVE
  IN_PROGESS_BG = "#ECFFDC",
  PROGESSED_BG = "#ECF3FE",
  BLUE_OYSTER_CULT = "#4C79EC",
  RADICAL_RED = "#FF3361", //RADICAL RED
  AZUL = "#2058E8", //AZUL
  TRANSPARENT = "transparent",
  HIGHLIGHT = "#ffcf44",
  TABLEGRAY = "#363640",
  TEXT_GRAY = "#787878",
  DISABLED_GRAY = "#C8C8C8",
  TABLE_BLACK = "#505050",
  LINK_TEXT = "#8AB4F8",
  LEGACY_BLUE = "#2058e8",
  CHAT_LIST_BOX_SHADOW_COLOR = "#c2e8ff",
  SORCE_LIST_BORDER_BLUE = "#D7DEE9",
  CHAT_AVATAR_BORDER_COLOR = "#bbd6ff",

  // DARK THEME NEW DESIGN COLORS
  CINDER = "#171717",
  SHIP_GREY = "323446",
  DARK_JUNGLE_GREEN = "#212121",
  VAMPIRE_GREY = "#585858",
  CHARCOAL_GREY = "#404040",
  BRIGHT_BLUE = "#0d74f6",
  BLACK_EEL_GREY = "#434343",
  ASHY_BlACK = "#373737",
  AGENTS_BORDER_COLOR = '#FFFFFF12',
  BLUE_CHALK = '#FFFFFF4D',
  PAGE_TAB_SHADOW_COLOR = '#00000040',
  PAGE_TAB_Border_COLOR = '#FFFFFF4D',
  CHAT_SIDEBAR_HEADER_BORDER_COLOR = '#FFFFFF21',
  DARK_GREY_NEW = "#272727",
  CHARCOAL_SHIP_GREY = "#3F3F3F",
  BALTIC_SEA = "#292929",
  CHAT_LIST_DARK_BOX_SHADOW_COLOR = "#292929",
  PLACEHOLDER_COLOR = "#727272",
  OPTION_LIST_TEXT_COLOR = "#2C2C2C",
  OPTION_LIST_DARK_TEXT_COLOR = "#ECECEC",
  DEBUG_BTN_COLOR = "#B4B4B4",
  GREY_WHITE = "#727272",
  SILVER_CHALICE = "#9D9D9D",
  GRAY = "#D1D1D1",
    // LIGHT THEME  NEW DESIGN COLORS
    SEASHELL_BLUE = '#eaf3ff',
    LIGHT_SEASHELL_BLUE = '#E7EFFA',
    ALICE_BLUE = '#F0F5FF',
    BABY_BLUE = '#A4C7F8',
    AQUA_BLUE = '#BAD5FA',
    TROPICAL_BLUE = '#cfdcef',
    COMET = '#727272',
    PALE_BLUE = '#C2E7FF',
    CORAL_BLUE = '#AFE0FF',
    CHALK_BLUE = '#E2EAFF',
    LIGHT_GREY = '#DEDEDE',
    LIGHT_ICE_BLUE = "#BAE3FF",
    AGENTS_LIGHT_BORDER_COLOR = '#D7DEE9',
    PAGE_TAB_SHADOW_LIGHT_COLOR = '#00000072',
    ASH_GREY = "#e5e5e5",
    LAVENDER = "#EFF3FD",
    SILVER = "#BCBCBC",
    CHAT_INPUT_BORDER_COLOR = "#0000001C",
    PARAMETER_DETAIL_TEXT_COLOR = "#2C2C2C",
    PARAMETER_DETAIL_SPAN_COLOR = "#727272",
    PARAMETER_DETAIL_BORDER_COLOR = "#00000012",
    LIGHT_BLACK = "#383838",
    BORDER_LIGHT_GREY = "#D9D9D9",
    BLUE = "#1D51D5",

    // COMMON COLORS
    GREY = "#727272",


  // AttachedV2 Suggestions colors for light mode
  LavenderMist = "#E9EAEF",

  // AttachedV2 Suggestions colors for dark mode
  DavyGrey = "#5C5C5C",
}